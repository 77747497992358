import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Field, SelectTag } from 'components';
import { useFilter } from 'components/engine/hooks';
import { configFilters } from 'config/filters';

const Languages = ( { network, onChange, disabled, max }) => {
  const filterTarget = 'languages';
  const intl = useIntl();
  const value = useFilter(filterTarget, network);
  const { datas } = configFilters[filterTarget];

  const handleChange = useCallback(
    ({ item, selected }) => onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      network,
    }),
    [onChange, network]
  );

  const getData = useMemo(
    () => datas.map(data => {
      const translatedLabel = intl.formatDisplayName(data, {type: 'language'});
      return {
        id: data,
        value: data,
        label: translatedLabel
          ? translatedLabel.charAt(0).toUpperCase() + translatedLabel.slice(1)
          : data,
      };
    }),
    [datas, intl]
  );

  return (
    <Field label={intl.formatMessage({ id: 'languages.label'})}>
      <SelectTag
        id={`filter-${network}`}
        hasSearch
        selected={value}
        dataset={getData}
        maxTags={max}
        defaultOptionLabel={intl.formatMessage(
          { id: 'languages.filterBy'},
          { max }
        )}
        onChange={handleChange}
        disabled={disabled}
      />
    </Field>
  )
};

Languages.displayName = 'Languages';

Languages.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Languages.defaultProps = {
  disabled: false,
};

export default memo(Languages);
