import { FormatNumberOptions, useIntl } from 'react-intl';

import { useSelector } from 'utils/redux';
import numberFormatter from 'config/number';
import currencies, { BASE_CURRENCY_BACKEND, Currency } from 'config/currencies';

import useCurrency from './useCurrency';

type NumberFormatHook = () => (
  value: number | string | null,
  options?: Partial<
    Omit<FormatNumberOptions, 'currency'> & {
      sign: boolean;
      percentage: boolean;
      currency: boolean | string;
      displayCurrency: boolean | string;
      hideCurrencySymbol: boolean;
      showNaNAs: string;
      compact: boolean;
      unit: string;
      threeDigitsRule: boolean;
      fromCurrency: Currency;
    }
  >,
) => string;

/**
 * Same params as useNumberFormat but uses react-intl under the hood
 * @returns a formated number
 */
const useNumberFormat: NumberFormatHook = () => {
  const intl = useIntl();
  const { convert, currentCurrency: currencyKey } = useCurrency();

  return (
    value,
    {
      sign = false,
      percentage = false,
      currency = false,
      displayCurrency = false,
      hideCurrencySymbol = false,
      showNaNAs = undefined,
      compact = true,
      unit = undefined,
      threeDigitsRule = false,
      fromCurrency = undefined,
      ...options
    } = {},
  ) => {
    if (
      value === '' ||
      value === null ||
      value === undefined ||
      Number.isNaN(value) ||
      Number.isNaN(Number(value))
    )
      return showNaNAs || '-';

    let style;
    let displayValue = Number(value);

    const currencyToUse =
      typeof currency === 'string' ? (currency as Currency) : currencyKey;

    const symbolAlwaysAtStart =
      currencies.find((curr) => curr.value === currencyToUse)
        ?.symbolAlwaysAtStart || false;

    const maximumFractionDigits =
      currency && displayValue > 5 && displayValue < 100 ? 0 : 2;

    if (percentage) {
      style = 'percent';
      displayValue /= 100;
    } else if (currency) {
      style = 'currency';
      displayValue =
        convert(displayValue, {
          fromCurrency: fromCurrency || BASE_CURRENCY_BACKEND,
          toCurrency: currencyToUse,
        }) || 0;
    } else if (displayCurrency) {
      style = 'currency';
    } else {
      style = 'decimal';
    }

    if (!percentage) {
      displayValue = Number(displayValue.toFixed(2));
    }

    let localizedNumber = intl
      .formatNumber(displayValue, {
        currency: displayCurrency
          ? currencyKey
          : currency
          ? currencyToUse
          : undefined,
        currencyDisplay: hideCurrencySymbol ? 'code' : 'narrowSymbol',
        signDisplay: sign ? 'always' : 'auto',
        style,
        notation: compact ? 'compact' : 'standard',
        unit,
        minimumFractionDigits: 0,
        maximumFractionDigits,
        trailingZeroDisplay: 'stripIfInteger',
        ...(threeDigitsRule && Math.abs(displayValue) >= 0.01
          ? { maximumSignificantDigits: 3 }
          : null),
        ...options,
      })
      .toUpperCase();

    if (hideCurrencySymbol) {
      localizedNumber = localizedNumber.replace(currencyToUse, '');
    }

    if (symbolAlwaysAtStart) {
      localizedNumber = localizedNumber.replace(
        /^([0-9.,]+(\s?\S+)?)(\s?)([$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6])$/,
        '$4$3$1',
      );
    } else {
      localizedNumber = localizedNumber.replace(
        /^([$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6])(\s?)([0-9.,]+(\s?\S+)?)$/,
        '$3$2$1',
      );
    }

    // strip trailing zeroes
    if (compact) {
      localizedNumber = localizedNumber.replace(/[.,]00/, '');
    }

    return localizedNumber;
  };
};

export const useThousandSeparator = () => {
  const envLocale = useSelector(({ env }) => env.locale);
  return {
    separator: numberFormatter?.[envLocale]?.separator,
  };
};

export default useNumberFormat;
