import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Icon, Checkbox2, Spaces } from 'kolkit';

import { useFilter } from 'components/engine/hooks';

import styles from './ExcludeFavorite.module.scss';


const ExcludeFavorite = ({ network, onChange }) => {
  const filterTarget = 'excludeFavorite';
  const intl = useIntl();
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(() => {
    onChange({
      field: filterTarget,
      value: !value,
      selected: !value,
      network
    });
  }, [network, onChange, value]);

  return (
    <>
      <div className={styles.container}>
        <Checkbox2
          id={filterTarget}
          checked={value}
          onChange={handleChange}
          label= {
            <div className={styles.label}>
              <span className={styles.title}>{intl.formatMessage({ id: 'excludeFavorites.label' })}</span>
              <Icon
                tooltip={intl.formatMessage({ id: 'excludeFavorites.description' })}
                label="info-circle"
                size="small"
              />
            </div>
          }
        />
      </div>
      <Spaces bottom={8} />
    </>
  )
};

ExcludeFavorite.displayName = 'ExcludeFavorite';

ExcludeFavorite.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ExcludeFavorite;
