import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { Button3, Icon } from 'kolkit';

import { useSelector, useDispatch } from 'utils/redux';
import { addProfileToFavorite, removeProfileFromFavorite } from 'actions/favorites';


const ButtonAddProfileToFavorites = ({ profileId, withLabel, from, iconSize }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [profile] = useSelector(({ profiles }) => [
    profiles[profileId],
  ]);

  const isFavorite = useMemo(
    () => profile?.core?.isFavorite,
    [profile]
  );

  const toggleAddToFavorites = useCallback(
    () => isFavorite
      ? dispatch(removeProfileFromFavorite({ id: profile.id, from }))
      : dispatch(addProfileToFavorite({ id: profile.id, from })),
    [profile, isFavorite, from, dispatch]
  );

  if (withLabel) {
    return (
      <Button3
        theme="secondary"
        size="small"
        icon="star"
        iconTheme={isFavorite ? 'solid' : 'thin'}
        onClick={toggleAddToFavorites}
      >
        <FormattedMessage id={`favorite.cta.${isFavorite ? 'remove' : 'add'}`} />
      </Button3>
    );
  }

  return (
    <Icon
      theme={isFavorite ? 'solid' : 'thin'}
      label="star"
      onClick={toggleAddToFavorites}
      isButton
      size={iconSize}
      fill={isFavorite ? '#00ccff' : undefined}
      tooltip={intl.formatMessage({id: `favorite.cta.${isFavorite ? 'remove' : 'add'}`})}
    />
  );
};

export default memo(ButtonAddProfileToFavorites);
