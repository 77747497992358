import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Item } from 'kolkit';


import styles from './ItemModal.module.scss';

const ItemModal = ({ id, onSelect, onDelete, selected, query, name, className }) => {
  const cnBar = cn(styles.bar, className, {
    [styles.selected]: selected
  });

  const handleSelect = useCallback(
    () => onSelect({ id, name, query }),
    [onSelect, id, name, query]
  );

  const handleDelete = useCallback(
    () => onDelete(id, name),
    [id, name, onDelete]
  );

  return (
    <div className={styles.item}>
      <div role="button" className={cnBar} onClick={handleSelect}>
        <Item cla={styles.name}>
          {name}
        </Item>
      </div>
      <div>
        <Icon
          label="trash-alt"
          onClick={handleDelete}
          isButton
        />
      </div>
    </div>
  );
};

ItemModal.propTypes = {
  id: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

ItemModal.defaultProps = {
  onDelete: () => {},
  onSelect: () => {},
  selected: false,
}

export default memo(ItemModal);
