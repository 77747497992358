import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Text, TruncateText } from 'kolkit';
import styles from './MultiValueSelectionItem.module.scss';

const MultiValueSelectionItem = ({ value, onRemove, disabled, children, isFull }) => {
  const handleRemove = useCallback(
    () => {
      onRemove(value)
    },
    [value, onRemove]
  );

  const cnContent = cn(styles.content, {
    [styles.full]: isFull,
  });

  return (
    <div className={styles.container}>
      { !disabled && (
        <Icon
          label="times"
          onClick={handleRemove}
          className={styles.remove}
          size="small"
          isButton
        />
      )}
      <Text
        className={cnContent}
        size="small"
        tag="span"
        resetMargin
      >
        <TruncateText>{children}</TruncateText>
      </Text>
    </div>
  )
}

MultiValueSelectionItem.defaultProps = {
  disabled: false,
  isFull: true,
};

MultiValueSelectionItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isFull: PropTypes.bool,
};

export default MultiValueSelectionItem;
