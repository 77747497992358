import PropTypes from 'prop-types';

export const ActionButtonTypes = Object.freeze({
  ADD_TO_CAMPAIGNS: 'ADD_TO_CAMPAIGNS',
  ADD_TO_FAVORITES: 'ADD_TO_FAVORITES',
  APPLY_TAGS: 'APPLY_TAGS',
  ASK_INSTA_SHARING: 'ASK_INSTA_SHARING',
  REMOVE_FROM_CAMPAIGN: 'REMOVE_FROM_CAMPAIGN',
  REMOVE_PROMO_CODE: 'REMOVE_PROMO_CODE',
  EXPIRE_TRACKING_LINKS: 'EXPIRE_TRACKING_LINKS',
  SET_STATUS: 'SET_STATUS',
  SEND_MESSAGE: 'SEND_MESSAGE',
  ADD_TO_LIST: 'ADD_TO_LIST',
  MERGE_PROFILES: 'MERGE_PROFILES',
  SET_MEDIA_COUNT: 'SET_MEDIA_COUNT',
});

export const ActionButtonsPropTypes = {
  selectedProfiles: PropTypes.arrayOf(PropTypes.number),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(Object.keys(ActionButtonTypes)),
      actionType: PropTypes.string,
      analyticsLabel: PropTypes.string,
    }),
  ),
  onActionDone: PropTypes.func,
};
