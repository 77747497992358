import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox2 } from 'kolkit';

import { useActiveFilter } from './useActiveFilter';
import styles from './ActiveFilter.module.scss';


const ActiveFilter = ({ network, onChange }) => {
  const {checked, handleChange} = useActiveFilter(network, onChange);

  return (
    <Checkbox2
      id={network}
      checked={checked}
      onChange={handleChange}
      className={styles.checkbox}
    />
  );
};

ActiveFilter.displayName = 'ActiveFilter';

ActiveFilter.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(ActiveFilter);
