import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';

import { Field, SelectPercent } from 'components';
import { configFilters } from 'config/filters';
import { useFilter } from 'components/engine/hooks';

import styles from '../engineModule.module.scss';


const Interest = ({ network, onChange, disabled }) => {
  const filterTarget = 'communityInterests';
  const intl = useIntl();
  const { datas, params } = configFilters[filterTarget];
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(
    ({ item, selected }) => onChange && onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      network,
    }),
    [onChange, filterTarget, network]
  );

  const getData = useMemo(
    () => datas.map(
      item => ({
        id: item,
        label: intl.formatMessage({ id: `interests.list.${item}`}),
        value: item,
      })),
    [datas, intl]
  );

  return (
    <Field label={intl.formatMessage(
      { id: "audienceInterests.label"},
      { count: params.max}
    )}>
      <SelectPercent
        className={styles.select}
        selected={value}
        dataset={getData}
        sort
        label={intl.formatMessage(
          {id: "interests.filterBy"},
          {max: params.max}
        )}
        onChange={handleChange}
        disabled={disabled}
        max={params.max}
        noMatchPlaceholder={intl.formatMessage({ id: "interests.noMatching"})}
      />
    </Field>
  )
};

Interest.displayName = 'Interest';

Interest.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Interest.defaultProps = {
  disabled: false,
};

export default Interest;
