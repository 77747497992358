import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { Loader, Text } from 'kolkit';

import { update } from 'actions/profileDetails';
import { useSelector, useDispatch } from 'utils/redux';

import SearchFooter from 'components/engine/SearchFooter';

import Card from '../profile/Card';

const SearchResults = () => {
	const [engineResults, engineSortBy, engineSelectedResults, loading, hasSearch] = useSelector(
		({ engine }) => [
			engine.results,
			engine.sortBy,
			engine.selectedResults,
			engine.results.loading,
			engine.results.hasSearch,
		]
	);

	return (
		<div className="engine-search-results">
			<div className="engine-search-results-grid">
				{loading && <Loader fullScreen background="rgba(250, 250, 250, 0.8)" />}
				{hasSearch
					? (
						<ListResults
							engineResults={engineResults}
							engineSortBy={engineSortBy}
							engineSelectedResults={engineSelectedResults}
						/>
					)
					: <WelcomePlaceholder />}
			</div>
			<SearchFooter />
		</div>
	);
};

const ListResults = memo(({ engineResults, engineSelectedResults }) => {
	const { searchType, searchquery } = useSelector(({ engine }) => ({
		searchType: engine?.type,
		searchquery: engine?.filters.search,
	}));

	const dispatch = useDispatch();

	const onClickProfile = useCallback(
		() => {
			if (searchType !== '' && searchquery?.length) {
				dispatch(update({ currentTab: 'content' }));
			}
		},
		[searchType, searchquery, dispatch]
	);

	const getSearchIndex = useCallback(
		i => (engineResults.page * engineResults.resultsPerPage) + i + 1,
		[engineResults]
	);

	if (!engineResults || engineResults.total === null || !engineResults.rows) return null;

	if (engineResults.total === 0) return <NoResultPlaceholder />;

	return engineResults.rows.map((profile, i) => (
		<Card
			key={profile.id}
			id={profile.id}
			className="result-item"
			searchIndex={getSearchIndex(i)}
			onClickProfile={onClickProfile}
			onClickArgs={i}
			matchingSnas={profile.matchSnas}
			isChecked={engineSelectedResults.includes(profile.id)}
		/>
	));
});

const NoResultPlaceholder = memo(() => {
	return (
		<div className="engine-search__placeholder">
			<div className="engine-search__placeholder-body">
				<FormattedMessage
					id="engine.results.noResults"
					defaultMessage="There are no results for your search"
				/>
			</div>
			<div className="engine-search__placeholder-image">
				<img src="/imgs/engine/no-result.png" alt="" />
			</div>
		</div>
	)
});

const WelcomePlaceholder = memo(() => {
	return (
		<div className="engine-search__placeholder">
			<div className="engine-search__placeholder-body">
				<Text>
					<FormattedMessage
						id="engine.results.placeholder"
						defaultMessage="Search for your KOLs"
					/>
				</Text>
			</div>
			<div className="engine-search__placeholder-image">
				<img src="/imgs/engine/welcome.png" alt="" />
			</div>
		</div>
	)
});

export default memo(SearchResults);
