import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button3, Icon } from 'kolkit';
import { useIntl } from 'react-intl-phraseapp';

import useModal from 'utils/hooks/useModal';

const AddToList = ({ profileIds, noLabel, onClick, buttonProps, iconProps, from, icon, dataId }) => {
  const intl = useIntl();

  const { on } = useModal('manage_lists');

  const getIds = useMemo(
    () => (typeof ids === 'string' || typeof ids === 'number') ? [profileIds] : profileIds,
    [profileIds]
  );

  const handleClick = useCallback(
    () => {
      on({ id: getIds, from });
      onClick()
    },
    [getIds, onClick, on, from]
  );

  const title = useMemo(
    () => intl.formatMessage({ id: 'lists.cta.addTo' }),
    [intl],
  );

  return (
    <>
      {!icon && (
        <Button3
          onClick={handleClick}
          icon="list"
          theme="tertiaryReverse"
          tooltip={noLabel && title}
          dataId={dataId}
          {...buttonProps}
        >
          {!noLabel && title}
        </Button3>
      )}
      {icon && (
        <Icon
          label="list"
          onClick={handleClick}
          tooltip={title}
          isButton
          dataId={dataId}
          {...iconProps}
        />
      )}
    </>
  )
};

AddToList.propTypes = {
  profileIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  noLabel: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.bool,
}

AddToList.defaultProps = {
  noLabel: false,
  onClick: () => {},
  icon: false,
};

export default memo(AddToList);
