import React, { Children } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { INSTAGRAM } from 'constants/networks';
import { Community } from '../Modules';

type Props = {
  type: 'reels' | 'stories';
  hint?: string;
  onChange: (data: any) => void;
  className?: string;
};

const FIELDS = [
  {
    key: 'EstimatedReach',
    phrase: 'estimatedReach'
  },
  {
    key: 'Plays',
    phrase: 'volumePlays'
  },
  {
    key: 'Likes',
    phrase: 'volumeLikes'
  },
];

const InstagramContentFilter: React.FC<Props> = ({
  type,
  hint,
  onChange,
  className,
}) => {

  const intl = useIntl();

  return (
    <div className={className}>
      {Children.toArray(FIELDS.map(field => (
        <Community
          label={intl.formatMessage({ id: field?.phrase})}
          network={INSTAGRAM}
          filterKey={`${type}${field?.key}`}
          onChange={onChange}
        />
      )))}
      <span>{hint}</span>
    </div>
  );
};

export default InstagramContentFilter;
