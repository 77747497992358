
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import cn from 'classnames';

import useResizeObserver from 'utils/hooks/useResizeObserver';

import TabMenu from './TabMenu';

import styles from './Tabs.module.scss';


const Tabs = ({ tabs, currentTab, onChange, className, tabClassName, tabMenuClassName }) => {
  const [activeTab, setActiveTab] = useState(currentTab);
  const [node, size] = useResizeObserver(true);
  const handleClick = useCallback(
    index => setActiveTab(index),
    []
  );

  useEffect(() => {
    if (currentTab) setActiveTab(currentTab);
    return () => setActiveTab(0);
  }, [currentTab]);

  const transitions = useTransition(activeTab, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const style = useMemo(
    () => size && ({ minHeight: size.height }),
    [size]
  );

  const cnTabs = cn(styles.tabs, className);

  return (
    <div className={cnTabs}>
      <TabMenu
        tabs={tabs}
        onClick={handleClick}
        onChange={onChange}
        selected={activeTab}
        tabClassName={tabClassName}
        className={tabMenuClassName}
      />
      <div className={styles.panel} style={style}>
        {transitions.map(({ item, props, key }) => {
          const Tab = tabs[item].component;
          return (
            <animated.div style={props} key={key}>
              <animated.div ref={node}>
                <Tab />
              </animated.div>
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  currentTab: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.number,
  ]),
  className: PropTypes.string,
};

Tabs.defaultProps = {
  currentTab: 0,
  className: undefined,
};

export default memo(Tabs);
