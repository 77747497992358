import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  NETWORKS,
  INSTAGRAM,
  TWITTER,
  YOUTUBE,
  TIK_TOK,
  FACEBOOK,
  SNAPCHAT,
} from 'constants/networks';

import Icon from 'kolkit/Icon';

const SocialIcon = ({ label, color, style, variant, size='medium', className=undefined }) => {
  switch (label) {
    case INSTAGRAM:
      if (variant.isStory)
        return <Icon label='instagram-stories' theme='brand' style={style} fill={color || '#AC35A9'} size={size} className={className} />;
      if (variant.isIgtv)
        return <Icon label='igtv' theme='brand' style={style} fill={color || 'url(#igtvGradientColor)'} size={size} className={className} />;
      return <Icon label='instagram' theme='brand' style={style} fill={color || '#AC35A9'} size={size} className={className} />;
    case TWITTER:
      return <Icon label='twitter' theme='brand' style={style} fill={color || '#1DA1F2'} size={size} className={className} />;
    case YOUTUBE:
      return <Icon label='youtube' theme='brand' style={style} fill={color || '#E62117'} size={size} className={className} />;
    case TIK_TOK:
      return <Icon label='tiktok' theme='brand' style={style} fill={color || '#010101'} size={size} className={className} />;
    case FACEBOOK:
      return <Icon label='facebook' theme='brand' style={style} fill={color || '#1877F2'} size={size} className={className} />;
    case SNAPCHAT:
      return <Icon label='snapchat' theme='brand' style={style} fill={color || '#000000'} size={size} className={className} />;
    default:
      return null;
  }
};

SocialIcon.propTypes = {
  label: PropTypes.oneOf(NETWORKS).isRequired,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  variant: PropTypes.shape({
    isStory: PropTypes.bool,
    isIgtv: PropTypes.bool,
    isReel: PropTypes.bool,
  }),
};

SocialIcon.defaultProps = {
  color: undefined,
  style: undefined,
  variant: {
    isStory: false,
    isIgtv: false,
    isReel: false,
  },
};

export default memo(SocialIcon);
