import React, { useState, useCallback, useMemo, PropsWithChildren } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Button3, Tooltip } from 'kolkit';

import styles from './ClickToCopy.module.scss';

type Props = PropsWithChildren<{
  text: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  dataId?: string;
  onClickCallback?: () => void;
}>;

const ClickToCopy = ({
  text = '',
  size,
  fullWidth,
  className,
  children,
  dataId,
  onClickCallback,
}: Props) => {
  const [copied, setCopied] = useState(false);
  const [hasError, setHasError] = useState(false);

  const intl = useIntl();

  const handleCopy = useCallback(() => {
    if (!navigator.clipboard) {
      console.error('Clipboard API not available');
      setHasError(true);
      setTimeout(() => setHasError(false), 3000);
      return null;
    }
    void navigator.clipboard.writeText(text);
    if (onClickCallback) onClickCallback()
    setCopied(true);
  }, [text, onClickCallback]);

  const handleReset = useCallback(
    () =>
      setTimeout(() => {
        setCopied(false);
        setHasError(false);
      }, 500),
    [],
  );

  const tooltip = useMemo(() => {
    if (hasError) return intl.formatMessage({ id: 'global.clickToCopy.error' });
    if (copied) return intl.formatMessage({ id: 'global.clickToCopy.copied' });

    return intl.formatMessage({ id: 'global.clickToCopy' });
  }, [hasError, copied, intl]);

  const cnButton = cn(styles.clickToCopy, className);

  if (!text) return null;

  return (
    <Tooltip
      content={tooltip}
      onClose={handleReset}
    >
      <Button3
        size={size}
        theme="link"
        onClick={handleCopy}
        className={cnButton}
        fullWidth={fullWidth}
        dataId={dataId}
      >
        {children || text}
      </Button3>
    </Tooltip>
  );
};

export default ClickToCopy;
