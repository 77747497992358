import React, { lazy, memo, useMemo, Suspense } from 'react';
import { Icon, Text } from 'kolkit';
import cn from 'classnames';

import { API_INTEGRATIONS } from 'config/integrations';
import Tile from 'components/ui/Tile';

import styles from './Block.module.scss';

const Button3 = lazy(() => import('kolkit/Button2'));
const ButtonGoogle = lazy(() => import('components/ui/ButtonGoogle'));
const Affilae = lazy(() => import('components/modules/Affilae'));
const ShopifyIntegrationButton = lazy(() =>
  import('components/shopify/ShopifyIntegrationButton'),
);

const Block = ({ id, title, subtitle, iconLabel, buttonProps, whiteSpace }) => {
  const renderButton = useMemo(
    () => {
      switch (id) {
        case API_INTEGRATIONS.GMAIL:
          return (
            <Suspense fallback={null}>
              <ButtonGoogle />
            </Suspense>
          );
        case API_INTEGRATIONS.SHOPIFY:
          return (
            <Suspense fallback={null}>
              <ShopifyIntegrationButton />
            </Suspense>
          );
        case API_INTEGRATIONS.AFFILAE:
          return (
            <Suspense fallback={null}>
              <Affilae />
            </Suspense>
          );
        default:
          return <Button3 {...buttonProps} />;
      }
    },
    [id, buttonProps]
  );

  const cnIcon = cn({
    [styles.iconAffilae]: iconLabel === 'affilae'
  })

  return (
    <Tile className={styles.container}>
      <div className={styles.content}>
        <Icon
          theme="brand"
          label={iconLabel}
          width={iconLabel !== 'affilae' ? 48 : 110}
          className={cnIcon}
        />
        <Text className={styles.title}>{title}</Text>
        <Text whiteSpace={whiteSpace}>{subtitle}</Text>
      </div>
      <div>{renderButton}</div>
    </Tile>
  );
};

export default memo(Block);
