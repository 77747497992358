import { post, remove } from './index';

import mapper from '../mappers/favorites';

export const addNewFavorite = id => post({
	endpoint: `influence/favorites`,
	params: mapper.addFavorite.toApi(id),
	catchErrors: [422],
	debug: true,
});

export const addProfilesToFavorite = ids => post({
	endpoint: `influence/favorites/batch`,
	params: mapper.addFavorites.toApi(ids),
	debug: true,
});

export const removeFavorite = ({ id }) => remove({
	endpoint: `influence/favorites/${id}`,
	debug: true
});

export const removeFavorites = ids => remove({
	endpoint: `influence/favorites/batch`,
	params: mapper.removeFavorites.toApi(ids),
	debug: true
});
