import { atom } from 'jotai';

import { DEFAULT_DATA } from './config';

export const networkAtom = atom('all');
export const publicationTypeAtom = atom('all');

export const isEstimatedAtom = atom(false);
export const followersDataAtom = atom(DEFAULT_DATA.followersInsight);
export const statsDataAtom = atom(DEFAULT_DATA.statistics);

export const forecastNeedsUserRefreshAtom = atom(false);
export const forecastDataLoadedAtom = atom(false);
export const lastForecastProfileIdsAtom = atom([]);
