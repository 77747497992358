import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TabItem from './TabItem';

import styles from './Tabs.module.scss';


const TabMenu = ( { tabs, onClick, onChange, selected, className, tabClassName }) => {
  const cnTabList = cn(styles.ulTab, className);
  const cnTabItem = id => cn(styles.liTab, tabClassName, styles[id]);

  const handleClick = useCallback(
    index => onClick(index),
    [onClick]
  );

  return (
    <ul className={cnTabList}>
      {
        tabs.map((tab, index) => (
          <TabItem
            key={tab.label}
            className={cnTabItem(tab.label)}
            onClick={() => handleClick(index)}
            onChange={onChange}
            selected={index === selected}
            icon={tab.icon}
            item={tab.item}
          >
            {tab.label}
          </TabItem>
        )
      )}
    </ul>
  )
};

TabMenu.displayName = "Tab";

TabMenu.propTypes = {
  tabs: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tabClassName: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
};

TabMenu.defaultProps = {
  onClick: null,
  className: undefined,
  tabClassName: undefined,
  selected: false
};


export default memo(TabMenu);
