const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    if (!chart) return;
    const ul = getOrCreateLegendList(chart, options.containerId);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items =
      chart.options.plugins.legend.labels.generateLabels(chart) || [];

    items.reverse().forEach((item) => {
      const li = document.createElement('li');
      li.style.cursor = 'pointer';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex),
          );
        }
        chart.update();
      };

      // Color box + label container
      const topDiv = document.createElement('div');
      topDiv.style.alignItems = 'center';
      topDiv.style.cursor = 'pointer';
      topDiv.style.display = 'flex';
      topDiv.style.flexDirection = 'row';

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = `${item.lineWidth}px`;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = '0';
      boxSpan.style.height = '8px';
      boxSpan.style.width = '8px';
      boxSpan.style.marginRight = '4px';
      boxSpan.style.borderRadius = '50%';

      // Label
      const labelContainer = document.createElement('p');
      labelContainer.style.color = 'var(--color-dominant-tertiary)';
      labelContainer.style.fontSize = '14px';
      labelContainer.style.lineHeight = '1.5';
      labelContainer.style.margin = '0';
      labelContainer.style.padding = '0';
      labelContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      labelContainer.appendChild(text);

      // Gender percentage
      const percentageContainer = document.createElement('p');
      percentageContainer.style.color = 'var(--color-tertiary-500)';
      percentageContainer.style.fontSize = '14px';
      labelContainer.style.lineHeight = '1.5';
      percentageContainer.style.padding = '0';
      percentageContainer.style.textDecoration = item.hidden
        ? 'line-through'
        : '';

      const percentageText = document.createTextNode(
        item.datasetIndex === 0 ? options.malePercent : options.femalePercent,
      );
      percentageContainer.appendChild(percentageText);

      topDiv.appendChild(boxSpan);
      topDiv.appendChild(labelContainer);
      li.appendChild(topDiv);
      li.appendChild(percentageContainer);
      ul.appendChild(li);
    });
  },
};

export default htmlLegendPlugin;
