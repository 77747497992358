import React, {
  Children,
  cloneElement,
  memo,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Portal from '@mui/base/Portal';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import useMeasure from 'react-use-measure';
import ResizeObserver from 'resize-observer-polyfill';

import Button from '../Button2';
import Input from '../Input2';
import Item from '../Item';

import useSearch from '../hooks/useSearch';

import styles from './Dropdown.module.scss';

let inputTimer = null;

const Dropdown = ({
  dataId,
  options,
  renderItem,
  onSelectItem,
  selected,
  selectedOptionKey,
  trigger,
  label,
  labelPosition,
  placeholder,
  position,
  className,
  width,
  size,
  disabled,
  onOpen,
  maxHeight,
  fullWidth,
  optionMaxWidth,
  search,
  searchKey,
  inputSearchPlaceholder,
  disablePortal,
  themeCollapse,
  unmountOnExit,
  noResultTitle,
  localState,
  selectWidth
}) => {
  const inputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [localSelected, setLocalSelected] = useState('');

  const [ref, measure] = useMeasure({ polyfill: ResizeObserver });

  const {
    searchQuery,
    handleChange,
    result,
    reset
  } = useSearch({
    data: options,
    key: searchKey
  });

  useLayoutEffect(() => {
    if (search && open) reset();
  }, [search, open, reset]);

  useEffect(() => {
    return () => clearTimeout(inputTimer)
  }, [])

  useEffect(() => {
    if (onOpen) onOpen(open);
  }, [onOpen, open]);

  const handleOpen = useCallback(() => {
    if (disabled) return false;
    if (inputTimer) clearTimeout(inputTimer);
    setOpen(prevState => !prevState);
    if (search) {
      inputTimer = setTimeout(
        () => inputRef?.current?.focus(),
        200
      );
    }
  }, [disabled, search]);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClickItem = useCallback(
    async item => {
      if (item.disabled) return null;
      if (onSelectItem) await onSelectItem(item);
      if (item?.onClick) await item?.onClick(item);
      if (localState) await setLocalSelected(item[selectedOptionKey])
      handleClose();
    },
    [onSelectItem, handleClose, selectedOptionKey, localState]
  );

  const collapseStyle = useMemo(
    () => {
      const isTop = position.includes('top');
      const isBottom = position.includes('bottom');
      const isLeft = position.includes('left');
      const isRight = position.includes('right');

      const getWidth = {
        maxWidth: measure?.width > optionMaxWidth ? '100%' : optionMaxWidth,
        width: !width ? 'auto' : `${width}px`
      }

      const noPortal = Object.assign(
        { ...getWidth },
        isTop && { bottom: measure.height + 4 },
        isBottom && { top: measure.height + 4 },
        isLeft && { left: 0 },
        isRight && { right: 0 }
      );

      const withPortal = Object.assign(
        { ...getWidth },
        isTop && { bottom: measure?.top },
        isBottom && { top: measure?.bottom + 4 },
        (isLeft || isRight) && { left: measure?.left }
      )

      return disablePortal ? noPortal : withPortal;
    },
    [width, measure, position, optionMaxWidth, disablePortal]
  );

  const selectedOption = useMemo(
    () => options?.find(option => option?.[selectedOptionKey] === selected),
    [selected, options, selectedOptionKey]
  );

  const renderTrigger = useMemo(
    () => {
      if (trigger) {
        return cloneElement(trigger, {
          ref,
          onClick: handleOpen,
          'data-id': dataId
        });
      }

      const cnButton = cn(styles.button, {
        [styles.open]: open,
        [styles.isPlaceholder]: !(selectedOption?.label || (selectedOptionKey && selectedOption?.[selectedOptionKey]))
      });

      return (
        <Button
          ref={ref}
          className={cnButton}
          reverse
          icon="caret-circle-down"
          iconTheme="solid"
          iconSize="huge"
          iconRotate={open}
          label={localState && localSelected !== ''
            ? localSelected
            : selectedOption?.label || placeholder || label
          }
          onClick={handleOpen}
          dataId={dataId}
          theme="secondary"
          size={size}
          active={open}
          disabled={disabled}
          fullWidth={fullWidth}
          isDropdown
        />
      );
    },
    [
      ref,
      handleOpen,
      dataId,
      trigger,
      label,
      placeholder,
      open,
      disabled,
      size,
      fullWidth,
      selectedOption,
      selectedOptionKey,
      localSelected,
      localState
    ]
  );

  const renderInputSearch = useMemo(
    () => search && (
      <div className={styles.input}>
        <Input
          ref={inputRef}
          placeholder={inputSearchPlaceholder}
          value={searchQuery}
          onChange={handleChange}
          fullWidth
          theme="secondary"
        />
      </div>
    ),
    [search, handleChange, searchQuery, inputSearchPlaceholder]
  );

  const getDataSource = useMemo(
    () => search ? result : options,
    [search, result, options]
  );

  const styleItems = useMemo(
    () => ({ maxHeight: `${maxHeight}px` }),
    [maxHeight]
  );

  const cnWrapper = cn(
    styles.wrapper,
    {
      [styles.fullWidth]: fullWidth,
      [styles.labelTop]: labelPosition === 'top'
    },
    className
  );

  const cnCollapse = cn(styles.collapseContainer, {
    [styles.left]: position.includes('left') && !disablePortal
  });

  const cnCollapseWrapper = cn(styles.collapseWrapper, styles[themeCollapse], styles[size]);
  const cnLabel = cn(styles.label, styles[size]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cnWrapper}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {label && <label className={cnLabel}>{label}</label>}
        <div
          style={{ width: selectWidth, flex: `0 0 ${selectWidth}` }}
          className={styles.triggerWrapper}
        >
          {renderTrigger}
          <Portal disablePortal={disablePortal}>
            <div className={styles.collapse} style={collapseStyle}>
              <div className={cnCollapse}>
                <Collapse in={open} unmountOnExit={unmountOnExit} timeout={100}>
                  <div className={cnCollapseWrapper}>
                    {renderInputSearch}
                    <div className={styles.items} style={styleItems}>
                      {Children.toArray(
                        getDataSource?.map(item => {
                          const el = renderItem(item);
                          const cnItem = cn(
                            styles.item,
                            {
                              [styles.selected]: item.value === selected,
                              [styles.disabled]: item.disabled
                            },
                            el?.props?.className
                          );
                          return cloneElement(el, {
                            className: cnItem,
                            id: item.id
                              ? parseInt(item.id, 10)
                              : item.value || item.label,
                            onClick: () => handleClickItem(item),
                          });
                        })
                      )}
                    </div>
                    {!getDataSource?.length && (
                      <Item
                        padding="8px 16px"
                        fontSize="1rem"
                        className={styles.noResultTitle}
                      >
                        {noResultTitle}
                      </Item>
                    )}
                  </div>
                </Collapse>
              </div>
            </div>
          </Portal>
        </div>
      </div>
    </ClickAwayListener>
  );
};

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  dataId: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any
    })
  ),
  onSelectItem: PropTypes.func,
  // eslint-disable-next-line
  selected: PropTypes.any,
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left']),
  placeholder: PropTypes.string,
  position: PropTypes.string,
  renderItem: PropTypes.func,
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  width: PropTypes.number,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onOpen: PropTypes.func,
  maxHeight: PropTypes.number,
  optionMaxWidth: PropTypes.number,
  disablePortal: PropTypes.bool,
  search: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  inputSearchPlaceholder: PropTypes.string,
  searchKey: PropTypes.string,
  selectedOptionKey: PropTypes.string,
  noResultTitle: PropTypes.string,
  selectWidth: PropTypes.string,
  localState: PropTypes.bool,
  themeCollapse: PropTypes.oneOf(['primary', 'secondary'])
};

Dropdown.defaultProps = {
  dataId: null,
  options: [],
  size: 'medium',
  position: 'bottom-left',
  labelPosition: 'left',
  placeholder: '',
  fullWidth: false,
  renderItem: item => <span key={item.value}>{item.label || item.value}</span>,
  maxHeight: 182,
  optionMaxWidth: 200,
  disablePortal: true,
  search: false,
  unmountOnExit: false,
  inputSearchPlaceholder: null,
  trigger: null,
  className: null,
  themeCollapse: 'primary',
  searchKey: 'value',
  selectedOptionKey: 'value',
  localState: false,
  disabled: false,
  onSelectItem: () => {},
  onOpen: () => {},
  noResultTitle: '',
  selectWidth: '100%'
};

export default memo(Dropdown);
