import React from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { Modal2 } from 'kolkit';
import { Markdown } from 'components/ui';

import useModal from 'utils/hooks/useModal';
import renderModal from 'utils/HOCs/renderModal';

import styles from './SearchHelpModal.module.scss';

const MODAL_ID = 'searchEngine.helper';

const SearchHelpModal = () => {
  const intl = useIntl();
  const { off, show } = useModal(MODAL_ID);
  return (
    <Modal2
      title={intl.formatMessage({ id: 'engineSearch.help.title' })}
      subTitle={intl.formatMessage({ id: 'engineSearch.help.subTitle' })}
      on={show}
      onClick={off}
    >
      <div className={styles.modalHelperItems}>
        {React.Children.toArray(
          [
            'term',
            'mention',
            'hashtag',
            'expression',
            'addTerm',
            'deleteTerm',
          ].map((item) => (
            <div className={styles.modalHelperItem}>
              <Markdown className={styles.modalHelperTerm}>
                {intl.formatMessage({ id: `engineSearch.help.${item}` })}
              </Markdown>

              <Markdown className={styles.modalHelperDesc}>
                {intl.formatMessage({ id: `engineSearch.help.${item}Desc` })}
              </Markdown>
            </div>
          )),
        )}
      </div>
    </Modal2>
  );
};

export default renderModal(MODAL_ID, SearchHelpModal);
