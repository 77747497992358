import React, { useCallback } from 'react';

import { setAllSelected } from 'actions/engine';
import { useDispatch, useSelector } from 'utils/redux';
import ProfileListMultiAction, {
  ActionButtonTypes,
} from 'components/ui/ProfileListMultiAction';

const actions = [
  {
    name: ActionButtonTypes.ADD_TO_FAVORITES,
    actionType: 'add',
    analyticsLabel: 'engine-card',
  },
  { name: ActionButtonTypes.APPLY_TAGS, analyticsLabel: 'Search_Results' },
  { name: ActionButtonTypes.ADD_TO_CAMPAIGNS, analyticsLabel: 'Search_Results' },
  { name: ActionButtonTypes.ADD_TO_LIST },
  { name: ActionButtonTypes.MERGE_PROFILES },
];

/**
 * Show the "select all" checkbox and options for search engine page
 * @component
 */
const TopMultiSearch = () => {
  const dispatch = useDispatch();

  const [searchResult, selectedSearchResult] = useSelector(({ engine }) => [
    engine.results.rows.map(r => r.id),
    engine.selectedResults,
  ]);

  const handleSelection = useCallback(
    selectAll => {
      dispatch(setAllSelected(selectAll ? searchResult : []));
    },
    [searchResult, dispatch],
  );

  return (
    <ProfileListMultiAction
      profileIds={searchResult}
      selectedProfiles={selectedSearchResult}
      onSelectAllChange={handleSelection}
      actions={actions}
      enableAutoDeselection
    />
  );
};

TopMultiSearch.displayName = 'TopMultiSearch';

export default TopMultiSearch;
