import React, { memo } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

import { DELIVERY_NETWORKS } from 'constants/networks';
import type { AggregatedContentRequest } from 'slices/contentValidation.slice';
import { useDateFnsLocale } from 'utils/date-fns';

import { Icon } from 'kolkit';
import { Markdown, Typography, InlineMessage } from 'components/ui';

import styles from './DisplayExpectedContent.module.scss';

type Props = {
  aggregateContentProposals: AggregatedContentRequest;
  isMediaPlanEmpty?: boolean;
  onEdit: () => void;
};

const DisplayExpectedContent = ({
  aggregateContentProposals,
  isMediaPlanEmpty,
  onEdit,
}: Props) => {
  const intl = useIntl();
  const dateFnsLocale = useDateFnsLocale();

  if (isMediaPlanEmpty) {
    return (
      <div className="flex fdc gap8">
        <Typography variant="text/body large">
          <FormattedMessage id="profileDetails.profileInfos.contentExpected.title" />
        </Typography>
        <InlineMessage type="warning">
          <div className="flex aic fg1 jcsb">
            <span>
              <Markdown>
                {intl.formatMessage({
                  id: 'profileDetails.profileInfos.contentExpected.fillMediaPlanFist',
                })}
              </Markdown>
            </span>
            <Icon label="pencil" isButton onClick={onEdit} />
          </div>
        </InlineMessage>
      </div>
    );
  }

  return (
    <div className="flex fdc gap8">
      <div className="flex jcsb">
        <div className="flex fdc fg1">
          <Typography variant="text/body large">
            <FormattedMessage id="profileDetails.profileInfos.contentExpected.title" />
          </Typography>
          <Typography variant="text/body medium">
            <FormattedMessage id="profileDetails.profileInfos.contentExpected.subTitle" />
          </Typography>
        </div>
        <Icon label="pencil" theme="regular" isButton onClick={onEdit} />
      </div>
      <div className="flex wrap gap8">
        {DELIVERY_NETWORKS.map(({ network, mediaType }) => {
          const contentByDate = aggregateContentProposals[network]?.[mediaType];
          if (!contentByDate || Object.keys(contentByDate).length === 0) {
            return null;
          }
          let iconLabel = `${network}-color`;
          switch (mediaType) {
            case 'reel':
              iconLabel = 'instagram-video-color';
              break;
            case 'story':
              iconLabel = 'instagram-story-color';
              break;
            case 'saved_story':
              iconLabel = 'snapchat-story';
              break;
            case 'spotlight':
              iconLabel = 'snapchat-spotlight';
              break;
            default:
              break;
          }

          return (
            <div
              className={styles.root}
              key={`expected-content-${network}-${mediaType}`}
            >
              <Icon label={iconLabel} theme="brand" />
              <Typography
                label={`${intl.formatMessage({
                  id: `global.content.${mediaType}`,
                })} : `}
                variant="label/large"
              />
              <Typography
                variant="text/body medium bold"
                className={styles.perDate}
              >
                {Object.entries(contentByDate).flatMap(
                  ([date, content], index, array) => {
                    return [
                      date ? (
                        <FormattedMessage
                          id="profileDetails.profileInfos.contentExpected.perDate"
                          values={{
                            count: content,
                            date: format(new Date(date), 'PPP', {
                              locale: dateFnsLocale,
                            }),
                          }}
                        />
                      ) : (
                        <span className="flex aic gap4">
                          <span>
                            {`${content} ${intl.formatMessage({ id: "global.for"})}`}
                          </span>
                          <span className={cn('flex aic gap4', styles.noDate)}>
                            <Icon
                              label="exclamation-triangle"
                              fill="var(--color-alert-error)"
                              theme="solid"
                              width={12}
                            />
                            <FormattedMessage id="profileDetails.profileInfos.contentExpected.noDate" />
                          </span>
                        </span>
                      ),
                      index < array.length - 1 ? ' & ' : '',
                    ];
                  },
                )}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(DisplayExpectedContent);
