import React, { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { Button3 } from 'kolkit';

import { exportSearch } from 'actions/engine';
import { Wrapper } from 'components';
import { useSelector, useDispatch } from 'utils/redux';

import SuggestInfluencers from 'components/profileController/modules/SuggestInfluencers';

const PROFILES_TO_EXPORT = 200;

const TopSortFilter = memo(() => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { hasNoResults, canExport } = useSelector(
    ({ engine, user: { canExport } }) => ({
      hasNoResults: !engine.results.hasSearch || engine.results.total === 0,
      canExport,
    }),
  );

  const launchExport = useCallback(
    () => dispatch(exportSearch()),
    [dispatch]
  );

  return (
    <Wrapper space="small">
      <Button3
        size="small"
        disabled={hasNoResults || !canExport}
        onClick={launchExport}
        theme="secondary"
        icon="file-download"
        tooltip={intl.formatMessage(
          { id: 'engine.actions.export.tooltip'},
          { count: PROFILES_TO_EXPORT}
        )}
      >
        <FormattedMessage id="engine.actions.export.button" />
      </Button3>
      <SuggestInfluencers
        icon="plus"
        label={intl.formatMessage({id: 'engine.actions.addKols'})}
        from="Search"
      />
    </Wrapper>
  );
});

export default TopSortFilter;
