import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { Input2, Loader, Modal2 } from 'kolkit';

import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'utils/redux';

import renderModal from 'utils/HOCs/renderModal';
import { Team, saveTeam } from 'slices/organization.slice';

const MODAL_ID = 'addOrganizationTeamModal';

const EditTeamModal: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { show, off, data } = useModal<{ onSuccess: (team: Team) => void }>(
    MODAL_ID,
  );

  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);

  const loaders = useSelector(({ organization: { loading } }) => loading);

  const loading = useMemo(
    () => loaders?.includes(saveTeam.typePrefix),
    [loaders]
  );

  const handleChangeInput = useCallback(
    ({ value }) => setName(value),
    []
  );

  const resetModal = useCallback(
    () => setName(''),
    []
  );

  const actions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: 'global.cta.save' }),
        onClick: () =>
          dispatch(
            saveTeam({
              team: { name },
              onSuccess: team => {
                if (data.onSuccess) data.onSuccess(team);
                off();
              },
              onError: error => {
                setHasError(!!error);
              },
            }),
          ),
        disabled: !name?.trim()?.length,
        dataId: "settings-team-create",
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: () => off(),
      },
    }),
    [data, dispatch, intl, name, off],
  );

  return (
    <Modal2
      title={intl.formatMessage({ id: 'modal.organization.addTeam.title' })}
      on={show}
      TransitionProps = {{
        onExited: resetModal,
      }}
      onClick={off}
      action={actions}
      loading={loading}
    >
      {loading && <Loader full />}
      <Input2
        value={name}
        placeholder={intl.formatMessage({
          id: 'modal.organization.addTeam.field.name',
        })}
        name="name"
        onChange={handleChangeInput}
        fullWidth
        required
        disabled={loading}
        error={hasError}
        errorMessage={intl.formatMessage({ id: 'modal.organization.addTeam.error'})}
        dataId="settings-team-name"
      />
    </Modal2>
  );
};

export default renderModal(MODAL_ID, EditTeamModal);
