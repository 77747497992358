
const knowledgeFeatures = {
  myKols: 'estimated_reach_in_irm',
  estimatedImpressionsGlobalStatsReporting: 'estimated_impressions_in_global_stats_reporting',
  estimatedImpressionsPerfStatsReporting: 'estimated_impressions_in_perf_stats_reporting',
  kolsAdditionDashboard: 'kols_addition_dashboard',
  kolsAdditionDashboardMenu: 'kols_addition_dashboard_menu',
}

export default knowledgeFeatures;
