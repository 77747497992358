/* eslint-disable react/require-default-props,react/forbid-prop-types,no-console */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { Loader, Text } from 'kolkit';
import Appsignal from '@appsignal/javascript';

import { randomFromArray } from 'utils';
import { setClientToken, newApplicationVersionRequired } from 'actions/env';
import { getUserParams, logout } from 'actions/user';
import { conf, env, ENV_DEV } from 'config/env';
import logError from 'utils/logError';
import loadingSentences from 'locales/loadingSentences';

import Structure from './Structure';
import ProfileDrawer from './profileDetails/Drawer';
import PageNavigatorChecker from './dispatchers/PageNavigatorChecker';
import OutdatedBrowserAlert from './ui/OutdatedBrowserAlert';
import ApiErrorModale from './ui/ApiErrorModale';
import WarningNoInternet from './ui/WarningNoInternet';
import ToastCloseButton from './ui/ToastCloseButton';
import NewVersionRequired from './ui/NewVersionRequired';
import MaintenanceMode from './ui/MaintenanceMode';
import ForbiddenActionAlert from './ui/ForbiddenActionAlert';

import packageInfo from '../../package.json';

import 'css/scss/selections/selection.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import '@brandandcelebrities/viz/dist/css/viz.css';

export const appsignal = new Appsignal({
	key: conf?.appsignal,
	revision: packageInfo?.version,
	namespace: 'Influence',
});

console.log('env', env);


class MainContainer extends PureComponent {
	state = {
		mounted: false,
		isJsError: false,
		isChunkLoadError: false,
	};

	componentDidMount = async () => {
		// Getting Token from Cookies yeah
		const cookies = new Cookies();
		let cookieToken = cookies?.get(conf.cookieToken);

		if (!cookieToken) {
			// No cookie set => redirection to login page
			if (!conf.overrideEnv && env !== ENV_DEV) await this.props.actions?.logout();
			else {
				cookieToken = process.env.COOKIE_TOKEN || 'dev-hashstrings';
			}
		}

		const { actions: { setClientToken, getUserParams }} = this.props;

		await setClientToken(cookieToken);

		const res = await getUserParams();

		if (res) this.setState({ mounted: true });

		return true;
	};

	componentDidCatch(error, errorInfo) {
		console.error('componentDidCatch error', error);
		console.error('componentDidCatch errorInfo', errorInfo);

		if (typeof error === 'object' && error?.name === 'ChunkLoadError') {
			const { newApplicationVersionRequired } = this.props.actions;
			newApplicationVersionRequired();
			this.setState({ isChunkLoadError: true });
		} else if (
			error?.name === 'TypeError' &&
			error.message?.includes(
				"Cannot read properties of undefined (reading 'call')",
			)
		) {
			window.location.reload(true);
		} else {
			const { id, firstName, lastName, companyName } = this.props.user;
			logError(error, {
				errorInfo,
				id,
				name: `${firstName}  ${lastName}`,
				companyName,
			});
			console.log('its not heeeeere')
			this.setState({ isJsError: true });
		}
	}

	componentWillUnmount() {
		this.setState({ mounted: false })
	}

	renderJsError = () => {
		return <ApiErrorModale isRuntimeError />
	};

	renderEnvModales = () => {
		const { hasInternet, hasApiError } = this.props;

		return (
			<>
				{!hasInternet && <WarningNoInternet />}
				{hasApiError && <ApiErrorModale />}
				<NewVersionRequired />
				<MaintenanceMode />
				<ForbiddenActionAlert />
			</>
		)
	};

	renderGlobalLoading = () => {
		const { locale } = this.props;
		const welcomeSentence = randomFromArray(loadingSentences[locale]);

		return (
			<div className="global-loader">
				<div className="loader">
					<div>
						<h1>
							<img src="/logo/logo-horizontal-navy.svg" className="logo" alt="Kolsquare" />
						</h1>
					</div>
					<Loader className="global-loader_loader" padding={16} />
					<Text tag="h5" fontTitle>
						{welcomeSentence}
					</Text>
				</div>
			</div>
		);
	};

	renderApp = () => {
		const { isJsError, isChunkLoadError, mounted } = this.state;

		if (isChunkLoadError) return null;
		if (isJsError) return this.renderJsError();
		if (!mounted) return this.renderGlobalLoading();

		return (
			<>
				<CssBaseline />
				<Structure />

				{ /* Checkers */ }
				<PageNavigatorChecker />

				<ProfileDrawer />
				<OutdatedBrowserAlert />
			</>
		);
	};

	render() {
		const { enableToasts } = this.props;

		return (
			<>
				{this.renderApp()}
				{ /* ERROR AND WARNINGS */ }
				{this.renderEnvModales()}
				{enableToasts && (
					<ToastContainer
						enableMultiContainer
						transition={Flip}
						autoClose={2000}
						closeButton={<ToastCloseButton />}
						toastClassName="toast"
						position={toast.POSITION.BOTTOM_CENTER}
						closeOnClick={false}
						pauseOnHover
						draggablePercent={40}
					/>
				)}
			</>
		);
	}
}

MainContainer.propTypes = {
	actions: PropTypes.shape({
		setClientToken: PropTypes.any,
		getUserParams: PropTypes.any,
		logout: PropTypes.any,
	}),
	hasInternet: PropTypes.bool,
	hasApiError: PropTypes.bool,
	locale: PropTypes.string,
	enableToasts: PropTypes.bool,
};

MainContainer.defaultProps = {
	actions: {},
};

const mapStateToProps = ({ env, ui, user }) => ({
	locale: env.locale,
	hasApiError: ui.modales.apiError.show,
	hasInternet: env.hasInternet,
	enableToasts: !ui.modales.apiError.show && env.hasInternet && !env.newVersionRequired && !env.maintenanceModeActivated && !env.forbiddenActionAlert,
	user: user.profile
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ setClientToken, getUserParams, logout, newApplicationVersionRequired }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
