import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import _deburr from 'lodash.deburr';
import _sortBy from 'lodash.sortby';

import { Field, SelectTag } from 'components';
import { configFilters } from 'config/filters';
import { useFilter } from 'components/engine/hooks';
import useActivitiesIntl from 'utils/hooks/useActivitiesIntl';

import styles from './Activities.module.scss';

const Activities = ({ network, onChange, disabled, max }) => {
  const filterTarget = 'activities';
  const intl = useIntl();
  const { tree, params } = configFilters[filterTarget];
  const value = useFilter(filterTarget, network);
  const activitiesIntl = useActivitiesIntl();

  const handleChange = useCallback(
    ({ item, selected }) => onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      network,
    }),
    [onChange, network, filterTarget]
  );

  const getData = useMemo(() => {
    if (!tree) return null;

    const level0 = tree.map(item => ({
      lexique: _deburr(activitiesIntl.formatMessage({ id: item.label, gender: 'male' })),
      children: item.children,
      label: item.label || '',
    }));

    const level0Sorted = _sortBy(level0, ['lexique']);

    const items = level0Sorted.reduce((p, item) => {
      const subItems = (item.children || []).map(subItem => ({
        value: subItem.label,
        label: activitiesIntl.formatMessage({ id: subItem.label, gender: 'male' }),
        lexique: _deburr(activitiesIntl.formatMessage({ id: subItem.label, gender: 'male' })),
        className: styles.itemLevel1,
      }));

      return [
        ...p,
        {
          value: item.label,
          label: activitiesIntl.formatMessage({ id: item.label, gender: 'male' }),
          className: styles.itemLevel0,
        },
        ..._sortBy(subItems, ['lexique'])
      ];
    }, []);

    return params?.pinned?.length
      ? [
          ...items.filter( ({ value }) => params?.pinned.includes(value)),
          ...items.filter( ({ value }) => !params?.pinned.includes(value)),
        ]
      : items;
  }, [tree, params?.pinned, activitiesIntl]);

  return (
    <Field label={intl.formatMessage({ id: 'activities.label'})}>
      <SelectTag
        selected={value}
        dataset={getData}
        hasSearch
        sort={params.sort}
        pinned={params.pinned}
        maxTags={3}
        defaultOptionLabel={intl.formatMessage(
          { id: 'activities.filterBy'},
          { max }
        )}
        onChange={handleChange}
        disabled={disabled}
        inPortal
      />
    </Field>
  )
};

Activities.displayName = 'Activities';

Activities.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Activities.defaultProps = {
  disabled: false,
};

export default memo(Activities);
