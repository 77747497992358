import { Reducer, useReducer } from 'react';
import moment from 'moment';
import _groupBy from 'lodash.groupby';
import { LicenceDetails, LicenceEdition } from 'slices/organization.slice';

export type LicenceSelectItem = {
  id: string;
  free: number[];
  assigned: { licenceId: number; contact_id: any; used_days: any }[];
} & Omit<LicenceDetails, 'id' | 'contact_id'>;

const areAllDaysUsedUp = licence => {
  return licence.days && licence.days === licence.used_days;
};

const generateLicenceKey = licence => {
  let days = '';
  let all_days_are_used_up = false;

  if (licence.days) {
    // is moderate
    days =
      licence.used_days && licence.contact_id === null // used then removed
        ? `_${licence.used_days}/${licence.days}d`
        : `_${licence.days}d`;
    all_days_are_used_up = licence.days === licence.used_days;
  }

  return `${licence.name}${days}___${
    licence.licence_group_id
  }_${licence.is_active && !all_days_are_used_up}`.trim();
};

const makeDatasetBaseItem: (
  licence: LicenceDetails,
) => LicenceSelectItem = licence => ({
  id: generateLicenceKey(licence),
  days: licence.days,
  used_days: licence.used_days,
  end_date: licence.end_date,
  start_date: licence.start_date,
  is_active: licence.is_active,
  all_days_are_used_up: areAllDaysUsedUp(licence),
  name: licence.name,
  is_moderate: licence.is_moderate,
  licence_group_id: licence.licence_group_id,
  assigned: [],
  free: [],
});

type RegroupCompanyLicencesFunction = (params: {
  licences: LicenceDetails[];
  modifications?: Record<number, LicenceEdition>;
}) => LicenceSelectItem[];

const regroupCompanyLicences: RegroupCompanyLicencesFunction = ({
  licences = [],
  modifications = {},
}) => {
  const licencesMergedWithChanges = licences.map(initialLicence =>
    modifications[initialLicence.id]
      ? {
          ...initialLicence,
          ...modifications[initialLicence.id],
        }
      : initialLicence,
  );
  // Consumed moderate licences but not assigned to anyone should be displayed explicitly
  const explicitlyDisplayedLicences = licencesMergedWithChanges
    .filter((l) => !l.contact_id && l.used_days)
    .map((l) => ({
      ...makeDatasetBaseItem(l),
      free: [l.id],
      assigned: [],
      licenceId: l.id,
    }));

  const licenceGroup = Object.values(
    // The licences excluding explicitly displayed ones
    _groupBy(
      licencesMergedWithChanges.filter(
        l =>
          !explicitlyDisplayedLicences.find(expDL => l.id === expDL.licenceId),
      ),
      'licence_group_id',
    ),
  ).map(licencesOfTheGroup => {
    // Take only the first element to represent the group
    const licence = licencesOfTheGroup[0];

    const baseItem = {
      ...makeDatasetBaseItem(licence),
      assigned: licencesOfTheGroup
        .filter(l => l.contact_id !== null && l.contact_id !== undefined)
        .map(l => ({
          licenceId: l.id,
          contact_id: l.contact_id,
          used_days: l.used_days,
        })),
      free: licencesOfTheGroup
        .filter(l => l.contact_id === null || l.contact_id === undefined)
        .map(l => l.id),
    };
    return baseItem;
  });

  return [...explicitlyDisplayedLicences, ...licenceGroup].sort(
    (a, b) =>
      (b.is_active ? 1 : -1) - (a.is_active ? 1 : -1) ||
      moment(a.start_date).unix() - moment(b.start_date).unix(),
  );
};

type State = {
  licences: LicenceDetails[];
  modifications: Record<number, LicenceEdition>;
  licencesDataSet: ReturnType<RegroupCompanyLicencesFunction>;
};

const initialLicencesState: State = {
  licences: [],
  modifications: {},
  licencesDataSet: [],
};

const licencesStateReducer: Reducer<
  State,
  {
    type: 'init' | 'reset' | 'edit';
    licences?: LicenceDetails[];
  } & Partial<LicenceDetails>
> = (state, { type, ...action }) => {
  switch (type) {
    case 'init':
      return {
        licences: action.licences || [],
        modifications: {},
        licencesDataSet: regroupCompanyLicences({
          licences: action.licences || [],
        }),
      };
    case 'reset':
      return {
        licences: state.licences || [],
        modifications: {},
        licencesDataSet: regroupCompanyLicences({
          licences: state.licences || [],
        }),
      };
    case 'edit':
    default: {
      const modifications = action.id
        ? {
            ...state.modifications,
            [action.id]: {
              ...(state.modifications[action.id] || {}),
              ...action,
            },
          }
        : state.modifications;
      return {
        licences: state.licences,
        modifications,
        licencesDataSet: regroupCompanyLicences({
          licences: state.licences,
          modifications,
        }),
      };
    }
  }
};

export default () => useReducer(licencesStateReducer, initialLicencesState);
