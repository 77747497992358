import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup
} from '@mui/material';

import styles from './ToggleButton.module.scss';

const ToggleButtonGroup = ({
  disabled,
  onChange,
  options,
  selectedOption,
}) => {
  const handleChange = useCallback(
    (_, newValue) => {
      const selected = selectedOption.indexOf(newValue) > -1
        ? selectedOption.filter(option => option !== newValue)
        : [...selectedOption, newValue];

      return onChange({
        item: newValue,
        selected,
        type: 'toggle-buttons'
      });
    },
    [selectedOption, onChange]
  );

  const groupClasses = {
    root: styles.root,
    grouped: styles.grouped,
  }

  const buttonClasses = {
    root: styles.buttonRoot,
    selected: styles.selected,
    sizeSmall: styles.small,
    sizeLarge: styles.large,
    disabled: styles.disabled,
  }

  return (
    <div>
      <MuiToggleButtonGroup
        size="small"
        exclusive
        value={selectedOption}
        onChange={handleChange}
        classes={groupClasses}
      >
        {options && React.Children.toArray(options.map(option => {
          const { id, value, label } = option;
          return (
            <MuiToggleButton
              value={value}
              classes={buttonClasses}
              id={id}
              name={value}
              disabled={disabled}
              checked={selectedOption.indexOf(value) > -1}
            >
              <span className={styles.label}>
                {label}
              </span>
            </MuiToggleButton>
          );
        }))}
      </MuiToggleButtonGroup>
    </div>
  );
};

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

ToggleButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedOption: PropTypes.arrayOf(PropTypes.string),
};

ToggleButtonGroup.defaultProps = {
  disabled: false,
  selectedOption: [],
  onChange: null,
};

export default ToggleButtonGroup;
