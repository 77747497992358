import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { WrapperFilter } from '../Components';
import { Community } from '../Modules';
import useActiveFilter from '../hooks/useActiveFilter';

const Twitter = ({ onChange }) => {
  const network = 'twitter';
  const intl = useIntl();
  const isActive = useActiveFilter(network);

  return (
    <WrapperFilter title={network} isActive={isActive}>
      <div>
        <Community
        label={intl.formatMessage({id: 'communitySize'})}
          network={network}
          filterKey="communitySize"
          onChange={onChange}
          disabled={!isActive}
        />
      </div>
      <div>
        <Community
        label={intl.formatMessage({id: 'engagementRate'})}
          network={network}
          filterKey="communityEngagementRate"
          onChange={onChange}
          disabled={!isActive}
        />
      </div>
    </WrapperFilter>
  )
};

Twitter.displayName = 'Twitter';

Twitter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(Twitter);

