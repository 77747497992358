/* eslint-disable */
import React, { lazy, memo, useMemo, useEffect, Suspense } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigationType,
  NavigationType,
} from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import { FullStory } from '@fullstory/browser';
import browser from 'browser-detect';

import { Loader } from 'kolkit';

import { updateKey } from 'slices/search/searchEngine.slice';
import { setViewport } from 'actions/env';
import { setDevice } from 'actions/ui';
import { env, ENV_PROD } from 'config/env';
import routes, { getRedirectUrl } from 'config/routes';
import * as featureNames from 'constants/feature-names';
import { MountRouteByFeature, isFeatureActive } from 'utils/feature-toggle';

import { Navbar } from 'components/modules';
import { useDispatch, useSelector } from 'utils/redux';
import useWindowSizeObserver from 'utils/hooks/useWindowSizeObserver';
import useLoading from 'utils/hooks/useLoading';
import useViews from 'utils/hooks/useViews';
import useLocalStorage from 'utils/hooks/useLocalStorage';

import EntityDispatcher from './dispatchers/ProfileDetails';

// const Modals = lazy(() => import('components/modules/Modal/Modals'));

const FreePlaceholder = lazy(() => import('./loaders/FreePlaceholder'));
// const AnnouncementBanner = lazy(() => import('./CustomerAnnouncements/AnnouncementBanner'));
// const AsyncTasksListener = lazy(() => import('./modules/AsyncTasksListener'));
const ZendeskRedirect = lazy(() => import('./zendesk/ZendeskRedirect'));
const CampaignsList = lazy(() => import('./campaigns/CampaignsList'));
const CampaignDetails = lazy(() => import('./campaigns/CampaignDetails'));
const Messaging = lazy(() => import('./messaging/Loader'));
const Engine = lazy(() => import('./engine/Loader'));
const SearchEngine = lazy(() => import('./search/SearchEngine'));
const ContentSearch = lazy(() => import('./search/ContentSearch'));
const SettingsPage = lazy(() => import('./account/SettingsPage'));
const DebugPage = lazy(() => import('./DebugPage'));
const MyInfluencers = lazy(() => import('./myInfluencers/MyInfluencers'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const ListeningDetails = lazy(() => import('./listeningV2/ListeningDetails'));

// const Modales = lazy(() => import('./ui/Modales'));
// const SatisMeter = lazy(() => import('../utils/SatisMeter'));
// const ZendeskScript = lazy(() => import('./zendesk/ZendeskScript'));


const Structure = () => {
  const dispatch = useDispatch();
  const dimensions = useWindowSizeObserver();
  const { isListeningPage } = useViews();

  const [isNewVersionEnabled] = useLocalStorage('search.use-new-version', true);

  const { loading: globalLoader } = useLoading('globalLoader');
  const { loading: phraseLoader } = useLoading('phraseLoader');

  const { locale, user } = useSelector(({ user, env }) => ({
    locale: env?.locale,
    user: user?.profile,
  }));

  // Used to better handle profile details modal
  const location = useLocation();
  const navigationType = useNavigationType();

  const underProfileModalLocation = useMemo(
    () => {
    // To know if it's in a new tab or from a navigation event
    // https://github.com/remix-run/history/blob/main/docs/api-reference.md#location.key
      const isNewTab = location.key === 'default';
      const isRefreshedPage = navigationType === NavigationType.Pop;
      const locationMatchProfile =
        location.pathname.match(
          // For campaign URLs (we don't add /profiles/ID to the URL)
          new RegExp(`(/${routes.campaigns}/\\d+/(?!${routes.messaging})[\\w-]+/)\\d+$`),
        ) || // For other URLs with /profiles/ID
        location.pathname.match(new RegExp(`(.+)?/${routes.profile}/\\d+$`));

      if (!locationMatchProfile) return location;
      if (isNewTab || isRefreshedPage) return undefined;
      return { ...location, pathname: locationMatchProfile?.[1] || '/' };
    },
    [location, navigationType]
  );

  useEffect(() => {
    dispatch(setViewport(dimensions));
    dispatch(setDevice(browser()?.mobile ? 'mobile' : 'desktop'))
    // eslint-disable-next-line
  }, [dimensions]);

  useEffect(() => {
    moment.locale(locale?.toLowerCase());
  }, [locale]);

  useEffect(() => {
    dispatch(updateKey({ key: 'enabled', data: isNewVersionEnabled }));
    // eslint-disable-next-line
  }, [isNewVersionEnabled]);

  useEffect(() => {
    FullStory('setIdentity', {
      uid: user?.email,
      properties: {
        // email and displayName are specific to FullStory
        email: user?.email,
        displayName: user?.email,
        userId: user?.id,
        teamId: user?.teamId,
        teamName: user?.teamName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        companyName: user?.companyName,
        accountType: user?.accountType,
        ...user?.licences?.reduce(
          (acc, licence, index) => ({
            ...acc,
            [`licence_${index + 1}`]: licence.name,
          }),
          {},
        ),
      },
    });
    // eslint-disable-next-line
  }, [])

  const renderRoutes = useMemo(() => {
    if (!isFeatureActive(featureNames.INFLUENCE_CORE))
      return (
        <Routes location={underProfileModalLocation}>
          <Route path={`/${routes.dashboard}/*`} element={<Dashboard />} />
          {/* Listening routes */}
          <Route path={`/${routes.listening}/:monitoringId`}>
            <Route path=":startDate/:endDate" element={<ListeningDetails />} />
            <Route path=":startDate" element={<ListeningDetails />} />
            <Route path="" element={<ListeningDetails />} />
          </Route>
          <Route path={`/${routes.zendesk}/*`} element={<ZendeskRedirect />} />
          <Route path={`/${routes.myAccount}/*`} element={<SettingsPage />} />
          <Route path="/" element={<FreePlaceholder />} />
          {underProfileModalLocation && (
            <Route path="*" element={<Navigate to="/" />} replace />
          )}
        </Routes>
      );

    const redirectUrl = getRedirectUrl();

    return (
      <>
        {underProfileModalLocation && (
        <Routes location={underProfileModalLocation}>
          {/* Engine routes */}
          <Route path="/" element={<MountRouteByFeature featureName={featureNames.SEARCH_ENGINE} redirectPath={redirectUrl} />}>
            <Route
              index
              element={isNewVersionEnabled ? <SearchEngine /> : <Engine />}
            />
          </Route>

          {/* Content search routes */}
          <Route path={`/${routes.contentSearch}`} element={<MountRouteByFeature featureName={featureNames.SEARCH_ENGINE} onlyForCompanyZZZ />}>
            <Route index element={<ContentSearch />} />
          </Route>

          {/* Campaigns routes */}
          <Route path={`/${routes.campaigns}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route index element={<CampaignsList />} />
            <Route path=":campaignId/*" element={<CampaignDetails />} />
          </Route>

          {/* Messaging routes */}
          <Route path={`/${routes.messaging}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route index element={<Messaging />} />
            <Route path=":conversationId" element={<Messaging />} />
          </Route>

          {/* Dashboard */}
          <Route path={`/${routes.dashboard}/*`} element={<Dashboard />} />

          {/* Listening routes */}
          <Route path={`/${routes.listening}/:monitoringId`}>
            <Route path=":startDate/:endDate" element={<ListeningDetails />} />
            <Route path=":startDate" element={<ListeningDetails />} />
            <Route path="" element={<ListeningDetails />} />
          </Route>

          {/* MyKOLs routes */}
          <Route path={`/${routes.myKOLs}`} element={<MountRouteByFeature featureName={{any: [featureNames.FAVORITES, featureNames.LABELS]}} />}>
            <Route index element={<MyInfluencers />} />
            <Route path="*" element={<MyInfluencers />} />
          </Route>

          {/* Others */}
          <Route path={`/${routes.zendesk}/*`} element={<ZendeskRedirect />} />
          <Route path={`/${routes.myAccount}/*`} element={<SettingsPage />} />
          {env !== ENV_PROD && <Route path={`/${routes.debug}`} element={<DebugPage />} />}

          {/* No routes matched => Redirect */}
          <Route path="*" element={<Navigate replace to={redirectUrl} />} />
        </Routes>
        )}
        <Routes>
          <Route path={`/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          <Route path={`/${routes.contentSearch}/${routes.profile}/:pId`} element={<EntityDispatcher />} />

          {/* Campaigns routes */}
          <Route path={`/${routes.campaigns}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route path={`:campaignId/${routes.profile}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.comparison}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.discountCode}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.trackingLinks}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.reporting}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.messaging}/:conversationId/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>

          {/* Messaging routes */}
          <Route path={`/${routes.messaging}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route path={`:conversationId/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>

          {/* Listening routes */}
          <Route path={`/${routes.listening}/:monitoringId`}>
            <Route path={`:startDate/:endDate/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>

          {/* MyKOLs routes */}
          <Route path={`/${routes.myKOLs}`} element={<MountRouteByFeature featureName={{any: [featureNames.FAVORITES, featureNames.LABELS]}} />}>
            <Route path={`${routes.manage}/${routes.profile}/:pId`} element={<EntityDispatcher />} />
            <Route path={`${routes.follow}/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>
        </Routes>
      </>
    );
  }, [isNewVersionEnabled, underProfileModalLocation]);

  const loader = useMemo(() => (
    <Loader
      theme="navy"
      full
      position="center"
      background="rgba(255, 255, 255, .8)"
    />
  ), []);

  const cnContent = cn('main-content', {
    'grey-bg': isListeningPage,
  });

  return (
    <div className="loaded">
      {(globalLoader || phraseLoader) && loader}
      <Navbar />
     {/* <Suspense fallback={loader}>
        <Modales />
        <Modals />
        <SatisMeter />
      </Suspense>

      <Suspense fallback={null}>
        <ZendeskScript />
      </Suspense> */}

      <div className="main_container">
        {/* <AnnouncementBanner />
        <AsyncTasksListener /> */}
        <div className="structure_scroll">
          <div className="scroll_content">
            <main className={cnContent}>
              {/* <Suspense fallback={loader}>
                {renderRoutes}
              </Suspense> */}
              OLA
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Structure);
