import { useCallback, useEffect, useState } from 'react';
import { setUserPreferences } from 'actions/user';
import { useDispatch, useSelector } from 'utils/redux';


const useHandleHighlight = paramKey => {
  const dispatch = useDispatch();

  const [displayHightLight, setDisplay] = useState(true);

  const { unacknowledgedFeatures, isAdmin } = useSelector(({ user }) => ({
    unacknowledgedFeatures: user.unacknowledgedFeatures,
    isAdmin: user.profile.admin
  }));

  useEffect(() => {
    setDisplay(unacknowledgedFeatures.includes(paramKey))
  }, [paramKey, unacknowledgedFeatures])

  const handleCloseHighlight = useCallback(
    () => dispatch(setUserPreferences(paramKey)),
    [dispatch, paramKey]
  );

  return {
    handleCloseHighlight,
    displayHightLight: displayHightLight && !isAdmin
  }
};

export default useHandleHighlight;