import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import useNumberFormat from 'utils/hooks/useNumberFormat';
import currencies from 'config/currencies';

const NumberFormat = ({
  value,
  style,
  className,
  sign,
  percentage,
  currency,
  displayCurrency,
  showNaNAs,
  compact,
  threeDigitsRule,
  fromCurrency,
}) => {
  const formatNumber = useNumberFormat();

  const output = useMemo(
    () =>
      formatNumber(
        value,
        { sign, percentage, currency, displayCurrency, showNaNAs, compact, threeDigitsRule, fromCurrency }
      ),
    [
      value,
      sign,
      percentage,
      currency,
      displayCurrency,
      formatNumber,
      showNaNAs,
      compact,
      threeDigitsRule,
      fromCurrency,
    ],
  );

  return (
    <span style={style} className={className} translate="no">
      {output}
    </span>
  );
};

NumberFormat.displayName = 'NumberFormat';

NumberFormat.propTypes = {
  value: PropTypes.number,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  className: PropTypes.string,
  sign: PropTypes.bool,
  percentage: PropTypes.bool,
  currency: PropTypes.bool,
  displayCurrency: PropTypes.bool,
  showNaNAs: PropTypes.string,
  compact: PropTypes.bool,
  threeDigitsRule: PropTypes.bool,
  fromCurrency: PropTypes.oneOf(currencies.map(cur => cur.value)),
};

NumberFormat.defaultProps = {
  value: undefined,
  style: {},
  className: null,
  sign: false,
  percentage: false,
  currency: false,
  displayCurrency: false,
  showNaNAs: undefined,
  compact: true,
  threeDigitsRule: false,
  fromCurrency: undefined,
};

export default memo(NumberFormat);
