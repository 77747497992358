import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { Text } from 'kolkit';

import useNumberFormat from 'utils/hooks/useNumberFormat';
import { useSelector } from 'utils/redux';

import styles from './Engine.module.scss';

const SearchTotal = memo(() => {
  const total = useSelector(({ engine }) => engine.results.total);
  const formatNumber = useNumberFormat();
  if (!total || total === 0) return null;

  return (
    <Text className={styles.searchTotalCounter}>
      <FormattedMessage
        id="engine.results.total"
        defaultMessage="<b>{count}</b> KOLs"
        values={{
          b: chunks => <strong>{chunks}</strong>,
          count: formatNumber(total, { compact: false }),
        }}
      />
    </Text>
  );
});

SearchTotal.displayName = 'SearchTotal';

export default SearchTotal;
