import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'kolkit/Loader/Loader';

const ContentSearch = Loadable({
  loader: () => import('components/search/ContentSearch/ContentSearch'),
  loading: () => <Loader full />,
});

export default ContentSearch;
