import React, { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'kolkit';

import styles from './TruncateContent.module.scss';

const TruncateContent = ( { children, className }) => {
  const [tooltip, setTooltip] = useState(false);
  const ref = useRef();
  const childRef = useRef();

  const cnSpan = cn(styles.span, className);

  useLayoutEffect(() => {
    const currentParent = ref.current;
    const currentChild = childRef.current;
    if (currentParent && currentChild) {
      setTooltip(currentParent.offsetWidth === currentChild.offsetWidth);
    }
  }, [ref, childRef, children]);

  const child = useMemo(
    () => (
      <span className={cnSpan} ref={ref}>
         <span className={styles.child} ref={childRef}>
          {children}
        </span>
      </span>

    ),
    [children, childRef, cnSpan]
  )

  return tooltip
    ? <Tooltip content={children}>{child}</Tooltip>
    : child;
};

TruncateContent.displayName = 'TruncateContent';

TruncateContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

TruncateContent.defaultProps = {
  children: null,
  className: undefined
};

export default memo(TruncateContent);
