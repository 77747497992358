import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from '../../Tooltip';

import styles from './Radio.module.scss';

const Radio = ({
  checked,
  className,
  disabled,
  id,
  dataId,
  label,
  name,
  onChange,
  required,
  fullWidth,
  labelClassName,
  theme,
  tooltipContent,
  node,
  ...rest
}) => {
  const cnInputRadio = cn(styles.inputRadio, styles[theme], className, {
    [styles.fullWidth]: fullWidth,
    [styles.required]: required,
    [styles.disabled]: disabled
  });

  const cnLabel = cn(styles.label, styles[theme], labelClassName, {
    [styles.disabled]: disabled
  });

  return (
    <Tooltip content={tooltipContent}>
      <div className={cnInputRadio}>
        <input
          {...rest}
          type="Radio"
          aria-describedby={label}
          checked={checked}
          data-id={dataId || id}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={required}
        />
        {label && (
          <label htmlFor={id} className={cnLabel}>
            <span> {label}</span>
          </label>
        )}
      </div>
    </Tooltip>
  );
};

Radio.displayName = 'Radio';

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dataId: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool
  ])
};

Radio.defaultProps = {
  checked: false,
  className: undefined,
  labelClassName: undefined,
  disabled: false,
  fullWidth: false,
  dataId: '',
  label: null,
  required: false,
  onChange: () => {},
  tooltipContent: null
};

export default memo(Radio);
