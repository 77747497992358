import { useCallback } from 'react';
import { useDeepSelector } from 'utils/redux';

const useFilter = (filterTarget, network) => {

  const chooseFilterTarget = useCallback(
    engine => network.startsWith('influencer') ?
      engine.filters[filterTarget] :
      engine.filters.socialNetworks[network][filterTarget],
    [network, filterTarget]
  );

  const value = useDeepSelector(
    ({ user }) => chooseFilterTarget(user)
  );

  return value;
};

export default useFilter;
