import React, { ComponentProps } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { isSameDay, isAfter, format } from 'date-fns';

import { useDateFnsLocale } from 'utils/date-fns';

import { Icon, Tooltip } from 'kolkit';
import { Typography } from 'components/ui';

type Props = {
  date?: string | null;
  typographyVariant?: ComponentProps<typeof Typography>['variant'];
};

const CampaignContentPublicationDate: React.FC<Props> = ({
  date,
  typographyVariant,
}) => {
  const intl = useIntl();
  const dateFnsLocale = useDateFnsLocale();

  if (!date) return null;

  const isSameDayWithNow = isSameDay(new Date(), new Date(date || ''));
  const isAfterNow =
    !isSameDayWithNow && isAfter(new Date(), new Date(date || ''));

  return (
    <div className="flex aic gap8">
      <Tooltip
        content={format(new Date(date), 'PPPP', { locale: dateFnsLocale })}
      >
        <Typography variant={typographyVariant}>
          {intl.formatDate(date, { day: 'numeric', month: 'long' })}
        </Typography>
      </Tooltip>
      {isSameDayWithNow || isAfterNow ? (
        <Icon
          label="exclamation-triangle"
          theme="solid"
          fill={
            isAfterNow
              ? 'var(--color-alert-error)'
              : 'var(--color-alert-warning)'
          }
        />
      ) : null}
    </div>
  );
};

export default CampaignContentPublicationDate;
