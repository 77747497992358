import React, { useCallback, useEffect, useState } from 'react';
import { Button3, Text, HeightTransition } from 'kolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LinearProgress from '@mui/material/LinearProgress';

import { getPropositionLogs, saveKolComment } from 'actions/campaigns';
import { Profile } from 'types/campaigns';

import usePanel from 'utils/hooks/usePanel';
import { useSelector, useDispatch } from 'utils/redux';
import useLoading from 'utils/hooks/useLoading';
import useGuestSession from 'components/campaigns/hooks/useGuestSession';

import Textarea2 from 'components/ui/Textarea2';

import { PANEL_ID } from '../ProfilePanel';
import PropositionLog from './components/PropositionLog';

import { timelineSx } from './config';
import styles from './TabWorkflow.module.scss';

const TabWorkflow = ({ openCreateComment = false }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [showCommentBox, setShowCommentBox] = useState(openCreateComment);
  const [comment, setComment] = useState('');
  const { show, data } = usePanel(PANEL_ID, false);
  const { profileId } = data || {};
  const { current_user_id, campaignId } = useSelector(
    ({
      user: {
        profile: { id: current_user_id },
      },
      views: {
        campaignDetails: { id: campaignId },
      },
    }) => ({
      current_user_id,
      campaignId,
    }),
  );
  const { wrapAction: wrapActionForRegisteredGuest } = useGuestSession({ campaignId });

  const paginated_proposition_logs = useSelector(({ views, campaigns }) => {
    const campaignId = views.campaignDetails.id;

    if (campaignId && profileId && campaigns) {
      const { proposition_logs } =
        campaigns[campaignId].profiles[profileId] || {};
      if (proposition_logs) return proposition_logs;
    }

    return {
      page: 0,
      per_page: 25,
      proposition_logs: [],
      total_count: 0,
    } as Profile['proposition_logs'];
  });

  const { loading: loadingGetPropositionLogs } =
    useLoading('getPropositionLogs');
  const { loading: loadingSaveKolComment } = useLoading('saveKolComment');

  const handleClickAddComment = useCallback(() => {
    wrapActionForRegisteredGuest(() => {
      setShowCommentBox((old) => !old);
    });
  }, [wrapActionForRegisteredGuest]);

  const handleClickCancel = useCallback(() => {
    setComment('');
    setShowCommentBox(false);
  }, []);

  const handleClickApply = useCallback(() => {
    void dispatch(
      saveKolComment({
        profileId,
        campaignId,
        comment,
      }),
    ).then(() => {
      setComment('');
      setShowCommentBox(false);
    });
  }, [campaignId, comment, dispatch, profileId]);

  const handleClickLoadMore = useCallback(() => {
    void dispatch(getPropositionLogs({ profileId, loadMore: true }));
  }, [dispatch, profileId]);

  useEffect(() => {
    if (profileId) {
      void dispatch(getPropositionLogs({ profileId }));
    }
  }, [dispatch, profileId]);

  useEffect(() => {
    setShowCommentBox(!!openCreateComment);
  }, [openCreateComment]);

  if (!show) return null;

  return (
    <div className="flex fdc fg1">
      {loadingGetPropositionLogs && <LinearProgress />}
      <div className={styles.container}>
        <Button3
          theme="primary"
          size="small"
          icon="plus"
          className="asfe"
          onClick={handleClickAddComment}
        >
          <FormattedMessage id="global.cta.addAComment" />
        </Button3>
        <HeightTransition on={showCommentBox}>
          <div className={styles.commentBox}>
            <Textarea2
              id="profile-note"
              name="note"
              placeholder={intl.formatMessage({ id: 'global.cta.addAComment' })}
              value={comment}
              onChange={setComment}
              rows={5}
              disabled={loadingSaveKolComment}
              autoFocus
              fullWidth
            />
            <div className="flex aic gap8 jce">
              <Button3
                theme="tertiary"
                onClick={handleClickCancel}
                disabled={loadingSaveKolComment}
              >
                <FormattedMessage id="global.cta.cancel" />
              </Button3>
              <Button3
                theme="secondary"
                onClick={handleClickApply}
                disabled={loadingSaveKolComment}
              >
                <FormattedMessage id="global.cta.save" />
              </Button3>
            </div>
          </div>
        </HeightTransition>
        {paginated_proposition_logs?.proposition_logs?.length > 0 ? (
          <Timeline sx={timelineSx}>
            {paginated_proposition_logs?.proposition_logs?.map((log) => {
              return (
                <TimelineItem key={log.id}>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot />
                    <TimelineConnector />
                    {log.comment && <TimelineConnector className="filler" />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <PropositionLog log={log} currentUserId={current_user_id} />
                  </TimelineContent>
                </TimelineItem>
              );
            })}
            {paginated_proposition_logs.total_count >
              paginated_proposition_logs?.proposition_logs?.length && (
              <TimelineItem key="loadMore" className="loadMore">
                <TimelineSeparator>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Button3
                    label={intl.formatMessage({ id: 'global.cta.loadMore' })}
                    onClick={handleClickLoadMore}
                    size="small"
                    theme="tertiaryNew"
                  />
                </TimelineContent>
              </TimelineItem>
            )}
            <TimelineItem key="botom-filler" className="filler">
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
            </TimelineItem>
          </Timeline>
        ) : (
          <div className={styles.placeholderContainer}>
            <Text resetMargin fontSize={22} fontWeight={600}>
              <FormattedMessage id="campaigns.shareCasting.placeholder.title" />
            </Text>
            <Text resetMargin fontSize={16} fontWeight={400}>
              <FormattedMessage id="campaigns.shareCasting.placeholder.subtitle" />
            </Text>
            <img
              srcSet={intl.formatMessage({
                id: 'campaigns.shareCasting.placeholder.image',
              })}
              alt="Share casting placeholder"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TabWorkflow;
