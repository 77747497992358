import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Text } from 'kolkit';

import { INSTAGRAM } from 'constants/networks';

import { WrapperFilter } from '../Components';
import {
  Ages,
  Community,
  CommunityLanguages,
  Countries,
  Genders,
  Interest,
} from '../Modules';
import useActiveFilter from '../hooks/useActiveFilter';

import InstagramContentFilter from './InstagramContentFilter';

import styles from './Filter.module.scss';

const Instagram = ({ onChange }) => {
  const network = INSTAGRAM;
  const intl = useIntl();

  const isActive = useActiveFilter(network);

  return (
    <WrapperFilter title={network} isActive={isActive}>
      <Community
        label={intl.formatMessage({id: 'communitySize'})}
        network={network}
        filterKey="communitySize"
        onChange={onChange}
        disabled={!isActive}
      />
      <div />
      <div />
      <h3 className={styles.sectionTitle}>{intl.formatMessage({id: 'engine.filters.audience'})}</h3>
      <Interest onChange={onChange} network={network} disabled={!isActive} />
      <Ages onChange={onChange} network={network} disabled={!isActive} />
      <Genders onChange={onChange} network={network} disabled={!isActive} />
      <CommunityLanguages
        onChange={onChange}
        network={network}
        disabled={!isActive}
      />
      <Countries
        onChange={onChange}
        network={network}
        filterKey="communityCountries"
        disabled={!isActive}
        isLocation
        className={styles.instagramCountries}
      />
      <h3 className={styles.sectionTitle}>{intl.formatMessage({id: 'engine.filters.content'})}</h3>
      <Community
        label={intl.formatMessage({id: 'engagementRate'})}
        network={network}
        filterKey="communityEngagementRate"
        onChange={onChange}
        disabled={!isActive}
      />
      <div className={styles.fullWidth}>
        <div className={styles.tile}>
          <Text tag="h6">{intl.formatMessage({id: 'engine.filters.reels'})}</Text>
          <InstagramContentFilter
            type="reels"
            onChange={onChange}
            className={styles.contentFilter}
            hint={intl.formatMessage({
              id: 'engine.filters.instagram.reels.hint',
            })}
          />
        </div>
      </div>
    </WrapperFilter>
  );
};

Instagram.displayName = 'Instagram';

Instagram.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(Instagram);
