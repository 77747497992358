import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { Button3 } from 'kolkit';

import { updatePage } from 'actions/engine';
import { useSelector, useDispatch } from 'utils/redux';

const SearchFooter = () => {
	const dispatch = useDispatch();
	const inlt = useIntl();

	const [currentPage, resultsPerPage, totalResults, hasSearch] = useSelector(
		({ engine }) => [
			engine.results.page,
			engine.results.resultsPerPage || 0,
			engine.results.total || 0,
			engine.results.hasSearch
		]
	);

	const handleChange = useCallback(
		() => dispatch(updatePage(currentPage + 1)),
		[dispatch, currentPage]
	);

	const hasMore = useMemo(
		() => resultsPerPage && (currentPage + 1) < Math.ceil(totalResults / resultsPerPage),
		[currentPage, totalResults, resultsPerPage]
	);

	if (totalResults === 0 || (totalResults <= resultsPerPage) || !hasSearch) return null;

	return (
		<div className="engine-search-pager">
			<Button3
				label={inlt.formatMessage({id: 'global.cta.loadMore'})}
				onClick={handleChange}
				size="large"
				disabled={!hasMore}
			/>
		</div>
	);
};

export default memo(SearchFooter);
