import React, { useCallback, useMemo, useState, lazy } from 'react';
import { useIntl} from 'react-intl-phraseapp';

import routes from 'config/routes';

import { TabsPage } from 'components/ui/';

import styles from './Dashboard.module.scss';

const Listening = lazy(() => import('components/listeningV2/Listening'));
const FollowersIncrease = lazy(() => import('components/followersIncrease/FollowersIncrease'));


const Dashboard = () => {
  const intl = useIntl();

  const [collapse, setCollapse] = useState(false);

  const handleCollapse = useCallback(
    forcedValue =>
      setCollapse(prevState =>
        forcedValue === undefined ? !prevState : forcedValue,
      ),
    [],
  );

  const dataset = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'dashboard.tabs.followers', defaultMessage: 'Followers increase'}),
        link: routes.followersIncrease,
        content: <FollowersIncrease />,
      },
      {
        label: intl.formatMessage({ id: 'dashboard.tabs.listening', defaultMessage: 'Listening'}),
        link: routes.listening,
        content: <Listening />,
      },
    ],
    [intl],
  );

  const Wrapper = useCallback(({ children }) => (
    <div className={styles.tabContent}>
      <div className={styles.scrollBarWrapper}>{children}</div>
    </div>
  ), []);

  return (
    <div className={styles.container}>
      <TabsPage
        parentPath={routes.dashboard}
        dataset={dataset}
        withRouter
        title={intl.formatMessage({ id: 'dashboard.title', defaultMessage: "Dashboard" })}
        subTitle={intl.formatMessage({ id: 'dashboard.subTitle', defaultMessage: "Subtitle" })}
        collapse={collapse}
        onCollapse={handleCollapse}
        ContentWrapper={Wrapper}
      />
    </div>

  );
};

export default Dashboard;
