import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Field, SelectGmaps } from 'components';

import { useFilter } from 'components/engine/hooks';

import styles from '../engineModule.module.scss';


const LocalisationsGmap = ({ network, onChange, disabled, max }) => {
  const filterTarget = 'localisations';
  const intl = useIntl();
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(
    ({ item, selected }) => {
      return onChange && onChange({
        field: filterTarget,
        value: selected,
        selected: item,
        network,
      })
    },
    [onChange, network, filterTarget]
  );

  return (
    <Field label={intl.formatMessage({ id: 'locations.label'})}>
      <SelectGmaps
        id={`filter-${network}`}
        className={styles.select}
        label=""
        selected={value}
        maxMaps={max}
        placeholder={intl.formatMessage(
          { id: 'locations.filterBy'},
          { max}
        )}
        onChange={handleChange}
        disabled={disabled}
      />
    </Field>
  )
};

LocalisationsGmap.displayName = 'LocalisationsGmap';

LocalisationsGmap.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

LocalisationsGmap.defaultProps = {
  disabled: false,
};


export default memo(LocalisationsGmap);
