import { PluginOptionsByType } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { ComponentProps } from 'react';
import { Bar } from 'react-chartjs-2';

import { getStore } from 'config/store';

type BarOptions = ComponentProps<typeof Bar>['options'];

const AGES_SLICE = ['13-17', '18-24', '25-34', '35-44', '45-64', '65+'];

export const verticalOptions: BarOptions = {
  indexAxis: 'x',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: tooltipItems => AGES_SLICE[tooltipItems[0]?.dataIndex],
        label: context => `${context?.dataset?.label}: ${context?.formattedValue}%`
      },
    },
    datalabels: {
      formatter: value => `${value}%`,
      anchor: 'end',    // Position of the data label
      align: 'end',     // Align the label at the end of the bar (top)
      color: '#355D67',
      offset: 0,
      padding: 2,
      font: {
        size: 10
      },
    },
    htmlLegend: {},
  } as _DeepPartialObject<PluginOptionsByType<"bar">>,
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  elements: {
    bar: {
      borderRadius: {
        topLeft: 4,
        topRight: 4,
      },
    },
  },
  layout: {
    padding: {
      top: 12
    }
  },
  scales: {
    x: { // Ages slice axis
      stacked: false,
      grid: {
        display: false,
      },
      ticks: {
        padding: 0,
        color: '#355D67',
        callback(value) {
          const yData = this.getLabelForValue(Number(value)) as unknown as {label: string; total: number};
          return yData?.label;
        },
      },
    },
    x0: { // Total percent axis
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        padding: 0,
        color: '#9BB4BB',
        callback(value) {
          const yData = this.getLabelForValue(Number(value)) as unknown as {label: string; total: number};
          return `(${yData?.total})`;
        }
      }
    },
    y: {
      stacked: false,
      display: false,
    },
  },
};

export const horizontalOptions: BarOptions = {
  indexAxis: 'y',
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: tooltipItems => AGES_SLICE[tooltipItems[0]?.dataIndex],
        label: context => `${context?.dataset?.label}: ${context?.formattedValue}%`
      },
    },
    htmlLegend: {},
    datalabels: {
      display: false,
    }
  } as _DeepPartialObject<PluginOptionsByType<'bar'>>,
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
    axis: 'y',
  },
  elements: {
    bar: {
      borderRadius: {
        topRight: 4,
        bottomRight: 4,
      },
    },
  },
  scales: {
    x: {
      display: false,
      stacked: false,
      grid: {
        display: false,
        drawOnChartArea: false,
      },
    },
    y: {
      stacked: false,
      grid: {
        display: false,
        drawOnChartArea: false,
      },
      ticks: {
        color: '#355D67',
        font: {
          size: 12,
        },
        callback(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          const yData = this.getLabelForValue(Number(value)) as unknown as {label: string; total: number};
          return yData?.label
        }
      },
    },
    y0: {
      stacked: false,
      position: 'right',
      grid: {
        display: false,
        drawOnChartArea: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#355D67',
        font: {
          size: 12,
        },
        callback(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          const yData = this.getLabelForValue(Number(value)) as unknown as {label: string; total: number};
          return yData?.total
        }
      },
    },
  },
};

export const generateOptionsWithGenders = ({ options, htmlLegendOptions={} }) => {
  const { env } = getStore();
  const newOptions = { ...options };
  newOptions.locale = env.locale;
  newOptions.plugins = {
    ...newOptions.plugins,
    legend: {
      display: false,
    },
    htmlLegend: {
      ...htmlLegendOptions,
    },
  } as _DeepPartialObject<PluginOptionsByType<'bar'>>;
  return newOptions;
};
