import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Field, SelectPercent, SelectTag } from 'components';
import { configFilters } from 'config/filters';
import useZendeskLink from 'utils/hooks/useZendeskLink';
import { useFilter } from 'components/engine/hooks';

import { Helper } from '../../Components';

import styles from './Countries.module.scss';

const Countries = ({
  network,
  filterKey,
  onChange,
  disabled,
  disableHelper,
  className,
  max,
  isLocation,
}) => {
  const intl = useIntl();
  const { datas, params } = configFilters[filterKey];
  const value = useFilter(filterKey, network);
  const handleChange = useCallback(
    ({ item, selected }) =>
      onChange({
        field: filterKey,
        value: selected,
        selected: item,
        network,
      }),
    [onChange, network, filterKey],
  );

  const getData = useMemo(
    () =>
      datas
        ?.map(item =>
          Object.assign(
            {
              id: item,
              label: intl.formatDisplayName(item, {type: 'region'}),
              value: item,
            },
            !isLocation && { countryCode: item },
          ),
        )
        ?.sort((current, next) => current?.label?.localeCompare(next?.label)),
    [datas, isLocation, intl],
  );

  const getMax = useMemo(() => max || params.max, [max, params.max]);

  const faqLink = useZendeskLink(configFilters?.[filterKey]?.zendeskFaqPath);

  return (
    <Field
      className={className}
      label={intl.formatMessage({id: filterKey === 'accountCountry' ? 'locations.declared':'locations.audience'},{count: getMax})}
    >
      <div>
        {filterKey === 'accountCountry' ? (
          <SelectTag
            hasSearch
            selected={value}
            dataset={getData}
            sort={params.sort}
            pinned={params.pinned}
            maxTags={getMax}
            defaultOptionLabel={intl.formatMessage(
              { id: isLocation ? 'locations.filterBy' : 'locations.filterByCountry' },
              { max: getMax }
            )}
            onChange={handleChange}
            disabled={disabled}
          />
        ) : (
          <SelectPercent
            selected={value}
            dataset={getData}
            defaultOptionLabel={intl.formatMessage(
              { id: isLocation ? 'locations.filterBy' : 'locations.filterByCountry' },
              { max: getMax }
            )}
            onChange={handleChange}
            disabled={disabled}
            max={getMax}
            isLocation={isLocation}
          />
        )}
        {!disableHelper && (
          <a
            className={styles.faqLink}
            target="_blank"
            rel="noopener noreferrer"
            href={faqLink}
          >
            <Helper text={intl.formatMessage({id: 'locations.helper'})} tooltip="" />
          </a>
        )}
      </div>
    </Field>
  );
};

Countries.displayName = 'Countries';

Countries.propTypes = {
  network: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disableHelper: PropTypes.bool,
  max: PropTypes.number,
};

Countries.defaultProps = {
  disabled: false,
  disableHelper: false,
  max: 1,
};

export default memo(Countries);
