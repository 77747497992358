export const statsSharingsStatus = {
  fromApi: profiles => profiles?.map(profile => ({
    id: profile.profile_id,
    statsSharing: {
      status: profile.state
    }
  })) || [],
  toApi: (profileIds, campaignId) => ({
    'profile_ids': profileIds,
    'project_id': campaignId
  })
};

export const sendStatsRequest = {
  fromApi: statsSharingsStatus.fromApi,
  toApi: (profileIds, projectId, message) => ({
    'profile_ids': profileIds,
    'project_id': projectId,
    message
  })
};
