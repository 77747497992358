import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Icon, Checkbox2, Spaces } from 'kolkit';

import { Markdown } from 'components/ui';
import { useFilter } from 'components/engine/hooks';

import styles from './SeedingKol.module.scss';

const SeedingKol = ({ network, onChange }) => {
  const filterTarget = 'seedingKol';
  const intl = useIntl();
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(() => {
    onChange({
      field: filterTarget,
      value: !value,
      selected: !value,
      network
    });
  }, [network, onChange, value]);

  return (
    <>
      <div className={styles.container}>
        <Checkbox2
          id={filterTarget}
          checked={value}
          onChange={handleChange}
          label= {
            <div className={styles.label}>
              <span className={styles.title}>{intl.formatMessage({ id: 'seedingProfiles.label' })}</span>
              <Icon
                tooltip={<Markdown>{intl.formatMessage({ id: 'seedingProfiles.description' })}</Markdown>}
                label="info-circle"
                size="small"
              />
            </div>
          }
        />
      </div>
      <Spaces bottom={8} />
    </>
  );
};

SeedingKol.displayName = 'SeedingKol';

SeedingKol.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SeedingKol;
