import { useEffect, useRef, useState, memo } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useSearchParams } from 'react-router-dom';
import { Slide, Id as ToastID, toast, ToastContainer } from 'react-toastify';
import cn from 'classnames';

import { notifications } from 'config/app';
import useLocalStorage from 'utils/hooks/useLocalStorage';

import { Button3, Icon } from 'kolkit';

import styles from './NewSearchToast.module.scss';

const CONTAINER_ID = 'searchToastContainer';
const CloseButton = ({ onClick }) => (
  <Icon
    label="times-circle"
    onClick={onClick}
    fill="#001B3E"
    size="medium"
    theme="solid"
    isButton
    className={styles.icon}
    dataId="SEA-banner-close"
  />
);

const NewSearchToast = () => {
  const toastId = useRef<ToastID>();
  //   Use undefined DURING collapsing
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>(false);
  const [, enableNewVersion] = useLocalStorage('search.use-new-version');
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    toastId.current = toast(
      <div className="flex aic gap8">
        <span className={styles.message}>
          <FormattedMessage id="engineSearch.switch.toNew" />
        </span>
        <Button3
          theme="tertiary"
          size="small"
          onClick={() => {
            enableNewVersion(true);
            setSearchParams((params) => ({ ...params, q: '' }));
          }}
          dataId="SEA-banner-try"
        >
          <FormattedMessage id="engineSearch.switch.tryIt" />
        </Button3>
      </div>,
      {
        containerId: CONTAINER_ID,
        icon: (props) => (
          <Icon
            {...props}
            label={notifications.new.icon}
            fill={notifications.new.color}
            size="huge"
            theme="regular"
          />
        ),
      },
    );

    return () => {
      toast.dismiss(toastId.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableNewVersion]);

  useEffect(() => {
    if (toastId?.current && isCollapsed) {
      toast.update(toastId.current, { closeButton: false });
    }
  }, [isCollapsed]);

  return (
    <ToastContainer
      enableMultiContainer
      containerId={CONTAINER_ID}
      transition={Slide}
      autoClose={false}
      closeOnClick={false}
      closeButton={() => (
        <CloseButton
          onClick={() => {
            setIsCollapsed(undefined);
            setTimeout(() => {
              setIsCollapsed(true);
            }, 500);
          }}
        />
      )}
      toastClassName={styles.toast}
      position={toast.POSITION.BOTTOM_RIGHT}
      hideProgressBar
      className={cn(styles.container, {
        [styles.collapsed]: isCollapsed,
        [styles.collapsing]: isCollapsed === undefined,
      })}
    />
  );
};

export default memo(NewSearchToast);
