import React, { lazy, Suspense, useCallback } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import * as featureNames from 'constants/feature-names';
import { MountComponentByFeature } from 'utils/feature-toggle';
import { useDispatch, useSelector } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import { promptDeleteDiscountCode } from 'actions/shopify';
import { promptDeleteTrackingLink } from 'actions/trackingLinks';
import { updateProfileState } from 'actions/campaigns';
import useGuestSession from 'components/campaigns/hooks/useGuestSession';
import useLoading from 'utils/hooks/useLoading';
import { MEDIA_PLAN_MODAL_ID } from 'components/campaigns/Modals/MediaPlanModal';

import { ActionButtonsPropTypes, ActionButtonTypes } from './SharedPropTypes';
import styles from './ProfileListMultiAction.module.scss';

const Icon = lazy(() => import('kolkit/Icon'));
const Favorite = lazy(() => import('components/ui/Favorite'));
const ButtonRemoveProfilesFromCampaign = lazy(() =>
  import('components/ui/ButtonRemoveProfilesFromCampaign'),
);
const InstaSharing = lazy(() => import('components/ui/InstaSharing'));
const AddLabel = lazy(() =>
  import('components/profileController/modules/AddLabel'),
);
const AddToCampaign = lazy(() =>
  import('components/profileController/modules/AddToCampaign'),
);
const AddToList = lazy(() =>
  import('components/profileController/modules/AddToList'),
);
const SendMessage = lazy(() =>
  import('components/profileController/modules/SendMessage'),
);
const MergeProfilesButton = lazy(() =>
  import('components/profileController/modules/MergeProfilesButton'),
);
const SelectCampaignStatus = lazy(() =>
  import('components/modules/SelectCampaignStatus'),
);

const multiSelectTrackerPrefix = 'from_multi_select_';

const ActionButtons = ({ selectedProfiles, actions, onActionDone }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { on: openEditDelivery } = useModal(MEDIA_PLAN_MODAL_ID);

  const { on: onGlobalLoader, off: offGlobalLoader } = useLoading('globalLoader');

  const { campaigns, currentCampaignId } = useSelector(
    ({
      campaigns,
      views: {
        campaignDetails: { id: currentCampaignId },
      },
    }) => ({ campaigns, currentCampaignId }),
  );

  const { wrapAction: wrapActionForRegisteredGuest } = useGuestSession({
    campaignId: currentCampaignId,
  });

  const updateState = useCallback(
    async ({ value }) => {
      wrapActionForRegisteredGuest(async () => {
        onGlobalLoader();
        await dispatch(
          updateProfileState({
            campaignId: currentCampaignId,
            profilesId: selectedProfiles,
            stateId: value,
          }),
        );
        offGlobalLoader();
      });
    },
    [
      wrapActionForRegisteredGuest,
      onGlobalLoader,
      dispatch,
      currentCampaignId,
      selectedProfiles,
      offGlobalLoader,
    ],
  );

  const handleOpenDelivery = useCallback(
    () => {
      if (selectedProfiles?.length === 1) {
        const profileDelivery = campaigns?.[currentCampaignId]?.profiles?.[selectedProfiles[0]]?.delivery || {}
        openEditDelivery({
          data: profileDelivery,
          profilesIds: selectedProfiles,
          campaignId: currentCampaignId,
        });
      } else {
        openEditDelivery({
          profilesIds: selectedProfiles,
          campaignId: currentCampaignId,
        });
      }
    },
    [selectedProfiles, currentCampaignId, campaigns, openEditDelivery]
  );

  return (
    <div className={styles.ButtonsWrapper}>
      {actions?.map((action) => {
        switch (action.name) {
          case ActionButtonTypes.ADD_TO_FAVORITES:
            return (
              <MountComponentByFeature
                key={ActionButtonTypes.ADD_TO_FAVORITES}
                featureName={featureNames.FAVORITES}
              >
                <Suspense fallback={null}>
                  <Favorite
                    from={action.analyticsLabel}
                    action={action.actionType}
                    profileIds={selectedProfiles}
                    onActionDone={onActionDone}
                    dataId="CA-multi-fav"
                  />
                </Suspense>
              </MountComponentByFeature>
            );
          case ActionButtonTypes.APPLY_TAGS:
            return (
              <Suspense key={ActionButtonTypes.APPLY_TAGS} fallback={null}>
                <AddLabel
                  from={multiSelectTrackerPrefix + action.analyticsLabel}
                  ids={selectedProfiles}
                  noLabel
                  icon
                  iconProps={{ theme: 'regular' }}
                  dataId="CA-multi-labels"
                />
              </Suspense>
            );
          case ActionButtonTypes.ADD_TO_CAMPAIGNS:
            return (
              <Suspense
                key={ActionButtonTypes.ADD_TO_CAMPAIGNS}
                fallback={null}
              >
                <AddToCampaign
                  from={multiSelectTrackerPrefix + action.analyticsLabel}
                  ids={selectedProfiles}
                  noLabel
                  icon
                  iconProps={{ theme: 'regular' }}
                  dataId="CA-multi-add-camp"
                />
              </Suspense>
            );
          case ActionButtonTypes.REMOVE_FROM_CAMPAIGN:
            return (
              <MountComponentByFeature
                key={ActionButtonTypes.REMOVE_FROM_CAMPAIGN}
                featureName={featureNames.MANAGE_CAMPAIGN}
              >
                <Suspense fallback={null}>
                  <ButtonRemoveProfilesFromCampaign
                    from={action.analyticsLabel}
                    profileIds={selectedProfiles}
                    fromMultiSelect
                    onActionDone={onActionDone}
                    dataId="CA-multi-remove"
                  />
                </Suspense>
              </MountComponentByFeature>
            );
          case ActionButtonTypes.SEND_MESSAGE:
            return (
              <Suspense key={ActionButtonTypes.SEND_MESSAGE} fallback={null}>
                <SendMessage
                  profileIds={selectedProfiles}
                  from="campaign"
                  icon
                  dataId="CA-multi-email"
                />
              </Suspense>
            );
          case ActionButtonTypes.ASK_INSTA_SHARING:
            return (
              <MountComponentByFeature
                key={ActionButtonTypes.ASK_INSTA_SHARING}
                featureName={featureNames.MANAGE_CAMPAIGN}
              >
                <Suspense fallback={null}>
                  <InstaSharing
                    profileIds={selectedProfiles}
                    from="List"
                    iconProps={{ theme: 'regular' }}
                    dataId="CA-multi-instaco"
                  />
                </Suspense>
              </MountComponentByFeature>
            );
          case ActionButtonTypes.ADD_TO_LIST:
            return (
              <Suspense key={ActionButtonTypes.ADD_TO_LIST} fallback={null}>
                <AddToList
                  profileIds={[selectedProfiles].flat()}
                  iconProps={{ theme: 'regular' }}
                  icon
                  dataId="CA-multi-list"
                />
              </Suspense>
            );
          case ActionButtonTypes.MERGE_PROFILES:
            return (
              <Suspense key={ActionButtonTypes.MERGE_PROFILES} fallback={null}>
                <MergeProfilesButton profileIds={selectedProfiles} />
              </Suspense>
            );
          case ActionButtonTypes.SET_STATUS:
            return (
              <div key={ActionButtonTypes.SET_STATUS} style={{ minWidth: 156 }}>
                <Suspense fallback={null}>
                  <SelectCampaignStatus
                    className={styles.setStatus}
                    value={null}
                    onChange={(params) => {
                      updateState(params);
                      if (action.onSuccess) action.onSuccess();
                    }}
                    disabled={action.disabled}
                    dataId="CA-multi-set-status"
                    active
                    theme="tone"
                    castingOnly={action.castingOnly}
                  />
                </Suspense>
              </div>
            );
          case ActionButtonTypes.REMOVE_PROMO_CODE:
            return (
              <Suspense
                key={ActionButtonTypes.REMOVE_PROMO_CODE}
                fallback={null}
              >
                <Icon
                  label="trash-alt"
                  theme="regular"
                  tooltip={intl.formatMessage(
                    { id: 'shopify.table.selected.delete' },
                    { count: selectedProfiles?.length },
                  )}
                  onClick={() =>
                    dispatch(
                      promptDeleteDiscountCode({
                        profileIds: selectedProfiles,
                      }),
                    )
                  }
                  disabled={action.disabled}
                  isButton
                />
              </Suspense>
            );
          case ActionButtonTypes.EXPIRE_TRACKING_LINKS:
            return (
              <Suspense
                key={ActionButtonTypes.EXPIRE_TRACKING_LINKS}
                fallback={null}
              >
                <Icon
                  label="trash-alt"
                  theme="regular"
                  tooltip={intl.formatMessage({
                    id: `campaigns.details.tracking_links.modal.disable.hint`,
                  })}
                  onClick={() =>
                    dispatch(
                      promptDeleteTrackingLink({
                        profileIds: selectedProfiles,
                      }),
                    )
                  }
                  disabled={action.disabled}
                  isButton
                />
              </Suspense>
            );
          case ActionButtonTypes.SET_MEDIA_COUNT:
            return (
              <Icon
                key={ActionButtonTypes.SET_MEDIA_COUNT}
                label="calculator"
                theme="regular"
                tooltip={intl.formatMessage({
                  id: `campaigns.delivery.multiple.cta.tooltip`,
                })}
                onClick={handleOpenDelivery}
                disabled={action.disabled}
                isButton
                dataId="CA-multi-plans"
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

ActionButtons.displayName = 'ActionButtons';

ActionButtons.propTypes = ActionButtonsPropTypes;

ActionButtons.defaultProps = {
  selectedProfiles: [],
  actions: [],
  onActionDone: undefined,
};

export default ActionButtons;
