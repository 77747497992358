import * as consentMapper from 'mappers/consent';
import { post } from './index';

export const getStatsSharingsStatus = (profileIds, campaignId) => post({
  endpoint: `influence/stats_sharing_requests/states`,
  params: consentMapper.statsSharingsStatus.toApi(profileIds, campaignId),
});

export const sendStatsRequest = (profileIds, projectId, message) => post({
  endpoint: `influence/stats_sharing_requests`,
  params: consentMapper.sendStatsRequest.toApi(profileIds, projectId, message),
});
