import { useCallback, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';

import { setClientToken } from 'actions/env';
import { update } from 'actions/views';
import { USER_ROLES } from 'constants/states';
import { useDispatch, useSelector } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import { RegisterGuest_MODAL_ID } from '../details/PublicCastingValidation/modules/RegisterGuestModal';

export interface Guest {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  session_token: string;
}

const useGuestSession = ({ campaignId }) => {
  const dispatch = useDispatch();
  const { on: showRegisterGuestModal } = useModal(RegisterGuest_MODAL_ID);
  const delayedAction = useRef<() => void>();
  const isGuestUser = useSelector(
    ({ user: { role } }) => role === USER_ROLES.GUEST,
  );

  const guest = useSelector<Guest | undefined>(
    ({
      views: {
        campaignDetails: { guest },
      },
    }) => guest as Guest | undefined,
  );

  useEffect(() => {
    if (!campaignId) return;
    if (!guest) {
      // Update token from cookies
      const cookies = new Cookies();
      const savedGuestInfos = cookies.get<Guest>(`_kolsquare_logged_guest_${campaignId}`);

      if (savedGuestInfos) {
        // Guest user is already authenticated
        dispatch(setClientToken(savedGuestInfos.session_token));
        dispatch(
          update({
            view: 'campaignDetails',
            guest: savedGuestInfos,
          }),
        );
      }
    }
  }, [dispatch, guest, campaignId]);

  useEffect(() => {
    // Do delayed action if logged
    if (guest && delayedAction.current) {
      delayedAction.current();
      delayedAction.current = undefined;
    }
  }, [guest]);

  const wrapAction = useCallback(
    (action) => {
      if (!action || typeof action !== 'function') return null;
      if (!isGuestUser || guest) {
        // User is authenticated
        action();
      } else {
        // Save action somewhere
        delayedAction.current = action;
        // Log user first then do action
        showRegisterGuestModal();
      }
    },
    [guest, isGuestUser, showRegisterGuestModal],
  );

  return {
    isGuestUser,
    isGuestLogged: !!guest,
    wrapAction,
  };
};

export default useGuestSession;
