import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';

import { FRONTEND_DATE_FORMAT } from 'config/app';
import { parseStringToDateRange } from 'utils/dateRangeUtil';
import useModal from 'utils/hooks/useModal';

import { ToggleButtons2 } from 'components/ui';

import styles from './DateSlicesPicker.module.scss';

const DateSlicesPicker = ({
  selected,
  size,
  disabled,
  dateSlices,
  onChange,
  className,
  theme,
  dataIdPrefix,
}) => {
  const intl = useIntl();
  const { on: openDateRangePickerModal } = useModal('dateRangePickerModal');

  const handleChangeSlice = useCallback(
    type => {
      if (type !== 'custom') {
        onChange(getSlices(type));
      }
    },
    [onChange],
  );

  const handleClickCustom = useCallback(
    () => {
      const slice = selected ? getSlices(selected) : undefined;
      openDateRangePickerModal({
        onDateRangeChange: onChange,
        startDate: slice?.startAt,
        endDate: slice?.endAt,
        noPresets: true,
        maxDate: moment(),
      });
    },
    [selected, onChange, openDateRangePickerModal]
  );

  const value = useMemo(
    () => (!dateSlices.includes(selected) ? 'custom' : selected),
    [dateSlices, selected],
  );

  const dateSlicesDataset = useMemo(
    () => {
      return dateSlices.map(slice => ({
        value: slice,
        label: slice === 'custom' ? null : intl.formatMessage({id: `global.filter.date.${slice}`}),
        icon: slice === 'custom' ? 'sliders-h-square' : null,
        selected: value === slice,
        onChange: slice === 'custom' ? handleClickCustom : null,
        dataId: dataIdPrefix ? `${dataIdPrefix}${slice}` : undefined,
      }));
    },
    [dateSlices, intl, value, handleClickCustom, dataIdPrefix]
  );

  return (
    <div className={cn(styles.picker, className)}>
      <ToggleButtons2
        value={value}
        dataset={dateSlicesDataset}
        disabled={disabled}
        size={size}
        disableRipple
        onChange={handleChangeSlice}
        className={styles.buttons}
        theme={theme || "primary"}
      />
    </div>
  );
};

const getSlices = slice => {
  let range = {
    startAt: moment().startOf('day'),
    endAt: moment().endOf('day'),
  };

  switch (slice) {
    case 'lastMonth':
      range.startAt.subtract(1, 'months');
      break;
    case 'last3Months':
      range.startAt.subtract(3, 'months');
      break;
    case 'last6Months':
      range.startAt.subtract(6, 'months');
      break;
    case 'lastYear':
      range.startAt.subtract(1, 'years');
      break;
    default:
      range = parseStringToDateRange(slice, FRONTEND_DATE_FORMAT);
  }

  return {
    slice,
    startAt: slice !== 'all' ? range.startAt.format() : '',
    endAt: slice !== 'all' ? range.endAt.format() : '',
  };
};

DateSlicesPicker.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.string,
  size: PropTypes.string,
  dateSlices: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  className: PropTypes.string,
  theme: PropTypes.string,
  dataIdPrefix: PropTypes.string,
};

DateSlicesPicker.defaultProps = {
  size: 'medium',
  disabled: false,
  selected: 'all',
  dateSlices: [],
  onChange: () => {},
  className: '',
  theme: "primary",
  dataIdPrefix: null,
};

DateSlicesPicker.displayName = 'DateSlicesPicker';

export default memo(DateSlicesPicker);
