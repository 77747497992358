export const COLUMNS: {
  name: string;
  label: string;
  sortable?: boolean;
  sortKey?: string;
  subLabel?: string;
  minWidth?: string | number;
}[] = [
  {
    name: 'name',
    sortKey: 'first_name',
    label: 'settings.organization.table.header.name',
    minWidth: 355,
  },
  {
    name: 'team',
    sortKey: 'team.name',
    label: 'settings.organization.table.header.team',
    minWidth: 150,
  },
  {
    name: 'role',
    sortKey: 'role',
    label: 'settings.organization.table.header.role',
    minWidth: 100,
  },
  {
    name: 'licences',
    sortKey: 'licences[0].name',
    label: 'settings.organization.table.header.license',
    minWidth: 255,
  },
  {
    name: 'details',
    label: 'settings.organization.table.header.details',
    sortable: false,
    minWidth: 172,
  },
  {
    name: 'end_date',
    sortKey: 'licences[0].end_date',
    label: 'settings.organization.table.header.end_date',
    subLabel: 'settings.organization.table.header.end_date.subtitle',
    minWidth: 202,
  },
];

export const GRID_TEMPLATE_COLUMNS = [
  '3.5fr',
  '1.5fr',
  '1fr',
  '2.5fr',
  '1.5fr',
  '2fr',
].reduce(
  (template, span, index) =>
    template.concat(` minmax(${COLUMNS[index].minWidth || 0}px,${span})`),
  ``,
);
