import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import useMeasure from 'react-use-measure';
import ResizeObserver from 'resize-observer-polyfill';
import { Button3, Loader, Sidebar } from 'kolkit';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { useAtom } from 'jotai';
import Loadable from 'react-loadable';

import routes from 'config/routes';
import { useSelector } from 'utils/redux';
import knowledgeFeatures from 'config/knowledgeFeatures';
import { TabsPage, Typography } from 'components/ui';
import * as featureNames from 'constants/feature-names';
import { MountComponentByFeature, isFeatureActive } from 'utils/feature-toggle';
import { modalImport } from 'components/myInfluencers/tabs/ImportTab/importState';

import styles from './MyInfluencers.module.scss';

const ManageKOLsTab = Loadable({
  loader: () => import('components/myInfluencers/tabs/ManageKOLsTab'),
  loading: () => <Loader full background="rgba(250, 250, 250, 0.8)" />,
})

const CustomFieldsTab = Loadable({
  loader: () => import('./tabs/CustomFieldsTab'),
  loading: () => <Loader full background="rgba(250, 250, 250, 0.8)" />,
})

const ListTab = Loadable({
  loader: () => import('./tabs/ListsTab'),
  loading: () => <Loader full background="rgba(250, 250, 250, 0.8)" />,
})

const TagsManagementTab = Loadable({
  loader: () => import('./tabs/TagsManagement'),
  loading: () => <Loader full background="rgba(250, 250, 250, 0.8)" />,
})

const AccountCreationRequestsTab = Loadable({
  loader: () => import('./tabs/AccountCreationRequestsTab'),
  loading: () => <Loader full background="rgba(250, 250, 250, 0.8)" />,
})

const ImportTab = Loadable({
  loader: () => import('./tabs/ImportTab'),
  loading: () => <Loader full background="rgba(250, 250, 250, 0.8)" />,
})


const MyInfluencers = () => {
  const intl = useIntl();

  const [collapse, setCollapse] = useState(false);
  const [openImport, setOpenImport] = useAtom(modalImport);

  const [ref, measure] = useMeasure({ polyfill: ResizeObserver });

  const { hasTeam, isAdmin, hasViewNewFeatures } = useSelector(({ user }) => ({
    hasTeam: user.hasTeam,
    isAdmin: user.profile.admin,
    hasViewNewFeatures: user.unacknowledgedFeatures,
  }));

  const handleCollapse = useCallback(
    forcedValue =>
      setCollapse(prevState =>
        forcedValue === undefined ? !prevState : forcedValue,
      ),
    [],
  );

  const handleOpenImport = value => setOpenImport(value);

  const dataset = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'title.myKols'}),
        link: routes.manage,
        content: <ManageKOLsTab />,
      },
      {
        label: intl.formatMessage({ id: 'title.lists' }),
        link: routes.list,
        content: <ListTab />,
      },
      {
        label: intl.formatMessage({ id: 'title.fields' }),
        link: routes.customFields,
        content: <CustomFieldsTab />,
        disabled: !isFeatureActive(featureNames.MANAGE_CAMPAIGN),
      },
      {
        label: intl.formatMessage({ id: 'title.labels' }),
        link: routes.manageLabels,
        content: <TagsManagementTab />,
        disabled: isAdmin
      },
      {
        label: intl.formatMessage({ id: 'title.requestAddKols' }),
        link: routes.follow,
        content: <AccountCreationRequestsTab />,
        highlight: hasViewNewFeatures?.includes(
          knowledgeFeatures.kolsAdditionDashboardMenu,
        )
          ? intl.formatMessage({ id: 'title.requestAddKols.highlight' })
          : '',
        highlightKey: knowledgeFeatures.kolsAdditionDashboardMenu,
      }
    ],
    [hasViewNewFeatures, intl, isAdmin],
  );

  const wrapperStyle = useMemo(
    () => ({ height: `calc(100vh - ${measure?.top}px)` }),
    [measure]
  );

  const cnMyKol = cn(styles.container, 'loaded');

  return (
    <>
      <div className={cnMyKol} ref={ref} style={wrapperStyle}>
        <TabsPage
          dataset={dataset}
          withRouter
          parentPath={routes.myKOLs}
          title={
            <div className="flex aic jcsb">
              <Typography tag="h1" variant="title/page-title">
                <FormattedMessage id="myKOLs.header.title" />
              </Typography>
              <MountComponentByFeature featureName={featureNames.MANAGE_CAMPAIGN}>
                <Button3
                  label={intl.formatMessage({ id: 'import.cta.importFile' })}
                  theme="secondary"
                  size="medium"
                  onClick={() => handleOpenImport(true)}
                />
              </MountComponentByFeature>
            </div>
          }
          extraAction={collapse && (
            <MountComponentByFeature featureName={featureNames.MANAGE_CAMPAIGN}>
              <Button3
                label={intl.formatMessage({ id: 'import.cta.importFile' })}
                theme="tertiaryNoBorder"
                size="small"
                onClick={() => handleOpenImport(true)}
              />
            </MountComponentByFeature>
          )}
          subTitle={intl.formatMessage({
            id: hasTeam
              ? 'myKOLs.header.subtitle'
              : 'myKOLs.header.subtitleNoTeam',
          })}
          collapse={collapse}
          onCollapse={handleCollapse}
        />
      </div>

      <Sidebar
        on={openImport}
        width="100vw"
        className={styles.panelImport}
      >
        <ImportTab onClose={handleOpenImport} />
      </Sidebar>
    </>
  );
};

export default MyInfluencers;
