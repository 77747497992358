import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

export const csvHeader = atom([]);
export const csvFields = atomWithImmer({});
export const csvRows = atom([]);
export const csvFileName = atom('');
export const csvErrors = atom([]);
export const csvLineMapper = atom([]);

export const csvData = atom({});

export const modalImport = atom(false);


