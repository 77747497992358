import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from "kolkit";
import styles from './Helper.module.scss';

const Helper = ({ text, tooltip }) => {
  return (
    <div className={styles.helper}>
      <Icon tooltip={tooltip} label="info-circle" size="big" />
      <span className={styles.text}>{text}</span>
    </div>
  )
};

Helper.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
};

export default Helper;
