import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { HeightTransition, Icon } from 'kolkit';
import format from 'date-fns/format';
import Scrollbars from 'react-custom-scrollbars-2';

import useModal from 'utils/hooks/useModal';
import renderModal from 'utils/HOCs/renderModal';
import { useDateFnsLocale } from 'utils/date-fns';
import {
  ContentProposalVersion,
  ContentRequest,
} from 'slices/contentValidation.slice';

import SideContentModal from 'kolkit/Modal2/SideContentModal';
import VideoPlayer from 'components/ui/VideoPlayer';
import Typography from 'components/ui/Typography';

import styles from './DisplayContentVersionModal.module.scss';

export const MODAL_ID = 'DisplayContentVersionModal';

const DisplayContentVersionModal: React.FC = () => {
  const intl = useIntl();
  const dateFnsLocalse = useDateFnsLocale();
  const {
    show,
    off,
    data: { contentProposal, contentRequest },
  } = useModal<{
    contentRequest: ContentRequest;
    contentProposal: ContentProposalVersion;
  }>(MODAL_ID);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const iconLabel = useMemo(() => {
    let iconLabel = `${contentRequest?.network}-color`;
    switch (contentRequest?.content_type) {
      case 'reel':
        iconLabel = 'instagram-video-color';
        break;
      case 'story':
        iconLabel = 'instagram-story-color';
        break;
      case 'saved_story':
        iconLabel = 'snapchat-story';
        break;
      case 'spotlight':
        iconLabel = 'snapchat-spotlight';
        break;
      default:
        break;
    }
    return iconLabel;
  }, [contentRequest?.network, contentRequest?.content_type]);

  if (!contentProposal || !contentRequest) {
    return null;
  }

  const { caption, created_at, version_number, attachments } = contentProposal;
  const { content_title } = contentRequest;

  return (
    <SideContentModal
      on={show}
      onClick={off}
      title={
        <div className="flex aic gap8">
          <Icon label={iconLabel} theme="brand" size="huge" />
          <Typography label={content_title} variant="title/modal-title" />
        </div>
      }
      size="big"
    >
      <div className={styles.contentMedia}>
        {attachments?.[currentSlide] ? (
          attachments?.[currentSlide].content_type.includes('image/') ? (
            <img
              src={attachments?.[currentSlide].url}
              alt={attachments?.[currentSlide]?.filename}
            />
          ) : (
            <VideoPlayer
              video={attachments?.[currentSlide]}
              onChangePlayState={setIsVideoPlaying}
            />
          )
        ) : null}
        {attachments && attachments.length > 1 && (
          <HeightTransition
            on={!isVideoPlaying}
            className={styles.sliderContainer}
          >
            <div className={styles.sliderContent}>
              <Scrollbars className={styles.scrollbars} hideTracksWhenNotNeeded>
                <div className={styles.slider}>
                  {attachments?.map((attachment, previewIndex) => {
                    return (
                      <img
                        key={attachment.filename}
                        src={attachment.thumbnail_url || attachment.url}
                        alt={attachment.filename}
                        className={
                          previewIndex === currentSlide
                            ? styles.selected
                            : undefined
                        }
                        onClick={() => {
                          setCurrentSlide(previewIndex);
                        }}
                      />
                    );
                  })}
                </div>
              </Scrollbars>
            </div>
          </HeightTransition>
        )}
      </div>
      <div className={styles.contentInformations}>
        <div className="flex gap8">
          <Typography
            label={intl.formatMessage(
              {
                id: 'profileDetails.panel.content.contentApproval.submittedOn',
              },
              {
                date: format(new Date(created_at), 'P', {
                  locale: dateFnsLocalse,
                }),
              },
            )}
            variant="text/body medium"
          />
          <Typography variant="text/body medium" label="-" />
          <Typography
            label={intl.formatMessage(
              {
                id: 'profileDetails.panel.content.contentApproval.uploadedVersion',
              },
              { version: version_number || 0 },
            )}
            variant="text/body medium"
          />
        </div>
        <Typography
          variant="text/body medium"
          label={caption}
          className="pre-wrap"
        />
      </div>
    </SideContentModal>
  );
};

DisplayContentVersionModal.displayName = 'DisplayContentVersionModal';

export default renderModal(MODAL_ID, DisplayContentVersionModal);
