import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Icon } from 'kolkit';

import { Field, Slider, Markdown } from 'components';
import { useFilter } from 'components/engine/hooks';

import styles from './Credibility.module.scss';

const Credibility = ({ network, onChange }) => {
  const filterTarget = 'credibility';
  const intl = useIntl();
  const value = useFilter(filterTarget, network);
  const [localValue, setLocalValue] = useState(value);

  const handleLocalChange = useCallback(
    (_, value) => {
      setLocalValue(value);
    },
    []
  );

  const handleChange = useCallback(
    (_, value) => onChange({
      field: filterTarget,
      value,
      network,
    }),
    [onChange, filterTarget, network]
  );

  const marks = useMemo(
    () => [
      {
        value: 0,
        label: '',
      },
      {
        value: 100,
        label: '100',
      },
    ],
    [],
  );

  return (
    <div className={styles.container}>
      <Field label={
        <>
          <span className={styles.title}>
            {intl.formatMessage({ id: 'credibility.label'})}
          </span>
          <Icon
            tooltip={<Markdown>{intl.formatMessage({ id: 'credibility.description' })}</Markdown>}
            label="info-circle"
            size="small"
          />
        </>
      }>
        <Slider
          onChange={handleLocalChange}
          onChangeCommitted={handleChange}
          value={localValue}
          valueLabelDisplay="on"
          step={5}
          marks={marks}
        />
      </Field>
    </div>
  )
};

Credibility.displayName = 'Credibility';

Credibility.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(Credibility);
