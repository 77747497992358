/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SliceState,
  ContentRequest,
  ContentProposalVersion,
} from './contentValidation.types';
import { mapContentRequestArrayToEditionMap } from './contentValidation.utils';

import { startLoading, stopLoading } from '../utils';
import {
  updateContentInstructions,
  getContentRequests,
  updateContentRequests,
  getContentProposals,
  uploadContentVersion,
  updateContentRequestStatus,
} from './contentValidation.actions';

const REGEX_LAST_NUMBER = /([0-9]+)$/;

const initialState: SliceState = {
  loading: [],
  contentRequestsByCampaign: {},
  contentRequestsByCampaignAggregated: {},
  contentRequestsToEdit: {},
  contentProposalsToEdit: [],
  isUploadInProgress: false,
};

// Reducers
const contentValidationSlice = createSlice({
  name: 'contentValidation',
  initialState,
  reducers: {
    updateKey: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    addMediaPlan: (
      state,
      action: PayloadAction<{
        title: string;
        content_type: ContentRequest['content_type'];
        network: ContentRequest['network'];
      }>,
    ) => {
      const  { network, content_type, title } = action.payload;
      const contentProposals = state.contentRequestsToEdit[network] || {};
      const lastContentProposal = contentProposals[content_type]?.[contentProposals[content_type]?.length - 1];
      const lastSuffix =
        lastContentProposal &&
        lastContentProposal?.content_title?.match(REGEX_LAST_NUMBER)?.[0] !==
          undefined
          ? Number(
              lastContentProposal?.content_title?.match(REGEX_LAST_NUMBER)?.[0],
            )
          : 0;

      contentProposals[content_type] = [
        ...(contentProposals[content_type] || []),
        {
          network,
          content_type,
          content_title: `${title} ${lastSuffix +1 }`,
          expected_publication_date: null,
          content_status: 'waiting_proposal',
        },
      ];
      state.contentRequestsToEdit[network] = contentProposals;
    },
    removeMediaPlan: (
      state,
      action: PayloadAction<{
        id: number;
        content_type: ContentRequest['content_type'];
        network: ContentRequest['network'];
        index: number;
      }>,
    ) => {
      const { id, content_type, network, index } = action.payload;
      const newContentProposals =
        state.contentRequestsToEdit[network]?.[content_type];

      if (index !== -1) {
        if (id) {
          // Mark the element for deletion with "_destroy" key to API
          newContentProposals[index]._destroy = true;
        } else {
          // Remove the element from the array
          newContentProposals.splice(index, 1);
        }
        state.contentRequestsToEdit[network][content_type] =
          newContentProposals;
      }
    },
    editMediaPlan: (
      state,
      action: PayloadAction<{
        index: number;
        content_type: ContentRequest['content_type'];
        network: ContentRequest['network'];
        date: ContentRequest['expected_publication_date'];
      }>,
    ) => {
      const { index, network, date, content_type } = action.payload;
      const newContentProposals =
        state.contentRequestsToEdit[network]?.[content_type] || [];
      if (index !== -1) {
        newContentProposals[index].expected_publication_date = date;
        state.contentRequestsToEdit[network][content_type] =
          newContentProposals;
      }
    },
    resetMediasPlan: (
      state,
      action: PayloadAction<{ campaignId?: number; profileId?: number }>,
    ) => {
      const { campaignId, profileId } = action.payload;
      if (campaignId && profileId) {
        const initialContentRequests =
          state.contentRequestsByCampaign?.[campaignId]?.[profileId] || {};
        state.contentRequestsToEdit = mapContentRequestArrayToEditionMap(
          initialContentRequests,
        );
      } else {
        state.contentRequestsToEdit = {};
      }
    },
    /** Update content proposal when upload finished processing */
    updateContentProposal: (
      state,
      action: PayloadAction<{
        contentProposal: ContentProposalVersion;
      }>,
    ) => {
      const { contentProposal } = action.payload;
      if (contentProposal) {
        const contentProposalToEditIndex =
          state.contentProposalsToEdit.findIndex(
            (oldCp) => Number(oldCp.id) === Number(contentProposal.id),
          );
        if (contentProposalToEditIndex !== -1) {
          state.contentProposalsToEdit[contentProposalToEditIndex] = {
            ...state.contentProposalsToEdit[contentProposalToEditIndex],
            ...contentProposal,
          };
        }
      }
    },
    // Handle upload state
    updateUploadInProgress: (state, action: PayloadAction<boolean>) => {
      state.isUploadInProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetching Content requests for a KOL in a campaing
    builder
      .addCase(getContentRequests.pending, (state) => {
        startLoading(state, getContentRequests);
      })
      .addCase(getContentRequests.fulfilled, (state, action) => {
        const {
          project_id,
          profile_id,
          content_requests,
          aggregated_content_requests,
        } = action.payload;

        state.contentRequestsByCampaign[project_id] = {
          ...(state.contentRequestsByCampaign[project_id] || {}),
          [profile_id]: content_requests,
        };
        state.contentRequestsToEdit = mapContentRequestArrayToEditionMap(
          content_requests || [],
        );

        state.contentRequestsByCampaignAggregated[project_id] = {
          ...(state.contentRequestsByCampaignAggregated[project_id] || {}),
          [profile_id]: aggregated_content_requests,
        };
        stopLoading(state, getContentRequests);
      })
      .addCase(getContentRequests.rejected, (state) => {
        stopLoading(state, getContentRequests);
      });

    // Updating Content requests for KOL(s) in a campaign
    builder
      .addCase(updateContentRequests.pending, (state) => {
        startLoading(state, updateContentRequests);
      })
      .addCase(updateContentRequests.fulfilled, (state, action) => {
        const {
          profile_ids,
          project_id,
          content_requests,
          aggregated_content_requests,
        } = action.payload;

        profile_ids.forEach((profile_id) => {
          state.contentRequestsByCampaign[project_id] = {
            ...(state.contentRequestsByCampaign[project_id] || {}),
            [profile_id]: content_requests,
          };
          state.contentRequestsToEdit = mapContentRequestArrayToEditionMap(
            content_requests || [],
          );

          state.contentRequestsByCampaignAggregated[project_id] = {
            ...(state.contentRequestsByCampaignAggregated[project_id] || {}),
            [profile_id]: aggregated_content_requests,
          };
        });
        stopLoading(state, updateContentRequests);
      })
      .addCase(updateContentRequests.rejected, (state) => {
        stopLoading(state, updateContentRequests);
      });

    // Updating Content instructions for a KOL in a campaign
    builder
      .addCase(updateContentInstructions.pending, (state) => {
        startLoading(state, updateContentInstructions);
      })
      .addCase(updateContentInstructions.fulfilled, (state) => {
        stopLoading(state, updateContentInstructions);
      })
      .addCase(updateContentInstructions.rejected, (state) => {
        stopLoading(state, updateContentInstructions);
      });

    // Updating Content request status
    builder
      .addCase(updateContentRequestStatus.pending, (state) => {
        startLoading(state, updateContentRequestStatus);
      })
      .addCase(updateContentRequestStatus.fulfilled, (state, action) => {
        const { project_id, profile_id, requestId, content_status } =
          action.payload;
        const contentRequests =
          state.contentRequestsByCampaign?.[project_id]?.[profile_id];
        contentRequests.forEach((contentRequest) => {
          if (contentRequest.id === requestId) {
            contentRequest.content_status = content_status;
          }
        });
        stopLoading(state, updateContentRequestStatus);
      })
      .addCase(updateContentRequestStatus.rejected, (state) => {
        stopLoading(state, updateContentRequestStatus);
      });

    // Fetching Content proposals for a KOL in a campaign
    builder
      .addCase(getContentProposals.pending, (state) => {
        startLoading(state, getContentProposals);
      })
      .addCase(getContentProposals.fulfilled, (state, action) => {
        state.contentProposalsToEdit = action.payload.content_proposals;
        stopLoading(state, getContentProposals);
      })
      .addCase(getContentProposals.rejected, (state) => {
        stopLoading(state, getContentProposals);
      });

    // Upload content proposal
    builder
      .addCase(uploadContentVersion.pending, (state) => {
        startLoading(state, uploadContentVersion);
      })
      .addCase(uploadContentVersion.fulfilled, (state, action) => {
        state.contentProposalsToEdit = [
          ...state.contentProposalsToEdit,
          action.payload.contentProposal,
        ];
        stopLoading(state, uploadContentVersion);
      })
      .addCase(uploadContentVersion.rejected, (state) => {
        stopLoading(state, uploadContentVersion);
      });
  },
});

// action creators
export const { actions } = contentValidationSlice;

export default contentValidationSlice.reducer;
