const isEventOutside = (e, dom, stopEvent = false) => {
  let current = e.target;

  const componentNode = dom;

  while(current.parentNode) {
    if (current === componentNode) {
      return false;
    }
    current = current.parentNode;
  }

  return !(current !== document || stopEvent);
}

export default isEventOutside;
