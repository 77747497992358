import React, { Children, memo } from 'react';
import cn from 'classnames';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Icon, Tooltip } from 'kolkit';

import styles from './ToggleButtons2.module.scss';

export type ValueType<T> = {
  value: T;
  label?: string;
  icon?: string;
  theme?: React.ComponentProps<typeof Icon>['theme'];
  className?: string;
  activeClassName?: string;
  tooltip?: string | null;
  disabled?: boolean;
  selected?: boolean;
  onChange?: (event: React.MouseEvent<HTMLElement>, args: T) => void;
  dataId?: string | null;
};

interface Props<T = string> {
  orientation?: 'horizontal' | 'vertical';
  size?: 'large' | 'medium' | 'small';
  disableRipple?: boolean;
  dataset: ValueType<T>[];
  onChange: (args?: T) => void;
  value?: string;
  required?: boolean;
  className?: string;
  classNameLabel?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  theme?: 'primary' | 'secondary' | 'tertiary' | 'tone';
  dataId?: string;
}

const ToggleButtons2: React.FC<Props> = ({
  value,
  orientation,
  size,
  disableRipple,
  dataset,
  onChange,
  required = true,
  theme = 'primary',
  className,
  classNameLabel,
  dataId,
  ...rest
}) => {
  const handleSelected = (
    event: React.MouseEvent<HTMLElement>,
    newSelected?: string,
  ) => {
    if (required && newSelected === null) return false;
    onChange(newSelected);
  };

  const groupClasses = {
    root: styles.root,
    grouped: styles.grouped,
  };

  const buttonClasses = {
    root: styles.buttonRoot,
    selected: styles.selected,
    sizeSmall: styles.small,
    sizeLarge: styles.large,
    disabled: styles.disabled,
  };

  return (
    <ToggleButtonGroup
      classes={groupClasses}
      value={value}
      exclusive
      onChange={handleSelected}
      orientation={orientation}
      size={size}
      className={cn(className, theme ? styles[theme] : undefined)}
      data-id={dataId}
      {...rest}
    >
      {Children.toArray(
        dataset.map((data) => {
          const cnIcon = cn(styles.iconStatus, {
            [`${data.className}`]: !!data.className,
            [`${data.activeClassName}`]: data.selected,
          });
          const label = (
            <span className={classNameLabel}>
              {data.icon && (
                <Icon
                  label={data.icon}
                  theme={data.theme || 'regular'}
                  className={cnIcon}
                />
              )}
              {data.label}
            </span>
          );

          return (
            <ToggleButton
              value={data.value}
              disableRipple={disableRipple}
              classes={buttonClasses}
              disabled={data.disabled}
              selected={data.selected}
              key={`toggle-button-${data.value}`}
              onClick={data.onChange}
              data-id={data.dataId}
            >
              {data.tooltip ? (
                // @ts-ignore-next-line
                <Tooltip content={data.tooltip}>
                  <span className={styles.label}>{label}</span>
                </Tooltip>
              ) : (
                <span className={styles.label}>{label}</span>
              )}
            </ToggleButton>
          );
        }),
      )}
    </ToggleButtonGroup>
  );
};

ToggleButtons2.displayName = 'ToggleButtons2';

const defaultProps = {
  orientation: 'horizontal' as const,
  size: 'small' as const,
  disableRipple: false,
  required: true,
};

ToggleButtons2.defaultProps = defaultProps;

export default memo(ToggleButtons2);
