import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { useDispatch, useSelector } from 'utils/redux';
import {
  CASTING_VALIDATION_KEYS,
  STATUSES_PHRASE_ALL_STATUS_KEY,
  STATUSES_PHRASE_NO_STATUS_KEY,
} from 'constants/states';
import { USER_ROLES } from 'constants/userRoles';
import { getCampaignWorkflows, Status } from 'slices/campaignWorkflow.slice';

const emptyStatuses = [];

type Props = { showOptionNoStatus?: boolean; campaignId?: number };
type HookResponse = {
  currentCampaignId?: number;
  statusesDataset: {
    value: number;
    label: string;
  }[];
  statuses: Status[];
  getTranslatedName: (status: { key?: string | null; name: string }) => string;
  refreshStatuses: () => void;
  proposition_count: Record<number, number>;
};

const useKolStatuses: (props?: Props) => HookResponse = ({
  showOptionNoStatus = false,
  campaignId: _cammpaignId,
} = {}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    campaigns,
    userRole,
    current_workflow_id,
    workflows,
    loading,
    teamId,
    campaignId,
    teamsAccess,
    team_statuses,
  } = useSelector(
    ({
      campaigns,
      user,
      views: {
        campaignDetails: { id },
      },
      messaging: {
        conversation: { projectId },
      },
      organization,
      campaignsWorkflow: {
        current_workflow_id,
        workflows,
        loading,
        team_statuses,
      },
    }) => ({
      campaigns,
      userRole: user.profile.userRole,
      current_workflow_id,
      workflows,
      loading,
      teamId: user?.profile?.teamId,
      campaignId: id,
      conversationCampaignId: projectId,
      teamsAccess: organization?.teamsAccess,
      team_statuses,
    }),
  );

  const currentCampaignId = _cammpaignId || campaignId;

  const campaignTeamId = useMemo(
    () =>
      currentCampaignId ? campaigns?.[currentCampaignId]?.core?.teamId : null,
    [currentCampaignId, campaigns],
  );

  const getStatuses: Status[] = useMemo(() => {
    if (
      userRole &&
      [USER_ROLES.MANAGER, USER_ROLES.SUPER_USER].includes(userRole) &&
      Number(teamId) !== Number(campaignTeamId)
    ) {
      const getTeamAccess = teamsAccess.find(
        (team) => Number(team?.id) === Number(campaignTeamId),
      );

      let teamAccessStatuses =
        getTeamAccess?.statuses || Object.values(team_statuses) || [];

      if (currentCampaignId && teamAccessStatuses?.length) {
        teamAccessStatuses = teamAccessStatuses.map((item) => {
          return {
            ...item,
            is_casting_validation: CASTING_VALIDATION_KEYS.map(String).includes(
              item.name,
            ),
            profile_count:
              (current_workflow_id &&
                workflows[current_workflow_id]?.proposition_count[item.id]) ||
              0,
          };
        });
      }

      return teamAccessStatuses;
    }

    if (currentCampaignId && current_workflow_id) {
      return (workflows[current_workflow_id]?.status_ids || [])
        .map((statusid) => {
          const item = team_statuses[statusid];
          if (!item) return null;
          return {
            ...item,
            is_casting_validation: CASTING_VALIDATION_KEYS.map(String).includes(
              item.name,
            ),
            profile_count:
              workflows[current_workflow_id]?.proposition_count[item.id] || 0,
          };
        })
        .filter(Boolean) as Status[];
    }

    return emptyStatuses;
  }, [
    userRole,
    teamId,
    campaignTeamId,
    currentCampaignId,
    current_workflow_id,
    teamsAccess,
    team_statuses,
    workflows,
  ]);

  const getTranslatedName = useCallback(
    ({ key, name }: { key?: string | null; name: string }) => {
      if (
        name === STATUSES_PHRASE_ALL_STATUS_KEY ||
        name === STATUSES_PHRASE_NO_STATUS_KEY
      ) {
        return intl.formatMessage({ id: name });
      }

      const status = getStatuses.find(
        (s) => (key && s.key === key) || s.name === name,
      );
      if (!status) return name;
      return status.is_default
        ? status.key
          ? intl.formatMessage({ id: `campaigns.statuses.${status.key}` })
          : ''
        : status?.name ||
            intl.formatMessage({ id: STATUSES_PHRASE_NO_STATUS_KEY });
    },
    [getStatuses, intl],
  );

  const statusesDataset = useMemo(() => {
    const data = !showOptionNoStatus
      ? getStatuses.filter(({ id }) => id !== 0)
      : getStatuses;

    return data.map((status) => ({
      value: status.id,
      label: status.is_default
        ? status.key
          ? intl.formatMessage({ id: `campaigns.statuses.${status.key}` })
          : ''
        : status.name,
    }));
  }, [showOptionNoStatus, getStatuses, intl]);

  const refreshStatuses = useCallback(() => {
    if (currentCampaignId) {
      void dispatch(getCampaignWorkflows({ project_id: currentCampaignId }));
    }
  }, [currentCampaignId, dispatch]);

  useEffect(() => {
    if (
      currentCampaignId &&
      current_workflow_id &&
      !workflows[current_workflow_id] &&
      !loading.includes(getCampaignWorkflows.typePrefix)
    ) {
      refreshStatuses();
    }
  }, [
    currentCampaignId,
    current_workflow_id,
    loading,
    refreshStatuses,
    workflows,
  ]);

  return {
    currentCampaignId,
    statusesDataset,
    statuses: getStatuses,
    getTranslatedName,
    refreshStatuses,
    proposition_count:
      (current_workflow_id &&
        workflows[current_workflow_id]?.proposition_count) ||
      {},
  };
};

export default useKolStatuses;
