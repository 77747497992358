import React, { memo, useMemo } from 'react';
import Slide from '@mui/material/Slider';

import styles from './Slider.module.scss';


const Slider = ({
  onChange,
  marks,
  defaultValue,
  step,
  disabled,
  valueLabelDisplay,
  className,
  ...rest
}) => {
  const style = useMemo(
    () => ({ paddingTop: '24px' }),
    []
  );

  const classes = {
    root: styles.root,
    disabled: styles.disabled,
    track: styles.track,
    rail: styles.rail,
    thumb: styles.thumb,
    marked: styles.marked,
    mark: styles.mark,
    markLabel: styles.markLabel,
    markLabelActive: styles.markLabelActive,
    valueLabel: styles.valueLabel,
  }

  return (
    <div style={style} className={className}>
      <Slide
        {...rest}
        onChange={onChange}
        defaultValue={defaultValue}
        step={step}
        valueLabelDisplay={valueLabelDisplay}
        marks={marks}
        disabled={disabled}
        size="small"
        classes={classes}
      />
    </div>
  )
};

export default memo(Slider);
