import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Grid.module.scss';

const Grid = ({ children, className, column }) => {
  const cnContainer = cn(styles.container, className);
  const cnItem = props => cn(styles.item, props);

  const grid = useMemo(
    () => ({ gridTemplateColumns: `repeat(${column}, 1fr)` }),
    [column]
  );

  let space = column;
  let start = 1;

  return (
    <div className={cnContainer} style={grid}>
      {React.Children.map(children, (child, index) => {
        space -= (index !== 0 ? children[index - 1].props.size : 0);
        start = index === 0 || child.props.size > space
          ? 1
          : start + children[index - 1].props.size;

        return React.cloneElement(
          child,
          {
            className: cnItem(child.props.className),
            style: {
              gridColumnStart: start,
              gridColumnEnd: child.props.size + start,
              gridRowStart: child.props.rowStart,
              gridRowEnd: child.props.rowEnd
            }
          }
        )
      })}
    </div>
  )
};

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(
      PropTypes.any,
    )
  ]).isRequired,
  className: PropTypes.string,
  column: PropTypes.number
}

Grid.defaultProps = {
  column: 12,
  className: null
}

export default memo(Grid);
