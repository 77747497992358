import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Button2 } from 'kolkit';

import { updateProfileState } from 'actions/campaigns';
import { useDispatch, useSelector } from 'utils/redux';
import useKolStatuses from 'components/campaigns/details/hooks/useKolStatuses';
import useGuestSession from 'components/campaigns/hooks/useGuestSession';
import {
  CASTING_VALIDATION_KEYS,
  USER_ROLES,
  CASTING_TAGS,
} from 'constants/states';

import styles from './CastingValidationButton.module.scss';

type Props = {
  profileId?: number;
  campaignId?: number;
  status_id?: number;
  actionFor?: 'approved' | 'refused';
  fallbackStatusName?: string;
  onStatusChangeStarted?: () => void;
  onStatusChangeSuccess?: () => void;
  fullWidth?: boolean;
};

const CastingValidationButton = ({
  profileId,
  campaignId: _campaignId,
  status_id,
  actionFor,
  fallbackStatusName,
  onStatusChangeStarted,
  onStatusChangeSuccess,
  fullWidth,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { campaignId, currentStatus, statusToAssign } = useSelector(
    ({
      user: { role },
      campaignsWorkflow: { team_statuses },
      views: {
        campaignDetails: { id: __campaignId },
      },
    }) => {
      const campaignId = _campaignId || __campaignId;
      return {
        campaignId,
        currentStatus: status_id ? team_statuses[status_id] : null,
        statusToAssign: actionFor
          ? Object.values(team_statuses).find(
              (status) => status.key === actionFor,
            )
          : null,
        isGuestUser: role === USER_ROLES.GUEST,
      };
    },
  );

  const { getTranslatedName } = useKolStatuses({});

  const { wrapAction: wrapActionForRegisteredGuest } = useGuestSession({
    campaignId,
  });

  const icon = useMemo(() => {
    switch (actionFor || currentStatus?.key) {
      case 'approved':
        return 'thumbs-up';
      case 'refused':
        return 'thumbs-down';
      default:
        return '';
    }
  }, [actionFor, currentStatus?.key]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (!actionFor || !profileId || !campaignId) return null;
      if (statusToAssign) {
        wrapActionForRegisteredGuest(async () => {
          if (onStatusChangeStarted) onStatusChangeStarted();
          await dispatch(
            updateProfileState({
              profileId,
              profilesId: [],
              campaignId,
              stateId: statusToAssign.id,
              fromConversation: false,
            }),
          );
          if (onStatusChangeSuccess) onStatusChangeSuccess();
        });
      }
    },
    [
      actionFor,
      campaignId,
      dispatch,
      profileId,
      statusToAssign,
      wrapActionForRegisteredGuest,
      onStatusChangeStarted,
      onStatusChangeSuccess,
    ],
  );

  const cnButton = cn(
    styles.button,
    currentStatus?.key &&
      (CASTING_VALIDATION_KEYS as ReadonlyArray<string>).includes(
        currentStatus.key,
      ) &&
      (actionFor ? actionFor === currentStatus.key : true)
      ? {
          [styles[currentStatus.key]]: true,
        }
      : null,
  );

  return (
    <Button2
      theme="secondary"
      size="small"
      active
      className={cnButton}
      icon={icon}
      onClick={actionFor ? handleClick : undefined}
      dataId={actionFor ? CASTING_TAGS[actionFor] : undefined}
      fullWidth={fullWidth}
      label={
        !currentStatus
          ? fallbackStatusName
          : actionFor
          ? intl.formatMessage({
              id: `campaigns.shareCasting.cta.for.${actionFor}`,
            })
          : getTranslatedName({
              key: currentStatus.key,
              name: currentStatus.name,
            })
      }
    />
  );
};

export default CastingValidationButton;
