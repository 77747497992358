const addFavorite = {
  toApi: id => ({ 'public_profile_id': id }),
}

const addFavorites = {
  toApi: ids => ({ 'profile_ids': ids }),
}

const addMultipleFavorite = {
  toApi: selected => selected
}

const removeFavorites = {
  toApi: ids => ({ 'profile_ids': ids }),
}

export default {
  addFavorite,
  addFavorites,
  addMultipleFavorite,
  removeFavorites,
}
