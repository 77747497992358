import React, { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'kolkit/Icon';

import styles from './FilterButton.module.scss';

const FilterButton = forwardRef(
  ({ className, label, icon, onClick, index, withArrow }, ref) => {
    const cnFilterButton = cn(styles.button, className);

    const handleClick = useCallback(() => onClick(index), [onClick, index]);

    return (
      <div
        className={cnFilterButton}
        ref={ref}
        onClick={handleClick}
        role="button"
      >
        <Icon
          label={icon}
          size="big"
          theme={icon === 'user' ? 'thin' : 'brand'}
        />
        <span>{label}</span>
        {withArrow && (
          <button type="button">
            <Icon
              label="chevron-down"
              size="small"
              className={styles.chevron}
            />
          </button>
        )}
      </div>
    );
  },
);

FilterButton.displayName = 'FilterButton';

FilterButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func,
  withArrow: PropTypes.bool,
};

FilterButton.defaultProps = {
  onClick: null,
  withArrow: true,
};

export default memo(FilterButton);
