import moment from 'moment-timezone';
import isValid from 'date-fns/isValid';

import { useSelector } from 'utils/redux';
import datesFormatter from 'config/dates';

type TimezoneFormatterFn = (
  date: moment.MomentInput,
  dateFormat?: string | undefined,
  doNotTimezone?: boolean,
) => string;

const useTimezone = () => {
  const { timezone, locale, dateFormat } = useSelector(({ env }) => ({
    timezone: env.timezone || moment.tz.guess(false),
    locale: env.locale,
    dateFormat: env.dateFormat ?? 'dd/mm/yyyy',
  }));

  const timezoneFormatter: TimezoneFormatterFn = (
    date = '',
    dateFormat = 'MMMM Do YYYY, h:mm:ss a',
    doNotTimezone = false,
  ) => {
    if (!date || !isValid(moment(date).toDate())) return '';
    let momentDate = moment(date);
    if (!doNotTimezone && timezone) momentDate = momentDate.tz(timezone);
    return momentDate.locale(locale?.toLowerCase()).format(dateFormat);
  };

  const dateRangeFormatter = ({ startDate, endDate, dateFormat, doNotTimezone = false }) => {
    if (moment(startDate).isSame(moment(endDate)))
      return timezoneFormatter(startDate, dateFormat, doNotTimezone);
    return `${timezoneFormatter(startDate, dateFormat, doNotTimezone)} - ${timezoneFormatter(
      endDate,
      dateFormat,
      doNotTimezone
    )}`;
  };

  return {
    timezoneFormatter,
    dateRangeFormatter,
    format: {
      ...datesFormatter[dateFormat],
      longDate:
        locale === 'fr-FR'
          ? datesFormatter[dateFormat]?.longDate
          : datesFormatter[dateFormat]?.commaLongDate,
      hour: locale === 'fr-FR' ? 'H:mm' : 'h:mm a',
    },
  };
};

export default useTimezone;
