import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-phraseapp';
import { useAtom } from 'jotai';
import Loadable from 'react-loadable';

import { Modal2, Loader, Text } from 'kolkit';
import { updateProfilesDelivery } from 'actions/campaigns';
import { CAMPAIGN_STATES } from 'constants/states';

import useCurrency from 'utils/hooks/useCurrency';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import useLoading from 'utils/hooks/useLoading';
import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'utils/redux';
import { getUserRightsOnSelection } from 'utils/rights';
import renderModal from 'utils/HOCs/renderModal';
import { Currency } from 'config/currencies';
import {
  getForecastFollowers,
  getForecastStats,
} from 'actions/campaignDetails';

import {
  followersDataAtom,
  isEstimatedAtom,
  networkAtom,
  publicationTypeAtom,
  statsDataAtom,
} from 'components/campaigns/Modules/ForecastPanel/atomState';
import { DEFAULT_DATA } from 'components/campaigns/Modules/ForecastPanel/config';
import { InlineMessage } from 'components/ui';

import {
  updateContentRequests,
  getContentRequests,
  resetMediasPlan,
} from 'slices/contentValidation.slice';

const MediaPlanForm = Loadable({
  loader: () => import('components/campaigns/Composants/MediaPlanForm'),
  loading: () => <Loader full />,
})

type ModalData = {
  profilesIds: number[];
}

export const MEDIA_PLAN_MODAL_ID = 'mediaPlanModal';

const TAB_PLAN_ACTIONS = [
  'campaign/content_validation/getContentRequests',
  'campaign/content_validation/updateContentRequests',
];

const MediaPlanModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [campaignsForecastOpen] = useLocalStorage('campaigns_forecast_open', []);
  const { convert } = useCurrency();

  const { show, off, data } = useModal(MEDIA_PLAN_MODAL_ID);

  const { profilesIds } = data as ModalData || {
    profilesIds: [],
  };

  const { loading: investedBudgetLoading } = useLoading('updateDeliveryLoader');

  const [investedBudget, setInvestedBudget] = useState<string>();

  const [network] = useAtom(networkAtom);
  const [publicationType] = useAtom(publicationTypeAtom);

  const [, setIsEstimated] = useAtom(isEstimatedAtom);
  const [, setFollowersData] = useAtom(followersDataAtom);
  const [, setStatsData] = useAtom(statsDataAtom);

  const {
    campaignId,
    isCampaignArchived,
    userCan,
    campaignInvestedBudget,
    isAdmin,
    filteredProfiles,
    contentValidationLoading,
  } = useSelector(({ campaigns, views, user, contentValidation }) => {
    const campaignId = views.campaignDetails.id;
    const campaignInvestedBudget = (campaignId && profilesIds?.length === 1)
      ? campaigns[campaignId]?.profiles[profilesIds?.[0]]?.delivery
      : null;
    const isCampaignArchived = campaignId
      ? campaigns[campaignId]?.core.state === CAMPAIGN_STATES.ARCHIVED
      : false;
    const userCan = campaignId
      ? getUserRightsOnSelection(campaigns[campaignId]?.core?.role)
      : {};
    return {
      campaignId,
      isCampaignArchived,
      userCan,
      campaignInvestedBudget,
      isAdmin: !!user.profile.admin,
      filteredProfiles: views.campaignDetails.profiles.filteredProfiles,
      contentValidationLoading: contentValidation.loading,
    };
  });

  const handleClose = useCallback(
    () => {
      off();
      dispatch(resetMediasPlan({}));
    },
    [off, dispatch]
  );

  const loading = useMemo(
    () => investedBudgetLoading || TAB_PLAN_ACTIONS.some((action) => contentValidationLoading.includes(action)),
    [investedBudgetLoading, contentValidationLoading]
  );

  const isForecastOpen = useMemo(
    () => campaignsForecastOpen.includes(campaignId),
    [campaignsForecastOpen, campaignId],
  );

  const handleClickApply = useCallback(() => {
    const delivery = Object.fromEntries(
      Object.entries({ investedBudget })
        .filter(([, value]) => value)
        .map(([key, value]) => [key, Number(value)]),
    );

    (async () => {
      try {
        if (!campaignId) return null;
        await dispatch(
          updateProfilesDelivery({
            profilesIds,
            campaignId,
            delivery,
          }),
        );
        await dispatch(
          updateContentRequests({
            profile_ids: profilesIds,
            project_id: campaignId,
          }),
        );
        if (isForecastOpen) {
          const [followers, stats] = await Promise.all([
            dispatch(
              getForecastFollowers({
                campaignId,
                network,
                profilesIds: filteredProfiles,
              }),
            ),
            dispatch(
              getForecastStats({
                campaignId,
                network,
                profilesIds: filteredProfiles,
                publicationType,
              }),
            ),
          ]);
          setFollowersData(
            followers?.followers_insight || DEFAULT_DATA.followersInsight,
          );
          setStatsData(stats?.statistics || DEFAULT_DATA.statistics);
          setIsEstimated(stats?.is_estimated || false);
        }
        handleClose();
      } catch (error) {
        console.error('TabPlans:', error);
      }
    })();
  }, [
    campaignId,
    isForecastOpen,
    dispatch,
    investedBudget,
    network,
    publicationType,
    profilesIds,
    filteredProfiles,
    setFollowersData,
    setStatsData,
    setIsEstimated,
    handleClose,
  ]);

  const resetInvestedBudgetData = useCallback(
    (investedBudgetData) => {
      if (investedBudgetData) {
        const { investedBudget: investedBudgetFromData, investedBudgetCurrency } = investedBudgetData;
        const budgetConverted = convert(
          investedBudgetFromData,
          { fromCurrency: investedBudgetCurrency as Currency }
        );
        setInvestedBudget(budgetConverted?.toString());
      }
    },
    [convert],
  );

  useEffect(() => {
    if (show && campaignId && profilesIds?.length === 1) {
      resetInvestedBudgetData(campaignInvestedBudget);
      void dispatch(
        getContentRequests({
          profile_id: profilesIds[0],
          project_id: campaignId,
        }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, profilesIds, show]);

  const action = useMemo(
    () => {
      return {
        primary: {
          title: intl.formatMessage({
            id: 'global.cta.save',
          }),
          onClick: handleClickApply,
          disabled: loading,
        },
        secondary: {
          title: intl.formatMessage({
            id: 'global.cta.cancel',
          }),
          onClick: handleClose,
        },
      };
    },
    [intl, handleClickApply, handleClose, loading],
  );

  const disableEdit = isAdmin || isCampaignArchived || !userCan.updateReporting;

  return (
    <Modal2
      title={intl.formatMessage(
        { id: 'campaigns.mediaPlan.modale.title' },
        { count: profilesIds?.length },
      )}
      on={show}
      action={action}
      onClick={handleClose}
      loading={loading}
    >
      {profilesIds?.length > 1 && (
        <InlineMessage type="warning" className="mb16">
          <Text>
            <FormattedMessage id="campaigns.delivery.updateMany.warning" />
          </Text>
        </InlineMessage>
      )}
      <MediaPlanForm
        profileId={profilesIds?.length === 1 ? profilesIds[0] : null}
        investedBudget={investedBudget}
        onChangeInvestedBudget={setInvestedBudget}
        disableInvestitedBudget={disableEdit}
        borderedItem
      />
    </Modal2>
  );
};

export default renderModal(MEDIA_PLAN_MODAL_ID, MediaPlanModal);
