import React, { memo } from 'react';
import cn from 'classnames';
import styles from './Wrapper.module.scss';

const Wrapper = ({ children, className, space, ...rest }) => {
  const cnWrapper = cn(styles.wrapper, className, styles[space]);

  return (
    <div className={cnWrapper} {...rest}>
      {children}
    </div>
  )
};

export default memo(Wrapper);
