import React, { useCallback, useMemo, useEffect, lazy, Suspense } from 'react';
import { Button2, Icon, Text, Spaces, Truncate, Loader } from 'kolkit';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getKolDocuments, deleteKolDocuments } from 'actions/campaigns';
import { update as updateProfileDetailsView } from 'actions/profileDetails';
import routes from 'config/routes';
import { USER_ROLES } from 'constants/states';
import usePanel from 'utils/hooks/usePanel';
import useModal from 'utils/hooks/useModal';
import useLoading from 'utils/hooks/useLoading';
import useTimezone from 'utils/hooks/useTimezone';
import { useSelector, useDispatch } from 'utils/redux';

import ClickToCopy from 'components/modules/ClickToCopy';
import { Tile } from 'components/ui';

import { PANEL_ID } from '../ProfilePanel';

import styles from './TabInformations.module.scss';

const CampaignsHistory = lazy(() => import('./CampaignsHistory'));


const TabInformations = ({ fromCampaign }) => {
  const dispatch = useDispatch();

  const { on } = useModal('upload_documents');
  const { show, off, data } = usePanel(PANEL_ID, false);
  const { profileId } = data || {};

  const {
    timezoneFormatter,
    format: { slashDate },
  } = useTimezone();

  const { loading } = useLoading('getKolDocuments');

  const navigate = useNavigate();

  const { profileDetails, campaignId, campaigns, isGuestUser, isAdminUser } = useSelector(
    ({ profiles, views, campaigns, user: { role, profile } }) => ({
      profileDetails: profiles[profileId],
      campaignId: views.campaignDetails.id,
      campaigns,
      isGuestUser: role === USER_ROLES.GUEST,
      isAdminUser: profile.admin,
    }),
  );

  const documents = useMemo(
    () => campaigns?.[campaignId]?.profiles?.[profileDetails?.id]?.documents,
    [campaignId, campaigns, profileDetails?.id]
  );

  useEffect(() => {
    if (!isAdminUser && !isGuestUser && campaignId && profileDetails?.id) {
      dispatch(getKolDocuments({
        campaignId,
        kolId: profileDetails?.id
      }))
    }
    // eslint-disable-next-line
  }, [isAdminUser, isGuestUser, campaignId, profileDetails?.id])

  const handleClickEdit = useCallback(
    () => {
      off();
      if (profileId) navigate(`./${routes.profile}/${profileId}`, { relative: 'path' });
      dispatch(
        updateProfileDetailsView({
          currentTab: 'relation',
        }),
      );
    },
    [dispatch, navigate, off, profileId]
  );

  const handleDeleteDocument = useCallback(
    id => {
      dispatch(deleteKolDocuments({
        campaignId,
        kolId: profileDetails?.id,
        id
      }))
    },
    [profileDetails?.id, campaignId, dispatch]
  )

  if (!show || !profileDetails) return null;

  return (
    <div className={styles.container}>
      {/* PROFILE INFORMATIONS */}
      <Tile noShadow padding="var(--spacing-spacing-xx-small)">
        <div className="flex aic jcsb">
          <Text fontSize={16} fontWeight={800} resetMargin>
            <FormattedMessage id="profileDetails.profileInfos.title" />
          </Text>
          {!isGuestUser && (
            <Button2
              icon="pen-to-square"
              size="small"
              theme="tertiary"
              onClick={handleClickEdit}
            />
          )}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            rowGap: 8,
            marginTop: 8,
          }}
        >
          <Text fontSize={14} resetMargin>
            <FormattedMessage id="profileDetails.profileInfos.firstName.label" />
          </Text>
          <Text fontSize={14} fontWeight={500} resetMargin>
            {profileDetails.details.firstName || profileDetails.core.firstName}
          </Text>
          <Text fontSize={14} resetMargin>
            <FormattedMessage id="profileDetails.profileInfos.lastName.label" />
          </Text>
          <Text fontSize={14} fontWeight={500} resetMargin>
            {profileDetails.details.lastName || profileDetails.core.lastName}
          </Text>
          <Text fontSize={14} resetMargin>
            <FormattedMessage id="profileDetails.contactInfos.address.label" />
          </Text>
          <Text fontSize={14} fontWeight={500} resetMargin>
            {profileDetails.details.address}
          </Text>
          {profileDetails.contact.map((email, index) => (
            <>
              {index === 0 ? (
                <Text fontSize={14} resetMargin>
                  <FormattedMessage id="profileDetails.email.label" />
                </Text>
              ) : (
                <div />
              )}
              <Text
                fontSize={14}
                fontWeight={500}
                resetMargin
                className="flex aic"
              >
                <ClickToCopy
                  className={styles.email}
                  text={email.email}
                  size="small"
                />
              </Text>
            </>
          ))}
        </div>
      </Tile>

      {/* AGENT */}
      {!fromCampaign && (
        <Tile noShadow padding="var(--spacing-spacing-xx-small)">
          <div className="flex aic jcsb">
            <Text fontSize={16} fontWeight={800} resetMargin>
              <FormattedMessage id="profileDetails.agent.title" />
            </Text>
            {!isGuestUser && (
              <Button2
                icon="pen-to-square"
                size="small"
                theme="tertiary"
                onClick={handleClickEdit}
              />
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 2fr',
              rowGap: 8,
              marginTop: 8,
            }}
          >
            <Text fontSize={14} resetMargin>
              <FormattedMessage id="profileDetails.agent.name.label" />
            </Text>
            <Text fontSize={14} fontWeight={500} resetMargin>
              {profileDetails.details.agentName}
            </Text>
            <Text fontSize={14} resetMargin>
              <FormattedMessage id="profileDetails.agent.email.label" />
            </Text>
            <Text fontSize={14} fontWeight={500} resetMargin>
              {profileDetails.details.agentEmail}
            </Text>
            <Text fontSize={14} resetMargin>
              <FormattedMessage id="profileDetails.agent.phone.label" />
            </Text>
            <Text fontSize={14} fontWeight={500} resetMargin>
              {profileDetails.details.agentPhoneNumber}
            </Text>
          </div>
        </Tile>
      )}

      {/* DOCUMENTS */}
      { !isAdminUser && !isGuestUser && fromCampaign && (
        <Tile noShadow padding="var(--spacing-spacing-xx-small)">
          <div className="flex aic jcsb">
            <Text fontSize={16} fontWeight={800} resetMargin>
              <FormattedMessage id="profileDetails.profileInfos.documents" />
            </Text>

            <Button2
              icon="file-arrow-down"
              size="small"
              theme="tertiary"
              onClick={() =>
                on({
                  kolId: profileDetails?.id,
                  campaignId,
                })
              }
            >
              <FormattedMessage id="global.cta.uploadDocument" />
            </Button2>
          </div>

          <Spaces bottom={8} />

          <div>
            {loading ? (
              <div className="flex aic jcc">
                <Loader />
              </div>
            ) : !documents?.length ? (
              <Text fontSize={14} fontWeight={400} margin="16px 0">
                <FormattedMessage id="profileDetails.profileInfos.documentsPlaceholder" />
              </Text>
            ) : (
              documents?.map((doc) => (
                <div className={styles.document} key={doc?.id}>
                  <a href={doc?.url}>
                    <span>
                      <Truncate>{doc?.filename}</Truncate>
                    </span>
                  </a>
                  <span>{timezoneFormatter(doc?.created_at, slashDate)}</span>
                  <Icon
                    label="trash-alt"
                    onClick={() => handleDeleteDocument(doc?.id)}
                    iconTheme="regular"
                    isButton
                  />
                </div>
              ))
            )}
          </div>
          <Spaces bottom={8} />
        </Tile>
      )}

      {/* CAMPAIGN HISTORY */}
      {!isAdminUser && !isGuestUser && fromCampaign && (
        <Tile noShadow padding="var(--spacing-spacing-xx-small)">
          <Text fontSize={16} fontWeight={800} resetMargin>
            <FormattedMessage id="global.campaignsHistory" />
          </Text>
          <Spaces bottom={16} />
          <Suspense fallback={<Loader />}>
            <CampaignsHistory profileId={profileDetails?.id} />
          </Suspense>
        </Tile>
      )}
    </div>
  );
};

export default TabInformations;
