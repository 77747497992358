import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';

import { WrapperFilter } from '../Components';
import {
  Community,
  Countries,
  Ages,
  Genders,
  CommunityLanguages,
} from '../Modules';
import useActiveFilter from '../hooks/useActiveFilter';

const Youtube = ({ onChange }) => {
  const network = 'youtube';
  const intl = useIntl();
  const isActive = useActiveFilter(network);

  return (
    <>
      <WrapperFilter
        title={intl.formatMessage({id: 'engine.filters.aboutYoutuber'})}
        isActive={isActive}
        noMargin
      >
        <Countries
          onChange={onChange}
          network={network}
          filterKey="accountCountry"
          disabled={!isActive}
          disableHelper
          max={1}
        />
      </WrapperFilter>
      <WrapperFilter title={intl.formatMessage({id: 'engine.filters.audienceYoutuber'})} isActive={isActive}>
        <div>
          <Community
            label={intl.formatMessage({id: 'subscribersSize'})}
            network={network}
            filterKey="communitySize"
            onChange={onChange}
            disabled={!isActive}
          />
        </div>
        <div>
          <Community
            label={intl.formatMessage({id: 'viewsAverage'})}
            network={network}
            filterKey="viewsAverage"
            onChange={onChange}
            disabled={!isActive}
          />
        </div>
        <div>
          <Community
            label={intl.formatMessage({id: 'engagementRate'})}
            network={network}
            filterKey="communityEngagementRate"
            onChange={onChange}
            disabled={!isActive}
          />
        </div>
      </WrapperFilter>
      <WrapperFilter isActive={isActive}>
        <div>
          <Countries
            onChange={onChange}
            network={network}
            filterKey="communityCountries"
            disabled={!isActive}
            disableHelper
            isLocation={false}
            max={3}
          />
          <CommunityLanguages
            onChange={onChange}
            network={network}
            disabled={!isActive}
          />
        </div>
        <div>
          <Ages
            onChange={onChange}
            network={network}
            disabled={!isActive}
          />
        </div>
        <div>
          <Genders
            onChange={onChange}
            network={network}
            disabled={!isActive}
          />
        </div>
      </WrapperFilter>
    </>
  )
};

Youtube.displayName = 'Youtube';

Youtube.propTypes = {
  onChange: PropTypes.func.isRequired,
};


export default memo(Youtube);
