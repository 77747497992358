import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { useAtom } from 'jotai';
import Loadable from 'react-loadable';

import { Button3, Loader } from 'kolkit';
import { updateProfilesDelivery } from 'actions/campaigns';
import { CAMPAIGN_STATES } from 'constants/states';

import useCurrency from 'utils/hooks/useCurrency';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import usePanel from 'utils/hooks/usePanel';
import useLoading from 'utils/hooks/useLoading';
import { useDispatch, useSelector } from 'utils/redux';
import { getUserRightsOnSelection } from 'utils/rights';
import { Currency } from 'config/currencies';
import {
  getForecastFollowers,
  getForecastStats,
} from 'actions/campaignDetails';

import {
  followersDataAtom,
  isEstimatedAtom,
  networkAtom,
  publicationTypeAtom,
  statsDataAtom,
} from 'components/campaigns/Modules/ForecastPanel/atomState';
import { DEFAULT_DATA } from 'components/campaigns/Modules/ForecastPanel/config';

import {
  updateContentRequests,
} from 'slices/contentValidation.slice/contentValidation.actions';
import { resetMediasPlan } from 'slices/contentValidation.slice';

import { PANEL_ID } from '../ProfilePanel';

import styles from './TabPlan.module.scss';

const MediaPlanForm = Loadable({
  loader: () => import('components/campaigns/Composants/MediaPlanForm'),
  loading: () => <Loader full />,
})

const TAB_PLAN_ACTIONS = [
  'campaign/content_validation/getContentRequests',
  'campaign/content_validation/updateContentRequests',
];

const TabPlan = () => {
  const dispatch = useDispatch();

  const [campaignsForecastOpen] = useLocalStorage('campaigns_forecast_open', []);

  const { convert } = useCurrency();

  const { data } = usePanel(PANEL_ID, false);

  const { loading: investedBudgetLoading } = useLoading('updateDeliveryLoader');

  const [investedBudget, setInvestedBudget] = useState<string>();

  const [network] = useAtom(networkAtom);
  const [publicationType] = useAtom(publicationTypeAtom);

  const [, setIsEstimated] = useAtom(isEstimatedAtom);
  const [, setFollowersData] = useAtom(followersDataAtom);
  const [, setStatsData] = useAtom(statsDataAtom);

  const profileId = useMemo(() => data?.profileId || '', [data?.profileId]);

  const {
    campaignId,
    isCampaignArchived,
    userCan,
    campaignInvestedBudget,
    isAdmin,
    profilesIds,
    contentValidationLoading,
  } = useSelector(({ campaigns, views, user, contentValidation }) => {
    const campaignId = views.campaignDetails.id;
    const campaignInvestedBudget = campaignId
      ? campaigns[campaignId]?.profiles[profileId]?.delivery
      : null;
    const isCampaignArchived = campaignId
      ? campaigns[campaignId]?.core.state === CAMPAIGN_STATES.ARCHIVED
      : false;
    const userCan = campaignId
      ? getUserRightsOnSelection(campaigns[campaignId]?.core?.role)
      : {};
    return {
      campaignId,
      isCampaignArchived,
      userCan,
      campaignInvestedBudget,
      isAdmin: !!user.profile.admin,
      profilesIds: views.campaignDetails.profiles.filteredProfiles,
      contentValidationLoading: contentValidation.loading,
    };
  });

  const loading = useMemo(
    () => investedBudgetLoading || TAB_PLAN_ACTIONS.some((action) => contentValidationLoading.includes(action)),
    [investedBudgetLoading, contentValidationLoading]
  );

  const isForecastOpen = useMemo(
    () => campaignsForecastOpen.includes(campaignId),
    [campaignsForecastOpen, campaignId],
  );

  const handleClickApply = useCallback(() => {
    const delivery = Object.fromEntries(
      Object.entries({ investedBudget })
        .filter(([, value]) => value)
        .map(([key, value]) => [key, Number(value)]),
    );

    (async () => {
      try {
        if (!campaignId) return null;
        await dispatch(
          updateProfilesDelivery({
            profilesIds: [profileId],
            campaignId,
            delivery,
          }),
        );
        await dispatch(
          updateContentRequests({
            profile_ids: [profileId],
            project_id: campaignId,
          }),
        );
        if (isForecastOpen) {
          const [followers, stats] = await Promise.all([
            dispatch(
              getForecastFollowers({
                campaignId,
                network,
                profilesIds,
              }),
            ),
            dispatch(
              getForecastStats({
                campaignId,
                network,
                profilesIds,
                publicationType,
              }),
            ),
          ]);
          setFollowersData(
            followers?.followers_insight || DEFAULT_DATA.followersInsight,
          );
          setStatsData(stats?.statistics || DEFAULT_DATA.statistics);
          setIsEstimated(stats?.is_estimated || false);
        }
      } catch (error) {
        console.error('TabPlans:', error);
      }
    })();
  }, [
    campaignId,
    isForecastOpen,
    dispatch,
    investedBudget,
    profileId,
    network,
    publicationType,
    profilesIds,
    setFollowersData,
    setStatsData,
    setIsEstimated,
  ]);

  const resetInvestedBudgetData = useCallback(
    (investedBudgetData) => {
      if (investedBudgetData) {
        const { investedBudget: investedBudgetFromData, investedBudgetCurrency } = investedBudgetData;
        const budgetConverted = convert(
          investedBudgetFromData,
          { fromCurrency: investedBudgetCurrency as Currency }
        );
        setInvestedBudget(budgetConverted?.toString());
      }
    },
    [convert],
  );

  const handleCancel = useCallback(
    () => {
      if (campaignId) {
        dispatch(resetMediasPlan({ campaignId, profileId }));
        resetInvestedBudgetData(campaignInvestedBudget);
      }
    },
    [campaignId, profileId, resetInvestedBudgetData, campaignInvestedBudget, dispatch],
  );

  useEffect(() => {
    if (campaignId && profileId) {
      resetInvestedBudgetData(campaignInvestedBudget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, profileId]);

  const disableEdit = isAdmin || isCampaignArchived || !userCan.updateReporting;

  if (!profileId) return null;

  return (
    <div className="flex fdc ais jcsb fg1 prel">
      {loading && (
        <Loader full background="rgba(255, 255, 255, .8)" />
      )}

      <MediaPlanForm
        profileId={profileId}
        investedBudget={investedBudget}
        onChangeInvestedBudget={setInvestedBudget}
        disableInvestitedBudget={disableEdit}
        className="pad16"
        borderedItem={false}
      />

      <div className={styles.footer}>
        <Button3 theme="tertiary" onClick={handleCancel} disabled={disableEdit}>
          <FormattedMessage id="global.cta.cancel" />
        </Button3>
        <Button3 onClick={handleClickApply} disabled={disableEdit}>
          <FormattedMessage id="global.cta.save" />
        </Button3>
      </div>
    </div>
  );
};

export default TabPlan;
