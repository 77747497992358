import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import { InputGmap } from 'components';
import { conf } from 'config/env';
import MultiValueSelectionItem from '../components/MultiValueSelectionItem';
import styles from './SelectGmaps.module.scss';

class SelectGmaps extends Component {
	state = {
		placeSearched: '',
	};

	onChangeSelect = async ({ placeSearched, mapped }) => {
		const { onChange, selected, id } = this.props;

		this.setState({
			placeSearched
		});

		if (isEmpty(mapped)) return null;

		// Check if value is already in selected values
		if (!selected.includes(mapped?.g_place_id)) {
			const newSelected = [...selected, mapped];
			onChange({
				item: mapped,
				selected: newSelected,
				id,
				type: 'select-tags'
			})
		}

		this.setState({ placeSearched: '' })
	};

	onRemoveMap = item => {
		const { onChange, selected, id } = this.props;
		const newSelected = selected?.filter(s => s?.g_place_id !== item?.g_place_id);
		return onChange && onChange({ item, selected: newSelected, id, type:'select-tags' })
	};

	getLabelLocalisation = s => {
		return s.g_city && s.g_city !== ""
			? s.g_city
			: s.g_formatted_address
		    ? s.g_formatted_address
				: ''
	}

	render() {
		const { className, selected, maxMaps, disabled, label, placeholder } = this.props;
		const { placeSearched } = this.state;

		const isFull = maxMaps > 0 && selected?.length >= maxMaps;

		const cnSelectGmaps = cn('bnc_field bnc_field_select_tags', styles.selectGmaps, className);

		return (
			<div className={cnSelectGmaps}>
				<InputGmap
					className={styles.inputGmap}
					label={label}
					placeholder={placeholder}
					value={placeSearched}
					disabled={isFull || disabled}
					GmapApiKey={conf.gmapApiKey}
					GmapApiLanguage={conf.gmapApiLanguage}
					onChange={this.onChangeSelect}
					size="small"
				/>
				{selected?.length > 0 && (
					<div className={styles.tagContainer}>
						<ul className={styles.tagList}>
							{selected.map(s => (
								<MultiValueSelectionItem
									className={styles.tagItem}
									key={s.g_place_id}
									disabled={disabled}
									value={s}
									onRemove={this.onRemoveMap}
								>
									{ String(this.getLabelLocalisation(s)) }
								</MultiValueSelectionItem>
							))}
						</ul>
					</div>
				)}
			</div>
		)
	}
}

SelectGmaps.propTypes = {
	id: PropTypes.string,
	selected: PropTypes.arrayOf(PropTypes.shape({
		g_place_id: PropTypes.string,
	})),
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
	maxMaps: PropTypes.number,
};

SelectGmaps.defaultProps = {
	id: '',
	onChange: null,
	placeholder: '',
	label: ``,
	className: ``,
	selected: [],
	maxMaps: -1,
};

export default SelectGmaps;
