import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl-phraseapp';
import { Input2 } from 'kolkit';

import { Field } from 'components';
import { useFilter } from 'components/engine/hooks';

import styles from './Community.module.scss';

const Community = ({
  network,
  label,
  filterKey,
  className,
  onChange,
  disabled,
}) => {
  const intl = useIntl();
  const value = useFilter(filterKey, network);

  const hasError = value =>
    value.min !== '' &&
    value.max !== '' &&
    Number(value.min) > Number(value.max);

  const handleChange = useCallback(
    ({ value: newValue, name }) => {
      onChange({
        field: filterKey,
        value: {
          ...value,
          [name]: newValue,
        },
        network,
      });
    },
    [onChange, value, filterKey, network],
  );

  const triggerLogEvent = useCallback(
    key => () => {
      // take advantage of the `selected` field to log the final input value;
      // whereas the `value` field tracks the current input value (during editing)
      onChange({
        field: filterKey,
        value,
        network,
        selected: value[key],
      });
    },
    [onChange, value, filterKey, network],
  );

  const cnCommunity = cn(styles.community, className, styles[network]);

  return (
    <div className={cnCommunity}>
      <Field label={label}>
      <div className={styles.grid}>
        {value &&
          Object.entries(value).map(([key, num]) => {
            return (
              <Input2
                id={`input-${network}-${filterKey}-${key}`}
                key={key}
                name={key}
                size="small"
                value={num}
                onChange={handleChange}
                onBlur={triggerLogEvent(key)}
                placeholder={intl.formatMessage({id: `global.${key}`})}
                error={hasError(value)}
                disabled={disabled}
                fullWidth
                locale={intl.locale}
                type="number"
              />
            );
          })
        }
      </div>
      </Field>
    </div>
  );
};

Community.displayName = 'Community';

Community.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Community.defaultProps = {
  className: undefined,
  disabled: false,
  label: null,
};

export default Community;
