import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { timelineConnectorClasses } from '@mui/lab/TimelineConnector';
import { timelineDotClasses } from '@mui/lab/TimelineDot';
import { timelineContentClasses } from '@mui/lab/TimelineContent';
import { timelineSeparatorClasses } from '@mui/lab';

export const timelineSx = {
  padding: `6px 0`,
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  [`& .${timelineItemClasses.root}`]: {
    minHeight: 'auto',

    '&.filler,&.loadMore': {
      [`& .${timelineSeparatorClasses.root}`]: {
        minWidth: '9px',
      },
    },
    '&.filler': {
      flexGrow: 1,
    },
    '&.loadMore': {
      [`& .${timelineContentClasses.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  [`& .${timelineDotClasses.root}`]: {
    margin: 0,
    padding: '2.5px',
    backgroundColor: 'var(--color-dominant-on-dominant)',
  },
  [`& .${timelineConnectorClasses.root}`]: {
    width: '1px',
    backgroundColor: 'var(--color-dominant-on-dominant)',

    '&.filler': {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '22px',
    },
  },
  [`& .${timelineContentClasses.root}`]: {
    paddingRight: 0,
  },
};
