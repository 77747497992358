import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { Modal2 } from 'kolkit';

import useModal from 'utils/hooks/useModal';
import Textarea2 from 'components/ui/Textarea2';
import { useSelector, useDispatch } from 'utils/redux';
import renderModal from 'utils/HOCs/renderModal';

import { updateContentInstructions } from 'slices/contentValidation.slice';

export const MODAL_ID = 'ContentInstructionsModal';

const ContentInstructionsModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    show,
    off,
    data: { profileId, campaignId, instructions: initialInstructions },
  } = useModal<{ profileId: number; campaignId: number; instructions: string; }>(MODAL_ID);

  const [instructions, setInstructions] = useState(initialInstructions);

  const loading = useSelector( ({ contentValidation: { loading } }) => {
    return loading.includes('campaign/content_validation/updateContentInstructions')
  });

  const handleChange = useCallback((value) => {
    setInstructions(value)
  }, []);

  const handleSave = useCallback(
    async () => {
    if (campaignId) {
      void await dispatch(
          updateContentInstructions({
            profile_id: profileId,
            project_id: campaignId,
            content_instructions: instructions,
          }),
        );
        off();
      }
    },
    [dispatch, off, instructions, profileId, campaignId]
  );

  const handleClose = useCallback(() => {
    off();
  }, [off]);

  const action = useMemo(() => {
    return {
      primary: {
        title: intl.formatMessage({ id: 'global.cta.save' }),
        onClick: handleSave,
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: handleClose,
      },
    };
  }, [handleClose, handleSave, intl]);

  return (
    <Modal2
      title={intl.formatMessage({ id: 'profileDetails.profileInfos.instructions.modal.title' })}
      on={show}
      action={action}
      onClick={handleClose}
      loading={loading}
    >
      <Textarea2
        id="profile-content-instruction"
        name="instructionsKol"
        value={instructions}
        onChange={handleChange}
        rows={10}
        autoGrowing
        fullWidth
      />
    </Modal2>
  );
};

ContentInstructionsModal.displayName = 'ContentInstructionsModal';

export default renderModal(MODAL_ID, ContentInstructionsModal);
