import { useCallback } from 'react';
import { BASE_CURRENCY_BACKEND, Currency } from 'config/currencies';
import { useSelector } from 'utils/redux';

type ConvertFunction = (
  value: string | number,
  options: { toCurrency?: Currency; fromCurrency?: Currency },
) => number | null;

const useCurrency = () => {
  const { currentCurrency, currentCurrencySymbol, currencyRates } = useSelector(
    ({
      env: {
        currency: {
          value: currentCurrency,
          symbol: currentCurrencySymbol,
          rates,
        },
      },
    }) => ({
      currentCurrency,
      currentCurrencySymbol,
      currencyRates: rates ?? [],
    }),
  );

  const convert: ConvertFunction = useCallback(
    (value, { toCurrency = currentCurrency, fromCurrency = BASE_CURRENCY_BACKEND } = {}) => {
      if (value === null || value === undefined) return null;
      let toRate = 1;
      let fromRate = 1;

      const targetRate = currencyRates.find(currency => currency.target === toCurrency);
      if (targetRate) {
        toRate = Number(targetRate.rate);
      }

      const sourceRate = currencyRates.find(
        currency => currency.target === fromCurrency,
      );
      if (sourceRate) {
        fromRate = Number(sourceRate.rate);
      }

      return Number(Number((Number(value) / fromRate) * toRate).toFixed(2));
    },
    [currencyRates, currentCurrency],
  );

  return {
    convert,
    currentCurrency,
    currentCurrencySymbol,
    rates: currencyRates,
  };
};

export default useCurrency;