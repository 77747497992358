import React from 'react';
import cn from 'classnames';
import styles from './Wrapper.module.scss';

const WrapperFilter = ({ children, style, title, subTitle, isActive, noMargin, ...rest }) => {
	const cnWrapperFilter = cn(styles.wrapperFilter, {
		[styles.inactive]: !isActive,
		[styles.noMargin]: noMargin
	});

	return (
		<div {...rest} className={cnWrapperFilter}>
			{ title && <h3 translate="no">{title} <span>{subTitle}</span></h3>}
			<div className={styles.content}>
				{children}
			</div>
		</div>
	)
};

export default WrapperFilter;
