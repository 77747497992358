export enum API_INTEGRATIONS {
  GMAIL = 'gmail',
  SHOPIFY = 'shopify',
  AFFILAE = 'affilae',
}

export const API_INTEGRATIONS_LIST = [
  { id: API_INTEGRATIONS.GMAIL, icon: 'gmail' },
  { id: API_INTEGRATIONS.SHOPIFY, icon: 'shopify' },
  { id: API_INTEGRATIONS.AFFILAE, icon: 'affilae' },
];

export const SHOPIFY_MODAL_ID = 'shopify-integration';
