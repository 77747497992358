import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';
import { Checkbox2 } from 'kolkit';
import { useAllActiveFilter } from './useActiveFilter';

import styles from './ActiveFilter.module.scss';


const ActiveAllFilter = ({ onChange, className }) => {
  const {checked, handleChange} = useAllActiveFilter(onChange);
  const intl = useIntl();
  const cnFilter = cn(styles.container, className);

  return (
    <div className={cnFilter}>
      <Checkbox2
        id="activeAllFilter"
        name="activeAllFilter"
        checked={checked}
        onChange={handleChange}
        label={intl.formatMessage({id: `global.${checked ? 'unSelectAll' : 'selectAll'}`})}
      />
    </div>
  );
};

ActiveAllFilter.displayName = 'ActiveAllFilter';

ActiveAllFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(ActiveAllFilter);
