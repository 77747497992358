import React, {
  useState,
  useCallback,
  ComponentPropsWithRef,
  useEffect
} from 'react';
import cn from 'classnames';
import debounce from 'lodash.debounce';

import { Icon } from 'kolkit';

import styles from './useTableScroll.module.scss';

const useTableScroll = ({
  tableContentRef,
  scrollTop = 200,
  onClickCallback,
  debounceValue = 450,
}) => {
  const [displayBackToTop, setDisplayBackToTop] = useState(false);
  const [scrollPosition, setScrollPosition] = useState({
    top: 0,
    left: 0
  });

  const handleScroll = debounce(
    () => {
      setScrollPosition({
        top: tableContentRef?.current?.scrollTop,
        left: tableContentRef?.current?.scrollLeft,
      })
    },
    debounceValue
  )

  const handleDisplayBackToTop = debounce(
    () => setDisplayBackToTop(tableContentRef?.current?.scrollTop > scrollTop),
    1000
  );

  useEffect(() => {
    handleDisplayBackToTop();
    // eslint-disable-next-line
  }, [tableContentRef?.current?.scrollTop])

  const isScrollable = useCallback(
    () => tableContentRef?.current?.scrollHeight > tableContentRef?.current?.clientHeight,
    [tableContentRef]
  );

  const isScrollableX = useCallback(
    () => tableContentRef?.current?.scrollWidth > tableContentRef?.current?.clientWidth,
    [tableContentRef]
  );

  const handleClickScrollTop = useCallback(
    () => {
      tableContentRef?.current?.scroll({
        top: 0,
        behavior: 'smooth',
      });
      if (onClickCallback) onClickCallback();
    },
    [tableContentRef, onClickCallback]
  );

  const handleScrollToIndex = useCallback(
    (index) => {
      tableContentRef?.current?.scrollToIndex({
        index,
        behavior: 'smooth',
      })
    },
    [tableContentRef]
  );

  const ScrollTopButton = useCallback(
    ({ className, ...props }: Partial<ComponentPropsWithRef<typeof Icon>>) => {
      const cnScrollTopButton = cn(styles.scrollTopButton, {
        [styles.visible]: displayBackToTop,
        className,
      });

      return (
        <Icon
          className={cnScrollTopButton}
          onClick={handleClickScrollTop}
          label="arrow-up"
          theme="solid"
          isButton
          {...props}
        />
      );
    },
    [handleClickScrollTop, displayBackToTop],
  );

  return {
    displayBackToTop,
    handleScroll,
    handleClickScrollTop,
    handleScrollToIndex,
    scrollPosition,
    ScrollTopButton,
    isScrollable,
    isScrollableX,
  };
};

export default useTableScroll;
