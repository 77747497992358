import routes from 'config/routes';

const envConfig = {
  gmapApiKey: process.env.REACT_APP_GMAP_API_KEY,
  gOAuthClientId: process.env.REACT_APP_GOAUTH_CLIENT_ID,
  salesMachineToken: process.env.REACT_APP_SALES_MACHINE_TOKEN,
  satisMeterWriteKey: process.env.REACT_APP_SATISMETER_WRITE_KEY,
  phraseAccessToken: process.env.REACT_APP_PHRASE,
  phraseAccountId: process.env.REACT_APP_PHRASE_ACCOUNT_ID,
  slackWebhooks: process.env.REACT_APP_SLACK_WEBHOOKS,
  fullStory: process.env.REACT_APP_FULLSTORY,
  appsignal: process.env.REACT_APP_APPSIGNAL,
  planhat: process.env.REACT_APP_PLANHAT,
  productFruitsWorkspaceCode: process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE,
  ipgeolocationIoApiKey: process.env.REACT_APP_IPGEOLOCATION_IO_API_KEY,
};

const DEV = {
  api: 'http://api.localdev.com:3000',
  apiV1: 'http://api.localdev.com:3000/v1',
  websocket: 'ws://api.localdev.com:3000/cable',
  translation: 'https://api.phrase.com/v2/projects',
  cookieToken: '_kolsquare_api_token_development',
  cookieHostname: '.localdev.com',
  shortIoDomain: 'klsq.fr',
  urls: {
    app: 'http://influence.localdev.com:3001',
    // login: `http://localhost:3001/${routes.login}`,
    signUp: 'http://kolsquare.localdev.com:3004/register',
    login: `http://influence.localdev.com:3001/${routes.login}`,
    account: 'http://app.localdev.com:3005/agencies/{0}/edit',
    adminPublicProfile: 'http://admin.localdev.com:3002/catalog/profiles/{0}',
    celebNews: 'http://app.localdev.com:3005/celeb_news?profile_id=',
    kolsquare: 'http://kolsquare.localdev.com:3004',
    requestDemo:
      'http://kolsquare.localdev.com:3004/{lang}/request-a-demo?utm_source=demo_influence',
    influence: 'http://influence.localdev.com:3001',
    admin: 'http://admin.localdev.com:3002',
    reporting: 'http://reports.localdev.com',
  },
  whitelist: {
    pptx: [],
  }
};

const getRemoteConfig = () => {
  const { hostname, protocol } = window.location;
  return {
    api: `${protocol}//${hostname}:3000`,
    apiV1: `${protocol}//${hostname}:3000/v1`,
    websocket: `ws://${hostname}:3000/cable`,
    translation: 'https://api.phrase.com/v2/projects',
    cookieToken: '_kolsquare_api_token_development',
    cookieHostname: hostname,
    shortIoDomain: 'klsq.fr',
    urls: {
      app: `${protocol}//${hostname}:3001`,
      signUp: `${protocol}//${hostname}:3004/register`,
      login: `${protocol}//${hostname}:3001/${routes.login}`,
      account: `${protocol}//${hostname}:3005/agencies/{0}/edit`,
      adminPublicProfile: `${protocol}//${hostname}:3002/catalog/profiles/{0}`,
      celebNews: `${protocol}//${hostname}:3005/celeb_news?profile_id=`,
      kolsquare: `${protocol}//${hostname}:3004`,
      requestDemo:
        `${protocol}://${hostname}:3004/{lang}/request-a-demo?utm_source=demo_influence`,
      influence: `${protocol}//${hostname}:3001`,
      admin: `${protocol}//${hostname}:3002`,
      reporting: `${protocol}//reports.${hostname}`,
    },
    whitelist: {
      pptx: [],
    }
  };
};

const STAGING = {
  api: 'https://api-staging.kolsquare.com',
  apiV1: 'https://api-staging.kolsquare.com/v1',
  websocket: 'wss://api-staging.kolsquare.com/cable',
  translation: 'https://api.phrase.com/v2/projects',
  cookieToken: '_kolsquare_api_token_staging',
  cookieHostname: '.kolsquare.com',
  shortIoDomain: 'klsq.fr',
  urls: {
    app: 'https://influence-staging.kolsquare.com',
    signUp: 'https://staging.kolsquare.com/register',
    login: `https://influence-staging.kolsquare.com/${routes.login}`,
    account: 'https://app-staging.brandandcelebrities.com/agencies/{0}/edit',
    adminPublicProfile:
      'https://admin-staging.kolsquare.com/catalog/profiles/{0}',
    celebNews:
      'https://app-staging.brandandcelebrities.com/celeb_news?profile_id=',
    kolsquare: 'https://staging.kolsquare.com',
    requestDemo:
      'https://staging.kolsquare.com/{lang}/request-a-demo?utm_source=demo_influence',
    influence: 'https://influence-staging.kolsquare.com',
    admin: 'https://admin-staging.kolsquare.com',
    reporting: 'https://reports-staging.kolsquare.com',
  },
  whitelist: {
    pptx: [
      23113, // romain.knecht+stagingzz2@kolsquare.com
      21506, // maxime.modolo+legacy@kolsquare.com
      24017, // maxime.menigoz+agence@kolsquare.com
      14946, // hoby.ratovoarison+agence@brandandcelebrities.com
    ]
  }
};

const LAB = {
  api: 'https://api-lab.kolsquare.com',
  apiV1: 'https://api-lab.kolsquare.com/v1',
  websocket: 'wss://api-lab.kolsquare.com/cable',
  translation: 'https://api.phrase.com/v2/projects',
  cookieToken: '_kolsquare_api_token_lab',
  shortIoDomain: 'klsq.fr',
  urls: {
    app: 'https://influence-lab.kolsquare.com',
    signUp: 'https://lab.kolsquare.com/register',
    login: `https://influence-lab.kolsquare.com/${routes.login}`,
    account: 'https://app-lab.brandandcelebrities.com/agencies/{0}/edit',
    adminPublicProfile: 'https://admin-lab.kolsquare.com/catalog/profiles/{0}',
    celebNews: 'https://app-lab.brandandcelebrities.com/celeb_news?profile_id=',
    kolsquare: 'https://lab.kolsquare.com',
    requestDemo:
      'https://lab.kolsquare.com/{lang}/request-a-demo?utm_source=demo_influence',
    influence: 'https://influence-lab.kolsquare.com',
    admin: 'https://admin-lab.kolsquare.com',
    reporting: 'https://reports-lab.kolsquare.com',
  },
  whitelist: {
    pptx: [
      23735, // romain.knecht+zzz1@kolsquare.com
      21506, // maxime.modolo+legacy@kolsquare.com
      25533, // maxime.menigoz+agence@kolsquare.com
      14946, // hoby.ratovoarison+agence@brandandcelebrities.com
    ]
  }
};

const PROD = {
  api: 'https://api.kolsquare.com',
  apiV1: 'https://api.kolsquare.com/v1',
  websocket: 'wss://api.kolsquare.com/cable',
  translation: 'https://api.phrase.com/v2/projects',
  cookieToken: '_kolsquare_api_token',
  cookieHostname: '.kolsquare.com',
  shortIoDomain: 'klsq.io',
  urls: {
    app: 'https://influence.kolsquare.com',
    signUp: 'https://www.kolsquare.com/register',
    login: `https://influence.kolsquare.com/${routes.login}`,
    account: 'https://app.brandandcelebrities.com/agencies/{0}/edit',
    adminPublicProfile: 'https://admin.kolsquare.com/catalog/profiles/{0}',
    celebNews: 'https://app.brandandcelebrities.com/celeb_news?profile_id=',
    requestDemo:
      'https://www.kolsquare.com/{lang}/request-a-demo?utm_source=influence',
    kolsquare: 'https://www.kolsquare.com',
    influence: 'https://influence.kolsquare.com',
    admin: 'https://admin.kolsquare.com',
    reporting: 'https://reports.kolsquare.com',
  },
  whitelist: {
    pptx: [
      21080, // romain.knecht+agence@kolsquare.com
      12742, // maxime.modolo+agence@brandandcelebrities.com
      21506, // maxime.modolo+legacy@kolsquare.com
      2972,  // alix+agence@brandandcelebrities.com
      27193, // mehdi.jouahri@kolsquare.com
      21653, // pierre-nicolas.ngamby+agence@kolsquare.com
      14946, // hoby.ratovoarison+agence@brandandcelebrities.com
      15934, // goldasarah.benchetrit@hermes.com
      16840, // jennifer.alegre@hermes.com
      22425, // charlotte.gaspard@hermes.com
      25394, // joyce.atty@hermes.com
    ]
  }
};

export const ENV_DEV = 'env|dev';
export const ENV_REMOTE = 'env|remote';
export const ENV_STAGING = 'env|staging';
export const ENV_LAB = 'env|lab';
export const ENV_PROD = 'env|prod';

export const EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/kolsquare-analytics-sideb/kfdkgfifhmnmplmlglbjamphjdahhbbn';

// REACT_APP_ENV=staging npm start
const overrideEnv = process.env.REACT_APP_ENV;

const location = window.location.hostname;
export const DEV_DEBUG = !overrideEnv && (location?.includes('localhost') && !location?.includes('localdev'));
// export const DEV_DEBUG = false;

export const env = (() => {
  // If ENV is given, use it
  if(overrideEnv) {
    switch (overrideEnv) {
      case 'prod':
        return ENV_PROD;
      case 'staging':
        return ENV_STAGING;
      case 'lab':
        return ENV_LAB;
      default:
        throw new Error(`Incorrect ENV override: ${overrideEnv}`);
    }
  }

  // If no ENV given, infer from URL
  if (location.indexOf('dev-kolsquare-app-') !== -1) return ENV_REMOTE;
  if (
    location.indexOf('localhost') !== -1 ||
    location.indexOf('localdev') !== -1 ||
    location.indexOf('192.168.') !== -1
  )
    return ENV_DEV;
  if (location.indexOf('staging.kolsquare.com') !== -1) return ENV_STAGING;
  if (location.indexOf('lab.kolsquare.com') !== -1) return ENV_LAB;
  return ENV_PROD;
})();

export const getEnv = () => {
  switch (env) {
    case ENV_DEV:
      return DEV;
    case ENV_REMOTE:
      return getRemoteConfig();
    case ENV_STAGING:
      return STAGING;
    case ENV_LAB:
      return LAB;
    default:
      return PROD;
  }
};

export const conf = {
  gmapApiLanguage: 'en',
  ...envConfig,
  ...getEnv(),
  overrideEnv,
};
