import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox2, Spaces } from 'kolkit';
import { useIntl } from 'react-intl/lib';

import { useFilter } from 'components/engine/hooks';

import styles from './Arpp.module.scss';


const Arpp = ({ network, onChange }) => {
  const filterTarget = 'arpp_certified';

  const intl = useIntl();

  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(
    () => {
      onChange({
        field: filterTarget,
        value: !value,
        selected: !value,
        network
      });
    },
    [network, onChange, value]
  );

  return (
    <>
      <div className={styles.container}>
        <Checkbox2
          id={filterTarget}
          checked={value}
          onChange={handleChange}
          label= {
            <div className={styles.label}>
              <span className={styles.title}>{intl.formatMessage({ id: 'arpp.label' })}</span>
              <Icon tooltip={intl.formatMessage({ id: 'arpp.tooltip' })} label="info-circle" size="small" />
            </div>
          }
        />
      </div>
      <Spaces bottom={8} />
    </>
  )
};

Arpp.displayName = 'Arpp';

Arpp.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Arpp);
