import { Icon } from 'kolkit';
import React from 'react';

type Props = {
  value: number;
};

const CommunityEvolutionArrow: React.FC<Props> = ({ value }) => {
  let icon;
  let fill;
  if (value > 0) {
    icon = 'arrow-up-right';
    fill = 'var(--color-evolution-up)';
  } else if (value < 0) {
    icon = 'arrow-down-right';
    fill = 'var(--color-evolution-down)';
  } else {
    icon = 'hyphen';
  }

  return <Icon theme="solid" label={icon} fill={fill} />;
};

export default CommunityEvolutionArrow;
