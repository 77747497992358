import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { Helmet } from 'react-helmet';

import { Icon } from 'kolkit';
import {
	Button,
	Select,
	AnimatedBullet,
} from 'components/ui';
import { filters as initialFilters } from 'config/init';
import { updateFilter, resetFilters } from 'actions/user';
import { decodeSearchUrl, updateTab, getSavedSearches } from 'actions/engine';
import { useSelector, useDispatch } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import useHandleHighlight from 'utils/hooks/useHandleHighlight';

import SearchHelpModal from './Modals/SearchHelpModal';
import { SearchBar } from './Modules';

import styles from './Engine.module.scss';

const SEARCH_TYPES = ['post', 'people', 'description', 'url'];

const SearchBarWrapper = () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const { on } = useModal('saveSearch');
	const { on: showHelpModal } = useModal('searchEngine.helper');

	const { displayHightLight, handleCloseHighlight } = useHandleHighlight('save_searches');

	const [search, type, hasSearch, isAdmin] = useSelector(({ user, engine }) => [
		user.filters.search || [],
		engine.type,
		engine?.results?.hasSearch,
		user.profile.admin
	]);

	useEffect(() => {
		dispatch(getSavedSearches());
	}, [dispatch])

	useEffect(() => {
		const urlDecoder = decodeSearchUrl(window.location.search);
		const urlValue = urlDecoder?.filters?.search;
		if (urlValue && (JSON.stringify(search) !== JSON.stringify(urlValue))) {
			dispatch(updateFilter({
				field: 'search',
				value: urlValue,
			}));
		}
		// Search tags are not updated if this is removed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	const typeValues = useMemo(
		() => SEARCH_TYPES.map(type => ({
			value: type,
			label: intl.formatMessage({ id: `engineSearch.${type}.label`})
		})),
		[intl]
	);

	const updateSearch = useCallback(
		({ tags, action }) => {
			const immediateSearch = action === 'deletion' && tags?.length > 0;
			// on writing the first keyword, default time period to lastMonth, can be custom for next ones
			if(!search?.length){
				dispatch(
					updateFilter({
						field: 'period',
						value: initialFilters?.period,
						topSort: true,
					}),
				);
			}
			dispatch(updateFilter({
				field: 'search',
				value: tags,
				immediateSearch,
			}));
		},
		[dispatch, search]
	);

	const onChangeSearchType = useCallback(
		({ value }) => dispatch(updateTab(value)),
		[dispatch]
	);

	const onResetFilters = useCallback(
		() => dispatch(resetFilters()),
		[dispatch]
	);

	return (
		<div className="engine-header">
			<Helmet>
				<title>
					{intl.formatMessage({ id: `global.search.title` })} - Kolsquare
				</title>
			</Helmet>
			<div className="master-search-bar">
				<div className="search-container">
					<Select
						className="master-search-bar-select"
						hasSearch={false}
						sort={false}
						dataset={typeValues}
						value={type}
						onChange={onChangeSearchType}
						inPortal={false}
					/>
					<SearchBar
						placeholder={intl.formatMessage({ id: `engineSearch.${type}.placeholder`})}
						onChange={updateSearch}
						tags={search}
						editable
						displayPlaceholder
						displaySearchButton
					/>
				</div>
				<div className={styles.searchRightAction}>
					<Icon
						className={styles.iconHelper}
						label="info-circle"
						size="huge"
						isButton
						onClick={() => showHelpModal()}
					/>
					{!isAdmin && (
						<Icon
							className={styles.iconHelper}
							label="bookmark"
							size="huge"
							isButton
							onClick={() => on()}
							tooltip={intl.formatMessage({ id: 'engineSearch.save.addTitle' })}
							disabled={!hasSearch}
						/>
					)}
					{displayHightLight && (
						<AnimatedBullet
							id="save_searches"
							message={intl.formatMessage({ id: 'engineSearch.save.announcement' })}
							onClose={handleCloseHighlight}
							className={styles.bullet}
							theme="avender"
						/>
					)}
				</div>
			</div>

			<div className={styles.resetSearchContainer}>
				<Button theme="ghost" onClick={onResetFilters}>
					{intl.formatMessage({ id: 'global.cta.resetSearch' })}
				</Button>
			</div>
			<SearchHelpModal />
		</div>
	)
};

export default memo(SearchBarWrapper);
