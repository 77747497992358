import React, { memo, forwardRef } from 'react';
import cn from 'classnames';

import styles from './Tile.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
  noPadding?: boolean;
  allowOverflow?: boolean;
  hideOverflow?: boolean;
  border?: boolean;
  noShadow?: boolean;
  padding?: number | string;
  style?: React.CSSProperties;
}

type Ref = HTMLDivElement;

const Tile = forwardRef<Ref, Props>(
  (
    {
      children,
      className,
      noPadding,
      allowOverflow,
      hideOverflow,
      noShadow,
      border,
      padding,
      style,
      ...rest
    },
    ref,
  ) => {
    const cnTile = cn(
      styles.tile,
      {
        [styles.noShadow]: noShadow,
        [styles.border]: border,
        [styles.noPadding]: noPadding,
        [styles.overflowVisible]: allowOverflow,
        [styles.overflowHidden]: hideOverflow,
      },
      className,
    );

    return (
      <div
        ref={ref}
        className={cnTile}
        style={{
          padding: noPadding ? 0 : padding || 24,
          ...style,
        }}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

Tile.displayName = 'Tile';

export default memo(Tile);
