import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useMeasure from 'react-use-measure';
import ResizeObserver from 'resize-observer-polyfill';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

import styles from './Chips2.module.scss';

const Chips2 = forwardRef(({ label, className, onClick, theme, size, status, maxWidth, dataId, ...rest }, ref) => {
  const [refMeasure, measure] = useMeasure({ polyfill: ResizeObserver });

  const cnChips = cn(
    styles.chips,
    styles[theme],
    styles[size],
    styles[status],
    className
  );

  const cnSpan = cn(
    styles.span,
    styles[theme],
    styles[size],
    styles[status], {
      [styles.maxWidth]: maxWidth
    }
  );

  const initTooltip = useMemo(
    () => maxWidth < (measure?.width + 16),
    [maxWidth, measure.width]
  );

  const handleClick = useCallback(
    (id, value) => onClick({ id, value }),
    [onClick]
  )

  const styleChips = useMemo(
    () => ({ maxWidth: !maxWidth ? 'auto' : maxWidth }),
    [maxWidth]
  );

  const displayChips = () => (
    <div className={cnChips} ref={refMeasure} style={styleChips} {...rest}>
      <div className={cnSpan} ref={ref}>{label}</div>
      {onClick && (
        <Icon
          className={styles.close}
          label="times"
          width={12}
          onClick={handleClick}
          dataId={dataId}
        />
      )}
    </div>
  );

  return !initTooltip
    ? displayChips()
    : (
      <Tooltip content={label}>
        {displayChips()}
      </Tooltip>
    );
});

Chips2.displayName = 'Chips2';

Chips2.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'status']),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big']),
  status: PropTypes.oneOf(['draft', 'ready', 'started', 'finished', 'archived']),
  maxWidth: PropTypes.string,
  dataId: PropTypes.string
};

Chips2.defaultProps = {
  className: undefined,
  onClick: null,
  theme: 'default',
  size: 'small',
  status: null,
  maxWidth: '130px',
  dataId: null
};

export default memo(Chips2);
