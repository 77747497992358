import { useLayoutEffect } from 'react';

const useLockBodyOnScroll = on => {
  useLayoutEffect(
    () => {
      const previousOverflowValue = document.body.style.overflow;
      if (on) document.body.style.overflow = 'hidden';
      return () => document.body.style.overflow = previousOverflowValue || 'visible';
    },
    [on]
  );
};

export default useLockBodyOnScroll;
