import React, { memo, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'utils/redux';
import { hasActiveFilter } from 'actions/user';
import { filters as initialFilters } from 'config/init';
import NewSearchToast from 'components/search/SearchEngine/modules/NewSearchToast';
import ProfilePanel from 'components/profilePanel/ProfilePanel';

import SearchResults from './SearchResults';
import SearchBarWrapper from './SearchBarWrapper';
import SearchFilters from './SearchFilters';
import TopSortFilter from './TopSortFilter';
import TopActions from './TopActions';
import TopMultiSearch from './TopMultiSearch';

import { SavedSearches, SaveSearch } from './Modals';
import { Admin } from './Modules';

import 'css/scss/engine/engine.scss';
import 'css/scss/selections/switcher.scss';

import styles from './Engine.module.scss';

const noFilterKey = ['period', 'hasActiveFilter', 'status', 'state', 'sid', 'initFilter', 'search'];

const mapper = data => Object.entries(data)
	?.reduce((acc, [key, value]) => {
		if (noFilterKey.includes(key)) return acc;
		return ({
			...acc,
			[key]: value
		})
	}, {})

const Engine = () => {
	const dispatch = useDispatch();

	const { isAdmin, filters } = useSelector(({ user }) => ({
		isAdmin: user.profile.admin,
		filters: user.filters,
	}));

	const activeFilter = useMemo(
		() => {
			const mapFilter = mapper(filters);
			const mapDefaultFilter = mapper(initialFilters);
			return JSON.stringify(mapFilter) !== JSON.stringify(mapDefaultFilter)
		},
		[filters]
	);

	useEffect(() => {
		dispatch(hasActiveFilter({ value: activeFilter }));
		// eslint-disable-next-line
	}, [activeFilter]);

	return (
		<>
			<SearchBarWrapper />
			<SearchFilters />

			<div className="engine-container">
				{isAdmin && <Admin />}
				<div className={styles.engineTop}>
					<TopActions />
					<div className={styles.engineTopMulti}>
						<TopMultiSearch />
					</div>
					<TopSortFilter />
				</div>
				<div className="engine-container_columns">
					<SearchResults />
				</div>
			</div>

			<SavedSearches />
			<SaveSearch />
			<NewSearchToast />
			<ProfilePanel />
		</>
	);
};

Engine.displayName = 'Engine';

export default memo(Engine)
