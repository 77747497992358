import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Icon, Input } from 'kolkit'

class SearchBarTag extends PureComponent {
  state = {
    isEdited: false,
    tagValue: '',
    widthEditionMode: 0,
  }

  tagRef = React.createRef();

  onKeyDownSelectedTag = e => {
    const { deleteTag, onArrowKey, onKeyDownSelectedTagActive, activateOnKeyDownSelectedTag } = this.props;
    const { key } = e;

    if (onKeyDownSelectedTagActive) {
      if (key === 'ArrowLeft') { onArrowKey('left') }
      if (key === 'ArrowRight') { onArrowKey('right') }
    }
    else {
      activateOnKeyDownSelectedTag()
    }
    if (key !== 'Delete' && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') return null;
    if (key === 'Delete' || key === 'Backspace') {
      deleteTag();
    } else if (key === 'Enter') {
      this.quitEditMode();
    } else {
      e.preventDefault();
    }
  }

  onBlurSelectedTag = () => {
    const { onChangeSelectedTag } = this.props;
    onChangeSelectedTag({ value: false });
  }

  selectTag = () => {
    const { selectable, onChangeSelectedTag } = this.props;

    if (!selectable) return null;

    onChangeSelectedTag({ value: true });
  }

  editTag = ({value: tagValue}) => {
    const comma = tagValue.indexOf(',');
    if (comma > -1) {
      if (tagValue?.trim().length === 1) return null;
      this.updateTag()
    }
    this.setState({tagValue})
  }

  activateEditionMode = () => {
    const { editable, value } = this.props;

    if (!editable) return null;
    const elementOffset = this.tagRef?.current?.offsetWidth;
    if (!elementOffset) return null;
    this.setState({ isEdited: true, tagValue: value, widthEditionMode: elementOffset });
  }

  renderPrefixedTag = () => {
    const {value: tag} = this.props;
    const colorTag = tag.substring(0,1);
    const simpleTag = tag.substring(1);

    return (
      <button
        className="search-bar-tag-inner"
        onClick ={ this.selectTag }
        onDoubleClick={this.activateEditionMode}
        onBlur={ this.onBlurSelectedTag }
      >
        <span className="search-bar-tag-inner--colored"> {colorTag} </span>
        {simpleTag}
      </button> )
  }

  renderWrappedTag = () => {
    const {  value: tag} = this.props;
    const colorTag = tag.substring(0,1);
    const simpleTag = tag.substring(1, tag.length -1);

    return (
      <button
        className="search-bar-tag-inner"
        onClick ={ this.selectTag }
        onDoubleClick={this.activateEditionMode}
        onBlur={ this.onBlurSelectedTag }
      >
        <span className="search-bar-tag-inner--colored"> {colorTag} </span>
        {simpleTag}
        <span className="search-bar-tag-inner--colored search-bar-tag-inner--colored-right-quote"> {colorTag} </span>
     </button> )
  }

  renderHighlightedWordTag = searchHighlightWord => {
    const {value: tag} = this.props;
    const colorTag = tag.substring(0, searchHighlightWord.length + 1);
    const simpleTag = tag.substring(searchHighlightWord.length + 1);
    return (
      <button
        className="search-bar-tag-inner"
        onClick ={ this.selectTag }
        onDoubleClick={this.activateEditionMode}
        onBlur={ this.onBlurSelectedTag }
      >
        <span className="search-bar-tag-inner--colored"> {colorTag} </span>
        {simpleTag}
      </button> )
  }

  renderTag = () => {
    const {value: tag} = this.props;
    const prefixer = ['+', '-', '@', '#'];
    const prefixIndex = prefixer.indexOf(tag[0]);
    if (prefixIndex > -1) { return(this.renderPrefixedTag()) }

    const wrapper = ['"']
    const prefixWrapper = wrapper.indexOf(tag[0]);
    if (prefixWrapper > -1 && tag[0] === tag[tag.length - 1]) { return (this.renderWrappedTag()) }

    const { highlightWords } = this.props;
    const searchHighlightWord = highlightWords.find(word => tag.indexOf(`${word}:`) === 0);
    if (searchHighlightWord) {
      return this.renderHighlightedWordTag(searchHighlightWord)
    }

    return (
      <button
        className='search-bar-tag-inner'
        onClick ={ this.selectTag }
        onBlur={ this.onBlurSelectedTag }
        onDoubleClick={this.activateEditionMode}
      >
        { tag }
      </button>);
  }

  quitEditMode = () => {
    this.setState({ isEdited: false})
    if (this.props.onQuitEditMode) this.props.onQuitEditMode();
  }

  updateTag = () => {
    const { updateTag, onlyHashtagOrAt, value, deleteTag, onChangeSelectedTag } = this.props;
    const { tagValue } = this.state;

    if (onlyHashtagOrAt && tagValue[0] !== '@' && tagValue[0] !== '#') return null;

    if (tagValue?.trim() === '') {
      deleteTag()
    }
    if (tagValue !== value) {
      updateTag(tagValue)
    }
    this.quitEditMode();
    onChangeSelectedTag({ value: false});
  }

  onKeyDownWhileEditTag = (event) => {
    const { key } = event;
    if (key === 'Escape') this.quitEditMode();
    if (key === 'Tab') {
      event.preventDefault();
      this.updateTag();
    }
  }

  onBlurEditTag = () => {
    this.updateTag()
  }

  render() {
    const { disabled, deleteTag, isSelected } = this.props;
    const { isEdited, tagValue, widthEditionMode } = this.state;

    return(
      <div className='seach-bar-tag-container'>
        {!isEdited &&
          <div
            onKeyDown={this.onKeyDownSelectedTag}
            ref={this.tagRef}
            role="listitem"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex="0"
            className={cn('search-bar-tag', {
              'search-bar-tag--clicked': isSelected,
              'search-bar-tag--disabled': disabled,
            })}
          >
            {this.renderTag()}
            {!disabled && (
              <Icon
                label="times"
                onClick={deleteTag}
                size="tiny"
                theme="solid"
                fill="#fff"
                isButton
              />
            )}
          </div>
        }
        {isEdited &&
          <div className="search-bar-tag--edition" style={{ width: `${widthEditionMode}px` }}>
            <Input
              autoFocus
              placeholder=''
              value={tagValue}
              onChange={this.editTag}
              onEnterPress={this.updateTag}
              onBlur={this.onBlurEditTag}
              onKeyDown={this.onKeyDownWhileEditTag}
              size="small"
              fullWidth
            />
          </div>
        }
      </div>
    )
  }
}

export default SearchBarTag;
