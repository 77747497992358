import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';

import { Field, SelectTag } from 'components';
import { useFilter } from 'components/engine/hooks';
import { KOL_THEME_KEYS } from 'components/engine/constants';

import styles from '../engineModule.module.scss';


const Theme = ({ network, onChange, disabled, max }) => {
  const filterTarget = `${network}Themes`;
  const intl = useIntl();
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(
    ({ item, selected }) => {
      return onChange && onChange({
        field: filterTarget,
        value: selected,
        selected: item,
        network,
      })
    },
    [onChange, filterTarget, network]
  );

  const getData = useMemo(
    () => KOL_THEME_KEYS.map( (theme) => ({
      id: theme,
      label: intl.formatMessage({ id: `kolTheme.${theme}`}),
      value: theme,
    })),
    [intl]
  );

  return (
    <Field label={intl.formatMessage({ id: 'interests.label'})}>
      <SelectTag
        className={styles.select}
        selected={value}
        dataset={getData}
        hasSearch
        maxTags={max}
        defaultOptionLabel={intl.formatMessage(
          { id: 'interests.filterBy'},
          { max },
        )}
        onChange={handleChange}
        disabled={disabled}
      />
    </Field>
  )
};

Theme.displayName = 'Theme';

Theme.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  max: PropTypes.isRequired,
};

Theme.defaultProps = {
  disabled: false,
};

export default Theme;
