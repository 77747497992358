import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { format } from 'date-fns';

import { DELIVERY_NETWORKS } from 'constants/networks';
import {
  ContentProposalVersion,
  ContentRequest,
  updateContentRequestStatus,
} from 'slices/contentValidation.slice';
import useModal from 'utils/hooks/useModal';
import { downloadLinks } from 'utils/downloadLinks';
import { useDispatch } from 'utils/redux';
import { useDateFnsLocale } from 'utils/date-fns';

import { Divider, Icon, HeightTransition, Tooltip, Loader } from 'kolkit';
import Typography from 'components/ui/Typography';
import ContentValidationStateDropdown from 'components/campaigns/Composants/ContentValidationStateDropdown';
import CampaignContentPublicationDate from 'components/campaigns/Composants/CampaignContentPublicationDate';

import { MODAL_ID as DisplayContentModalId } from '../DisplayContentVersionModal';

import styles from './ContentToBeApproved.module.scss';

type Props = {
  campaignId?: number;
  profileId?: number;
  contentRequests: ContentRequest[];
  contentProposals: ContentProposalVersion[];
};

const ContentToBeApproved: React.FC<Props> = ({
  campaignId,
  profileId,
  contentRequests,
  contentProposals,
}) => {
  const intl = useIntl();
  const dateFnsLocale = useDateFnsLocale();
  const dispatch = useDispatch();
  const parentRef = useRef<HTMLDivElement>(null);
  const { on: showDisplayContentModal } = useModal(DisplayContentModalId);
  const [showPreviousVersions, setShowPreviousVersions] = useState<
    Record<string, boolean>
  >({});

  const sortedContentRequests = useMemo(() => {
    return DELIVERY_NETWORKS.reduce((prev, { network, mediaType }) => {
      const associatedContentRequests = contentRequests.filter(
        (request) =>
          request.network === network && request.content_type === mediaType,
      );
      if (associatedContentRequests.length === 0) {
        return prev;
      }
      return [
        ...prev,
        {
          network,
          mediaType,
          contentRequests: associatedContentRequests,
        },
      ];
    }, [] as Array<{ network: string; mediaType: string; contentRequests: ContentRequest[] }>);
  }, [contentRequests]);

  const { proposals: mappedContentProposals } = useMemo<{
    proposals: Record<string, Array<ContentProposalVersion>>;
  }>(() => {
    const proposals = contentProposals.reduce(
      (prev, current) => ({
        ...prev,
        [current.content_request_id]: [
          ...(prev[current.content_request_id] || []),
          current,
        ],
      }),
      {},
    );

    Object.keys(proposals).forEach((key) => {
      proposals[key] = proposals[key].sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    });

    return { proposals };
  }, [contentProposals]);

  const handleChangeState = useCallback(
    ({ requestId, oldStatus, newStatus }) => {
      if (campaignId && profileId) {
        void dispatch(
          updateContentRequestStatus({
            project_id: campaignId,
            profile_id: profileId,
            requestId,
            oldStatus,
            newStatus,
          }),
        );
      }
    },
    [campaignId, profileId, dispatch],
  );

  const handleDisplayContentModal = useCallback(
    ({
      contentProposal,
      contentRequest,
    }: {
      contentRequest: ContentRequest;
      contentProposal: ContentProposalVersion;
    }) => {
      showDisplayContentModal({ contentRequest, contentProposal });
    },
    [showDisplayContentModal],
  );

  const handleClickDownload = useCallback(
    (attachments: ContentProposalVersion['attachments']) => {
      downloadLinks(
        attachments?.map((attachement) => ({
          name: attachement.filename,
          path: attachement.download_url,
        })) || [],
        parentRef.current,
      );
    },
    [],
  );

  const toggleDisplayPreviousVersions = useCallback(
    (networkMediaType: string) => {
      setShowPreviousVersions((prev) => ({
        ...prev,
        [networkMediaType]: !prev[networkMediaType],
      }));
    },
    [],
  );

  const renderContentProposal: (
    contentRequest: ContentRequest,
    contentProposal: ContentProposalVersion,
  ) => React.ReactNode = useCallback(
    (contentRequest, contentProposal) => {
      return (
        <div
          key={`contentProposal-${contentRequest.id}-${contentProposal?.id}`}
          className={cn(
            'flex gap16',
            contentProposal && contentProposal.upload_status !== 'processing'
              ? styles.clickable
              : null,
          )}
          role="button"
          onClick={() =>
            contentProposal && contentProposal.upload_status !== 'processing'
              ? handleDisplayContentModal({
                  contentRequest,
                  contentProposal,
                })
              : undefined
          }
        >
          <div className={styles.thumbnail}>
            {contentProposal &&
            contentProposal.upload_status === 'processing' ? (
              <Tooltip
                content={intl.formatMessage({
                  id: 'profileDetails.panel.content.contentApproval.videoProcessing',
                })}
              >
                <div className={styles.processingTooltip}>
                  <Loader
                    size="small"
                    background="transparent"
                  />
                </div>
              </Tooltip>
            ) : contentProposal && contentProposal.attachments?.[0] ? (
              <img
                src={
                  contentProposal.attachments[0].thumbnail_url ||
                  contentProposal.attachments?.[0]?.url ||
                  ''
                }
                alt="Post thumbnail"
              />
            ) : null}
          </div>
          <div className="flex fdc fg1">
            <Typography
              label={contentRequest.content_title}
              variant="text/body medium bold"
            />
            <div className="flex aic gap8">
              <Typography
                label={intl.formatMessage(
                  {
                    id: 'profileDetails.panel.content.contentApproval.uploadedVersion',
                  },
                  { version: contentProposal?.version_number || 0 },
                )}
                variant="text/body medium"
              />
              {contentProposal?.version_number ? (
                <Typography
                  label={intl.formatMessage(
                    {
                      id: 'profileDetails.panel.content.contentApproval.submittedOn',
                    },
                    {
                      date: format(new Date(contentProposal.created_at), 'P', {
                        locale: dateFnsLocale,
                      }),
                    },
                  )}
                  variant="text/body medium"
                />
              ) : null}
            </div>
            <div className="flex aic gap8">
              {contentRequest.expected_publication_date ? (
                <>
                  <Typography
                    label={intl.formatMessage({
                      id: 'profileDetails.panel.content.contentApproval.deadline',
                    })}
                    variant="text/body medium"
                  />
                  :
                  <CampaignContentPublicationDate
                    date={contentRequest.expected_publication_date}
                    typographyVariant="text/body medium"
                  />
                </>
              ) : (
                <Typography
                  label={intl.formatMessage({
                    id: 'profileDetails.panel.content.contentApproval.deadlineNotSet',
                  })}
                  variant="text/body medium"
                />
              )}
            </div>
          </div>
          <ContentValidationStateDropdown
            size="small"
            className="flex aifs"
            selected={contentRequest?.content_status}
            onChange={(newStatus) =>
              handleChangeState({
                requestId: contentRequest?.id,
                oldStatus: contentRequest?.content_status,
                newStatus,
              })
            }
          />
          <Icon
            isButton
            label="eye"
            theme="solid"
            disabled={!contentProposal}
          />
          <Icon
            isButton
            label="download"
            theme="solid"
            onClick={(e) => {
              e.stopPropagation();
              handleClickDownload(contentProposal.attachments);
            }}
            disabled={!contentProposal}
          />
        </div>
      );
    },
    [
      dateFnsLocale,
      handleChangeState,
      handleClickDownload,
      handleDisplayContentModal,
      intl,
    ],
  );

  return (
    <div className="flex fdc">
      {sortedContentRequests.map(
        ({ network, mediaType, contentRequests }, index, array) => {
          let iconLabel = `${network}-color`;
          switch (mediaType) {
            case 'reel':
              iconLabel = 'instagram-video-color';
              break;
            case 'story':
              iconLabel = 'instagram-story-color';
              break;
            case 'saved_story':
              iconLabel = 'snapchat-story';
              break;
            case 'spotlight':
              iconLabel = 'snapchat-spotlight';
              break;
            default:
              break;
          }

          const hasPreviousVersions = contentRequests.find(
            (contentRequest) =>
              mappedContentProposals?.[contentRequest.id]?.length > 1,
          );
          return [
            <div
              key={`${network}-${mediaType}`}
              className="flex fdc gap8"
              ref={parentRef}
            >
              <div className="flex aic gap8">
                <Icon label={iconLabel} theme="brand" />
                <Typography
                  label={intl.formatMessage({
                    id: `global.content.${mediaType}`,
                  })}
                  variant="label/large"
                />
              </div>
              {contentRequests.map((contentRequest) => {
                const contentProposal =
                  mappedContentProposals?.[contentRequest.id]?.[0];

                return renderContentProposal(contentRequest, contentProposal);
              })}
              {hasPreviousVersions ? (
                <div className="flex fdc gap8">
                  <div
                    className="flex aic gap16 jcsb cursor-pointer"
                    role="button"
                    onClick={() =>
                      toggleDisplayPreviousVersions(`${network}-${mediaType}`)
                    }
                  >
                    <Typography
                      variant="text/body medium"
                      label={intl.formatMessage({
                        id: 'profileDetails.panel.content.contentApproval.seePreviousVersions',
                      })}
                    />
                    <Icon
                      size="small"
                      theme="solid"
                      label={
                        showPreviousVersions[`${network}-${mediaType}`]
                          ? 'caret-up'
                          : 'caret-down'
                      }
                    />
                  </div>
                  <HeightTransition
                    on={showPreviousVersions[`${network}-${mediaType}`]}
                  >
                    <div className="flex fdc gap8">
                      {contentRequests.map((contentRequest) => {
                        if (
                          mappedContentProposals?.[contentRequest.id]?.length >
                          1
                        ) {
                          return mappedContentProposals?.[contentRequest.id]
                            .slice(1)
                            .map((contentProposal) => {
                              return renderContentProposal(
                                contentRequest,
                                contentProposal,
                              );
                            });
                        }
                        return null;
                      })}
                    </div>
                  </HeightTransition>
                </div>
              ) : null}
            </div>,
            index !== array.length - 1 ? <Divider size="small" /> : null,
          ];
        },
      )}
    </div>
  );
};

ContentToBeApproved.displayName = 'ContentToBeApproved';

export default memo(ContentToBeApproved);
