import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl-phraseapp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Item, Select2 } from 'kolkit';

import buildCountryOptionLabel from 'utils/buildCountryOptionLabel';

import MultiValueSelectionItem from 'components/ui/Select/components/MultiValueSelectionItem';
import  InputLocation from 'components/ui/InputLocation';
import SelectTag from 'components/ui/Select/SelectTag';

import styles from './SelectPercent.module.scss'

const MAX_TAGS = 3;

class SelectPercent extends PureComponent {
	createPercentArray = () => {
		const STEP = 5;
		return Array(
			Math.floor(100 / STEP) + 1 )
			.fill('')
			.map((v, i) => Math.max(i * STEP, 1).toString())
	};

	onChangeSelect = ({ item }) => {
		const { onChange, selected, id, intl, max, defaultPercent } = this.props;

		if (
			(max > -1 && selected.length === max) ||
			(!max && selected.length === MAX_TAGS ) ||
			(selected.find(s => s.id === item.id))
		) return null;

		const newItem = {
			...item,
			country: intl.formatDisplayName(item.countryCode, {
				type: 'region',
			}),
			percent: defaultPercent || '10',
		};
		const newSelected = [...selected, newItem];

		onChange({
			item,
			selected: newSelected,
			id,
			type:'select-percent'
		})
	};

	onRemoveMap = itemId => {
		const { onChange, selected, id } = this.props;
		const newSelected = selected.filter(s => s.id !== itemId);
		const item = selected.find(s => s.id === itemId)

		if (!item) return null;

		onChange({
			item,
			selected: newSelected,
			id,
			type:'select-percent'
		})
	};

	getPercentlabel = percent => percent === '100'
		? `${percent}%`
		: `> ${percent}%`

	setDataset = percents => percents.map(data => ({
			value: data,
			label: this.getPercentlabel(data)
		})
	);


	onChangePercent = item => target => {
		const { onChange, selected, id  } = this.props;
		const idx = selected.findIndex(s => s.id === item.id);

		if (idx === -1) return null;
		if (target.value === item.percent) return null;

		const newItem = { ...item, percent: target.value };
		const newSelected = selected.map((value, index) => index === idx ? newItem : value);

		onChange({
			item: newItem,
			selected: newSelected,
			id,
			type: 'select'
		})
	};

	locations = dataset => dataset.reduce((acc, v) => ({
		...acc,
		[v.value]: v.label
	}), {});

	render() {
		const {
			className,
			selected,
			label,
			dataset,
			defaultOptionLabel,
			errorPlaceholder,
			isLocation,
			pinned,
			max,
			disabled,
			noMatchPlaceholder,
			search,
		} = this.props;
		const influencePercent = this.createPercentArray();

		const useMax = max || MAX_TAGS;

		const cnSelectPercent = cn(styles.selectPercent, className);

		return (
			<div className={cnSelectPercent}>
				{isLocation ? (
					<InputLocation
						onChange={this.onChangeSelect}
						placeholder={defaultOptionLabel}
						selected={selected}
						minChars={3}
						notFoundText={errorPlaceholder}
						disabled={disabled || selected.length >= useMax}
					/>
				) : (
					<SelectTag
						className={styles.select}
						label={label}
						dataset={dataset}
						onChange={this.onChangeSelect}
						defaultOptionLabel={defaultOptionLabel || label}
						maxTags={useMax}
						disabled={(selected.length === useMax || disabled)}
						closeOnChange
						pinned={pinned}
						labelAsPlaceholder
						noMatchPlaceholder={noMatchPlaceholder}
						hasSearch={search}
					/>
				)}
				{selected && selected.length > 0 && (
					<div className={styles.selectTagContainer}>
						{React.Children.toArray(selected.map(s => (
							<div className={styles.selectionContainer} key={`select-percent-${s.id}`}>
								<MultiValueSelectionItem
									value={s.id}
									onRemove={this.onRemoveMap}
									isFull={false}
								>
									{isLocation
										? buildCountryOptionLabel(s.city, s.country)
										: s.label
									}
								</MultiValueSelectionItem>
								<div className={styles.selectionPercent}>
									<Select2
										placeholder={this.getPercentlabel(s?.percent)}
										className={styles.selectChild}
										options={this.setDataset(influencePercent)}
										selected={s.percent}
										onSelectItem={this.onChangePercent(s)}
										size="small"
										position="bottom-right"
										renderItem={item => (
											<Item padding="4px 16px">
												{item.label}
											</Item>
										)}
										fullWidth
										width={110}
									/>
								</div>
							</div>
						)))}
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = ({ env }) => ({
	locale: env.locale,
	userToken: env.userToken
});

SelectPercent.defaultProps = {
	id: '',
	label: '',
	className: '',
	noMatchPlaceholder: null,
	selected: [],
	search: true,
};

SelectPercent.propTypes = {
	id: PropTypes.string,
	selected: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		label: PropTypes.string,
	})),
	onChange: PropTypes.func.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
	noMatchPlaceholder: PropTypes.string,
	search: PropTypes.bool
};

export default injectIntl(connect(mapStateToProps)(SelectPercent))
