import React, { useCallback, useMemo, useRef, Children } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { SNAPCHAT } from 'constants/networks';
import { Tag } from 'components';
import { useDispatch, useSelector } from 'utils/redux';
import { networks, configFilters } from 'config/filters';
import { updateFilter } from 'actions/user';
import useActivitiesIntl from 'utils/hooks/useActivitiesIntl';
import useNumberFormat from 'utils/hooks/useNumberFormat';
import buildCountryOptionLabel from 'utils/buildCountryOptionLabel';

import styles from './TagContainer.module.scss';

const networksWithoutSnapchat = networks.filter(network => network !== SNAPCHAT);

const Tags = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const formatNumber = useNumberFormat();
  const intl = useIntl();
  const activatiesIntl = useActivitiesIntl();

  const { filter, tagName } = useSelector(({ user, engine }) => ({
    filter: engine.filters,
    tagName: user?.tags,
  }));

  const getTagName = useCallback(
    item => tagName?.find(({ id }) => id === item),
    [tagName]
  );

  const handleResetItem = useCallback(
    (filterTarget, network, value) =>
      dispatch(updateFilter({
        field: filterTarget,
        value: filter[filterTarget].filter(initialValue => initialValue !== value),
        forceTriggerSearch: true,
        network,
      })),
    [dispatch, filter],
  );

  const handleResetNetworkItem = useCallback(
    (filterTarget, network, value) => dispatch(updateFilter({
      field: filterTarget,
      value: [...filter.socialNetworks?.[network]?.[filterTarget]?.filter(initialValue => initialValue.id
        ? initialValue.id !== value
        : initialValue !== value
      )],
      forceTriggerSearch: true,
      network,
    })),
    [filter.socialNetworks, dispatch]
  );

  const handleReset = useCallback(
    (filterTarget, network) => dispatch(updateFilter({
      field: filterTarget,
      value: configFilters[filterTarget].default,
      forceTriggerSearch: true,
      network: !network ? 'influencer' : network,
    })),
    [dispatch]
  );

  const handleActivateFilter = useCallback(
    network => dispatch(updateFilter({
      field: 'activeFilter',
      value: [...filter.activeFilter, network],
      network,
      forceTriggerSearch: true,
    })),
    [dispatch, filter.activeFilter]
  );

  const getFilterData = useMemo(
    () => Object.entries(filter)
      .filter(([key, value]) => value
        && (value.length > 0 || value !== false)
        && key !== 'socialNetworks'
        && key !== 'activeFilter'
        && key !== 'state'
        && key !== 'sid'
        && key !== 'period')
      .map(([key, value]) => {
        switch (key) {
          case 'credibility':
            return <Tag label={`${intl.formatMessage({id: 'credibility.tag'})} ${value}`} icon="user" onClick={() => handleReset(key)} />;

          case 'influencerThemes':
            return value.map(subValue => (
              <Tag
                label={intl.formatMessage({ id: `kolTheme.${subValue}`})}
                icon="user"
                onClick={() => handleResetItem(key, '', subValue)}
              />
            ));
          case 'ages':
          case 'genders':
            return value.map(subValue => (
              <Tag
                label={intl.formatMessage({id: `engine.filters.${subValue}`})}
                icon="user"
                onClick={() => handleResetItem(key, '', subValue)}
              />
            ));
          case 'languages':
            return value.map(subValue => (
              <Tag
                label={intl.formatDisplayName(subValue, {type: 'language'})}
                icon="user"
                onClick={() => handleResetItem(key, '', subValue)}
              />
            ));
          case 'activities':
            return value.map(subValue => (
              <Tag
                label={activatiesIntl.formatMessage({ id: subValue, gender: "male" })}
                icon="user"
                onClick={() => handleResetItem(key, '', subValue)}
              />
            ));

          case 'localisations':
            return Object.entries(value).map(([, subValue]) => (
              <Tag
                label={`${subValue?.g_city ? subValue?.g_city : ''} ${subValue.g_country && subValue.g_country}`}
                icon="user"
                onClick={() => handleResetItem(key, 'influencer', subValue)} />
            ));

          case 'enlisted':
            return <Tag label={intl.formatMessage({id: 'influenceForGood.tag'})} icon="user" onClick={() => handleReset(key)}/>;
          case 'seedingKol':
            return <Tag label={intl.formatMessage({id: 'seedingProfiles.tag'})} icon="user" onClick={() => handleReset(key)}/>;

          case 'excludeFavorite':
            return <Tag label={intl.formatMessage({id: 'excludeFavorites.tag'})} icon="eye-slash" onClick={() => handleReset(key)} theme="reverse" />;

          case 'arpp_certified':
            return <Tag label={intl.formatMessage({ id: 'arpp.label' })} icon="user" onClick={() => handleReset(key)} />;

          case 'excludeTag':
            return value?.length < 11 ? (
              value.map(subValue =>
                <Tag
                  label={getTagName(subValue)?.value}
                  icon="eye-slash"
                  onClick={() => handleResetItem(key, '', subValue)}
                  theme="reverse"
                  />
              )
            ) : (
              <Tag
                label={intl.formatMessage(
                  {id: 'tags.exclude.many'},
                  {count: value?.length}
                )}
                icon="eye-slash"
                onClick={() => handleReset(key)}
                theme="reverse"
              />
            );

          case 'status':
            return <Tag label={intl.formatMessage({id: 'qualifiedProfiles.label'})} icon="user" onClick={() => handleReset(key)}/>;

          default: return null;
        }
      }),
    [filter, handleReset, handleResetItem, getTagName, intl, activatiesIntl],
  );

  const getNetworkFilterData = useCallback(
    network => {
      return filter?.socialNetworks && Object.entries(filter.socialNetworks[network])
        .filter(([, value]) => (value || value?.length > 0) && value !== 0 && value !== 'bad')
        .map(([key, value]) => {
          switch (key) {
            case 'accountCountry':
              return value && Children.toArray(value.map(subValue => (
                <Tag
                  label={intl.formatDisplayName(subValue, {type: 'region'})}
                  icon="youtube"
                  iconTheme="brand"
                  onClick={() => handleResetNetworkItem(key, network, subValue)}
                />
              )));
            case 'communityCountries':
              return value && Children.toArray(value.map(country => {
                const label = network !== 'youtube'
                  ? buildCountryOptionLabel(country.city, intl.formatDisplayName(country.countryCode, {type: 'region'}))
                  : country.label
                return (
                  <Tag
                    label={label}
                    icon={network}
                    onClick={() => handleResetNetworkItem(key, network, country.id)}
                    iconTheme="brand"
                  />
                )
              }));

            case 'communityAges':
            case 'communityGender':
              return value && Children.toArray(value.map(subValue => (
                <Tag
                  label={`${intl.formatMessage({id: `engine.filters.${subValue.id}`})} > ${subValue.percent}%`}
                  icon={network}
                  iconTheme="brand"
                  onClick={() => handleResetNetworkItem(key, network, subValue.id)}
                />
              )));

            case 'communityLanguages':
              return value && Children.toArray(value.map(subValue => {
                return (
                  <Tag
                    label={`${intl.formatDisplayName(subValue.label, {type: 'language'})} ${subValue.percent}%`}
                    icon={network}
                    iconTheme="brand"
                    onClick={() => handleResetNetworkItem(key, network, subValue.id)}
                  />
                )
              }));
            case 'communityInterests':
              return value && Children.toArray(value.map(subValue => {
                return (
                  <Tag
                    label={`${intl.formatMessage({id: `interests.list.${subValue.id}`})} ${subValue.percent}%`}
                    icon={network}
                    iconTheme="brand"
                    onClick={() => handleResetNetworkItem(key, network, subValue.id)}
                  />
                )
              }));
            default: return null;
          }
        });
    },
    [filter.socialNetworks, handleResetNetworkItem, intl]
  );

  const getNetworkFilterCommunityData = useCallback(
    network => {
      const phraseLabels = {
        communitySize: 'communitySize',
        communityEngagementRate: 'engagementRate',
        reelsEstimatedReach: 'estimatedReach',
        reelsPlays: 'volumePlays',
        reelsLikes: 'volumeLikes',
        viewsAverage: 'viewsAverage',
      };

      return (
        filter?.socialNetworks &&
        Children.toArray(Object.entries(filter.socialNetworks[network])
          .filter(([key, value]) => {
            const { min, max } = value;
            return (
              value &&
              typeof configFilters[key].default === 'object' &&
              'min' in configFilters[key]?.default &&
              'max' in configFilters[key]?.default &&
              (min !== '' || max !== '')
            );
          })
          .map(([key, value]) => {
            return (
              <Tag
                label={`${!value.min ? '∞' : formatNumber(value.min, { compact: false })}${value.min && key === 'communityEngagementRate' ? '%' : ''} - ${!value.max ? '∞' : formatNumber(value.max, { compact: false })}${value.max && key === 'communityEngagementRate' ? '%' : ''}`}
                icon={network}
                iconTheme="brand"
                tooltip={intl.formatMessage({id: phraseLabels[key]})}
                onClick={() => handleReset(key, network)}
              />
            )
          })
      ));
    },
    [filter.socialNetworks, handleReset, intl, formatNumber]
  );

  const getInactiveNetworkFilter = useMemo(
    () => networksWithoutSnapchat
      .filter(network => filter?.activeFilter?.indexOf(network) === -1)
      .map(network => {
        return (
          <Tag
            key={`tag-inactive-${network}`}
            theme="grey"
            label={intl.formatMessage({id: 'global.disable'})}
            icon={network}
            iconTheme="brand"
            onClick={() => handleActivateFilter(network)}
          />
        )
      }),
    [filter.activeFilter, handleActivateFilter, intl]
  );

  return (
    <div className={styles.tagWrapper} ref={ref}>
      {getFilterData}
      {networksWithoutSnapchat.map(network => getNetworkFilterData(network))}
      {networksWithoutSnapchat.map(network => getNetworkFilterCommunityData(network))}
      {getInactiveNetworkFilter}
    </div>
  );
};

Tags.displayName = 'Tags';

export default Tags;
