import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import * as api from 'api/consent';
import * as consentMapper from 'mappers/consent';

import { batch as profileBatchAction } from './profiles';


export const getStatsSharingsStatus = (profileIds, campaignId) => async (dispatch) => {
  /**
   * @param {number} profileIds - required
   * @param {number} campaignId - required
   */
  const getProfilesIds = Array.isArray(profileIds) ? profileIds : [profileIds];
  if (!getProfilesIds?.length) return null;

  const response = await api.getStatsSharingsStatus(getProfilesIds, campaignId);
  if(response && !response.error) {
    const statsSharingsStatus = consentMapper.statsSharingsStatus.fromApi(response);
    dispatch(profileBatchAction(statsSharingsStatus));
  }
}

export const sendStatsRequest = (profiles, projectId, message = '') => async (dispatch) => {
  if (!profiles?.length) return null;
  const profileIds = profiles.map(profile => Number(profile.id));
  const response = await api.sendStatsRequest(profileIds, projectId, message);
  const statsSharingsStatus = consentMapper.sendStatsRequest.fromApi(response);

  dispatch(profileBatchAction(statsSharingsStatus));

  const intl = getIntl();
  toast(
    intl.formatMessage(
      { id: "askStats.toast.success" },
      {
        kolName: profiles?.[0]?.name,
        count: profiles?.length
      }
    ),
    { type: 'success' }
  );
}
