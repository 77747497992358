import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'kolkit/Icon';
import styles from './InputCheck.module.scss';

const InputCheck = ({
  checked,
  className,
  disabled,
  id,
  itemId,
  label,
  name,
  onChange,
  required,
  type,
  value,
  ...rest
}) => {
  const classNames = cn(styles.inputCheck, className, {
    [styles.noLabel]: !label,
    [styles.required]: required
  });

  return (
    <div className={classNames}>
      <input
        {...rest}
        aria-describedby={label}
        checked={checked}
        data-id={itemId}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        type={type}
        value={value}
      />
      <label htmlFor={id}>
        {checked && type === 'checkbox' && (
          <Icon
            label="check"
            theme="solid"
            className={styles.icon}
          />
        )}
      </label>
      {label &&
        <span className={styles.label}>
          {label}
        </span>
      }
    </div>
  );
};

InputCheck.displayName = 'InputCheck';

InputCheck.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  itemId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.string,
};

InputCheck.defaultProps = {
  type: 'checkbox',
  checked: false,
  className: undefined,
  disabled: false,
  itemId: '',
  label: '',
  name: undefined,
  value: undefined,
  required: false,
  onChange: () => {},
};

export default InputCheck;
