export const COLUMNS = [
  { name: 'name', label: 'settings.organization.table.header.name' },
  {
    name: 'details',
    sortKey: 'days',
    label: 'settings.organization.table.header.details',
  },
  {
    name: 'time_period',
    sortKey: 'end_date',
    label: 'settings.organization.table.header.timePeriod',
    minWidth: 200,
  },
  {
    name: 'availability',
    sortKey: 'free',
    label: 'settings.organization.table.header.availability',
  },
];

export const GRID_TEMPLATE_COLUMNS = ['1.5fr', '1fr', '1.5fr', '1fr'].reduce(
  (template, span) => template.concat(` minmax(0,${span})`),
  ``,
);
