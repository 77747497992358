import React, { useMemo, forwardRef, useCallback } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { toast as toastify } from 'react-toastify';

// Import React FilePond
import { FilePond, registerPlugin, FilePondProps } from 'react-filepond';

// Import the Image EXIF Orientation and Image Preview plugins and File type validation
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import { useSelector } from 'utils/redux';
import { conf } from 'config/env';
import toast from 'utils/toast';

import Markdown from 'components/ui/Markdown';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import styles from './UploadMultiplePartDropZone.module.scss';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

const ACCEPTED_FILE_TYPES = [
  'image/svg+xml',
  'image/png',
  'image/jpeg',
  'application/pdf',
  'video/*',
];

const MAX_FILE_SIZE = '10000MB'; // Can't take GB

type Props = {
  processURL: string;
  label?: string;
  stylePanelLayout?: FilePondProps['stylePanelLayout'];
  asButton?: boolean;
  instantUpload?: boolean;
  chunkSize?: number;
  maxFiles?: number;
  allowMultiple?: boolean;
  disabled?: boolean;
  appendData?: Record<string, string>;
  onUpdateFiles?: FilePondProps['onupdatefiles'];
  files?: FilePondProps['files'];
  onProcessFileProgress?: FilePondProps['onprocessfileprogress'];

  // TODO: remove this later
  forceToken?: string;
  forceUrl?: string;
  acceptedFileTypes?: string[];
};

const UploadMultiPartDropZone = (
  {
    processURL,
    label = 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
    stylePanelLayout = null,
    asButton = false,
    chunkSize = 5000000,
    maxFiles = 50,
    allowMultiple = false,
    appendData,
    forceToken,
    forceUrl,
    instantUpload = false,
    disabled = false,
    acceptedFileTypes = ACCEPTED_FILE_TYPES,
    onUpdateFiles,
    files = [],
    onProcessFileProgress,
  }: Props,
  ref,
) => {
  const intl = useIntl();
  const userToken = useSelector(({ env: { userToken } }) => {
    return forceToken || userToken;
  });

  const serverProps: FilePondProps['server'] = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${userToken}`,
        'X-Kolsquare-Profile-Id': appendData?.public_profile_id || '',
      },
      withCredentials: true,
      url: `${forceUrl || conf.api}${processURL}`,
      process: {
        url: '/',
        ondata: (formData) => {
          if (appendData) {
            Object.entries(appendData).forEach(([key, value]) => {
              formData.append(key, value);
            });
          }
          return formData;
        },

        onload: (response) => {
          // This is usefull to get the serverId for each file, before uploading chunks
          return response.responseText;
        },
      },
      patch: {
        url: '/',
      },
      fetch: null,
      revert: null,
    };
  }, [appendData, forceUrl, processURL, userToken]);

  const handleProcessFileProgress = useCallback<
    NonNullable<FilePondProps['onprocessfileprogress']>
  >(
    (file, progress) => {
      if (onProcessFileProgress) {
        onProcessFileProgress(file, progress);
      } else {
        if (file.fileType.includes('image')) {
          // Do nothing, no toast
          return null;
        }
        const toastId = file.id;
        if (toastify.isActive(toastId)) {
          toastify.update(
            toastId,
            progress === 1
              ? {
                  progress: 0.9999,
                  isLoading: true,
                  autoClose: false,
                  render: (
                    <div>
                      <Markdown className="Toastify-message">
                        {intl.formatMessage(
                          { id: 'global.toast.uploadContent.processing' },
                          { filename: file.filename },
                        )}
                      </Markdown>
                    </div>
                  ),
                }
              : { progress },
          );
        } else {
          toast(
            intl.formatMessage(
              { id: 'global.toast.uploadContent.inProgress' },
              { filename: file.filename },
            ),
            {
              type: 'info',
              progress: 0,
              hideProgressBar: false,
              autoClose: false,
              toastId,
            },
          );
        }
      }
    },
    [intl, onProcessFileProgress],
  );

  const cnFilepond = cn(styles.filepond, { [styles.asButton]: asButton });

  return (
    <FilePond
      ref={ref}
      name="content" /* sets the file input name, it's filepond by default */
      chunkForce
      chunkUploads
      server={serverProps}
      // Behavior controls
      disabled={disabled}
      maxFiles={maxFiles}
      chunkSize={chunkSize}
      itemInsertLocation="after"
      maxFileSize={MAX_FILE_SIZE}
      instantUpload={instantUpload}
      allowMultiple={allowMultiple}
      acceptedFileTypes={acceptedFileTypes}
      // Styling
      className={cnFilepond}
      stylePanelLayout={stylePanelLayout}
      // Events
      onupdatefiles={onUpdateFiles}
      // Text labels
      labelIdle={label}
      labelMaxFileSizeExceeded={intl.formatMessage({
        id: 'content.upload.maxFileSizeExceeded',
        defaultMessage: 'File is too heavy',
      })}
      labelMaxFileSize={intl.formatMessage({
        id: 'content.upload.maxFileSize',
        defaultMessage: "Maximum file size is '{filesize}",
      })}
      labelFileTypeNotAllowed={intl.formatMessage({
        id: 'content.upload.fileTypeNotAllowed',
        defaultMessage: 'File of invalid type',
      })}
      fileValidateTypeLabelExpectedTypes={intl.formatMessage({
        id: 'content.upload.expectedTypes',
        defaultMessage: "Expects '{allButLastType} or '{lastType}",
      })}
      files={files}
      onprocessfile={(e, file) => {
        // Update the toast id so that we can update it later with serverId from websocket
        toastify.update(file.id, {
          toastId: file.serverId,
        });
      }}
      onprocessfileprogress={handleProcessFileProgress}
    />
  );
};

UploadMultiPartDropZone.displayName = 'UploadMultiPartDropZone';

export default forwardRef(UploadMultiPartDropZone);
