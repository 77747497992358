import React, { useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { Button3, Icon, Select2 } from 'kolkit';

import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'utils/redux';

import { Team, fetchTeams } from 'slices/organization.slice';

type Props = {
  team?: Team;
  onChange: (team: Team) => void;
  disabled?: boolean;
};

const MODAL_ID = 'addOrganizationTeamModal';
const ADD_TEAM_ITEM = 'add-team-button';

const ChooseTeam: React.FC<Props> = ({ team, onChange, disabled }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { on: openCreateTeamModal } = useModal(MODAL_ID);

  const teams = useSelector(({ organization: { teams } }) => teams);

  useEffect(() => {
    void dispatch(fetchTeams());
  }, [dispatch]);

  const options = useMemo(
    () => [
      ...teams
        .filter((team) => !team.is_ghost_team)
        .map((team) => ({
          id: team.id,
          value: team.id.toString(),
          label: team.name,
        })),
      { id: ADD_TEAM_ITEM, label: '', value: '' },
    ],
    [teams],
  );

  const handleSelectTeam: (props: {
    label: string;
    id?: number | string;
  }) => void = useCallback(
    ({ label, id }) => {
      if (!id) return null;
      if (id === ADD_TEAM_ITEM) {
        openCreateTeamModal({ onSuccess: onChange });
      } else onChange({ id: id as number, name: label });
    },
    [onChange, openCreateTeamModal],
  );

  const renderItem = useCallback((item) => {
    return item.id === ADD_TEAM_ITEM ? (
      <li className="flex aic text-underline" key={item.value}>
        <Icon label="circle-plus" theme="regular" className="mr10 " />
        Create a team
      </li>
    ) : (
      <li key={item.value}>{item.label || item.value}</li>
    );
  }, []);

  return teams.length ? (
    <Select2
      options={options}
      selected={team?.id?.toString() || null}
      onSelectItem={handleSelectTeam}
      placeholder={intl.formatMessage({
        id: 'modal.organization.addMember.team.placeholder',
      })}
      fullWidth
      width={368}
      renderItem={renderItem}
      search
      searchKey="label"
      inputSearchPlaceholder={intl.formatMessage({ id: 'global.search.placeholder' })}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      disabled={disabled}
    />
  ) : (
    <Button3
      theme="tertiary"
      label={`+ ${intl.formatMessage({
        id: 'modal.organization.addMember.team.create',
      })}`}
      onClick={() => openCreateTeamModal({ onSuccess: handleSelectTeam })}
      disabled={disabled}
    />
  );
};

export default ChooseTeam;
