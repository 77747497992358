import React, { useMemo, memo, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl-phraseapp';

import routes from 'config/routes';
import { useSelector } from 'utils/redux';

import TabsPage from 'components/ui/TabsPage';

import MyAccount from './Views/MyAccount';
import Organization from './Views/Organization';
import Integrations from './paneIntegrations';

import styles from './SettingsPage.module.scss';

const ResponsiveContentWrapper: React.FC<{ children?: ReactNode }> = ({
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.content}>{children}</div>
  </div>
);

const SettingsPage = () => {
  const intl = useIntl();

  const { isAdmin } = useSelector(({ user: { profile } }) => ({
    isAdmin: profile?.admin || false,
  }));

  const dataset = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'settings.tabs.account' }),
        link: routes.myAccountInfos,
        content: <MyAccount />,
      },
      {
        label: intl.formatMessage({ id: 'settings.tabs.organization' }),
        link: routes.myAccount_organization,
        content: <Organization />,
      },
      {
        label: intl.formatMessage({ id: 'settings.tabs.integrations' }),
        link: routes.myAccount_integrations,
        content: <Integrations />,
        disabled: isAdmin,
      },
    ],
    [intl, isAdmin],
  );

  return (
    <div className={styles.fullHeight}>
      <Helmet>
        <title>{intl.formatMessage({ id: "pageTitles.settings"})}</title>
      </Helmet>
      <TabsPage
        dataset={dataset}
        withRouter
        parentPath={routes.myAccount}
        title={intl.formatMessage({ id: 'settings.header.title' })}
        ContentWrapper={ResponsiveContentWrapper}
      />
    </div>
  );
};

export default memo(SettingsPage);
