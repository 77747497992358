import React, { useCallback, memo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl-phraseapp';
import { Button3 } from 'kolkit';

import { Tabs } from 'components';
import { useDispatch, useSelector } from 'utils/redux';
import { updateFilter, unselectSna } from 'actions/user';
import itemsConfig from './config/itemsConfig';
import { filterComponents } from './Filter/index';
import { ActiveFilter, ActiveAllFilter } from './Modules';

import styles from './Engine.module.scss';


const Filters = ( { onClick, defaultTab, className }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const value = useSelector(({ user }) => user.filters.activeFilter);

  const handleChangeFilter = useCallback(
    data => dispatch(updateFilter(data)),
    [dispatch]
  );

  const handleSelectSna = useCallback(
    data => {
      dispatch(unselectSna(data))
    },
    [dispatch]
  );

  const initFilter = useCallback(
    () => {
      dispatch(updateFilter({
        field: 'initFilter',
        value: true,
        forceTriggerSearch: true,
      }));
      if (onClick) onClick();
    },
    [dispatch, onClick]
  );

  const initFilterTab = itemsConfig.map((item, index) => {
    const { icon, label } = item;
    return {
      label,
      icon,
      item: label !== 'KOL' && <ActiveFilter network={label} onChange={handleChangeFilter} />,
      component: filterComponents(handleChangeFilter)[index],
    }
  });

  const cnGrid = cn(styles.grid, className);

  return (
    <div className={cnGrid}>
      <Tabs
        className={styles.tabs}
        tabs={initFilterTab}
        currentTab={defaultTab}
        tabClassName={styles.tabItem}
        tabMenuClassName={styles.tabMenu}
      />
      <div className={styles.bottom}>
        <ActiveAllFilter
          className={styles.activeAllFilter}
          onChange={handleSelectSna}
        />
        <div className={styles.btnContent}>
          <Button3
            size="medium"
            onClick={initFilter}
            disabled={!value.length}
          >
            {intl.formatMessage({id: 'global.cta.submit'})}
          </Button3>
        </div>
      </div>
    </div>
  )
};

Filters.displayName = 'Filters';

export default memo(Filters);
