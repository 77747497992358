import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';

import { Icon } from 'kolkit';

import * as featureNames from 'constants/feature-names';
import { useSelector } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import { MountComponentByFeature } from 'utils/feature-toggle';

import ButtonAddProfileToFavorites from './ButtonAddProfileToFavorites';

import 'css/scss/ui/profile-actions.scss';

const ProfileActions = ({ profileId, from, iconSize }) => {
  const intl = useIntl();
  const { on: showNoteModal } = useModal('profileNote')

  const { profile } = useSelector(({ profiles }) => ({
    profile: profiles?.[profileId],
  }));

  const handleNote = useCallback(() => {
    showNoteModal({
      id: profileId,
      from,
    });
  }, [profileId, from, showNoteModal]);

  return (
    <>
     <MountComponentByFeature featureName={featureNames.FAVORITES}>
        <ButtonAddProfileToFavorites
          from={from}
          profileId={[profile?.id]}
          iconSize={iconSize}
        />
      </MountComponentByFeature>
      <MountComponentByFeature featureName={featureNames.NOTES}>
        <Icon
          theme={profile?.core?.hasNote ? 'solid' : 'thin'}
          label="file-alt"
          onClick={handleNote}
          isButton
          size={iconSize}
          fill={profile?.core?.hasNote ? '#00ccff' : undefined}
          tooltip={intl.formatMessage({id: 'note.cta.add'})}
        />
      </MountComponentByFeature>
    </>
  );
};

ProfileActions.displayName = 'ProfileActions';

ProfileActions.propTypes = {
  from: PropTypes.string,
  iconSize: PropTypes.string,
};

ProfileActions.defaultProps = {
  iconSize: 'medium',
  from: '',
};

export default memo(ProfileActions);
