import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Field, SelectPercent } from 'components';
import { configFilters } from 'config/filters';
import { useFilter } from 'components/engine/hooks';


const Ages = ( { network, required, disabled, onChange, ...rest }) => {
  const filterTarget = 'communityAges';
  const intl = useIntl();

  const value = useFilter(filterTarget, network);
  const { datas, params } = configFilters[filterTarget];

  const handleChange = useCallback(
    ({ item, selected }) => onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      network
    }),
    [onChange, network, filterTarget]
  );

  const getData = useMemo(
    () => datas.map(
      (item) => ({
        id: item,
        label: intl.formatMessage({id: `engine.filters.${item}`}),
        value: item,
      })),
    [datas, intl]
  );

  return (
    <div {...rest}>
      <Field label={intl.formatMessage(
        {id: 'audienceAgeGroup.label'},
        {count: params.max},
      )}>
        <SelectPercent
          selected={value}
          dataset={getData}
          sort
          search={false}
          label={intl.formatMessage({id: 'ageGroup.filterBy'})}
          onChange={handleChange}
          disabled={disabled}
          max={params.max}
        />
      </Field>
    </div>
  )
};

Ages.displayName = 'Ages';

Ages.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Ages.defaultProps = {
  disabled: false,
  required: false,
};

export default memo(Ages);
