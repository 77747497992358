import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'kolkit/Loader';

const CampaignDetails = Loadable({
  loader: () => import('components/search/SearchEngine/SearchEngine'),
  loading: () => <Loader full />,
});

export default CampaignDetails;
