import React, { useEffect, useMemo, memo, PropsWithChildren } from 'react';
import { Button3, Icon, Text } from 'kolkit';
import { FormattedMessage } from 'react-intl-phraseapp';
import { Drawer } from '@mui/material';

import usePanel from 'utils/hooks/usePanel';
import { Link } from 'react-router-dom';
import { Avatar, TabsPage } from 'components/ui';
import { useDispatch, useSelector } from 'utils/redux';
import { extractKolMention, extractKolName, formatUrl } from 'utils';
import { getUserRightsOnSelection } from 'utils/rights';

import {
  loadContactableEmails,
  getTopMentions,
  loadProfileCoreAndAudience,
  loadProfileDetails,
  loadProfileAbout,
} from 'actions/profiles';
import KolAction from 'components/modules/KolAction';
import { USER_ROLES } from 'constants/states';
import routes from 'config/routes';
import { getContentRequests } from 'slices/contentValidation.slice';

import TabInformations from './TabInformations';
import TabPlans from './TabPlans';
import TabContent from './TabContent';
import TabSNAs from './TabSNAs';
import TabWorkflow from './TabWorkflow';

import styles from './ProfilePanel.module.scss';

export const PANEL_ID = 'ProfilePanel';

// eslint-disable-next-line react/display-name
const ContentWrapper = memo<PropsWithChildren>(({ children }) => (
  <div className={styles.tabs}>{children}</div>
));

const ProfilePanel = () => {
  const { show, off, data, onUpdateData } = usePanel(PANEL_ID, false);
  const { profileId, tabToOpen, options } = data || {};

  const dispatch = useDispatch();

  const { campaignId, profileDetails, userCan, isGuest, features } =
    useSelector(
      ({ profiles, campaigns, views: { campaignDetails }, user }) => ({
        campaignId: campaignDetails?.id,
        profileDetails: profiles[profileId],
        userCan: campaignDetails?.id
          ? getUserRightsOnSelection(campaigns[campaignDetails.id]?.core?.role)
          : {},
        isGuest: user.role === USER_ROLES.GUEST,
        features: user.features,
      }),
    );

  const dataset = useMemo(
    () => {
      const tabs = [
        {
          label: (
            <div className="flex aic gap8">
              <Icon label="chart-simple" theme="solid" />
              <Text fontWeight={400}>
                <FormattedMessage id="profile.tabs.statistics" />
              </Text>
            </div>
          ),
          content: <TabSNAs />,
          onClick: () => onUpdateData({ tabToOpen: 'statistics' }),
          dataId: 'CA-tab-stats',
          active: true,
        },
        {
          label: (
            <div className="flex aic gap8">
              <Icon label="calculator" theme="solid" />
              <Text fontWeight={400}>
                <FormattedMessage id="profile.tabs.plans" />
              </Text>
            </div>
          ),
          content: <TabPlans />,
          onClick: () => onUpdateData({ tabToOpen: 'delivery' }),
          dataId: 'CA-tab-plans',
          active: options?.fromCampaign && userCan.edit,
        },
        {
          label: (
            <div className="flex aic gap8">
              <Icon label="image" theme="solid" />
              <Text fontWeight={400}>
                <FormattedMessage id="profile.tabs.content" />
              </Text>
            </div>
          ),
          content: <TabContent />,
          onClick: () => onUpdateData({ tabToOpen: 'contentInstructions' }),
          dataId: 'CA-tab-content',
          active: options?.fromCampaign && userCan.edit,
        },
        {
          label: (
            <div className="flex aic gap8">
              <Icon label="user" theme="solid" />
              <Text fontWeight={400}>
                <FormattedMessage id="profile.tabs.informations" />
              </Text>
            </div>
          ),
          content: <TabInformations fromCampaign={options?.fromCampaign} />,
          onClick: () => onUpdateData({ tabToOpen: 'informations' }),
          dataId: 'CA-tab-info',
          active: true,
        },
        {
          label: (
            <div className="flex aic gap8">
              <Icon label="arrow-progress" theme="solid" />
              <Text fontWeight={400}>
                <FormattedMessage id="profile.tabs.workflow" />
              </Text>
            </div>
          ),
          content: <TabWorkflow openCreateComment={!!options?.openCreateComment} />,
          onClick: () => onUpdateData({ tabToOpen: 'workflow' }),
          dataId: 'CA-tab-workflow',
          active: options?.fromCampaign,
        },
      ];

      return tabs.filter(tab => tab.active);
    },
    [
      options?.openCreateComment,
      options?.fromCampaign,
      userCan.edit,
      onUpdateData,
    ],
  );

  const getProfileUrl = useMemo(
    () => options?.fromCampaign
      ? `./${routes.profile}/${profileId}`
      : formatUrl(`/${routes.profile}/${profileId}`),
    [profileId, options?.fromCampaign]
  );

  const renderTitle = useMemo(
    () =>
      profileDetails ? (
        <div className="flex jcsb aifs mb8">
          <Avatar
            name={extractKolName(profileDetails)}
            mention={extractKolMention(profileDetails)}
            image={profileDetails.core.picture}
            // @ts-ignore
            isInactive={profileDetails.core.isInactive}
          />
          <div className="flex aic gap8">
            {!isGuest && (
              <Link to={getProfileUrl}>
                <Button3
                  dataId="CA-full-profile"
                  icon="user"
                  label="Profile"
                  theme="tertiary"
                  size="small"
                  iconTheme="solid"
                  onClick={() => {
                    // @ts-ignore
                    if (window?.satismeter) {
                      // @ts-ignore
                      window?.satismeter('track', { event: 'ClickProfileSidePanel' })
                    }
                    off();
                  }}
                />
              </Link>
            )}
            <Button3 theme="tertiary" size="small" icon="times" onClick={off} />
          </div>
        </div>
      ) : null,
    [profileDetails, isGuest, off, getProfileUrl],
  );

  useEffect(() => {
    if (profileId) {
      void dispatch(loadProfileCoreAndAudience(profileId));
      if (!isGuest) {
        if (features.manageCampaigns) {
          void dispatch(loadProfileDetails(profileId));
          void dispatch(loadContactableEmails(profileId));
        }
        void dispatch(
          getTopMentions({
            id: profileId,
            filters: { date: { slice: 'all' } },
          }),
        );
        void dispatch(loadProfileAbout({ id: profileId }));
        if (campaignId) {
          void dispatch(
            getContentRequests({
              profile_id: profileId,
              project_id: campaignId,
            }),
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [isGuest, profileId, campaignId]);

  const initialTabIndex = useMemo(
    () => {
      switch (tabToOpen) {
        case 'workflow':
          return userCan.edit ? 4 : 2;
        case 'delivery':
          return userCan.edit ? 1 : 0;
        case 'contentInstructions':
          return userCan.edit ? 2 : 1;
        case 'informations':
          return userCan.edit ? 3 : 1;
        default:
          return 0;
      }
    },
    [tabToOpen, userCan.edit]
  );

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={off}
      sx={{
        '.MuiDrawer-paper': {
          width: 800,
        },
      }}
      PaperProps={{
        className: styles.container,
      }}
      ModalProps={{
        className: styles.modal,
      }}
      hideBackdrop
      disableEnforceFocus
      // Stop propagation to underlying Clickawaylistener if clicking on the sidepanel
      onClick={(e) => e.stopPropagation()}
    >
      <TabsPage
        theme="tone"
        title={renderTitle}
        dataset={dataset}
        className={styles.header}
        padding={16}
        initialTabIndex={initialTabIndex}
        noCollapse
        ContentWrapper={ContentWrapper}
        extraAction={
          !isGuest && profileId ? (
            <KolAction
              id={profileId}
              from={options?.fromCampaign ? 'Campaign' : 'Search'}
              expect={!options?.fromCampaign
                ? ['message', 'remove_from_campaigns', 'ask_stats_sharing', 'remove_from_list']
                : ['remove_from_list']
              }
              trigger={
                <Button3
                  label="Actions"
                  icon="circle-caret-down"
                  iconTheme="solid"
                  theme="tertiary"
                  size="small"
                  active
                  reverse
                />
              }
            />
          ) : null
        }
      />
    </Drawer>
  );
};

export default ProfilePanel;
