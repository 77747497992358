import React, { lazy, useCallback, useEffect, useMemo, useState, Suspense } from 'react';
import { FormattedDisplayName } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { FullStory } from '@fullstory/browser';
import _sortBy from 'lodash.sortby';
import cn from 'classnames';

import { INSTAGRAM, NETWORKS, TWITTER } from 'constants/networks';
import { CREDIBILITY_SCORE_KEYS } from 'constants/profiles';
import usePanel from 'utils/hooks/usePanel';
import { useSelector } from 'utils/redux';
import useNumberFormat from 'utils/hooks/useNumberFormat';
import { getDefaultAudienceTypeByNetwork } from 'utils/networkAudience';

import { Icon, Loader, Text, Spaces } from 'kolkit';
import NetworkButton from 'components/ui/NetworkButton';
import Tile from 'components/ui/Tile';
import ToggleButtons2 from 'components/ui/ToggleButtons2';
import CommunityEvolutionArrow from 'components/ui/CommunityEvolutionArrow';
import AudienceCredibilityDot from 'components/ui/AudienceCredibilityDot';
import AudienceTypeToggle from 'components/ui/AudienceTypeToggle';

import AudienceAgeGenderChart from 'components/ui/AudienceAgeGenderChart';
import { InlineMessage, Markdown, Typography } from 'components/ui';

import { PANEL_ID } from '../ProfilePanel';

import styles from './TabSNAs.module.scss';

const localisationsTypes = ['country', 'city'] as const;

const Contents = lazy(() => import('./modules/Contents'))

const TabSNAs = () => {
  const intl = useIntl();

  const { show, data } = usePanel(PANEL_ID, false);

  const { profileId, networksToOpen, search_terms, from, to, snaToOpen, sortBy } = data || {};

  const formatNumber = useNumberFormat();

  const { profileDetails } = useSelector(({ profiles }) => {
    return {
      profileDetails: profiles?.[profileId],
    };
  });

  const [currentSnaId, setCurrentSnaId] = useState<number | undefined>();
  const [audienceType, setAudienceType] = useState<string>(getDefaultAudienceTypeByNetwork(INSTAGRAM));
  const [localisationType, setLocalisationType] = useState<'country' | 'city'>(
    'country',
  );

  const localisationsTypeDataset = useMemo(
    () =>
      localisationsTypes.map((type) => ({
        value: type,
        label: intl.formatMessage({ id: `global.label.${type}` }),
        dataId: `CA-SNA-${type}`,
      })),
    [intl],
  );

  useEffect(() => {
    const byNetworks = profileDetails?.sortedSnas?.byNetworks;
    let snaId;

    // Compute the sna to open
    if (snaToOpen) {
      snaId = snaToOpen;
    } else if (networksToOpen?.length) {
      // If sort is enabled
      if (sortBy === 'posts_engagement_rate_growth') {
        const snas = networksToOpen.flatMap(
          (network) => byNetworks?.[network]?.ids,
        );
        snaId = _sortBy(snas, [
          (snaId) =>
            profileDetails.snas[snaId].stats
              .commitmentPerMediaProgressionRate || 0,
        ]).pop();
      } else {
        // Else get the first one
        snaId = byNetworks?.[networksToOpen[0]].ids?.[0];
      }
    } else {
      snaId = NETWORKS.flatMap(
        (network) => byNetworks?.[network]?.ids || [],
      )?.[0];
    }

    setCurrentSnaId(snaId);
    setAudienceType(
      getDefaultAudienceTypeByNetwork(profileDetails?.snas?.[snaId]?.network),
    );
  }, [profileDetails, networksToOpen, snaToOpen, sortBy]);

  const { currentSna, currentSnaAudience } = useMemo(() => {
    return {
      currentSna: currentSnaId ? profileDetails?.snas?.[currentSnaId] : null,
      currentSnaAudience: currentSnaId
        ? profileDetails?.audience?.snas?.[currentSnaId]?.[audienceType]
        : null,
    };
  }, [
    audienceType,
    currentSnaId,
    profileDetails?.audience?.snas,
    profileDetails?.snas,
  ]);

  const credibilityLevel = useMemo(
    () =>
      CREDIBILITY_SCORE_KEYS.find((level) => {
        const splitted = level.split('-');
        return (
          (currentSna?.audience_credibility_score || 0) <= Number(splitted?.[1])
        );
      }),
    [currentSna?.audience_credibility_score],
  );

  const handleChangeAudienceType = useCallback((newAudienceType) => {
    setAudienceType(newAudienceType);
  }, []);

  if (!show || !profileDetails) return null;

  const hasAgeAndGenders =
    currentSnaAudience?.ages?.length || currentSnaAudience?.genders?.length;
  const hasLocations = !!currentSnaAudience?.locations?.length;
  const hasLanguages = !!currentSnaAudience?.languages?.length;
  const hasInterests = !!currentSnaAudience?.interests?.length;
  const hasTopMentions =
    currentSna?.network === INSTAGRAM && !!profileDetails?.topMentions?.length;

  return (
    <div className={styles.container}>
      <div className="flex aic wrap gap8">
        {NETWORKS.flatMap((network) =>
          profileDetails?.sortedSnas?.byNetworks?.[network]?.ids?.map((snaId) => {
            const { username, isInactive } = profileDetails?.snas?.[snaId] || {};
            return (
              <NetworkButton
                key={snaId}
                network={network}
                onClick={() => {
                  setCurrentSnaId(snaId);
                  FullStory('trackEvent', {
                    name: 'CA-SNA-visited',
                    properties: { network },
                  });
                }}
                active={currentSnaId === snaId}
                selected={currentSnaId === snaId}
                className={cn({[styles.inactiveIcon]: isInactive})}
              >
                {username} {isInactive}
              </NetworkButton>
            );
          }),
        )}
      </div>

      {currentSna?.network === TWITTER && (
        <InlineMessage type="info">
          <FormattedMessage id="engineSearch.filters.twitter.hint" />
        </InlineMessage>
      )}

      {currentSna?.isInactive && (
        <InlineMessage type="warning">
          <Markdown>
            {intl.formatMessage({ id: "profileDetails.inactiveSna.warning" })}
          </Markdown>
        </InlineMessage>
      )}

      <div className="flex ais gap16">
        {currentSna && (
          <Tile
            noShadow
            className="flex fdc gap8 f2"
            padding="var(--spacing-spacing-xx-small)"
          >
            <div className="flex aic gap8">
              <a
                className="flex aic"
                href={currentSna?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  label={`${currentSna?.network}-color`}
                  size="huge"
                  theme="brand"
                />
                <Text margin="0 0 0 8px">{currentSna.username}</Text>
              </a>
            </div>
            <div className="flex jcsb">
              <div className="flex fdc f1">
                <div className="flex aic gap8 f1">
                  <Text fontSize={24} fontWeight={500} resetMargin>
                    {formatNumber(currentSna?.stats?.communityCount, {
                      threeDigitsRule: true,
                    })}
                  </Text>
                  <Text fontSize={14} resetMargin>
                    {formatNumber(
                      currentSna?.stats?.communityProgressionRate || 0,
                      {
                        percentage: true,
                        sign: true,
                      },
                    )}
                  </Text>
                  <CommunityEvolutionArrow
                    value={currentSna?.stats?.communityProgressionRate || 0}
                  />
                </div>
                <Text fontSize={14} className="text-capitalize">
                  <FormattedMessage id="global.label.followers" />
                </Text>
              </div>
              <div className={cn("flex fdc f1", {"inactive-section": currentSna?.isInactive})}>
                <div className="flex aic gap8 f1">
                  <Text fontSize={24} fontWeight={500} resetMargin>
                    {formatNumber(
                      currentSna.stats.commitmentPerMediaPercent || 0,
                      { threeDigitsRule: true, percentage: true, },
                    )}
                  </Text>
                  <Text fontSize={14} resetMargin>
                    {formatNumber(
                      currentSna.stats.commitmentPerMediaProgressionRate || 0,
                      {
                        percentage: true,
                        sign: true,
                      },
                    )}
                  </Text>
                  <CommunityEvolutionArrow
                    value={
                      currentSna.stats.commitmentPerMediaProgressionRate || 0
                    }
                  />
                </div>
                <Text fontSize={14} className="text-capitalize">
                  <FormattedMessage id="global.label.engagementRate" />
                </Text>
              </div>
            </div>
          </Tile>
        )}
        {currentSna?.network === INSTAGRAM && (
          <Tile
            noShadow
            padding="var(--spacing-spacing-xx-small)"
            className="f1"
          >
            <div className="flex aic jcsb mb8">
              <div className="flex aic gap8">
                <AudienceCredibilityDot
                  value={currentSna.audience_credibility_score || 0}
                />
                <Text fontSize={24} fontWeight={500} resetMargin>
                  {formatNumber(currentSna.audience_credibility_score || 0, {
                    percentage: true,
                  })}
                </Text>
              </div>
              <Icon
                label="info-circle"
                tooltip={intl.formatMessage({ id: 'credibility.description' })}
              />
            </div>
            <Text resetMargin>
              <FormattedMessage
                id={`credibility.score.${
                  !currentSna?.audience_credibility_score
                    ? 'noScore'
                    : credibilityLevel
                }`}
              />
            </Text>
            <Text fontSize={14} className="text-capitalize">
              <FormattedMessage id="global.label.audienceCredibility" />
            </Text>
          </Tile>
        )}
      </div>

      {currentSna?.biography && (
        <Tile noShadow padding="var(--spacing-spacing-xx-small)">
          <Typography variant="title/tile-title" tag="p">
            <FormattedMessage id="global.label.biography" />
          </Typography>
          <Spaces bottom={8} />
          <Typography variant="text/body medium">
            <div dangerouslySetInnerHTML={{__html: currentSna?.biography}} />
          </Typography>
        </Tile>
      )}

      <div className="flex fdc gap16">
        <div className="flex aic jcsb mt8">
          <Typography variant="title/section-title-S" tag="p">
            <FormattedMessage id="global.audience.socioDemographicInformation" />
          </Typography>
          <AudienceTypeToggle
            value={audienceType}
            network={currentSna?.network}
            onChange={handleChangeAudienceType}
            dataIdPrefix="CA-SNA-"
          />
        </div>
        {hasAgeAndGenders && (
          <Tile noShadow padding="var(--spacing-spacing-xx-small)">
            <Typography variant="title/tile-title" tag="p">
              <FormattedMessage id="global.label.ageAndGender" />
            </Typography>
            <div className={cn({"inactive-section": currentSna?.isInactive})}>
              <AudienceAgeGenderChart
                barOrientation="vertical"
                ages={currentSnaAudience?.ages || []}
                genders={currentSnaAudience?.genders || {}}
              />
            </div>
          </Tile>
        )}
        <div
          className="grid ais gap16"
          style={{ gridTemplateColumns: '1fr 1fr' }}
        >
          {hasLocations && (
            <Tile
              noShadow
              className="flex fdc gap8 f1"
              padding="var(--spacing-spacing-xx-small)"
            >
              <div className="flex aic jcsb">
                <Typography variant="title/tile-title" tag="p">
                  <FormattedMessage id="global.label.locations" />
                </Typography>
                {!!currentSnaAudience?.locations.flatMap(
                  (location) => location.cities,
                )?.length && (
                  <ToggleButtons2
                    dataset={localisationsTypeDataset}
                    onChange={(type) =>
                      setLocalisationType(
                        (type as (typeof localisationsTypes)[number]) ||
                        ('country' as const),
                      )
                    }
                    value={localisationType}
                  />
                )}
              </div>
              {localisationType === 'country' &&
                currentSnaAudience?.locations?.slice(0, 3)?.map((location) => {
                  return (
                    <div
                      key={`audience-country-${location.code}`}
                      className={cn("flex aic jcsb text-capitalize", {"inactive-section": currentSna?.isInactive})}
                    >
                      <Text fontSize={14} resetMargin>
                        <FormattedDisplayName
                          value={location.code}
                          type="region"
                        />
                      </Text>
                      <Text fontSize={14} fontWeight={500} resetMargin>
                        {formatNumber(location.value * 100, {
                          percentage: true,
                        })}
                      </Text>
                    </div>
                  );
                })}
              {localisationType === 'city' &&
                currentSnaAudience?.locations
                  .flatMap((location) => location.cities)
                  .sort((city1, city2) => city2.value - city1.value)
                  .slice(0, 3)
                  .map((city, index) => {
                    return (
                      <div
                        key={`audience-city-${index}`}
                        className={cn("flex aic jcsb text-capitalize", {"inactive-section": currentSna?.isInactive})}
                      >
                        <Text fontSize={14} resetMargin>
                          {city.name}
                        </Text>
                        <Text fontSize={14} fontWeight={500} resetMargin>
                          {formatNumber(city.value * 100, {
                            percentage: true,
                          })}
                        </Text>
                      </div>
                    );
                  })}
            </Tile>
          )}
          {hasLanguages && (
            <Tile
              noShadow
              className="flex fdc gap8 f1"
              padding="var(--spacing-spacing-xx-small)"
            >
              <Typography variant="title/tile-title" tag="p">
                <FormattedMessage id="global.label.languages" />
              </Typography>
              {currentSnaAudience?.languages?.slice(0, 3)?.map((language, index) => {
                return (
                  <div
                    key={`audience-language-${index}`}
                    className={cn("flex aic jcsb text-capitalize", {"inactive-section": currentSna?.isInactive})}
                  >
                    <Text fontSize={14} resetMargin>
                      <FormattedDisplayName
                        value={language.label}
                        type="language"
                      />
                    </Text>
                    <Text fontSize={14} fontWeight={500} resetMargin>
                      {formatNumber(language.percent, { percentage: true })}
                    </Text>
                  </div>
                );
              })}
            </Tile>
          )}
          {hasInterests && (
            <Tile
              noShadow
              className="flex fdc gap8 f1"
              padding="var(--spacing-spacing-xx-small)"
            >
              <Typography variant="title/tile-title" tag="p">
                <FormattedMessage id="global.label.interests" />
              </Typography>
              {currentSnaAudience?.interests?.slice(0, 3)?.map((interest, index) => {
                return (
                  <div
                    key={`audience-interest-${index}`}
                    className={cn("flex aic jcsb text-capitalize", {"inactive-section": currentSna?.isInactive})}
                  >
                    <Text fontSize={14} resetMargin>
                      <FormattedMessage
                        id={`interests.list.${interest.label}`}
                      />
                    </Text>
                    <Text fontSize={14} fontWeight={500} resetMargin>
                      {formatNumber(interest.percent, { percentage: true })}
                    </Text>
                  </div>
                );
              })}
            </Tile>
          )}
          {hasTopMentions && (
            <Tile
              noShadow
              className="flex fdc gap8 f1"
              padding="var(--spacing-spacing-xx-small)"
            >
              <Typography variant="title/tile-title" tag="p">
                <FormattedMessage id="global.label.topMentions" />
              </Typography>
              {profileDetails?.topMentions?.slice(0, 3)?.map((mention, index) => {
                return (
                  <div
                    key={`top-mention-${index}`}
                    className={cn("flex aic jcsb text-capitalize", {"inactive-section": currentSna?.isInactive})}
                  >
                    <Text fontSize={14} resetMargin>
                      @{mention.username}
                    </Text>
                    <Text fontSize={14} fontWeight={500} resetMargin>
                      {formatNumber(mention.count, { compact: false })}
                    </Text>
                  </div>
                );
              })}
            </Tile>
          )}
        </div>
      </div>

      {(currentSna?.network !== TWITTER) && currentSna?.network && (
        <Tile
          noShadow
          className="flex fdc gap8 f1"
          padding="var(--spacing-spacing-xx-small)"
        >
          <Suspense fallback={<Loader full />}>
            <Contents
              kolId={profileId}
              terms={search_terms || ''}
              from={from || ''}
              to={to || ''}
              network={currentSna?.network}
            />
          </Suspense>
        </Tile>
      )}
    </div>
  );
};

export default TabSNAs;
