import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './LazyImage.module.scss';

const defaultFallbackImage = '/svg/avatar-placeholder-large.svg';

const Image = ({ src, fallback, alt, className, placeholder, loading, ...rest }) => {
  const cnImg = cn(styles.img, className);

  const handleError = useCallback(
    e => {
      e.target.src = e.target.src === src && fallback ? fallback : defaultFallbackImage;
      if (e.target.src === fallback) e.onerror = null;
    },
    [src, fallback],
  );

  return (
    <img
      {...rest}
      className={cnImg}
      src={src || fallback || placeholder || defaultFallbackImage}
      alt={alt}
      loading={loading}
      onError={handleError}
    />
  );
};

Image.propTypes = {
  placeholder: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.string,
};

Image.defaultProps = {
  src: "/svg/avatar-placeholder-large.svg",
  placeholder: '',
  alt: '',
  className: undefined,
  loading: 'lazy'
};

export default memo(Image);
