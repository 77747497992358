import React, { memo, useCallback, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Text, Tooltip, Truncate, Icon, Loader } from 'kolkit';
import { Typography } from 'components/ui'
import useTableScroll from 'utils/hooks/useTableScroll';

import SorterButton from './SorterButton/SorterButton';
import useSort from './hooks/useSort';

import styles from './DataTable.module.scss';

const DataTable = ({
  title,
  filter,
  columns,
  data,
  renderItem,
  renderFooter,
  extractSortValue,
  className,
  headerClassName,
  contentClassName,
  filtersClassName,
  toolbarClassName,
  gridTemplateColumns,
  onColumnSort,
  defaultSortedColumn,
  defaultSortDirection,
  resetScrollOnSort,
  scrollTopMargin,
  placeholder,
  onScroll,
  displayBackToTopBuffer,
  hasAction,
  forceTopBorder,
  noPadding,
  stickyFirstColumn,
  loading,
}) => {
  const contentRef = useRef(null);
  const headerRef = useRef(null);

  const { sortedColumn, sortedData, direction, changeSortDirection } = useSort({
    data,
    extractSortValue,
    defaultSortedColumn,
    defaultSortDirection,
  });

  const resetScroll = useCallback(
    () => {
      if (!headerRef?.current || !contentRef?.current) return null;
      const headerGeometry = headerRef.current.getBoundingClientRect();
      const contentGeometry = contentRef.current.getBoundingClientRect();

      const datatableTopPosition =
        contentGeometry.top -
        headerGeometry.height -
        scrollTopMargin +
        window.scrollY;

      if (window.pageYOffset > datatableTopPosition) {
        window.scrollTo({
          top: datatableTopPosition,
          behavior: 'smooth',
        });
      }
    },
    [scrollTopMargin, headerRef, contentRef]
  );

  const { handleScroll, ScrollTopButton, displayBackToTop } = useTableScroll(
    contentRef,
    displayBackToTopBuffer,
  );

  const handleSorter = useCallback(
    ({ name: column, sortKey }) => {
      if (onColumnSort) onColumnSort(sortKey || column);
      else changeSortDirection(sortKey || column);
      if (resetScrollOnSort) resetScroll();
    },
    [changeSortDirection, resetScroll, resetScrollOnSort, onColumnSort],
  );

  const defaultGridTemplateColumns = useMemo(
    () => `repeat(${columns?.length}, 1fr`,
    [columns],
  );

  const renderPlaceholder = useMemo(
    () =>
      typeof placeholder === 'string' ? (
        <Text
          fontTitle
          fontSize={28}
          fontWeight={500}
          className={styles.placeholderText}
        >
          {placeholder}
        </Text>
      ) : (
        placeholder
      ),
    [placeholder],
  );

  const syncHorizontalScroll = useCallback(
    e => {
      e.persist();
      if (headerRef.current.contains(e.target)) {
        contentRef.current.firstChild.scrollLeft = headerRef.current.scrollLeft;
      }
      if (contentRef.current.firstChild.contains(e.target)) {
        headerRef.current.scrollLeft = contentRef.current.firstChild.scrollLeft;
        handleScroll(e);
      }
      contentRef.current.firstChild.dataset.scroll =
        contentRef.current.firstChild.scrollLeft;
      headerRef.current.dataset.scroll = headerRef.current.scrollLeft;
    },
    [handleScroll],
  );

  useEffect(() => {
    if (displayBackToTop && onScroll) onScroll();
  }, [displayBackToTop, onScroll]);

  const cnContainer = cn(styles.container, className);
  const cnToolbar = cn(styles.toolbar, toolbarClassName);
  const cnFilters = cn(styles.tableFilter, filtersClassName);
  const cnContent = cn(
    styles.content,
    {
      [styles.noPadding]: noPadding,
      [styles.stickyFirstColumn]: stickyFirstColumn,
    },
    contentClassName,
  );
  const cnHeader = cn(
    styles.header,
    {
      [styles.noPadding]: noPadding,
      [styles.noTopBorder]: !forceTopBorder && !title && !filter,
      [styles.hasAction]: hasAction,
      [styles.stickyFirstColumn]: stickyFirstColumn,
    },
    headerClassName,
  );

  return (
    <div className={cnContainer}>
      {loading && <Loader full background="rgba(255, 255, 255, .8)" />}
      {(title || filter) && (
        <div className={cnToolbar}>
          <div className={styles.tableTitle}>
            <Typography variant="title/tile-title" tag="p">
              {title}
            </Typography>
          </div>
          {filter && <div className={cnFilters}>{filter}</div>}
        </div>
      )}
      <div
        className={cnHeader}
        style={{
          gridTemplateColumns:
            gridTemplateColumns || defaultGridTemplateColumns,
        }}
        ref={headerRef}
        onScroll={syncHorizontalScroll}
        data-scroll={0}
      >
        {columns.map(
          ({
             name,
             sortKey,
             label,
             subLabel,
             tooltip,
             icon,
             className,
             sortable,
             minWidth,
             dataId,
           }, index) => {
            const cnHeaderItem = cn(styles.headerItem, className);
            const cnLabel = cn(styles.label, 'datatable-header-title', {
              [styles.active]: sortedColumn === name,
            });

            return (
              <SorterButton
                key={`key_${index}`}
                active={sortedColumn === sortKey || sortedColumn === name}
                name={name}
                sortKey={sortKey}
                onClick={handleSorter}
                direction={direction}
                className={cnHeaderItem}
                disabled={sortable === false}
                style={{ minWidth }}
                dataId={dataId}
              >
                <Tooltip content={tooltip}>
                  <div className={styles.labelWrapper}>
                    {icon && (
                      <Icon
                        label={icon?.label}
                        theme={icon?.theme}
                        className={styles.icon}
                        fill={styles.iconColor}
                        isButton
                      />
                    )}
                    <div>
                      <span className={cnLabel}>{label}</span>
                      <Truncate className={styles.subLabel}>
                        {subLabel}
                      </Truncate>
                    </div>
                  </div>
                </Tooltip>
              </SorterButton>
            );
          },
        )}
      </div>
      <div className={cnContent} ref={contentRef}>
        <div data-scroll={0} onScroll={syncHorizontalScroll}>
          {!data?.length && (
            <div className={styles.placeholder}>{renderPlaceholder}</div>
          )}
          {React.Children.toArray(
            sortedData?.map((item, index, array) =>
              React.cloneElement(renderItem(item, index, array), {
                style: {
                  gridTemplateColumns:
                    gridTemplateColumns || defaultGridTemplateColumns,
                },
              }),
            ),
          )}
        </div>
      </div>
      <ScrollTopButton />
      {renderFooter && renderFooter(data)}
    </div>
  );
};

DataTable.displayName = 'DataTable';

DataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      subLabel: PropTypes.string,
      tooltip: PropTypes.string,
      icon: PropTypes.string,
      className: PropTypes.string,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  scrollTopMargin: PropTypes.number,
  resetScrollOnSort: PropTypes.bool,
  filter: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderItem: PropTypes.func.isRequired,
  extractSortValue: PropTypes.func,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  filtersClassName: PropTypes.string,
  toolbarClassName: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  onColumnSort: PropTypes.func,
  defaultSortedColumn: PropTypes.string,
  defaultSortDirection: PropTypes.string,
  placeholder: PropTypes.node,
  onScroll: PropTypes.func,
  displayBackToTopBuffer: PropTypes.number,
  renderFooter: PropTypes.func,
  hasAction: PropTypes.bool,
  forceTopBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  stickyFirstColumn: PropTypes.bool,
  loading: PropTypes.bool,
};

DataTable.defaultProps = {
  title: undefined,
  resetScrollOnSort: true,
  scrollTopMargin: 60, // header height
  filter: undefined,
  extractSortValue: undefined,
  className: undefined,
  headerClassName: undefined,
  contentClassName: undefined,
  filtersClassName: undefined,
  toolbarClassName: undefined,
  gridTemplateColumns: undefined,
  onColumnSort: undefined,
  defaultSortedColumn: undefined,
  defaultSortDirection: undefined,
  placeholder: undefined,
  onScroll: undefined,
  displayBackToTopBuffer: 200,
  renderFooter: null,
  hasAction: false,
  forceTopBorder: false,
  noPadding: false,
  stickyFirstColumn: false,
  loading: false,
};

export default memo(DataTable);
