import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _differenceBy from 'lodash.differenceby';
import sortBy from 'lodash.sortby';
import deburr from 'lodash.deburr';
import { Virtuoso } from 'react-virtuoso';

import { Icon } from 'kolkit';
import { Input, PortalContainer, TruncateContent } from 'components';
import isEventOutside from 'utils/isEventOutside';

import './Select.scss';


class Select extends PureComponent {
  mount = null;
  timerReploy = null;
  listener = null;

  constructor(props) {
    super(props);
    this.state = {
      search: this.getItemFromValue(props.value).label || '',
      displayedSearch: this.getItemFromValue(props.value).label || '',
      deployed: false,
      displayFullDataset: true,
      renderOptions: false,
      activeResizeObserver: true,
      hasBeenFocused: false,
      focus: false,
      mount: false,
    };
    this.ref = createRef();
    this.portal = createRef();
    this.input = createRef();
  }

  componentDidMount() {
    const { inPortal } = this.props;

    if (inPortal) {
      if (this.mount) clearTimeout(this.mount);
      this.mount = setTimeout(() => this.onMount(true), 250);
    }
    if (!inPortal) {
      this.onMount(true);
    }
  }

  onMount = value => this.setState({ mount: value });

  onChangeDisplayedSearch = ({ value : displayedSearch }) => {
    this.setState({
      displayFullDataset: false,
      displayedSearch
    });
    if (this.props.hasSearch) this.setState({ search: displayedSearch });
    if (this.props.onInputChange) this.props.onInputChange(displayedSearch);
  };

  onChangeSelectNative = ({ target: { value }}) =>  this.onChange(value);

  componentWillUnmount = () => {
    this.removeButtonListeners();
    document.removeEventListener('click', this.onClickDocumentEvent);
    this.closeMenuOptions(true);
    clearTimeout(this.timerReploy);
    clearTimeout(this.listener);
    clearTimeout(this.mount);
  };

  getItemFromValue = value => {
    const { dataset } = this.props;
    if (value === '') {
      return ({ value: '', label: '' });
    }
    const ob = dataset.find(d => String(d?.value !== undefined ? d?.value : '') === String(value));

    if (!ob) {
      console.error("[Select] Value", value, "not found in dataset"); // eslint-disable-line
      return {};
    }

    return ob;
  };

  getItemFromLabel = label => {
    const { dataset } = this.props;

    if (label === '') return null;
    const ob = dataset.find(d => typeof d.label === "string" && d?.label?.toLowerCase() === label?.toLowerCase()) || null;
    return ob;
  };

  componentDidUpdate = oldProps => {
    const datasetDiff = _differenceBy(this.props.dataset, oldProps.dataset, 'value', 'label');

    if (oldProps.value !== this.props.value || datasetDiff.length > 0) {
      const ob = this.getItemFromValue(this.props.value);
      if (ob?.label) {
        this.handleStateUpdate(ob?.label);
      }
    }
  };

  handleStateUpdate = data => {
    this.setState({
      search: data,
      displayedSearch: data,
    })
  };

  onClickChange = value => () => this.onChange(value);

  onChange = value => {
    const { onChange, removeSearchOnClick, closeOnChange, isMultiple } = this.props;

    if (closeOnChange) this.closeMenuOptions();
    const ob = this.getItemFromValue(value);
    if (!isMultiple) this.setState({ search: removeSearchOnClick ? '' : ob.label, displayedSearch: removeSearchOnClick ? '' : ob.label });
    onChange({ type: 'select', ...ob });
    return value;
  };

  onFocusSearch = () => {
    const { onFocus } = this.props;
    const { hasBeenFocused } = this.state;

    this.setState({ focus: true });
    if (!hasBeenFocused) this.setState({ hasBeenFocused: true });
    this.setState({ displayFullDataset: true },() => {
      this.openMenuOptions();
    });
    if (onFocus) onFocus();
  };

  onBlurSearch = () => {
    const { onBlur, closeOnBlur } = this.props;
    const { deployed } = this.state;

    if (onBlur) onBlur();
    if (closeOnBlur && deployed) return this.closeMenuOptions;
  };

  openMenuOptions = () => {
    const { value } = this.props;
    const { deployed } = this.state;

    if (deployed) return null;

    this.setState({ activeResizeObserver: true });

    const displayedDataset = this.getDisplayDataset();

    if (this.props.useNative) return null;

    this.setState({ deployed: true, renderOptions: true });

    if (!this.dom) this.dom = this.ref.current;

    if (value && value !== '') {
      const index = displayedDataset.findIndex(search => search.value === value);
      const list = this.selectOptionList;
      if (!list) return null;
      const selected = list.getElementsByTagName('li')[index];
      if (selected?.offsetTop + selected?.clientHeight > list.clientHeight) list.scrollTop = selected?.clientHeight * index;
    }

    if (this.listener) clearTimeout(this.listener);

    this.listener = setTimeout(
      () => document.addEventListener('click', this.onClickDocumentEvent),
      300
    );
  };

  closeMenuOptions = (instant = false) => {
    const { useNative } = this.props;
    const { deployed } = this.state;

    this.setState({ focus: false });

    if (!deployed || useNative) return null;

    this.setState({ deployed: false });

    if (instant) this.setState({ renderOptions: false });

    document.removeEventListener('click', this.onClickDocumentEvent);

    this.removeButtonListeners();

    if (this.timerReploy) clearTimeout(this.timerReploy);

    this.timerReploy = setTimeout(() => {
      this.setState({
        renderOptions: false,
        activeResizeObserver: false
      });
    }, 300);
  };

  addButtonListener = () => {
    window.addEventListener("keydown", this.onDocumentKeyPressed);
  };

  removeButtonListeners = () => {
    window.removeEventListener("keydown", this.onDocumentKeyPressed);
  };

  onDocumentKeyPressed = e => {
    e.preventDefault();
    this.onKeyPress({
      key: e.key,
      keyCode: e.keyCode,
      event:e
    });
  };

  onClickButton = (e) => {
    e.stopPropagation();
    const { deployed } = this.state;

    this.setState({
      hasBeenFocused: true,
      focus: true
    });

    if (deployed) this.closeMenuOptions();
    else {
      this.openMenuOptions();
      this.addButtonListener()
    }
  };

  onKeyPress = event => {
    const { displayedSearch , deployed } = this.state;
    const { value, onFocusOut } = this.props;
    const { input } = this;
    const displayedDataset = this.getDisplayDataset();
    const index = displayedDataset.findIndex(search => search.label === displayedSearch);

    switch (event.key) {
      case 'Tab': {
        // TAB
        const hasBeenSelected = this.onEnterPress();
        if (this.props.onTabPressed) this.props.onTabPressed({event, value: hasBeenSelected});
        break;
      }
      case 'Escape': {
        // ESC
        const { label } = this.getItemFromValue(value);
        this.setState({search: label, displayedSearch: label});
        if (onFocusOut) onFocusOut();
        this.closeMenuOptions();
        if (displayedSearch === '') { this.onChange(""); }
        break;
      }
      case 'ArrowDown': {
        // DOWN ARROW
        const selectDown = index > -1 && index < displayedDataset.length - 1 ? index + 1 : 0 ;
        this.setState({displayedSearch: displayedDataset[selectDown]?.label})
        this.openMenuOptions();
        if (deployed) {
          const list = this.selectOptionList;
          if (!list) return null;
          const selected = list.getElementsByTagName("li")[selectDown];
          if (selectDown === 0) {
            list.scrollTop = 0
          }
          // if the selected item is off screen, scroll down
          if (
            selected &&
            selected.offsetTop + selected.clientHeight >=
              list.clientHeight + list.scrollTop
          ) {
            list.scrollTop += selected.clientHeight;
          }
        }
        break;
      }
      case 'ArrowUp': {
        // UP ARROW
        this.openMenuOptions();
        event.preventDefault(); // set caret position to the end
        if (input && input.value) input.setSelectionRange(input.value?.length, input.value?.length);
        const selectUp = index > 0 ? index - 1 : displayedDataset?.length - 1;
        this.setState({displayedSearch: displayedDataset?.[selectUp]?.label})
        if (deployed) {
          const list = this.selectOptionList;
          if (!list) return null;
          const selected = list.getElementsByTagName('li')[selectUp];
          if (selectUp === displayedDataset.length - 1) { list.scrollTop = selected.clientHeight * displayedDataset.length; }
          // if the selected item is off screen, scroll up
          else if (selected?.offsetTop < list.scrollTop) { list.scrollTop -= selected.clientHeight; }
        }
        break;
      }
      case 'Enter':
        this.onEnterPress();
        break;
      default:
        this.openMenuOptions();
    }
  };

  onClickDocumentEvent = e => {
    e.preventDefault();
    const { value, onFocusOut } = this.props;
    // Click outside component
    if (isEventOutside(e, this.portal.current)) {
      // We remove search and reinitialize displayed value
      this.setState({
        search: '',
        displayedSearch: this.getItemFromValue(value).label,
      }, () => this.closeMenuOptions());
      if (onFocusOut) onFocusOut();
    }
  };

  getDisplayDataset = () => {
    const { dataset, pinned, maxListItems, useNative, sort, selected, hasSearch } = this.props;
    const { displayedSearch, search, displayFullDataset } = this.state;

    const selectedItems = dataset.map(d => ({...d, selected: displayedSearch === d.label || selected.findIndex(p => p === d.value) > -1}));
    const sorted = sort ? sortBy(selectedItems, [({label}) => deburr(label)]) : selectedItems;
    const withPins = [...pinned.map(pin => ({pinned: true, ...sorted.find(d => d.value === pin)}) ), ...sorted.filter(s => pinned.findIndex(p => p === s.value) === -1)];
    if (displayFullDataset) return withPins;
    const filtered = !useNative && hasSearch ? withPins.filter(v => deburr(v?.label?.toLowerCase()).indexOf(deburr(search?.toLowerCase())) > -1) : withPins;
    return filtered.slice(0, (maxListItems > -1 && (!useNative && hasSearch)) ? maxListItems : filtered.length);
  };

  onEnterPress = () => {
    const { displayedSearch, search } = this.state;

    this.setState({ search: displayedSearch });
    const ob = this.getItemFromLabel(displayedSearch);
    // Label matches an item > we select it
    if (ob) return this.onChange(ob.value);

    // If a search exists
    if (search === '') {
      this.setState({search: '', displayedSearch: ''});
      return this.onChange('');
    }
    // we select first item in datalist
    const ds = this.getDisplayDataset();
    if (ds.length > 0) return this.onChange(ds[0].value);
    // else, we remove the search
    this.setState({ search: '', displayedSearch: '' });
    return '';
  };

  renderNative = () => {
    const { value, defaultOptionLabel, label, required, disabled, error, controlledError, shrink } = this.props;
    const { hasBeenFocused, focus } = this.state;

    const hasError = (error && !controlledError) || (hasBeenFocused && required && (!value || value === "") && !focus);

    const dataset = this.getDisplayDataset();
    const shouldDisplayError = hasBeenFocused && required && (!value || value === "");
    return (
      <div className={cn('bnc_field_input', 'bnc_field_select_native', {
        'bnc_field_input--shrink-label': (focus || defaultOptionLabel !== '') && shrink,
        'bnc_field_input--has-label': label && label !== '',
        'bnc_field_input--disabled': disabled,
        'bnc_field_input--error': hasError,
      })}>
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <label className={shouldDisplayError ? 'is-error' : ''} >
          {label && label !== '' && <span className="label">{label}{required && (<abbr>*</abbr>)}</span>}
          <select
            ref={node => this.input = node}
            value={shrink ? value : ''}
            disabled={disabled}
            onChange={this.onChangeSelectNative}
            onFocus={this.onFocusSearch}
            onBlur={this.onBlurSearch}
            data-testid="select"
          >
            { (!value || value === "") && <option value="" disabled>{defaultOptionLabel}</option>}
            {
              dataset.map(({value: ov, label: ol, disabled: od}) => (
                <option key={ov} value={ov} disabled={od}>{ol}</option>
              ))
            }
          </select>
          <Icon
            label="chevron-down"
            className={`icon icon--rotate${label ? " offset-top" : ""}`}
            width={10}
          />
        </label>
      </div>
    )
  }

  renderInput = () => {
    const { displayedSearch, hasBeenFocused, deployed } = this.state;
    const {
      required,
      value,
      error,
      controlledError,
      shrink,
      label,
      labelAsPlaceholder,
      withMask,
      disabled
    } = this.props;
    const shouldDisplayError = hasBeenFocused && required && (!value || value === "") && (!controlledError || error);

    return (
      <>
        <Input
          {...this.props}
          ref={node => this.input = node}
          value={(shrink || labelAsPlaceholder) ? displayedSearch : ''}
          onChange={this.onChangeDisplayedSearch}
          error={shouldDisplayError}
          onKeyPress={this.onKeyPress}
          required={required}
          useIcons={false}
          errorRequiredText=""
          label={labelAsPlaceholder ? '' : label}
          onFocus={this.onFocusSearch}
          placeholder={label}
          onBlur={this.onBlurSearch}
        />
        {withMask && !deployed && !disabled && (
          <div className="bnc_field_select_inputMask" onClick={this.onFocusSearch} role="button" />
        )}
      </>
    )
  };

  renderButton = () => {
    const { displayedSearch, hasBeenFocused, focus } = this.state;
    const { label, defaultOptionLabel, required, disabled, error, value, shrink } = this.props;

    const hasError = error || (hasBeenFocused && required && (!value || value === "") && !focus);

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={cn('bnc_field_button_as_input', {
        'bnc_field_select_button--shrink-label': (focus || defaultOptionLabel !== '' || (value && value !== "")) && shrink,
        'bnc_field_select_button--has-label': label && label !== '',
        'bnc_field_select_button--disabled': disabled,
        'bnc_field_select_button--error': hasError
      })}>
        {label && label !== '' && <span className="label">{label}{required && (<abbr>*</abbr>)}</span>}
        <button
          onClick={this.onClickButton}
          disabled={disabled}
          data-testid="select"
        >
          <TruncateContent>
            { shrink && displayedSearch && displayedSearch !== '' ? displayedSearch : (defaultOptionLabel || '') }
          </TruncateContent>
        </button>
      </label>
    )
  };

  renderEnhanced = () => {
    const { deployed, renderOptions, activeResizeObserver } = this.state;
    const { hasSearch, inPortal, theme } = this.props;

    const cnIcon = cn('selectIcon', {
      'icon--rotate--180': deployed
    });

    const cnMask = cn('bnc_field_select_options_mask', {
      'deployed': deployed,
      'rendered': renderOptions,
    });

    const cnOptions = cn('selectOptions', theme);

    const selectOptionsComponent = (
      <div className={cnOptions} ref={this.portal}>
        <div className={cnMask}>
          <div className="bnc_field_select_options_list" ref={node => this.selectOptionList = node}>
            {renderOptions && this.renderEnchancedOptions()}
          </div>
        </div>
      </div>
    );

    return (
      <PortalContainer disablePortal={!inPortal} html={selectOptionsComponent} on={activeResizeObserver}>
        <div className="bnc_field_select_enhanced">
          {hasSearch ? this.renderInput() : this.renderButton()}
          {theme === 'kolLab'
            ? <Icon label="caret-circle-down" className={cnIcon} theme="solid" fill="#0061AC" />
            : <Icon label="chevron-down" className={cnIcon} width={12} />
          }
        </div>
      </PortalContainer>
    );
  };

  renderEnchancedOptions = () => {
    const dataset = this.getDisplayDataset();

    if (!dataset?.length) {
      return this.props.noMatchPlaceholder && this.state.displayedSearch ?
        <div className="bnc_field_select_options_list_empty">
          { this.props.noMatchPlaceholder }
        </div> : null;
    }

    return (
      <ul>
        <Virtuoso
          style={{ height: 180 }}
          data={dataset}
          itemContent={(_, { value: ov, label: ol, react, disabled, icon, pinned, selected }) => (
            <li
              value={ov}
              className={cn({ pinned, selected, disabled })}
              role="option"
              aria-selected={selected || false}
              onClick={disabled ? null : this.onClickChange(ov)}
            >
              { react || (
                <TruncateContent>
                  { icon } { ol }
                </TruncateContent>
              )}
            </li>
          )}
        />
      </ul>
    );
  }

  render = () => {
    const { useNative, className, required, errorRequiredText, value, disabled, size, labelAsOption, activeLabel } = this.props;
    const { hasBeenFocused, focus, mount } = this.state;

    const cnSelect = cn('selectComponent', className, `bnc_field_select--size--${size}`, {
      'bnc_field_select--disabled': disabled,
      'bnc_field_select--label-as-option': labelAsOption,
      'bnc_field_select--active-placeholder': activeLabel,
    });

    return mount ? (
      <div ref={this.ref} className={cnSelect} data-testid="select-wrapper">
        {useNative ? this.renderNative() : this.renderEnhanced()}
        {hasBeenFocused && !focus && required && (!value || value === "") && errorRequiredText && errorRequiredText !== '' && <span className="error">{errorRequiredText}</span>}
      </div>
    ) : null;
  }
}

Select.displayName = 'Select';

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hasSearch: PropTypes.bool,
  dataset: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    react: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.element,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  pinned: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  useNative: PropTypes.bool,
  maxListItems: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errorRequiredText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultOptionLabel: PropTypes.string,
  className: PropTypes.string,
  sort: PropTypes.bool,
  removeSearchOnClick: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  controlledError: PropTypes.bool,
  error: PropTypes.bool,
  onTabPressed: PropTypes.func,
  size: PropTypes.string,
  closeOnChange: PropTypes.bool,
  onFocusOut: PropTypes.func,
  shrink: PropTypes.bool,
  labelAsOption: PropTypes.bool,
  labelAsPlaceholder: PropTypes.bool,
  closeOnBlur: PropTypes.bool,
  isMultiple: PropTypes.bool,
  multiSearch: PropTypes.func,
  placeholderOnFocus: PropTypes.string,
  noMatchPlaceholder: PropTypes.string,
  inPortal: PropTypes.bool,
  activeLabel: PropTypes.bool,
  theme: PropTypes.string,
};

Select.defaultProps = {
  hasSearch: true,
  useNative: false,
  maxListItems: -1,
  disabled: false,
  required: false,
  errorRequiredText: ``,
  label: ``,
  defaultOptionLabel: '',
  className: ``,
  sort: true,
  pinned: [],
  selected: [],
  removeSearchOnClick: false,
  onInputChange: null,
  onBlur: null,
  onFocus: null,
  controlledError: false,
  error: false,
  onTabPressed: null,
  size: "regular",
  closeOnChange: true,
  onFocusOut: null,
  shrink: true,
  labelAsOption: false,
  labelAsPlaceholder: false,
  closeOnBlur: true,
  isMultiple: false,
  multiSearch: null,
  placeholderOnFocus: '',
  noMatchPlaceholder: null,
  inPortal: true,
  activeLabel: false,
  theme: '',
};

export default Select;
