import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Label.module.scss';

const Label = ( { id, children, className, label }) => {
  const cnLabel = cn(styles.label, className);

  return (
    <label htmlFor={id} className={cnLabel}>
      {label && label}
      {children && children}
    </label>
  )
};

Label.displayName = 'label';

Label.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
};

Label.defaultProps = {
  id: null,
  children: null,
  label: null,
  className: undefined,
};

export default memo(Label);
