import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'kolkit';
import './Input.scss';

class Input extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasBeenFocused: false,
      focus: false,
      valueFormated: this.addSeparator(this.props.value),
    }
  }

  componentDidUpdate = oldProps => {
    const {numericThousandSeparator, value} = this.props;
    if(numericThousandSeparator && oldProps.value !== value) {
      this.handleChangeValueFormatted();
    }
  }

  handleChangeValueFormatted = () => {
    const valueFormated = this.addSeparator(this.props.value);
    this.setState({ valueFormated });
  }

  onKeyPress = e => {
    const { onKeyPress, onEnterPress } = this.props;

    const datas = { keyCode: e.keyCode, key: e.key, event:e };
    if (e.key === 'Enter' && onEnterPress) onEnterPress(datas);

    if (onKeyPress) onKeyPress(datas);
  }

  onFocus = () => {
    const { onFocus } = this.props;

    if (!this.state.hasBeenFocused) this.setState({hasBeenFocused: true});
    this.setState({focus: true});

    if (onFocus) onFocus();
  }

  onBlur = () => {
    const { onBlur } = this.props;

    this.setState({focus: false});

    if (onBlur) onBlur();
  }

  addSeparator = value => value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  onChange = e => {
    const { onChange, maxChars, numericThousandSeparator } = this.props;

    const { value } = e.target;
    const dispatch = maxChars === -1 || value.length <= maxChars || value.length < this.props.value.length;

    if (dispatch) {
      if (!numericThousandSeparator) return onChange({value, type: "input"})

      const valueFormated = this.addSeparator(value)
      this.setState({valueFormated})
      onChange({value: value.replace(/\s/g,''), type: "input"})
    }
  }

  renderLabelMaxChars() {
    const { value, maxChars, maxCharsText } = this.props;

    const current = value.length;
    const remaining = maxChars-current;

    const sentence = maxCharsText.split('{0}').join(current.toString()).split('{1}').join(maxChars.toString()).split('{2}').join(remaining.toString())

    const cnHelper = cn('help-text', {
      'help-text--orange': remaining < 20,
      'help-text--red': remaining <= 0,
    });

    return (
      <span className={cnHelper}>{sentence}</span>
    )
  }

  renderInput() {
    const { focus, valueFormated } = this.state;
    const { required, value, placeholder, disabled, autoFocus, style, type, numericThousandSeparator, label, onKeyPress, onEnterPress } = this.props;
    const shrink = !label || label === '' || focus || value !== '';

    const eventProps = Object.assign({},
      onKeyPress || onEnterPress ? { onKeyDown: this.onKeyPress } : null
    );

    return (
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        style={style}
        required={required}
        type={numericThousandSeparator ? 'text' : type}
        value={numericThousandSeparator ? valueFormated : value}
        placeholder={shrink ? placeholder : ''}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        disabled={disabled}
        {...eventProps}
      />
    )
  }

  renderTextarea() {
    const { focus } = this.state;
    const { required, value, placeholder, lines, disabled, style, autoFocus, label, onKeyPress, onEnterPress } = this.props
    const shrink = !label || label === '' || focus || value !== '';

    const eventProps = Object.assign({},
      onKeyPress || onEnterPress ? { onKeyDown: this.onKeyPress } : null
    );

    return (
      <textarea
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        required={required}
        style={style}
        value={ value }
        placeholder={shrink ? placeholder : ''}
        rows={lines}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        disabled={disabled}
        {...eventProps}
      />
    )
  }

  render() {
    const { required, value, label, errorText, errorRequiredText, error, useIcons, lines, className, maxChars, iconLeft, disabled, noOutline, size, labelAsOption } = this.props;
    const { hasBeenFocused, focus } = this.state;

    const hasError = error || (hasBeenFocused && required && (!value || value === "") && !focus);
    const success = required && value !== "" && !hasError;
    const shouldDisplayError = hasError && (errorText || errorRequiredText);

    const shrink = focus || value !== '';

    return (
      <div
        className={cn('bnc_field', 'bnc_field_input', `bnc_field_input--${size}` , className, {
          'bnc_field_input--icon': iconLeft,
          'bnc_field_input--icon-right': useIcons && (hasError || success),
          'bnc_field_input--has-label': label && label !== '',
          'bnc_field_input--shrink-label': shrink,
          'bnc_field_input--disabled': disabled,
          'bnc_field_input--error': hasError,
          'bnc_field_input--no-outline': noOutline,
          'bnc_field_input--multilines': lines > 1,
          'bnc_field_input--label-as-option': labelAsOption,
        })}
      >
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <label>
          {label && label !== '' && <span className="label">{label}{required && (<abbr>*</abbr>)}</span>}
          <span className="bnc_field_input_container">
					{ lines > 1
            ? this.renderTextarea()
            : this.renderInput()
          }
            { useIcons && hasError && <Icon label="times" className="icon icon--error" /> }
            { useIcons && success && <Icon label="check" className="icon icon--success" /> }
            { iconLeft && <span className="icon icon--left">{iconLeft}</span> }
				</span>
          {shouldDisplayError && (<span className="error">{errorText || errorRequiredText}</span>)}
          {maxChars > -1 && this.renderLabelMaxChars() }
        </label>
      </div>
    );
  }
}

Input.defaultProps = {
  required: false,
  disabled: false,
  placeholder: '',
  label: '',
  error: false,
  errorText: '',
  errorRequiredText: '',
  useIcons: true,
  className: '',
  lines: 1,
  maxChars: -1,
  maxCharsText: `{0}/{1}`,
  iconLeft: null,
  autoFocus: false,
  onEnterPress: null,
  onKeyPress: null,
  onchange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  style: {},
  type: "text",
  noOutline: false,
  size: "regular",
  labelAsOption: false,
  numericThousandSeparator: false,
}

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  errorRequiredText: PropTypes.string,
  useIcons: PropTypes.bool,
  lines: PropTypes.number,
  className: PropTypes.string,
  maxChars: PropTypes.number,
  maxCharsText: PropTypes.string,
  type: PropTypes.string,
  iconLeft: PropTypes.element,
  autoFocus: PropTypes.bool,
  onEnterPress: PropTypes.func,
  onKeyPress: PropTypes.func,
  onchange: PropTypes.func,
  style: PropTypes.shape({}),
  noOutline: PropTypes.bool,
  size: PropTypes.string,
  labelAsOption: PropTypes.bool,
  numericThousandSeparator: PropTypes.bool,
}


export default Input;
