export const downloadLinks: (
  downloads: Array<{ path: string; name: string }>,
  parent?: HTMLElement | null,
) => void = (downloads, parent) => {
  function download_next(i) {
    if (i >= downloads.length) {
      return;
    }
    const a = document.createElement('a');
    a.setAttribute('href', downloads[i].path);
    a.setAttribute('key', i);
    a.style.display = 'none';

    const parentElement = parent || document.body;

    // Use a.download if available, it prevents plugins from opening.
    a.setAttribute('download', downloads[i].name);
    // Add a to the doc for click to work.
    parentElement.appendChild(a);
    // Click
    a.click(); // The click method is supported by most browsers.
    // Delete the temporary link.
    parentElement.removeChild(a);

    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(() => {
      download_next(i + 1);
    }, 1000);
  }
  // Initiate the first download.
  download_next(0);
};
