import React, { cloneElement, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'kolkit';

import styles from './Tabs.module.scss';


const TabItem = ({ children, onClick, onChange, icon, className, selected, item, ...rest }) => {
  const cnTab = cn(styles.tab, className, {
    [styles.active]: selected,
  });

  return (
    <li
      role="tab"
      className={cnTab}
      onClick={onClick}
      aria-selected={selected}
      {...rest}
    >
      {item &&
      <span onClick={e => e.stopPropagation()} role="button">
        {cloneElement(item, onChange)}
      </span>}
      {icon && <Icon theme={icon === 'user' ? 'regular' : 'brand'} className={styles.icon} label={icon} />}
      {children}
    </li>
  );
};

TabItem.displayName = "TabItem";

TabItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.bool,
};

TabItem.defaultProps = {
  children: null,
  onClick: null,
  onChange: null,
  icon: null,
  className: undefined,
  selected: false
};

export default memo(TabItem);
