import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Icon } from 'kolkit';

import styles from './VideoPlayer.module.scss';

type Props = {
  video?: {
    url: string;
    content_type: string;
  };
  onChangePlayState?: (playing: boolean) => void;
};

const VideoPlayer: React.FC<Props> = ({ video, onChangePlayState }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleClickVideo = useCallback(() => {
    if (!videoRef.current) return null;

    if (videoRef.current.paused) {
      void videoRef.current.play().catch((error) => {
        console.error('Play request was interrupted', error);
        setIsVideoPlaying(false);
      });
    } else {
      videoRef.current.pause();
    }
  }, []);

  const handlePlayStart = useCallback(() => {
    setIsVideoPlaying(true);
    if (onChangePlayState) {
      onChangePlayState(true);
    }
  }, [onChangePlayState]);

  const handlePlayEnded = useCallback(() => {
    setIsVideoPlaying(false);
    if (onChangePlayState) {
      onChangePlayState(false);
    }
  }, [onChangePlayState]);

  useEffect(() => {
    let videoToClean: HTMLVideoElement | null = null;
    if (video && videoRef?.current) {
      videoRef.current.src = video.url;
      videoToClean = videoRef.current;
      setIsVideoPlaying(false);
    }
    return () => {
      if (videoToClean) {
        videoToClean.pause();
      }
    };
  }, [video, videoRef]);

  if (!video) return null;

  const cnRoot = cn(styles.root, { [styles.playing]: isVideoPlaying });

  return (
    <div className={cnRoot}>
      <div className={styles.videoContainer}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={videoRef}
          controls={isVideoPlaying}
          onPlay={handlePlayStart}
          onPause={handlePlayEnded}
          onEnded={handlePlayEnded}
        >
          <source src={video.url} type={video.content_type} />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        className={styles.playButton}
        role="button"
        onClick={handleClickVideo}
      />
      <Icon
        className={styles.playIcon}
        label={!isVideoPlaying ? 'play' : 'pause'}
        size="huge"
        fill="#FFFFFF"
        theme="solid"
        onClick={handleClickVideo}
      />
    </div>
  );
};

export default memo(VideoPlayer);
