const ICON_FOR = {
  profile: 'user',
  allPlatforms: 'layer-group',
  community_size: 'user-group',
  audience_location: 'earth-europe',
  community_ages: 'cake-candles',
  videos_avg_views_3m: 'clapperboard-play',
  impressions: 'eye',
  posts_engagement_rate: 'percent',
  moreFilters: 'bars-filter',
  spotlights_engagement_rate: 'percent',
  spotlights_views: 'eye',
};
const THEME_FOR = {
  posts_engagement_rate: 'solid',
  profile: 'solid',
  moreFilters: 'regular',
};
const SIZE_FOR = {
  posts_engagement_rate: 'small',
};

export const getIconProps: (props: { name?: string; network?: string }) => {
  icon: string;
  iconTheme: string;
  iconSize: string;
} = ({ name, network }) => {
  if (!name) {
    return {
      icon: network ? `${network}-color` : '',
      iconTheme: 'brand',
      iconSize: 'medium',
    };
  }
  return {
    icon: ICON_FOR[name],
    iconTheme: THEME_FOR[name] || 'regular',
    iconSize: SIZE_FOR[name] || 'medium',
  };
};
