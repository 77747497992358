import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatRelative } from 'date-fns';

import type { PropositionLog as PropositionLogType } from 'types/campaigns';
import { useDateFnsLocale } from 'utils/date-fns';
import useTimezone from 'utils/hooks/useTimezone';

import { Tooltip } from 'kolkit';
import CastingValidationButton from 'components/campaigns/Modules/CastingValidationButton';

import styles from '../TabWorkflow.module.scss';

type Props = {
  log: PropositionLogType;
  currentUserId?: number | string;
};

const PropositionLog: React.FC<Props> = ({ log, currentUserId }) => {
  const dateFnsLocale = useDateFnsLocale();
  const { timezoneFormatter, format: { slashDate } } = useTimezone();

  const {
    comment,
    user_name,
    contact_id,
    user_email,
    created_at,
    new_status_id,
    // old_status_id,
    new_status_name,
  } = log;
  const isMe = currentUserId && Number(currentUserId) === Number(contact_id);

  return (
    <div className={styles.timelineItem}>
      {comment && <div className={styles.timelineComment}>{comment}</div>}
      {new_status_id && (
        <div className={styles.timelineStatusChange}>
          <CastingValidationButton
            status_id={new_status_id}
            fallbackStatusName={new_status_name}
          />
        </div>
      )}
      <div className={styles.timelineAuthor}>
        <FormattedMessage id="global.label.by" />
        &nbsp;
        {isMe ? (
          <span className="text-lowercase">
            <FormattedMessage id="global.label.me" />
          </span>
        ) : (
          user_name || user_email
        )}
        &nbsp; - &nbsp;
        <Tooltip content={timezoneFormatter(created_at, slashDate)}>
          <span>
            {formatRelative(new Date(created_at), new Date(), {
              locale: dateFnsLocale,
            })}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

PropositionLog.displayName = 'PropositionLog';
export default PropositionLog;
