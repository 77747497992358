import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Tooltip } from 'kolkit';

import styles from './Tag.module.scss';


const Tag = ({ icon, label, className, onClick, theme, iconTheme, tooltip }) => {
  const cnTag = cn(styles.tag, className, styles[theme]);

  const cnIcon = cn(styles.icon);

  return (
    <Tooltip content={tooltip}>
      <div className={cnTag}>
        {icon && (
          <Icon
            className={cnIcon}
            width={14}
            label={icon}
            theme={iconTheme}
          />
        )}
        <span>{label}</span>
        {onClick && (
          <Icon
            className={styles.close}
            width={14}
            label="times"
            onClick={onClick}
          />
        )}
      </div>
    </Tooltip>
  )
};

Tag.displayName = 'Tag';

Tag.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'grey', 'reverse']),
  iconTheme: PropTypes.oneOf(['thin', 'brand']),
};

Tag.defaultProps = {
  icon: null,
  tooltip: null,
  className: undefined,
  onClick: null,
  theme: 'default',
  iconTheme: 'thin'
};

export default Tag;
