import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Select2, Item } from 'kolkit';

import MultiValueSelectionItem from '../components/MultiValueSelectionItem';

import styles from './SelectTag.module.scss';

let newSelected = [];

class SelectTags extends PureComponent {
	state = { hasBeenFocused: false };

	getItemFromValue = value => {
		const { dataset } = this.props;

		const ob = dataset.find(d => String(d?.value) === String(value));

		if (!ob) {
			console.error('[SelectTags] Value', value, 'not found in dataset'); // eslint-disable-line
			return null;
		}

		return ob;
	};

	onChangeSelect = ({ value }) => {
		this.onFocus();
		const { onChange, selected, id } = this.props;

		if (!value || value === '') return null;

		const ob = this.getItemFromValue(value);

		if (!ob) return null;

		if (!selected.includes(ob.value)) {
			newSelected = [...selected, ob.value];
		} else {
			newSelected = selected.filter(s => s !== ob.value);
		}

		onChange({
			item: ob,
			selected: newSelected,
			id,
			type: 'select-tags',
		});
	};

	onRemoveSelection = (value) => {
		this.onChangeSelect({ value });
	}

	onFocus = () => {
		if (!this.state.hasBeenFocused) (
			this.setState({ hasBeenFocused: true })
		);
	};

	onTabPressed = e => {
		if (e.value !== null && e.value !== '') {
			e.preventDefault();
		}
	};

	renderItem = item => {
		const cnItem = cn(styles.item, item?.className);
		return (
			<Item className={cnItem}>
				{item?.label}
			</Item>
		)
	}

	render() {
		const {className, selected, dataset, required, errorRequiredText, disabled, maxTags, sort, defaultOptionLabel, hasSearch } = this.props;
		const { hasBeenFocused } = this.state;

		const isFull = maxTags > 0 && selected.length >= maxTags;
		const getDataFromDatasetById = s => dataset.find(d => d.value === s);

		const cnSelectTag = cn(styles.selectTag, className);

		return (
			<div className={cnSelectTag}>
				<Select2
					className={styles.select}
					size="small"
					selected={selected}
					placeholder={defaultOptionLabel}
					options={dataset}
					sort={sort}
					renderItem={this.renderItem}
					onSelectItem={this.onChangeSelect}
					search={hasSearch}
					inputSearchPlaceholder="Search"
					searchKey="label"
					selectedOptionKey="value"
					disabled={disabled || isFull}
					fullWidth
					width={256}
				/>
				{selected?.length > 0 && (
					<ul className={styles.selectTagContainer}>
						{selected.map(s => (
							<MultiValueSelectionItem
								key={`select-tag-${s}`}
								disabled={disabled}
								value={s}
								onRemove={this.onRemoveSelection}
							>
								{ getDataFromDatasetById(s)?.label || '' }
							</MultiValueSelectionItem>
						))}
					</ul>
				)}
				{hasBeenFocused && required && (selected.length === 0) &&
				<span className="error">{errorRequiredText}</span>}
			</div>
		);
	}
}

SelectTags.defaultProps = {
	id: null,
	disabled: false,
	required: false,
	errorRequiredText: `This field is required`,
	defaultOptionLabel: '',
	className: ``,
	sort: true,
	selected: [],
	maxTags: -1,
	onChange: null,
};

SelectTags.propTypes = {
	id: PropTypes.string,
	selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	dataset: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
		react: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		icon: PropTypes.element,
		disabled: PropTypes.bool,
	})).isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	errorRequiredText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	defaultOptionLabel: PropTypes.string,
	className: PropTypes.string,
	sort: PropTypes.bool,
	maxTags: PropTypes.number,
};

export default SelectTags;
