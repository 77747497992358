import React, { PureComponent } from 'react'
import cn from 'classnames'

import '../../css/scss/ui/quality-score.scss'

class QualityScore extends PureComponent {
  render() {
    const { qualityScore } = this.props
    const { radiusSize } = this.props;
    const size = radiusSize || 35;
    const modifiedQualityScore = Number(qualityScore) || 0

    const radius = size / 2;
    const sizeSvg = size + 2;
    const center = sizeSvg / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * ( 1 - modifiedQualityScore / 100 );

    const { className } = this.props;

    return (
      <div className="quality-score" style={{width : sizeSvg, height : sizeSvg}}>
        <div className="quality-score-inner">
          <span className={className}>{qualityScore !== null ? `${Math.floor(qualityScore)}` : '-'}</span>
        </div>
        <svg style={{transform: 'rotate(-90deg)'}} width={sizeSvg} height={sizeSvg}>
          <circle cx={center} cy={center} r={radius} fill="none" stroke="#DDDDDD" strokeWidth="2"/>
          <circle
            className={cn(
              'quality-score-circle', {
              'quality-score-circle--green': modifiedQualityScore >= 85,
              'quality-score-circle--light-green': modifiedQualityScore >= 70 && modifiedQualityScore < 85,
              'quality-score-circle--yellow': modifiedQualityScore >= 60 && modifiedQualityScore < 70,
              'quality-score-circle--orange': modifiedQualityScore >= 50 && modifiedQualityScore < 60,
              'quality-score-circle--red': modifiedQualityScore < 50,
            })}
            cx={center}
            cy={center}
            r={radius}
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          />
        </svg>
      </div>
    )
  }
}

export default QualityScore
