import { networks } from 'config/filters';
import { useSelector } from 'utils/redux';

const isEqual = (array1, array2) =>
  array1.every(item => array2.includes(item));

const useActiveFilter = (network, onChange) => {
  const filterTarget = 'activeFilter';
  const value = useSelector(({ user }) => user.filters.activeFilter);

  let checked = value.includes(network);
  let activeFilterArray = value;

  const handleChange = () => {
    checked = checked ?
      activeFilterArray = activeFilterArray.filter(item => item !== network):
      activeFilterArray = [...activeFilterArray, network]
    ;

    onChange({
      field: filterTarget,
      value: activeFilterArray,
      network
    })
  };

  return {
    checked,
    getSelected: value,
    handleChange,
  }
};

const useAllActiveFilter = onChange => {
  const filterTarget = 'activeFilter';
  const value = useSelector(({ user }) => user.filters.activeFilter);

  let checked = isEqual(networks, value);
  let activeFilterArray = value;

  const handleChange = () => {
    checked = checked
      ? activeFilterArray = []
      : activeFilterArray = [...networks]
    ;

    onChange({
      field: filterTarget,
      value: activeFilterArray,
    })
  };

  return {
    checked,
    getSelected: value,
    handleChange,
  }
};

export {
  useActiveFilter,
  useAllActiveFilter,
};
