import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { InputCheckGroup } from 'components';


const AdminState = ({ onChange, options, value }) => {
  const filterTarget = 'state';

  const handleChange = useCallback(
    ({ item, selected }) => onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      admin: true,
      forceTriggerSearch: true,
    }),
    [onChange, filterTarget]
  );

  return (
    <InputCheckGroup
      options={options}
      selectedOption={value[filterTarget]}
      onChange={handleChange}
    />
  )
};

AdminState.displayName = 'AdminState';

AdminState.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default AdminState;
