import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl-phraseapp';
import {
  Checkbox2,
  Chips2,
  Icon,
  Item,
  Loader,
} from 'kolkit';

import { useDispatch, useSelector } from 'utils/redux';
import { fetchLicences, LicenceEdition } from 'slices/organization.slice';

import { DataTable } from 'components/ui';
import useCompanyLicences from 'utils/hooks/useCompanyLicences';
import useTimezone from 'utils/hooks/useTimezone';

import renderLicenceDetails from '../utils/renderLicenceDetails';

import { COLUMNS, GRID_TEMPLATE_COLUMNS } from './config';

import styles from './ChooseLicences.module.scss';

type Props = {
  userId?: number | null;
  onChange?: (modifications: Record<number, LicenceEdition>) => void;
  disabled?: boolean
};

/**
 *
 * @param userId: The current user's id if updating an user; Or zero if creating a new user
 * @returns
 */

const ChooseLicences: React.FC<Props> = ({ userId: _userId, onChange, disabled }) => {
  const isEditionEnabled = !!onChange;
  const userId = _userId || 0;

  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    format: { slashDate },
    dateRangeFormatter,
  } = useTimezone();

  const { loading, licences } = useSelector(
    ({ organization: { licences, loading } }) => ({
      loading: loading.includes(fetchLicences.typePrefix),
      licences,
    }),
  );

  const [{ licencesDataSet, modifications }, dispatchModification] = useCompanyLicences();

  const columns = COLUMNS.map((col) => ({
    ...col,
    label: intl.formatMessage({ id: col.label }),
  }));

  const handleClickLicence = useCallback(
    ({ id, addListeningLicence = false }) => {
      const licenceGroup = licencesDataSet.find(
        ({ id: licenceDataSetId }) => id === licenceDataSetId,
      );
      const assignedLicence = licenceGroup?.assigned.find(
        (licence) => licence.contact_id === userId,
      );
      const isAddition = addListeningLicence || !assignedLicence;

      if (!licenceGroup) return null;

      // Use zero as a placeholder for the user ID if the user is not created yet
      if (isAddition && licenceGroup?.free?.length) {
        dispatchModification({
          type: 'edit',
          contact_id: userId || 0,
          id: licenceGroup.free[0], // assign the first free licence to the user
        });
      } else {
        // Remove last assigned licence to user
        const lastAssignedLicence = licenceGroup.assigned
          ?.filter((assignation) => assignation.contact_id === userId)
          ?.pop();
        if (!lastAssignedLicence) return null;
        dispatchModification({
          type: 'edit',
          contact_id: null, // Use null as user ID to desassign an user from a licence
          id: lastAssignedLicence.licenceId,
        });
      }
    },
    [dispatchModification, licencesDataSet, userId],
  );

  const renderListeningCounter = useCallback(
    (licence: (typeof licencesDataSet)[number]) => {
      const handleClickMinusListening = () =>
        handleClickLicence({
          id: licence.id,
          addListeningLicence: false,
        });
      const handleClickPlusListening = () =>
        handleClickLicence({
          id: licence.id,
          addListeningLicence: true,
        });
      const disabledPlus = licence.free.length === 0;
      const disabledMinus = !licence.assigned.find(
        ({ contact_id }) => contact_id === (userId || 0),
      );
      return (
        <div className={styles.listeningButtons}>
          <Icon
            isButton
            label="circle-minus"
            fill="#0061AC"
            theme="regular"
            onClick={handleClickMinusListening}
            disabled={disabledMinus || disabled}
            className={styles.minusMargin}
          />
          <span className={disabledMinus && disabledPlus ? 'greyedOut' : ''}>
            {licence.assigned.filter((l) => l.contact_id === userId).length}
          </span>
          <Icon
            isButton
            label="circle-plus"
            fill="#0061AC"
            theme="regular"
            onClick={handleClickPlusListening}
            disabled={disabledPlus || disabled}
          />
        </div>
      );
    },
    [handleClickLicence, userId, disabled],
  );

  const renderItem = useCallback(
    (licence: (typeof licencesDataSet)[number]) => {
      const isSelected = !!licence.assigned.find(
        ({ contact_id }) => contact_id === (userId || 0),
      );
      const isDisabled = !isSelected && !licence.free.length;

      const cnRow = cn(styles.row, {
        [styles.isEditionEnabled]: isEditionEnabled,
        [styles.selected]: isSelected,
      });
      const cnCell = cn({
        greyedOut: !licence.is_active,
      });

      return (
        <div className={cnRow}>
          <div className={`flex aic ${cnCell}`}>
            {isEditionEnabled ? (
              <Checkbox2
                id={licence.id}
                label={intl.formatMessage(
                  { id: `licence.${licence.name}` },
                )}
                checked={isSelected}
                onChange={handleClickLicence}
                disabled={isDisabled || disabled}
              />
            ) : (
              <span>
                {intl.formatMessage(
                { id: `licence.${licence.name}` },
                )}
              </span>
            )}
          </div>
          <div className={cnCell}>
            <span>
              {isEditionEnabled &&
              licence.name.toLowerCase().includes('listening')
                ? renderListeningCounter(licence)
                : renderLicenceDetails(licence, intl)}
            </span>
          </div>
          <div
            className={cnCell}
            style={{
              minWidth: COLUMNS[2].minWidth,
            }}
          >
            <span className={styles.timePeriod}>
              {dateRangeFormatter({
                startDate: licence.start_date,
                endDate: licence.end_date,
                dateFormat: slashDate,
              })}
            </span>
          </div>
          <div className={styles.availability}>
            <span className={cnCell}>
              <Chips2
                label={licence.free.length.toString()}
                className={cn(styles.chip, {
                  [styles.unavailable]: !licence.free.length,
                })}
              />
              {` ${intl.formatMessage({ id: 'global.label.of' })} `}
              {licence.free.length + licence.assigned.length}
            </span>
            {!licence.is_active && (
              <Item
                icon="times-circle"
                iconTheme="solid"
                iconWidth={16}
                iconFill="#E93030"
                className={styles.expiredLabel}
              >
                {intl.formatMessage({
                  id: 'global.settings.expired',
                })}
              </Item>
            )}
          </div>
        </div>
      );
    },
    [
      isEditionEnabled,
      handleClickLicence,
      renderListeningCounter,
      intl,
      dateRangeFormatter,
      slashDate,
      userId,
      disabled,
    ],
  );

  useEffect(() => {
    void dispatch(fetchLicences());
  }, [dispatch]);

  useEffect(() => {
    dispatchModification({ type: 'init' as const, licences });
  }, [licences, dispatchModification]);

  useEffect(() => {
    // dispatch modification back to parent after changes
    if (onChange) onChange(modifications);
  }, [onChange, modifications]);

  return (
    <>
      {loading && <Loader full background="rgba(255, 255, 255, .8)" />}

      <DataTable
        columns={columns}
        data={licencesDataSet.filter(
          (licence) => !isEditionEnabled || licence.is_active,
        )}
        renderItem={renderItem}
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
        noPadding
        forceTopBorder
        headerClassName={cn(styles.header, {
          [styles.isEditionEnabled]: isEditionEnabled,
        })}
      />
    </>
  );
};

export default ChooseLicences;
