import React, {
  ReactElement,
  memo,
  cloneElement,
  useCallback,
  useMemo,
  useState,
  CSSProperties,
} from 'react';
import cn from 'classnames';
import MuiPopover,  { PopperPlacementType } from '@mui/material/Popper';
import { Icon } from 'kolkit';

import styles from './Popover.module.scss';

interface Props {
  theme?: string | undefined;
  open?: boolean;
  onClickDisabled?: boolean;
  id: string;
  message: string | ReactElement;
  onClose: () => void;
  trigger: ReactElement;
  placement?: PopperPlacementType;
  className?: string;
  full?: boolean;
  isTooltip?: boolean;
}

const Popover: React.FC<Props> = ({
  theme = 'default',
  open,
  id,
  message,
  onClose,
  trigger,
  placement,
  onClickDisabled,
  className,
  full,
  isTooltip,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const popoverId = useMemo(() => (isOpen ? id : undefined), [isOpen, id]);

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
      if (onClose) onClose();
    },
    [onClose]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event?.currentTarget);
    },
    [anchorEl]
  );

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isTooltip) setAnchorEl(event?.currentTarget);
    },
    [isTooltip]
  );

  const arrowStyle = useMemo<CSSProperties | undefined>( // TODO: left and right
    () => ({
      bottom: placement === 'top' ? 0 : 'auto',
      top: placement === 'bottom' ? 0 : 'auto',
    }),
    [placement]
  );

  const renderTrigger = useMemo(
    () => {
      return cloneElement(trigger, {
        'aria-describedby': popoverId,
        ref: open ? setAnchorEl : null,
        onClick: !onClickDisabled ? handleClick : undefined,
        onMouseEnter: handlePopoverOpen,
      });
    },
    [trigger, setAnchorEl, popoverId, handleClick, open, onClickDisabled, handlePopoverOpen]
  );

  const cnContainer = cn(
    styles.container,
    styles[theme],
    {[styles.full]: full},
    className
  );

  return (
    <div>
      {renderTrigger}
      <MuiPopover
        id={popoverId}
        placement={placement}
        modifiers={[
          {
           name: 'arrow',
           enabled: true,
           options: {
             element: arrowRef,
            }
          }
        ]}
        open={isOpen}
        anchorEl={anchorEl}
        className={styles.root}
        {...rest}
      >
        <div className={cnContainer}>
          <span
            className={styles.arrow}
            ref={setArrowRef}
            style={arrowStyle}
          />
          {message}
          <Icon
            className={styles.closeButton}
            label="times"
            isButton
            onClick={handleClose}
            size="small"
            fill={theme === 'default' ? 'white' : 'var(--color-dominant-on-dominant)'}
          />
        </div>
      </MuiPopover>
    </div>
  );
};

Popover.displayName = 'Popover';

Popover.defaultProps = {
  theme: 'default',
  full: false,
  open: false,
  isTooltip: false,
  placement: 'top',
  onClickDisabled: true,
  className: '',
}

export default memo(Popover);
