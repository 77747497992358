import { useState, useMemo, useCallback, useEffect } from 'react';
import orderBy from 'lodash.orderby';
import get from 'lodash.get';

const SORT_DIRECTION_ASC = 'asc';
const SORT_DIRECTION_DESC = 'desc';

const toggleDirection = direction =>
  direction === SORT_DIRECTION_ASC ? SORT_DIRECTION_DESC : SORT_DIRECTION_ASC;

const useTableSort = ({
  data,
  defaultSortedColumn,
  defaultSortDirection,
  extractSortValue,
}) => {
  const [sortedColumn, setSortedColumn] = useState(defaultSortedColumn);
  const [currentDirection, setCurrentDirection] = useState(
    defaultSortDirection || SORT_DIRECTION_ASC,
  );

  const changeSortDirection = useCallback(
    columnToSort => {
      if (columnToSort === sortedColumn) {
        return setCurrentDirection(toggleDirection(currentDirection));
      }
      setSortedColumn(columnToSort);
      return setCurrentDirection(defaultSortDirection || SORT_DIRECTION_ASC);
    },
    [defaultSortDirection, sortedColumn, currentDirection, setCurrentDirection],
  );

  const sortedData = useMemo(
    () =>
      sortedColumn
        ? orderBy(
          data || [],
          item => {
            const value = extractSortValue
              ? extractSortValue(item, sortedColumn)
              : get(item, sortedColumn);
            if (typeof value === 'string') {
              return value.toLocaleLowerCase();
            }
            if (Array.isArray(value)) {
              return value.length;
            }
            return value || '';
          },
          [currentDirection],
        )
        : data || [],
    [data, sortedColumn, currentDirection, extractSortValue],
  );

  useEffect(() => {
    setSortedColumn(defaultSortedColumn);
    setCurrentDirection(defaultSortDirection);
  }, [defaultSortDirection, defaultSortedColumn]);

  return {
    sortedColumn,
    sortedData,
    direction: currentDirection,
    changeSortDirection,
  };
};

export default useTableSort;
