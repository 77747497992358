import React, { memo } from 'react';
import { Icon } from 'kolkit';

type Props = {
  value: number;
  width?: number;
};

const AudienceCredibilityDot: React.FC<Props> = ({ value, width=16 }) => {
  let fill = 'var(--color-score-excellent)';
  if (value < 85) {
    fill = 'var(--color-score-hight)';
  }
  if (value < 70) {
    fill = 'var(--color-score-average)';
  }
  if (value < 60) {
    fill = 'var(--color-score-bad)';
  }
  if (value < 50) {
    fill = 'var(--color-score-poor)';
  }

  return <Icon label="circle" theme="solid" fill={fill} width={width} />;
};

export default memo(AudienceCredibilityDot);
