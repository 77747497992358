import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { Input2, Modal2, Text } from 'kolkit';

import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'utils/redux';
import renderModal from 'utils/HOCs/renderModal';
import { USER_ROLES } from 'constants/userRoles';

import {
  LicenceEdition,
  Member,
  MemberForm,
  saveMember,
} from 'slices/organization.slice';

import { Divider } from 'components/ui';
import { loadUserParamsUpdated } from 'actions/user';
import { ErrorResponse } from 'api/types';
import toast from 'utils/toast';
import ChooseTeam from '../ChooseTeam';
import ChooseLicences from '../ChooseLicences';
import RoleAndRules from '../RoleAndRules';

import styles from './EditMemberModal.module.scss';

const intialMemberState: MemberForm = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  position_title: '',
  team: undefined,
  role: USER_ROLES.MAKER,
  teams_access_ids: [],
};

const REQUIRED_INPUT_FIELDS: (keyof MemberForm)[] = [
  'first_name',
  'last_name',
  'email',
  `position_title`,
];

const MODAL_ID = 'addOrganizationMemberModal';

const EditMemberModal: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { show, off, data } = useModal<Member>(MODAL_ID);

  const [member, setMember] = useState<MemberForm>(intialMemberState);
  const [errors, setErrors] = useState<
    Partial<Record<keyof MemberForm, boolean>>
  >({});
  const [licencesModifications, setLicenceModifications] = useState<
    LicenceEdition[]
  >([]);
  const [teamOrRoleChanged, setTeamOrRoleChanged] = useState(false);

  const { loaders, profileId, role } = useSelector(
    ({ user, organization }) => ({
      profileId: user?.profile?.id,
      loaders: organization?.loading,
      role: user?.profile.userRole,
    }),
  );

  useEffect(() => {
    if (data) {
      setMember(data);
    }
  }, [data]);

  const loading = useMemo(
    () => loaders.includes('organization/saveMember'),
    [loaders],
  );

  const disabled = useMemo(
    () =>
      (role === 'manager' || role === 'maker') && member?.role === 'super_user',
    [role, member],
  );

  const handleChangeInput = useCallback<
    (params: { name?: string; value: string }) => void
  >(({ value, name }) => {
    if (!name) return null;
    if (name === 'phone') {
      return setMember((prev) => ({
        ...prev,
        [name]: value.replace(/\D+/g, ''),
      }));
    }
    setErrors((prev) => ({ ...prev, [name]: false }));
    return setMember((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleChangeTeam = useCallback((team) => {
    setTeamOrRoleChanged(true);
    setMember((prev) => ({
      ...prev,
      team,
      teams_access_ids: [],
    }));
  }, []);

  const handleChangeRole = useCallback(
    (role) => {
      setTeamOrRoleChanged(true);
      setMember((prev) => ({ ...prev, role }))
    },
    [],
  );

  const handleChangeTeamAccess = useCallback(
    (teamsIds) => {
      setTeamOrRoleChanged(true);
      setMember((prev) => ({ ...prev, teams_access_ids: teamsIds }))
    },
    []
  );

  const handleChangeLicences = useCallback(
    (modifications: Record<number, LicenceEdition>) => {
      setLicenceModifications(Object.values(modifications));
    },
    [],
  );

  const resetModal = useCallback(() => {
    setMember(intialMemberState);
    setTeamOrRoleChanged(false);
    setErrors({});
  }, []);

  const handleClickSave = useCallback(() => {
    let cancelSave = false;

    REQUIRED_INPUT_FIELDS.forEach((requiredField) => {
      const hasError = !member[requiredField]?.toString()?.trim();
      setErrors((prev) => ({ ...prev, [requiredField]: hasError }));
      cancelSave = cancelSave || hasError;
    });

    if (cancelSave) return null;

    return dispatch(saveMember({ member, licencesModifications, teamOrRoleChanged }))
      .unwrap()
      .then(
        async () => {
          if (Number(member.id) === Number(profileId)) {
            await dispatch(loadUserParamsUpdated());
          }
          toast(
            intl.formatMessage(
              {
                id: member.id
                  ? 'settings.organization.toast.saveMember.updated'
                  : 'settings.organization.toast.saveMember.created',
              },
              {
                name: `${member.first_name} ${member.last_name}`,
              },
            ),
            {
              title: intl.formatMessage({
                id: 'general.success',
              }),
              type: 'success',
            },
          );
          off();
        },
        (error: ErrorResponse) => {
          if (error.status === 422) {
            setErrors((prev) => ({ ...prev, email: true }));
            toast(
              intl.formatMessage({
                id: 'settings.organization.toast.saveMember.error',
              }),
              {
                type: 'error',
              },
            );
          } else {
            toast(
              intl.formatMessage({
                id: 'global.errorMessage.support',
              }),
              {
                type: 'error',
              },
            );
          }
        },
      );
  }, [dispatch, member, profileId, licencesModifications, teamOrRoleChanged, off, intl]);

  const modalActions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: 'global.cta.save' }),
        onClick: handleClickSave,
        disabled:
          loading ||
          Object.values(errors).some((hasError) => hasError) ||
          disabled,
        dataId: 'settings-user-save',
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: off,
      },
    }),
    [loading, errors, intl, off, handleClickSave, disabled],
  );

  return (
    <Modal2
      title={intl.formatMessage({ id: 'modal.organization.addMember.title' })}
      on={show}
      TransitionProps={{
        onExited: resetModal,
      }}
      onClick={off}
      action={modalActions}
      size="large"
      loading={loading}
    >
      <Text className={styles.title}>
        {intl.formatMessage({
          id: 'modal.organization.addMember.personalInformations',
        })}
      </Text>
      <div className={styles.grid}>
        <div className={styles.col}>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.field.firstName',
            })}
            *
          </span>
          <Input2
            value={member.first_name}
            placeholder={intl.formatMessage({
              id: 'global.placeholder.firstName',
            })}
            name="first_name"
            onChange={handleChangeInput}
            errorMessage={intl.formatMessage({
              id: 'global.messages.error.required',
            })}
            error={errors.first_name}
            fullWidth
            required
            disabled={loading || disabled}
          />
        </div>
        <div className={styles.col}>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.field.lastName',
            })}
            *
          </span>
          <Input2
            value={member.last_name}
            placeholder={intl.formatMessage({
              id: 'global.placeholder.lastName',
            })}
            name="last_name"
            onChange={handleChangeInput}
            errorMessage={intl.formatMessage({
              id: 'global.messages.error.required',
            })}
            error={errors.last_name}
            fullWidth
            required
            disabled={loading || disabled}
          />
        </div>
        <div className={styles.col}>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.field.email',
            })}
            *
          </span>
          <Input2
            value={member.email}
            placeholder={intl.formatMessage({
              id: 'global.placeholder.email',
            })}
            name="email"
            onChange={handleChangeInput}
            errorMessage={intl.formatMessage({
              id: 'global.messages.error.required',
            })}
            error={errors.email}
            fullWidth
            required
            disabled={loading || disabled}
          />
        </div>
        <div className={styles.col}>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.field.phoneNumber',
            })}
          </span>
          <Input2
            value={member.phone}
            placeholder={intl.formatMessage({
              id: 'global.placeholder.phoneNumber',
            })}
            name="phone"
            onChange={handleChangeInput}
            fullWidth
            disabled={loading || disabled}
          />
        </div>
        <div className={styles.col_2}>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.field.position_title',
            })}
            *
          </span>
          <Input2
            value={member.position_title}
            placeholder={intl.formatMessage({
              id: 'global.placeholder.position_title',
            })}
            name="position_title"
            onChange={handleChangeInput}
            errorMessage={intl.formatMessage({
              id: 'global.messages.error.required',
            })}
            error={errors.position_title}
            fullWidth
            required
            disabled={loading || disabled}
          />
        </div>
        <div className={styles.col_2}>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.team.title',
            })}
          </span>
          <span>
            {intl.formatMessage({
              id: 'modal.organization.addMember.team.subtitle',
            })}
          </span>
          <div className={styles.team}>
            <ChooseTeam
              team={member.team}
              onChange={handleChangeTeam}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <Divider />
      <Text className={styles.title}>
        {intl.formatMessage({
          id: 'modal.organization.addMember.roleAndRule',
        })}
      </Text>
      <RoleAndRules
        userId={member.id}
        selectedRole={member.role || USER_ROLES.MAKER}
        onChangeRole={handleChangeRole}
        selectedTeams={member.teams_access_ids}
        onChangeTeamAccess={handleChangeTeamAccess}
        teamId={member.team?.id}
        disabled={disabled}
      />

      <Divider />
      <Text className={styles.title}>
        {intl.formatMessage({
          id: 'modal.organization.addMember.licenseToAssign',
        })}
      </Text>
      <ChooseLicences
        userId={member.id}
        onChange={handleChangeLicences}
        disabled={disabled}
      />
    </Modal2>
  );
};

export default renderModal(MODAL_ID, EditMemberModal);
