import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { rawTimeZones } from "@vvo/tzdb";
import { Button3, Grid, Input2, Select2, Spaces, Chips2, Loader, Radios, Item } from 'kolkit';
import isEmail from 'validator/lib/isEmail';

import {
  updateEmailOnRequest,
  updateInfoRequest,
  updatePasswordOnRequest,
  updateAreaSettings,
} from 'actions/user';
import { useSelector, useDispatch } from 'utils/redux';
import { getUserRightsOnTeam } from 'utils/rights';
import toast from 'utils/toast';
import useLoading from 'utils/hooks/useLoading';

import Divider from 'components/ui/Divider';
import { LocalesSwitcher } from 'components/modules';
import Tile from 'components/ui/Tile';

import currencies from 'config/currencies';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';

import DoubleAuthenticationButton from 'components/modules/DoubleAuthentication/DoubleAuthenticationButton';
import styles from './MyAccount.module.scss';

const MyAccount = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { loading: areaSettingsLoading } = useLoading('updateAreaSettings');

  const { isAdmin, userRights, firstName, lastName, phone, email, locale, timezone, currency, dateFormat } = useSelector(
    ({
       env,
       user: {
         role,
         profile: { firstName, lastName, phone, email, admin },
       },
     }) => ({
      firstName,
      lastName,
      phone,
      email,
      locale: env.locale,
      timezone: env.timezone,
      currency: env.currency.value,
      dateFormat: env.dateFormat,
      userRights: getUserRightsOnTeam(role),
      isAdmin: admin,
    }),
  );

  const [state, setState] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phone || '',
    nameUpdateStatus: '',
    nameUpdateLoading: false,
    email: email || '',
    emailUpdateStatus: '',
    emailUpdateLoading: false,
    password: '',
    newPassword: '',
    confirmPassword: '',
    passwordUpdateStatus: '',
    passwordUpdateLoading: false,
  });

  const handleChangeInput = useCallback(
    ({ value, name }) => {
      if (name === 'phone') {
        return setState(prev => ({ ...prev, [name]: value.replace(/\D+/g, '') }));
      }
      return setState(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const onRequestChangeName = useCallback(
    async () => {
      const { firstName, lastName, phone } = state;

      setState(prev => ({ ...prev, nameUpdateLoading: true }));

      const res = await dispatch(updateInfoRequest(firstName, lastName, phone));

      setState(prev => ({ ...prev, nameUpdateLoading: false }));

      if (res?.error) {
        setState(prev => ({ ...prev, nameUpdateStatus: 'failure' }));
      } else {
        toast(formatMessage({ id: "settings.account.success.name"}), { type: 'success' });
        setState(prev => ({ ...prev, nameUpdateStatus: 'success' }));
      }
    },
    [dispatch, formatMessage, state]
  );

  const onRequestChangeEmail = useCallback(
    async () => {
      const { email } = state;

      setState(prev => ({ ...prev, emailUpdateLoading: true }));

      const res = await dispatch(updateEmailOnRequest(email));

      setState(prev => ({ ...prev, emailUpdateLoading: false }));

      if (res.error) {
        setState(prev => ({ ...prev, emailUpdateStatus: 'failure' }));
      } else {
        toast(formatMessage({ id: "settings.account.success.email"}), { type: 'success' });
        setState(prev => ({ ...prev, emailUpdateStatus: 'success' }));
      }
    },
    [dispatch, formatMessage, state]
  );

  const updatePassword = useCallback(
    async () => {
      const { password, newPassword, confirmPassword } = state;

      if (newPassword !== confirmPassword) {
        setState(prev => ({ ...prev, passwordUpdateStatus: 'failure' }));
        return null;
      }

      if (newPassword.length < MINIMUM_PASSWORD_LENGTH) {
        setState(prev => ({ ...prev, passwordUpdateStatus: 'tooShort' }));
        return null;
      }

      setState(prev => ({ ...prev, passwordUpdateLoading: true }));

      const res = await dispatch(
        updatePasswordOnRequest(password, newPassword, confirmPassword),
      );

      setState(prev => ({ ...prev, passwordUpdateLoading: false }));

      if (res.error) {
        setState(prev => ({ ...prev, passwordUpdateStatus: 'failure' }));
      } else {
        toast(formatMessage({ id: "settings.account.success.password"}), { type: 'success' });
        setState(prev => ({
          ...prev,
          passwordUpdateStatus: 'success',
          password: '',
          newPassword: '',
          confirmPassword: '',
        }));
      }
    },
    [dispatch, formatMessage, state]
  );

  const dateDataset = useMemo(
    () => ['dd/mm/yyyy', 'mm/dd/yyyy'].map(format => ({
      label: <span translate="no">{format}</span>,
      value: format,
    })),
    []
  );

  const handleChangeDate = useCallback(
    ({ target: { value } }) => {
      dispatch(updateAreaSettings({
        key: 'dateFormat',
        data: value,
      }))
    },
    [dispatch]
  );

  const tzDataset = useMemo(
    () => {
      /* rawTimeZones: array of
        {
          name: "America/Dawson_Creek",
          alternativeName: "Mountain Time",
          group: ["America/Creston", "America/Dawson_Creek", "America/Fort_Nelson"],
          continentCode: "NA",
          continentName: "North America",
          countryName: "Canada",
          countryCode: "CA",
          mainCities: ["Fort St. John", "Creston", "Fort Nelson"],
          rawOffsetInMinutes: -420,
          abbreviation: "MST",
          rawFormat: "-07:00 Mountain Time - Fort St. John, Creston, Fort Nelson",
          currentTimeOffsetInMinutes: -420,
          currentTimeFormat: "-07:00 Mountain Time - Fort St. John, Creston"
        }
      */
      return rawTimeZones
        ?.map(({ name, mainCities, countryCode, rawFormat }) => {
          const regionNamesIntl = new Intl.DisplayNames([locale?.split('-')?.[0]], { type: 'region' });
          const countryNameTranslated = regionNamesIntl?.of(countryCode)
          const label = `${countryNameTranslated} (${mainCities.join(", ")})`;

          return {
            label,
            value: name,
            toSearch: `${label} ${name}`,
            offset: rawFormat.substring(0, 6),
          }
        })
        ?.sort((current, next) => current?.label?.localeCompare(next?.label))
    },
    [locale]
  );

  const handleChangeTz= useCallback(
    ({ value }) => {
      dispatch(updateAreaSettings({
        key: 'timezone',
        data: value,
      }))
    },
    [dispatch],
  );

  const handleChangeCurrency = useCallback(
    ({ value }) => {
      dispatch(updateAreaSettings({
        key: 'currency',
        data: value,
      }))
    },
    [dispatch],
  );

  const renderTimezoneOption = useCallback(
    ({ value, label, offset}) => {
      return (
      <li key={value} className={styles.selectOption}>
        <Item fontSize="1rem" padding="0">{label}</Item>
        <Chips2 label={offset} />
      </li>
    )},
    [],
  );

  const renderCurrencyOption = useCallback(
    ({ label, value, symbol }) => (
      <li key={value} className={styles.selectOption}>
        <Item fontSize="1rem" padding="0">{`${value} - ${label}`}</Item>
        <Chips2 label={symbol} />
      </li>
    ),
    [],
  );

  const isUpdatePasswordButtonDisabled =
    !!state.password &&
    (!state.password?.trim()?.length ||
      !state.newPassword?.trim()?.length ||
      !state.confirmPassword?.trim()?.length ||
      state.confirmPassword !== state.newPassword);

  const isUpdateEmailButtonDisabled =
    !userRights.updateEmail ||
    !state.email?.trim()?.length ||
    !isEmail(state.email);

  const isUpdateNameButtonDisabled =
    !state.lastName?.trim()?.length || !state.firstName?.trim().length;

  return (
      <Grid>
        <div size={4} className="flex aifs">
          <Tile className={styles.tile}>
            {areaSettingsLoading && <Loader full background="rgba(255, 255, 255, .8)" />}
            <h3 className={styles.title}>
              <FormattedMessage id="settings.account.languageAndRegion" />
            </h3>
            <h6 className={styles.subtitle}>
              <FormattedMessage id="settings.account.changeLanguage" />
            </h6>
            <Spaces top={12} />
            <LocalesSwitcher />
            <Spaces top={16} />
            <h6 className={styles.subtitle}>
              <FormattedMessage
                id="settings.account.timezone.label"
                defaultMessage="Time zone [UTC]"
              />
            </h6>
            <Select2
              options={tzDataset}
              onSelectItem={handleChangeTz}
              selected={timezone}
              fullWidth
              renderItem={renderTimezoneOption}
              search
              searchKey="toSearch"
              inputSearchPlaceholder="Search..."
              maxHeight={265}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              width={256}
            />
            <Spaces top={24} />
            <h6 className={styles.subtitle}>
              <FormattedMessage
                id="settings.account.dateFormat.label"
                defaultMessage="Date format"
              />
            </h6>
            <Radios
              className={styles.radios}
              options={dateDataset}
              name="dateFormat"
              checked={dateFormat}
              onChange={handleChangeDate}
              theme="KolLab"
            />
            <Spaces top={16} />
            <h6 className={styles.subtitle}>
              <FormattedMessage
                id="settings.account.currency.label"
                defaultMessage="Currency"
              />
            </h6>
            <Select2
              options={currencies}
              onSelectItem={handleChangeCurrency}
              selected={currency}
              renderItem={renderCurrencyOption}
              fullWidth
              width={256}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            />
          </Tile>
        </div>
        <div size={8}>
          <Tile className={styles.tile}>
            <h3 className={styles.title}>
              <FormattedMessage id="settings.account.personalInformations" />
            </h3>
            <div className={styles.row}>
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.firstName" />
                </h6>
                <Input2
                  value={state.firstName}
                  placeholder={formatMessage({ id: "settings.account.firstName" })}
                  name="firstName"
                  onChange={handleChangeInput}
                  errorMessage={formatMessage({ id: "settings.account.error.required" })}
                  error={!firstName}
                  fullWidth
                  required
                  disabled={state.nameUpdateLoading}
                />
              </div>
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.lastName" />
                </h6>
                <Input2
                  value={state.lastName}
                  placeholder={formatMessage({ id: "settings.account.lastName" })}
                  name="lastName"
                  onChange={handleChangeInput}
                  errorMessage={formatMessage({ id: "settings.account.error.required" })}
                  error={!lastName}
                  fullWidth
                  required
                  disabled={state.nameUpdateLoading}
                />
              </div>
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.phone" />
                </h6>
                <Input2
                  value={state.phone}
                  placeholder={formatMessage({ id: "settings.account.phone" })}
                  name="phone"
                  onChange={handleChangeInput}
                  fullWidth
                  disabled={state.nameUpdateLoading}
                />
              </div>
            </div>
            <Button3
              size="medium"
              label={formatMessage({ id: "global.cta.saveChanges" })}
              onClick={onRequestChangeName}
              loading={state.nameUpdateLoading}
              disabled={isUpdateNameButtonDisabled}
              className={styles.saveButton}
            />
            <Divider size="large" />
            <h3 className={styles.title}>
              <FormattedMessage id="settings.account.changeEmail" />
            </h3>
            <div className={styles.row}>
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.email" />
                </h6>
                <Input2
                  value={state.email}
                  placeholder={email}
                  name="email"
                  onChange={handleChangeInput}
                  errorMessage={formatMessage({ id: "settings.account.error.alreadyUsedEmail" })}
                  error={state.emailUpdateStatus === 'failure'}
                  fullWidth
                  required
                  disabled={!userRights.updateEmail || state.emailUpdateLoading}
                />
              </div>
            </div>
            <Button3
              size="medium"
              label={formatMessage({ id: "global.cta.saveChanges" })}
              onClick={onRequestChangeEmail}
              loading={state.emailUpdateLoading}
              disabled={isUpdateEmailButtonDisabled}
              className={styles.saveButton}
            />
            <Divider size="large" />
            <h3 className={styles.title}>
              <FormattedMessage id="settings.account.changePassword" />
            </h3>
            <form className={styles.row}>
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.currentPasswordTitle" />
                </h6>
                <Input2
                  type="password"
                  value={state.password}
                  placeholder={formatMessage({ id: "settings.account.currentPasswordPlaceholder" })}
                  name="password"
                  onChange={handleChangeInput}
                  errorMessage={state.password && formatMessage({ id: "settings.account.error.incorrectPassword" })}
                  error={state.passwordUpdateStatus === 'failure'}
                  fullWidth
                  required={state.passwordUpdateStatus !== 'success'}
                  disabled={state.passwordUpdateLoading}
                  autoComplete="current-password"
                />
              </div>
              <div className={styles.col} />
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.newPasswordPlaceholder" />
                </h6>
                <Input2
                  type="password"
                  value={state.newPassword}
                  placeholder={formatMessage({ id: "settings.account.newPasswordPlaceholder" })}
                  name="newPassword"
                  onChange={handleChangeInput}
                  error={state.passwordUpdateStatus === 'tooShort'}
                  fullWidth
                  required={state.passwordUpdateStatus !== 'success'}
                  disabled={state.passwordUpdateLoading}
                  autoComplete="new-password"
                />
              </div>
              <div className={styles.col}>
                <h6 className={styles.subtitle}>
                  <FormattedMessage id="settings.account.newPasswordConfirmationPlaceholder" />
                </h6>
                <Input2
                  type="password"
                  value={state.confirmPassword}
                  placeholder={formatMessage({ id: "settings.account.newPasswordConfirmationPlaceholder" })}
                  name="confirmPassword"
                  onChange={handleChangeInput}
                  errorMessage={state.password && formatMessage(
                    { id: "settings.account.password.passwordTooShort" },
                    { min: MINIMUM_PASSWORD_LENGTH }
                  )}
                  error={state.passwordUpdateStatus === 'tooShort'}
                  fullWidth
                  required={state.passwordUpdateStatus !== 'success'}
                  disabled={state.passwordUpdateLoading}
                  autoComplete="new-password"
                />
              </div>
              {!isAdmin && (
                <div className={styles.col}>
                  <DoubleAuthenticationButton
                    size="medium"
                    defaultPhoneNumber={phone}
                  />
                </div>
              )}
            </form>
            <Button3
              size="medium"
              label={formatMessage({ id: "global.cta.saveChanges" })}
              onClick={updatePassword}
              loading={state.passwordUpdateLoading}
              disabled={isUpdatePasswordButtonDisabled}
              className={styles.saveButton}
            />
          </Tile>
        </div>
      </Grid>
  );
};

export default MyAccount;
