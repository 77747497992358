import React, { PropsWithChildren, useMemo } from 'react';
import cn from 'classnames';

import { Icon } from 'kolkit';

import { notifications } from 'config/app';

import styles from './InlineMessage.module.scss';

type Props = PropsWithChildren<{
  type: 'info' | 'success' | 'warning' | 'error' | 'new' | 'warning_light' | 'neutral';
  variant?: 'standard' | 'outlined' | 'filled' | 'transparent';
  size?: 'medium' | 'small';
  icon?: string;
  className?: string;
  iconClassName?: string;
}>;

const InlineMessage: React.FC<Props> = ({
  type,
  variant="standard",
  size="medium",
  icon,
  className="",
  iconClassName="",
  children
}) => {
  const cnRoot = cn(
    styles.root,
    styles[type],
    styles[variant],
    styles[size],
    className,
  );
  const cnIcon = cn(styles.icon, iconClassName);

  const theme = useMemo(
    () => {
      if (variant === 'transparent') return 'solid';
      if (variant === 'outlined') return 'thin';
      return 'regular'
    },
    [variant]
  );

  const fill = useMemo(
    () => variant !== 'filled' ? notifications[type]?.color : 'white',
    [variant, type]
  );

  return (
    <div className={cnRoot}>
      {type !== 'neutral' && (
        <Icon
          label={icon || notifications[type]?.icon}
          fill={fill}
          size={size === 'small' ? 'medium' : 'huge'}
          className={cnIcon}
          theme={theme}
        />
      )}
      {children}
    </div>
  );
};

export default InlineMessage;
