import React from 'react';

import Facebook from './Facebook';
import Influencer from './Influencer';
import Instagram from './Instagram';
import TikTok from './TikTok';
import Twitter from './Twitter';
import Youtube from './Youtube';

export { default as Facebook } from './Facebook';
export { default as Influencer } from './Influencer';
export { default as Instagram } from './Instagram';
export { default as TikTok } from './TikTok';
export { default as Twitter } from './Twitter';
export { default as Youtube } from './Youtube';

export const filterComponents = onChangeFilter => ([
  () => <Influencer onChange={onChangeFilter} />,
  () => <Instagram onChange={onChangeFilter} />,
  () => <Youtube onChange={onChangeFilter} />,
  () => <TikTok onChange={onChangeFilter} />,
  () => <Facebook onChange={onChangeFilter} />,
  () => <Twitter onChange={onChangeFilter} />
]);
