import React, { useCallback, memo } from 'react';
import { Button3 } from 'kolkit';

import useModal from 'utils/hooks/useModal';

type Button3Props = React.ComponentProps<typeof Button3>;

type Props = {
  selectedCampaignsIds?: string[];
  selectedListsIds?: string[];
  disableCampaignSelection?: boolean;
  label?: string;
  tooltip?: string;
  icon?: string;
  size?: Button3Props['size'];
  theme?: Button3Props['theme'];
  from: 'IRM' | 'Search' | 'Campaign';
};

const SuggestInfluencers: React.FC<Props> = ({
  selectedCampaignsIds,
  selectedListsIds,
  disableCampaignSelection,
  label,
  tooltip,
  icon,
  size,
  theme,
  from,
}) => {
  const { on: openSuggestInfluencerModale } = useModal('suggest_influencer');

  const handleClick = useCallback(
    () => {
      openSuggestInfluencerModale({
        disableCampaignSelection,
        selectedCampaignsIds,
        selectedListsIds,
        from,
      });
    },
    [
      openSuggestInfluencerModale,
      selectedCampaignsIds,
      selectedListsIds,
      disableCampaignSelection,
      from,
    ]
  );

  return (
    <Button3
      size={size}
      theme={theme}
      onClick={handleClick}
      icon={icon}
      label={label}
      tooltip={tooltip}
    />
  );
};

SuggestInfluencers.defaultProps = {
  selectedCampaignsIds: undefined,
  disableCampaignSelection: false,
  label: '',
  icon: '',
  size: 'small',
  theme: 'secondary',
};

SuggestInfluencers.displayName = 'SuggestInfluencers';

export default memo(SuggestInfluencers);
