import React, { memo, useCallback, useMemo, useState } from 'react';
import { Modal } from 'components';
import { updateFilter } from 'actions/user';
import { useDispatch, useSelector } from 'utils/redux';
import Filters from './Filters';
import { FilterButton } from './Components';
import { TagContainer } from './Modules';
import SearchTotal from './SearchTotal';
import itemsConfig from './config/itemsConfig';

import styles from './Engine.module.scss';

const SearchFilters = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);

  const { isActive } = useSelector(({ user }) => ({
    isActive: user.filters.hasActiveFilter,
  }));

  const initColumnNumber = useMemo(() => {
    const length = itemsConfig && itemsConfig.length;
    return { gridTemplateColumns: `repeat(${length}, auto)` };
  }, []);

  const handleOpen = useCallback(index => {
    setSelectedFilter(index);
    setOpen(state => !state);
  }, []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleSelectFilter = useCallback(data => dispatch(updateFilter(data)), [
    dispatch,
  ]);

  return (
    <>
      <div className={styles.filterContainer}>
        <SearchTotal />
        {!isActive ? (
          <div className={styles.filterWrapper} style={initColumnNumber}>
            {
              itemsConfig.map((item, index) => {
                const { label, icon, theme } = item;
                return (
                  <FilterButton
                    key={label}
                    index={index}
                    label={label}
                    icon={icon}
                    theme={theme}
                    onClick={handleOpen}
                  />
                );
              }
            )}
          </div>
        ) : (
          <TagContainer openFilter={() => handleOpen(0)} />
        )}
      </div>

      <Modal on={open} setToggle={handleClose}>
        <Filters
          defaultTab={selectedFilter}
          onChange={handleSelectFilter}
          onClick={handleClose}
        />
      </Modal>
    </>
  );
};

export default memo(SearchFilters);
