import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Button3 } from 'kolkit';
import useModal from 'utils/hooks/useModal';
import { useSelector } from 'utils/redux';

import SetupDoubleAuthenticationModal, {
  DoubleAuthenticationStep,
  DOUBLE_AUTHENTICATION_MODAL_ID,
} from 'components/modules/DoubleAuthentication/SetupDoubleAuthenticationModal';
import InlineMessage from 'components/ui/InlineMessage';
import Typography from 'components/ui/Typography';

import styles from './DoubleAuthentication.module.scss';

type Props = {
  size?: React.ComponentProps<typeof Button3>['size'];
  defaultPhoneNumber?: string;
};

const DoubleAuthenticationButton = ({
  size = 'large',
  defaultPhoneNumber = '',
}: Props) => {
  const intl = useIntl();
  const { on: openDoubleAuthModal } = useModal(DOUBLE_AUTHENTICATION_MODAL_ID);

  const { isActivated, otpMethod } = useSelector(
    ({
      user: { profile },
    }) => ({
      isActivated: profile?.otp_required_for_login,
      otpMethod: profile?.otp_method,
    }),
  );

  const handleOpenDoubleAuthModalAtSetup = useCallback(() => {
    openDoubleAuthModal({
      step: DoubleAuthenticationStep.METHOD,
      defaultPhoneNumber,
    });
  }, [defaultPhoneNumber, openDoubleAuthModal]);

  if (isActivated && otpMethod) {
    return (
      <div className="flex fdc aife ass">
        <InlineMessage type="success" className={styles.activatedMessage}>
          {intl.formatMessage({ id: `auth.doubleAuthentication.${otpMethod === 'authenticator' ? 'byApp' : 'bySms'}.active` })}
        </InlineMessage>
        <Button3
          size="small"
          theme="link"
          onClick={handleOpenDoubleAuthModalAtSetup}
          label={
            <Typography variant="title/tile-subtitle">
              {intl.formatMessage({
                id: 'auth.doubleAuthentication.cta.update',
              })}
            </Typography>
          }
        />
        <SetupDoubleAuthenticationModal />
      </div>
    );
  }

  return (
    <>
      <Button3
        theme="tone"
        size={size}
        label={intl.formatMessage({
          id: 'auth.doubleAuthentication.cta.activate',
        })}
        fullWidth
        onClick={handleOpenDoubleAuthModalAtSetup}
      />
      <SetupDoubleAuthenticationModal />
    </>
  );
};

export default DoubleAuthenticationButton;
