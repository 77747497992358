import { useCallback, memo } from 'react';
import { useIntl } from 'react-intl';
import { toast as toastify } from 'react-toastify';

import { reloadMonitoringIfOpened } from 'actions/listeningV2';
import { reloadProfileIfOpened } from 'actions/profileDetails';
import { appendToAccountRequestAction } from 'actions/myInfluencers';

import useActioncableSubscription from 'utils/hooks/useActioncableSubscription';
import { CHANNELS } from 'config/actioncable';
import { useDispatch } from 'utils/redux';
import toast from 'utils/toast';

import {
  ContentProposalVersion,
  updateContentProposal,
} from 'slices/contentValidation.slice';

import { mapAccountRequest } from './utils';

enum STATUSES {
  ACCEPTED = 'accepted',
  IN_PROGRESS = 'in_progress',
  REJECTED = 'rejected',
}

type AccountCreationRequestProfile = {
  id: number;
  status: STATUSES;
  url: string;
  monitoring_id?: number;
  profile?: {
    id: number;
    first_name: string;
    last_name: string;
    picture_url: string;
  };
  account?: {
    id: number;
    uid: string;
    username: string;
    label: string;
  };
};

type AsyncTasksSubscriptionData = {
  action_type?:
    | 'content_proposal_uploaded'
    | 'account_creation_request_processed'
    | 'account_validation_processed';
  requests?: Array<AccountCreationRequestProfile>;
  content_proposal?: ContentProposalVersion & {
    project_name: string;
    profile_name: string;
  };
};

const AsyncTasksListener = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleAsyncTasksNotificationReceived = useCallback(
    (data: AsyncTasksSubscriptionData) => {
      console.info('AsyncTasksListener :>>', data);
      switch (data.action_type) {
        case 'account_creation_request_processed': {
          if (data.requests) {
            const requests = data.requests.map(mapAccountRequest);
            // Notify
            const nbSuccess = requests.filter(
              (r) => r.status === 'accepted',
            ).length;
            const nbError = requests.length - nbSuccess;
            if (nbSuccess > 0) {
              toast(
                intl.formatMessage(
                  { id: 'suggestKOLs.async.success' },
                  { count: nbSuccess },
                ),
                {
                  type: 'success',
                },
              );
            }
            if (nbError > 0) {
              toast(
                intl.formatMessage(
                  { id: 'suggestKOLs.async.error' },
                  { count: nbError },
                ),
                { type: 'warning' },
              );
            }

            // Update store
            void dispatch(
              appendToAccountRequestAction({
                requests,
              }),
            );
            // If a profile page is opened, reload details
            void dispatch(
              reloadProfileIfOpened({
                profileIds: requests
                  .map((r) => (r.profile_id ? Number(r.profile_id) : null))
                  .filter((r) => !!r),
              }),
            );
            // If a monitoring page is opened, reload details
            void dispatch(
              reloadMonitoringIfOpened({
                monitoringIds: requests
                  .map((r) =>
                    r.monitoring_id ? Number(r.monitoring_id) : null,
                  )
                  .filter((r) => !!r),
              }),
            );
          }
          break;
        }
        case 'content_proposal_uploaded': {
          if (!data.content_proposal) return null;
          const { project_name, profile_name, ...contentProposal } =
            data.content_proposal;
          // Update store
          dispatch(
            updateContentProposal({
              contentProposal,
            }),
          );
          const attachmentInToasts = data.content_proposal.attachments?.filter(
            (attachment) => toastify.isActive(attachment.upload_id),
          );
          if (attachmentInToasts && attachmentInToasts?.length > 0) {
            attachmentInToasts.forEach((attachment) => {
              toastify.update(attachment.upload_id, {
                progress: null,
                isLoading: false,
                autoClose: 2000,
                type: 'success',
              });
            });
          } else {
            toast(
              intl.formatMessage(
                { id: 'toasts.contentValidation.upload.processed' },
                { campaignName: project_name, kolName: profile_name },
              ),
              {
                type: 'success',
              },
            );
          }
          break;
        }
        default:
          break;
      }
    },
    [dispatch, intl],
  );

  useActioncableSubscription({
    channel: CHANNELS.ASYNC_TASKS,
    onDataReceived: handleAsyncTasksNotificationReceived,
  });

  return null;
};

export default memo(AsyncTasksListener);
