import React, { useMemo, memo, useId } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useIntl } from 'react-intl-phraseapp';

import { AudienceAge, Genders } from 'types/profiles';

import useNumberFormat from 'utils/hooks/useNumberFormat';
import { generateOptionsWithGenders, horizontalOptions, verticalOptions } from './config';
import htmlLegendPlugin from './htmlLegendPlugin';

import styles from './AudienceAgeGenderChart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

type Props = {
  ages: AudienceAge[];
  genders: Genders;
  direction?: 'row' | 'column';
  barOrientation: 'horizontal' | 'vertical';
  height?: number;
};

const LEGEND_CONTAINER_ID = 'audience-gender-chart-legends';

ChartJS.defaults.scale.grid.drawOnChartArea = false;

const AudienceAgeGenderChart = (
  {
    ages,
    genders,
    direction = 'row',
    barOrientation = 'vertical',
    height,
  }: Props) => {
  const intl = useIntl();
  const formatNumber = useNumberFormat();
  const containerId = `${LEGEND_CONTAINER_ID}-${useId()}`;

  const labels = useMemo(
    () => {
      return ages?.map((age) => {
        let label = '';
        switch (age.label) {
          case '65-':
            label = '65+';
            break;
          case '35-UP':
            label = '35+';
            break;
          default:
            label = age.label;
        }

        return ({
          label,
          total: formatNumber(age?.totalPercent, { percentage: true, threeDigitsRule: true }),
        })
      })
    },
    [ages, formatNumber],
  );

  const options = useMemo(
    () => {
      const malePercent = (genders?.men / (genders?.men + genders?.women)) * 100;
      const femalePercent = 100.0 - malePercent;

      return generateOptionsWithGenders({
        htmlLegendOptions: {
          malePercent: formatNumber(malePercent, { percentage: true, threeDigitsRule: true }),
          femalePercent: formatNumber(femalePercent, { percentage: true, threeDigitsRule: true }),
          containerId,
        },
        options: barOrientation === 'horizontal' ? horizontalOptions : verticalOptions,
      });
    },
    [formatNumber, genders?.men, genders?.women, containerId, barOrientation]
  );

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: intl.formatMessage({ id: 'engine.filters.male' }),
          data: ages?.map((age) => age?.menPercent),
          backgroundColor: '#00C0FF',
        },
        {
          label: intl.formatMessage({ id: 'engine.filters.female' }),
          data: ages?.map((age) => age?.womenPercent),
          backgroundColor: '#6933F3',
        },
      ],
    }),
    [intl, labels, ages],
  );

  return (
    <div className={styles[direction]}>
      <div id={containerId} />
      <div>
        <Bar
          options={options}
          data={data}
          plugins={[htmlLegendPlugin]}
          height={height}
        />
      </div>
    </div>
  );
};

export default memo(AudienceAgeGenderChart);
