import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { adminFilters } from 'config/filters';
import { useDispatch, useSelector } from 'utils/redux';
import { updateFilter } from 'actions/user';

import AdminState from './AdminState';

import styles from './Admin.module.scss';


const Admin = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const data = adminFilters.state.datas;

  const value =  useSelector(({ user }) => user.filters);

  const onChangeFilter = useCallback(
    data => dispatch(updateFilter(data)),
    [dispatch]
  );

  const dataset = useMemo(
    () => data.map(
      item => ({
        id: item,
        label: intl.formatMessage({ id: `engine.filters.${item}`}),
        value: item,
      })),
    [data, intl],
  );

  return (
    <div className={styles.adminContainer}>
      <h3 className={styles.title}>ADMIN</h3>
      <AdminState
        value={value}
        options={dataset}
        onChange={onChangeFilter}
      />
    </div>
  )
};

export default Admin;
