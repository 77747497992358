import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Field, SelectPercent, ToggleButtonGroup } from 'components';
import { configFilters } from 'config/filters';
import { useFilter } from 'components/engine/hooks';

import styles from '../engineModule.module.scss';


const Genders = ( { network, onChange, required, disabled, ...rest }) => {
  const filterTarget = useMemo(
    () => network !== 'influencer' ? 'communityGender' : 'genders',
    [network],
  );
  const intl = useIntl();
  const { datas, params } = configFilters[filterTarget];
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(
    ({ item, selected }) => onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      network
    }),
    [onChange, network, filterTarget]
  );

  const getData = useMemo(
    () => datas.map(item => ({
      id: item,
      label: intl.formatMessage({ id: `engine.filters.${item}`}),
      value: item,
    })),
    [datas, intl]
  );

  return (
    <div {...rest}>
      <Field label={intl.formatMessage({ id: network === 'influencer' ? 'genders.label' : 'genders.audience'})}>
      {network === 'influencer' ?
        <div className={styles.gridToggleButton}>
          <ToggleButtonGroup
            options={getData}
            selectedOption={value}
            disabled={disabled}
            onChange={handleChange}
          />
        </div> :
        <SelectPercent
          selected={value}
          dataset={getData}
          sort
          search={false}
          label={intl.formatMessage(
            { id: 'genders.placeholder'},
            { max: params.max}
          )}
          onChange={handleChange}
          disabled={disabled}
          max={params.max}
          inPortal
        />}
        </Field>
    </div>
  )
};

Genders.displayName = 'Genders';

Genders.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Genders.defaultProps = {
  disabled: false,
  required: false,
};

export default Genders;
