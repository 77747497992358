import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';

import { Button3, Icon } from 'kolkit';
import { useSelector } from 'utils/redux';
import { MountComponentByFeature } from 'utils/feature-toggle';
import * as featureNames from 'constants/feature-names';
import useModal from 'utils/hooks/useModal';



const AddToCampaign = ({ ids, noLabel, onClick, buttonProps, iconProps, from, icon, dataId }) => {
  const intl = useIntl();

  const { on } = useModal('campaignsSelectModal');

  const { profiles } = useSelector(({ profiles }) => ({ profiles }));

  const getIds = useMemo(
    () => {
      if (Array.isArray(ids)) {
        return ids?.length === 1 ? ids?.[0] : ids
      }

      return Number(ids)
    },
    [ids]
  );

  const handleClick = useCallback(
    () => {
      on({ id: getIds, from, checked: profiles?.[getIds]?.campaigns?.ids });
      onClick()
    },
    [getIds, onClick, on, from, profiles]
  );

  return (
    <MountComponentByFeature featureName={featureNames.MANAGE_CAMPAIGN}>
      {!icon && (
        <Button3
          onClick={handleClick}
          icon="plus"
          theme="tertiaryReverse"
          tooltip={noLabel && intl.formatMessage({id: 'campaigns.cta.addKol'})}
          dataId={dataId}
          {...buttonProps}
        >
          {!noLabel && intl.formatMessage({id: 'campaigns.cta.addKol'})}
        </Button3>
      )}
      {icon && (
        <Icon
          label="plus"
          onClick={handleClick}
          tooltip={noLabel && intl.formatMessage({id: 'campaigns.cta.addKol'})}
          isButton
          dataId={dataId}
          {...iconProps}
        />
      )}
    </MountComponentByFeature>
  )
};

AddToCampaign.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  noLabel: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.bool,
}

AddToCampaign.defaultProps = {
  noLabel: false,
  onClick: () => {},
  icon: false,
};

export default memo(AddToCampaign);
