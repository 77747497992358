import { useRef, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (launch = false) => {
  const [size, setSize] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new ResizeObserver(entries => {
      // Try to fix sentry error w/ https://stackoverflow.com/a/50387233
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return null;
        }
        setSize(entries[0].target.getBoundingClientRect());
      });
    })
  );

  useEffect(() => {
    const { current } = observer;

    if (node || (node && launch)) {
      if (current) current.observe(node);
    }

    return () => current && current.disconnect();
  }, [node, observer, launch]);

  return [setNode, size];
};

export default useResizeObserver;
