import React from 'react';
import cn from 'classnames';

import styles from './Divider.module.scss';

const Divider: React.FC<{
  className?: string;
  size?: 'small' | 'medium' | 'large';
}> = ({ className = '', size = 'medium' }) => {
  const cnDivider = cn(styles.line, styles[size], className);
  return <hr className={cnDivider} />;
};

export default Divider;
