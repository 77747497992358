import React, { memo, useCallback, useEffect } from 'react';
import Loadable from 'react-loadable';

import { getFrom, reloadMessages } from 'actions/messaging';
import { startActionTimeout } from 'actions/ui';
import { useDispatch } from 'utils/redux';

const Messaging = Loadable({
  loader: () => import('components/messaging/Messaging'),
  loading: () => <Loader full />,
});


const Loader = () => {
  const dispatch = useDispatch();

  const handleDispatchAction = useCallback(
    () => {
      dispatch(getFrom('messaging'));
      dispatch(startActionTimeout(reloadMessages(false), 500));
    },
    [dispatch]
  );

  useEffect(() => {
    handleDispatchAction();
  }, [handleDispatchAction]);

  return <Messaging />;
};

export default memo(Loader);
