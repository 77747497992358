import React, { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl-phraseapp';

import { Select2, Button3 } from 'kolkit';

import { ContentStatus } from 'slices/contentValidation.slice';

import styles from './ContentValidationStateDropdown.module.scss';

const AVAILAIBLE_STATES = [
  'waiting_proposal', // Waiting proposal
  'to_be_approved', // To be approved
  'need_iteration', // Need iteration
  'approved', // Approved
  'published', // Published
]

interface Props {
  selected: ContentStatus;
  onChange: (state: string) => void;
  disabled?: boolean;
  disabledTooltip?: string;
  fullWidth?: boolean;
  size?: React.ComponentProps<typeof Button3>['size'];
  className?: string;
}

const ContentValidationStateDropdown: React.FC<Props> = ({
  selected,
  onChange,
  disabled,
  disabledTooltip,
  fullWidth,
  size,
  className
}) => {

  const intl = useIntl();

  const statesOptions = useMemo(
    () => AVAILAIBLE_STATES.map(status => ({
      value: status,
      label: intl.formatMessage({ id: `campaigns.contentValidation.state.${status}` })
    })),
    [intl]
  );

  const trigger = useMemo(
    () => {
      const cnButton = cn(styles.triggerButton, styles[selected], { [styles.disabled]: disabled})

      return (
        <Button3
          className={cnButton}
          reverse
          icon="caret-down"
          iconTheme="solid"
          theme="tertiary"
          disabled={disabled}
          fullWidth={fullWidth}
          size={size}
          label={intl.formatMessage({ id: `campaigns.contentValidation.state.${selected}` })}
        />
      );
    },
    [selected, size, disabled, fullWidth, intl]
  );

  const handleSelect = useCallback(
    ({ value }) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Select2
      className={cn(styles.root, className)}
      options={statesOptions}
      selected={selected || 'waiting_proposal'}
      trigger={trigger}
      onSelectItem={handleSelect}
      size="small"
      fullWidth={fullWidth}
      maxHeight="auto"
      disabled={disabled}
      tooltip={disabledTooltip}
    />
  )
}

export default memo(ContentValidationStateDropdown);