import React, { useCallback } from 'react';
import { Button3 } from 'kolkit';

import { NETWORKS } from 'constants/networks';

import { getIconProps } from './config';

type Props = React.PropsWithChildren<{
  network?: (typeof NETWORKS)[number];
  name?: string;
  onClick?: (e: { name?: string; network?: (typeof NETWORKS)[number] }) => void;
  onClickClose?: (e: {
    name?: string;
    network?: (typeof NETWORKS)[number];
  }) => void;
  active?: boolean;
  selected?: boolean;
  counter?: number;
  className?: string;
}>;

const NetworkButton: React.FC<Props> = ({
  network,
  name,
  onClick,
  onClickClose,
  active,
  selected,
  children,
  counter,
  className,
  ...rest
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick({ name, network });
    }
  }, [name, network, onClick]);

  const handleClickClose = useCallback(() => {
    if (onClickClose) {
      onClickClose({ name, network });
    }
  }, [name, network, onClickClose]);

  const { icon, iconTheme, iconSize } = getIconProps({ network, name });
  return (
    <Button3
      icon={icon}
      iconTheme={iconTheme}
      iconSize={iconSize}
      theme="tertiary"
      size="small"
      onClick={handleClick}
      onClickClose={active && onClickClose ? handleClickClose : undefined}
      counter={counter}
      name={name}
      active={active}
      selected={selected}
      className={className}
      {...rest}
    >
      {children}
    </Button3>
  );
};

export default NetworkButton;
