import React, { memo, useCallback } from 'react';
import cn from 'classnames';

import { Markdown, Popover } from 'components/ui';

import styles from './AnimatedBullet.module.scss';

interface Props {
  theme?: string;
  id: string;
  message: string;
  onClose: (state: string) => void;
  className?: string;
}

const AnimatedBullet: React.FC<Props> = ({ theme = 'default', id, message, onClose, className }) => {
  const handleClose = useCallback(
    () => onClose(id),
    [onClose, id]
  );

  const cnBullet = cn(styles.bullet, styles[theme]);

  return (
    <div className={className}>
      <Popover
        id={id}
        theme={theme}
        message={<Markdown>{message}</Markdown>}
        onClose={handleClose}
        isTooltip
        placement="top"
        trigger={<div className={cnBullet} />}
        full
      />
    </div>
  );
};

AnimatedBullet.displayName = 'AnimatedBullet';

AnimatedBullet.defaultProps = {
  theme: 'default',
  className: '',
};

export default memo(AnimatedBullet);
