import React, { memo, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { Icon } from 'kolkit';

import styles from './SorterButton.module.scss';

const SorterButton = ({
  active,
  direction,
  name,
  sortKey,
  onClick,
  children,
  className,
  disabled,
  style,
  dataId,
}) => {
  const iconLabel = useMemo(
    () => {
      if (!active) return 'sort-double-empty';
      return direction === 'asc' ? 'sort-up' : 'sort-down';
    },
    [direction, active]
  );

  const handleClick = useCallback(
    () => onClick && !disabled && onClick({ name, sortKey }),
    [onClick, name, sortKey, disabled],
  );

  const cnWrapper = cn(className, styles.wrapper, {
    [styles.active]: active,
    [styles.disabled]: disabled,
  });

  return (
    <div
      className={cnWrapper}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      style={style}
      data-id={dataId}
    >
      <div>{children}</div>
      {!disabled && (
        <Icon
          label={iconLabel}
          theme="duotone"
          className={styles.icon}
          width={styles.iconSize}
        />
      )}
    </div>
  );
};

SorterButton.defaultName = 'SorterButton';

SorterButton.defaultProps = {
  direction: 'asc',
  onClick: undefined,
  className: undefined,
  style: undefined,
  dataId: undefined,
};

export default memo(SorterButton);
