import React, { useMemo, memo } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { INSTAGRAM, NETWORKS, YOUTUBE } from 'constants/networks';
import { AUDIENCE_TYPES, AUDIENCE_TYPES_YOUTUBE } from 'constants/profiles';

import ToggleButtons2 from '../ToggleButtons2';

type Value = (typeof AUDIENCE_TYPES)[number] &
  (typeof AUDIENCE_TYPES_YOUTUBE)[number];
type Props = {
  network?: (typeof NETWORKS)[number];
  value: Value;
  onChange: (value?: Value) => void;
  dataIdPrefix?: string;
};

const AudienceTypeToggle = ({ network, value, onChange, dataIdPrefix }: Props) => {
  const intl = useIntl();

  const audienceDataset = useMemo(
    () =>
      (network === YOUTUBE ? AUDIENCE_TYPES_YOUTUBE : AUDIENCE_TYPES).map(
        (type) => ({
          value: type,
          label: intl.formatMessage({
            id: `profileDetails.filters.${
              network === YOUTUBE && type === 'followers' ? 'subscribers' : type
            }`,
          }),
          selected: type === value,
          dataId: dataIdPrefix ? `${dataIdPrefix}${value}` : undefined,
        }),
      ),
    [dataIdPrefix, intl, network, value],
  );

  return network === INSTAGRAM || network === YOUTUBE ? (
    <ToggleButtons2
      value={value}
      dataset={audienceDataset}
      size="small"
      disableRipple
      onChange={onChange}
    />
  ) : null;
};

export default memo(AudienceTypeToggle);
