import { createAction } from '@reduxjs/toolkit';

import * as api from 'api/trackingLinks';
import * as actionsViews from 'actions/views';
import { setControlledLoader, showModal } from 'actions/ui';
import { update as updateCampaign } from 'actions/campaigns';
import { update as updateProfile } from 'actions/profileDetails'
import { AppThunk } from 'config/store';
import { UPDATE_TRACKING_LINKS_PARAMS } from 'constants/campaigns';
import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import { initialTrackingLinksState } from 'reducers/views'

export const updateParams = createAction<{
  campaignId: string | number | null;
  searchValue?: string;
  sortKey?: string;
  sortDir?: 'asc' | 'desc';
  page?: number;
}>(UPDATE_TRACKING_LINKS_PARAMS);

export const LOADING_MODAL_ID = 'trackingLinksModal';
export const LOADING_LIST_ID = 'trackingLinksList';

export const updateNewTrackingLinks = ({ key, value }) => (dispatch, getState) => {
  const { newTrackingLinks } = getState().views.campaignDetails.trackingLinks;

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'trackingLinks',
    newTrackingLinks: {
      ...newTrackingLinks,
      [key]: value,
    }
  }));
};

export const resetNewTrackingLinks = () => dispatch => {
  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'trackingLinks',
    newTrackingLinks: initialTrackingLinksState,
  }));
};

export const checkNewTrackingLinks = (): AppThunk<void> => async (dispatch, getState) => {
  const { id, trackingLinks: { newTrackingLinks } } = getState().views.campaignDetails

  dispatch(setControlledLoader({ id: LOADING_MODAL_ID, show: true }));

  const response = await api.checkNewTrackingLinks({
    campaign_id: Number(id),
    long_link: newTrackingLinks?.urlDestination,
    profiles_ids: newTrackingLinks?.kolsIds,
  });

  dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: 'trackingLinks',
    newTrackingLinks: {
      ...newTrackingLinks,
      trackingLinksValid: response?.tracking_links?.map(link => ({
        id: Number(link?.id),
        profileId: Number(link?.profile_id),
        name: link?.profile_name,
        mention: link?.profile_mention,
        pictureUrl: link?.profile_image_url,
        shortLink: link?.short_link,
      }))
    }
  }));

  dispatch(setControlledLoader({ id: LOADING_MODAL_ID, show: false }));
  return response;
};

type Pagination = { page: number, per_page: number };

export const getTrackingLinksForCampaign: (props?: {
  campaignId?: number;
  appendResults?: boolean;
  replaceAllResults?: boolean;
}) => AppThunk<void> = ({ campaignId: _campaignId, appendResults, replaceAllResults } = {}) =>
  async (dispatch, getState) => {
    const {
      ui: { loader },
      views: {
        campaignDetails: { id: campaignIdFromState },
      },
    } = getState();

    const campaignId = _campaignId || campaignIdFromState;

    if (loader.includes(LOADING_LIST_ID) || !campaignId) return null;

    const {
      campaigns: {
        [campaignId]: {
          tracking_links: { search, sort, paginate, rows: previousRows },
        },
      },
    } = getState();

    dispatch(setControlledLoader({ id: LOADING_LIST_ID, show: true }));

    try {
      let rows;
      let pagination;

      if (replaceAllResults) {
        const _paginate = paginate as Pagination;
        rows = await Array(_paginate.page + 1)
          .fill(null)
          .reduce(async (_allRowsPromise, _page, index) => {
            const allRows = await _allRowsPromise;
            const {
              page,
              per_page,
              total_count: total,
              tracking_links: _rows,
            } = await api.getTrackingLinksForCampaign({
              campaign_id: campaignId,
              params: {
                search,
                sort: sort.key ? `${sort.key}-${sort.dir || 'asc'}` : '',
                page: index,
                per_page: _paginate.per_page,
                scopes: 'profiles,statistics',
              },
            });
            pagination = { page, per_page, total };
            return [...allRows, ..._rows];
          }, Promise.resolve([]));
      } else {
        const {
          page,
          per_page,
          total_count: total,
          tracking_links: _rows,
        } = await api.getTrackingLinksForCampaign({
          campaign_id: campaignId,
          params: {
            search,
            sort: sort.key ? `${sort.key}-${sort.dir || 'asc'}` : '',
            page: paginate.page,
            per_page: paginate.per_page,
            scopes: 'profiles,statistics',
          },
        });
        pagination = { page, per_page, total };
        rows = _rows;
      }
      const mapped = {
        paginate: {
          page: pagination.page,
          per_page: pagination.per_page,
        },
        total: pagination.total,
        rows: appendResults ? [...previousRows, ...rows] : rows, // do mapping here if necessary
      };

      dispatch(updateCampaign({ id: campaignId, tracking_links: mapped }));

      mapped.rows.forEach(kol =>
        dispatch(
          updateProfile({
            id: kol.profile.id,
            core: { isContactable: !!kol.profile.email },
          }),
        ),
      );
    } catch(e) {
      console.error('Tracking links update error:', e);
      const intl = getIntl();
      toast(intl.formatMessage({ id: 'trackingLinks.toasts.update.error' }), {
        title: intl.formatMessage({ id: 'global.toasts.oops' }),
        type: 'error',
      });
    } finally {
      dispatch(setControlledLoader({ id: LOADING_LIST_ID, show: false }));
    }
  };

export const saveNewTrackingLinks = (): AppThunk<void> => async (dispatch, getState) => {
  const { newTrackingLinks: { trackingLinksValid } } = getState().views.campaignDetails.trackingLinks;

  dispatch(setControlledLoader({ id: LOADING_MODAL_ID, show: true }));

  await api.saveNewTrackingLinks({
    ids: trackingLinksValid?.map(({ id }) => Number(id))
  });

  dispatch(setControlledLoader({ id: LOADING_MODAL_ID, show: false }));
  dispatch(getTrackingLinksForCampaign());
};

export const updateSentStatus = ({
  trackingLinkId,
  isSent,
}): AppThunk<void> => async (dispatch) => {
  await api.updateSentStatus({
    tracking_link_id: trackingLinkId,
    sent: !isSent,
  });
  return dispatch(getTrackingLinksForCampaign());
};

export const promptDeleteTrackingLink =
  ({
    trackingLinkIds = [] as number[],
    profileIds = [] as string[] | undefined,
  }): AppThunk<void> =>
  (dispatch, getState) => {
    const trackingLinkIdsFromProfileIds = [] as number[];
    if (profileIds?.length) {
      const {
        views: {
          campaignDetails: { id: campaignId },
        },
        campaigns,
      } = getState();

      if (campaignId && campaigns?.[campaignId]) {
        const campaign = campaigns[campaignId];
        trackingLinkIdsFromProfileIds.push(
          ...profileIds.reduce((trackingLinks, profileId) => {
            const trackingLink = campaign.tracking_links.rows.find(
              (trackingLink) => Number(trackingLink.profile.id) === Number(profileId),
            );
            return trackingLink?.id && trackingLink.status !== 'deleted'
              ? [...trackingLinks, Number(trackingLink.id)]
              : trackingLinks;
          }, [] as number[]),
        );
      }
    }

    const allTrackingLinkIds = [
      ...trackingLinkIdsFromProfileIds,
      ...trackingLinkIds,
    ];

    dispatch(
      showModal({
        id: 'tracking-links-delete-code',
        data: {
          nbTrackingLinks: allTrackingLinkIds?.length,
          onConfirm: async () => {
            await api.disableTrackingLinks({
              tracking_link_ids: allTrackingLinkIds,
            });
            dispatch(getTrackingLinksForCampaign({ replaceAllResults: true }));
          },
        },
      }),
    );
  };
