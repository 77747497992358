import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';

import { Button } from 'components';
import { useDispatch, useSelector } from 'utils/redux';
import { updateFilter } from 'actions/user';

import Tags from './Tags';

import styles from './TagContainer.module.scss';


const TagContainer = ({ openFilter }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const value = useSelector(({ user }) => user.filters.search);

  const handleClick = useCallback(
    () => openFilter(),
    [openFilter]
  );

  const handleResetFilter = useCallback(
    () => dispatch(updateFilter({
      action: 'resetFilterWithoutSearch',
      value,
      forceTriggerSearch: true,
    })),
    [dispatch, value]
  );

  return (
    <div className={styles.tagContainer}>
      <Tags />
      <div className={styles.btnWrapper}>
        <Button theme="ghost" onClick={handleClick} icon="filter">
          {intl.formatMessage({id: 'global.cta.updateFilters'})}
        </Button>
        <Button theme="ghost" onClick={handleResetFilter} icon="times">
          {intl.formatMessage({id: 'global.cta.resetFilters'})}
        </Button>
      </div>
    </div>
  );
};

TagContainer.displayName = 'TagContainer';

TagContainer.propTypes = {
  openFilter: PropTypes.func.isRequired,
};

export default memo(TagContainer);
