import React, { memo, useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-phraseapp';

import { Dropdown } from 'kolkit';

import { updateSortBy } from 'actions/engine';
import { updateFilter } from 'actions/user';
import DateSlicesPicker from 'components/ui/DateSlicesPicker';
import { sortBy as sortByValues } from 'config/filters';
import { formatDateRangeToString } from 'utils/dateRangeUtil';
import { useSelector, useDispatch } from 'utils/redux';

const TopSortFilter = memo(() => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const [sortBy, sortByPeriod, hasKeywordSearch, tabType, hasSearch, lang] = useSelector(
		({ engine, env }) => [
			engine.sortBy,
			engine.filters.period,
			engine.filters.search.length > 0,
			engine.type,
			engine.results.hasSearch,
			env.locale,
		]
	);

	const onSortChanged = useCallback(
		({ value }) => dispatch(updateSortBy(value)),
		[dispatch]
	);

	const onSortPeriodChanged = useCallback(
		({ slice, startDate, endDate }) => {
			const value = slice || formatDateRangeToString({ startDate, endDate });
			dispatch(
				updateFilter({
					field: 'period',
					value,
					topSort: true,
					forceTriggerSearch: true,
				}),
			);
		},
		[dispatch],
	);

	const getSortDataset = useMemo(
		() => sortByValues.map(s => ({
			label: intl.formatMessage({ id: `engine.filters.${s}`}),
			value: s,
			disabled: (s === "relevance" || s === "mostRecent") && !hasKeywordSearch,
		})),
		[intl, hasKeywordSearch]
	);

	const renderSortSelect = useMemo(
		() => {
			return (
				<div className="engine-top-sort-filter__item">
					{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
					<label>
						<FormattedMessage id="global.filter.label.sortBy" />
					</label>
					<Dropdown
						sort={false}
						selected={sortBy}
						options={getSortDataset}
						onSelectItem={onSortChanged}
						disabled={!hasSearch}
						size="small"
						key={`other-filter-${lang}`}
						inPortal={false}
						fullWidth
						width={180}
					/>
				</div>
			);
		},
		[sortBy, getSortDataset, onSortChanged, hasSearch, lang]
	);

	return (
    <div className="engine-top-sort-filter">
      {!!hasKeywordSearch && tabType === 'post' && (
				<DateSlicesPicker
					dateSlices={['lastMonth', 'last3Months', 'last6Months', 'lastYear', 'all', 'custom']}
					selected={sortByPeriod}
					size="small"
					onChange={onSortPeriodChanged}
				/>
      )}
      {renderSortSelect}
    </div>
  );
});

export default TopSortFilter;
