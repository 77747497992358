import { get, post, put, remove } from './index';
import { ApiPromise } from './types.d';

export type CampaignDiscountCode = {
  id: number;
  key: string;
  starts_at?: string; // '2022-05-13T20:00:45.000Z',
  ends_at?: string; // '2022-05-17T20:00:45.000Z',
  status: 'active' | 'inactive' | 'expired'; // 'inactive',
  public_profile: {
    id: number;
    name?: string;
    picture_url?: string;
    pseudo?: string;
    full_name?: string;
  };
  sent?: boolean;
  sales_count?: number;
  sales_turnover?: number;
};

export const getPriceRules: (id: number) => ApiPromise<any> = (id) => get({
  endpoint: `influence/shopify_stores/${id}`,
  debug: []
});

export const verifyNewCodesPromo: (params: {
  profile_ids: number[];
  pattern: string;
  prefix?: string;
  suffix?: string;
  discount_codes_data?: {
    [id: number]: string
  }
}) => ApiPromise<{
  invalid_codes: {
    profile_id: number;
    profile_picture: string;
    profile_display_name: string;
    profile_pseudo: string;
    code: string;
    is_missing_pattern: boolean;
    is_duplicated: boolean;
    is_existing: boolean;
  }[],
  valid_codes: {
    profile_id: number;
    profile_picture: string;
    profile_display_name: string;
    profile_pseudo: string;
    code: string;
    is_missing_pattern: boolean;
    is_duplicated: boolean;
    is_existing: boolean;
  }[]
}> = params =>
  get({
    endpoint: `influence/discount_codes/verify`,
    params,
    catchErrors: [422],
  });

export const batchCreateDiscountCodes: (params: {
  id: unknown,
  price_rule_xid: number;
  starts_at?: string;
  ends_at?: string;
  discount_codes_data?: {
    [id: number]: string
  } | null;
}) => ApiPromise<any> = ({
  id,
  ...params
}) =>
  post({
    endpoint: `influence/projects/${id}/discount_codes/batch_create`,
    params,
    debug: [],
  });

export const connectShopifyStore: (params: {
  name: string;
  access_token: string;
}) => ApiPromise<{ shopify_store: { id: string; name: string } }> = ({
  name,
  access_token,
}) =>
  post({
    endpoint: `influence/shopify_stores`,
    params: {
      name,
      access_token,
    },
    catchErrors: [422],
  });

export const disconnectShopifyStore: (params: {
  store_id: string;
}) => ApiPromise<any> = ({ store_id }) =>
  remove({
    endpoint: `influence/shopify_stores/${store_id}`,
    debug: true,
    catchErrors: [422],
  });

export const getDiscountCodesForCampaign: (params: {
  campaign_id: string;
}) => ApiPromise<{
  discount_codes: CampaignDiscountCode[];
}> = ({ campaign_id }) =>
  get({
    endpoint: `influence/projects/${campaign_id}/discount_codes`,
    catchErrors: [],
  });

  export const updateSentStatus: (params: {
    campaign_id: string;
    discount_code_id: string;
    sent: boolean;
  }) => ApiPromise<any> = ({
    campaign_id,
    discount_code_id,
    sent
  }) =>
    put({
      endpoint: `influence/projects/${campaign_id}/discount_code/${discount_code_id}/update`,
      params: {
        sent
      },
      debug: true
    });

export const disableDiscountCodes: (params: {
  discount_code_ids: string[];
  campaign_id: string;
}) => ApiPromise<{
  disable_ids: number[];
  failure_ids: number[];
}> = ({ discount_code_ids, campaign_id }) =>
  post({
    endpoint: `influence/projects/${campaign_id}/discount_codes/disable`,
    params: {
      ids: discount_code_ids || [],
    },
    debug: true,
    catchErrors: [422],
  });
