import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { WrapperFilter } from '../Components';
import {
  Ages,
  Community,
  CommunityLanguages,
  Countries,
  Genders,
} from '../Modules';
import useActiveFilter from '../hooks/useActiveFilter';

const TikTok = ( { onChange }) => {
  const network = 'tiktok';
  const intl = useIntl();
  const isActive = useActiveFilter(network);

  return (
    <WrapperFilter title={network} isActive={isActive}>
      <div>
        <Community
          label={intl.formatMessage({id: 'communitySize'})}
          network={network}
          filterKey="communitySize"
          onChange={onChange}
          disabled={!isActive}
        />
        <Community
          label={intl.formatMessage({id: 'engagementRate'})}
          network={network}
          filterKey="communityEngagementRate"
          onChange={onChange}
          disabled={!isActive}
        />
      </div>
      <div>
        <CommunityLanguages
          onChange={onChange}
          network={network}
          disabled={!isActive}
        />
        <Countries
          onChange={onChange}
          network={network}
          filterKey="communityCountries"
          disabled={!isActive}
        />
      </div>
      <div>
        <Ages
          onChange={onChange}
          network={network}
          disabled={!isActive}
        />
        <Genders
          onChange={onChange}
          network={network}
          disabled={!isActive}
        />
      </div>
    </WrapperFilter>
  )
};

TikTok.displayName = 'TikTok';

TikTok.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(TikTok);
