import React, {
  memo,
  ReactElement,
  Suspense,
  useCallback,
  useState,
  useMemo,
  useEffect,
  ReactNode,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';
import {
  NavLink,
  useNavigate,
  useMatch,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';

import { Loader, Icon } from 'kolkit';

import { setUserPreferences } from 'actions/user';
import { AnimatedBullet } from 'components/ui';
import { useDispatch } from 'utils/redux';

import Header from './Header';
import styles from './TabsPage.module.scss';

export type TabType = {
  hide?: boolean;
  label: ReactNode;
  content: ReactElement;
  link?: string;
  subRoutes?: string[];
  highlight?: string;
  highlightKey?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataId?: string;
};
interface Props {
  theme?: 'primary' | 'tone';
  size?: 'small' | 'medium',
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  extraAction?: ReactNode;
  withRouter?: boolean;
  parentPath?: string;
  dataset: TabType[];
  collapse?: boolean;
  onCollapse?: (forcedValue?: boolean) => void;
  headerBottomMargin?: number;
  ContentWrapper?: React.FC<{ children: ReactNode }>;
  className?: string;
  padding?: number;
  noCollapse?: boolean;
  initialTabIndex?: number;
}

const defaultContentWrapper = ({ children }: { children: ReactNode }) => (
  <>{children}</>
);

const TabsPage: React.FC<Props> = ({
  theme='primary',
  size='medium',
  title,
  subTitle,
  extraAction,
  withRouter,
  parentPath,
  dataset,
  collapse,
  onCollapse,
  headerBottomMargin,
  ContentWrapper = defaultContentWrapper,
  className,
  padding,
  noCollapse,
  initialTabIndex
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch(`/${parentPath}/:tab/*`);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [collapseHeader, setCollapseHeader] = useState(false);

  useEffect(() => {
    setCurrentTabIndex(initialTabIndex || 0);
  }, [initialTabIndex]);

  useEffect(() => {
    setCollapseHeader(Boolean(collapse));
  }, [collapse]);

  const handleCollapseHeader = useCallback(() => {
    if (onCollapse) {
      onCollapse();
      return true;
    }
    setCollapseHeader((prevState) => !prevState);
  }, [onCollapse]);

  const handleClick = useCallback(
    (link, newValue) => {
      if (withRouter && typeof link === 'string') {
        navigate(link);
      } else {
        const tab = dataset[newValue];
        if (tab?.onClick) tab.onClick();
        setCurrentTabIndex(newValue);
      }
    },
    [withRouter, navigate, dataset],
  );

  const renderHeader = useMemo(() => {
    if (!title && !subTitle) return null;

    const bottomMargin =
      headerBottomMargin === undefined || !headerBottomMargin
        ? 0
        : `${headerBottomMargin}px`;

    return (
      <Header
        title={title}
        subTitle={subTitle}
        collapse={collapseHeader}
        className={styles.headerTop}
        bottomMargin={bottomMargin}
        style={padding ? { padding, paddingBottom: 0 } : undefined}
      />
    );
  }, [title, subTitle, headerBottomMargin, collapseHeader, padding]);

  const cnIcon = cn(styles.collapseIcon, {
    [styles.rotate]: !collapseHeader,
  });

  const handleCloseHighlight = useCallback(
    (key) => {
      void dispatch(setUserPreferences(key));
    },
    [dispatch],
  );

  const renderLabel = useCallback(
    (tab, index) => {
      if (!tab.highlight) return <span>{tab.label}</span>;
      return (
        <div className={styles.highlighted}>
          <span>{tab.label}</span>
          <AnimatedBullet
            id={`hightlight-${index}`}
            message={tab.highlight}
            onClose={() => handleCloseHighlight(tab.highlightKey)}
            className={styles.bullet}
            theme="avender"
          />
        </div>
      );
    },
    [handleCloseHighlight],
  );

  const renderTabs = useMemo(
    () => (
      <div
        className={styles.tabsWrapper}
        style={
          padding ? { paddingLeft: padding, paddingRight: padding } : undefined
        }
      >
        <Tabs
          value={withRouter ? match?.params?.tab : currentTabIndex}
          onChange={handleClick}
          classes={{
            root: cn(styles.rootTabs, [styles[theme]], [styles[size]]),
            flexContainer: styles.flexContainer,
            indicator: styles.indicator,
          }}
        >
          {dataset
            ?.filter((tab) => !tab?.hide)
            ?.map((tab, index) => (
              <Tab
                key={`tab-${index}`}
                label={renderLabel(tab, index)}
                classes={{
                  root: styles.rootTab,
                  selected: styles.tabSelected,
                }}
                value={withRouter ? tab.link : index}
                component={(props) =>
                  withRouter ? (
                    <NavLink {...props} key={tab.content.key} to={tab.link} onClick={tab.onClick} />
                  ) : (
                    <button {...props} key={tab.content.key} />
                  )
                }
                variant="fullWidth"
                disabled={tab?.disabled}
                data-id={tab.dataId}
              />
            ))}
        </Tabs>
        {extraAction}
        {!noCollapse && (
          <Icon
            className={cnIcon}
            onClick={handleCollapseHeader}
            label="chevron-circle-down"
            size="big"
            isButton
          />
        )}
      </div>
    ),
    [
      theme,
      size,
      withRouter,
      match?.params?.tab,
      currentTabIndex,
      handleClick,
      padding,
      dataset,
      noCollapse,
      cnIcon,
      handleCollapseHeader,
      renderLabel,
      extraAction,
    ],
  );

  const renderContent = useMemo(() => {
    if (withRouter)
      return (
        <Suspense
          fallback={<Loader full background="rgba(255, 255, 255, .8)" />}
        >
          <Routes>
            {dataset
              .filter((tab) => !tab?.disabled && !tab?.hide)
              .map((tab, index) => {
                const element = React.cloneElement(tab.content, {
                  collapse,
                  onTabCollapse: onCollapse,
                });

                return [
                  <Route
                    path={tab.link}
                    element={element}
                    index={index === 0}
                  />,
                  ...(tab.subRoutes
                    ? tab.subRoutes.map((route) => (
                        <Route path={route} element={element} />
                      ))
                    : []),
                ];
              })
              .flat()}
            <Route
              path="*"
              element={<Navigate replace to={dataset[0]?.link || '/'} />}
            />
          </Routes>
        </Suspense>
      );

    return dataset
      ?.filter((tab) => !tab?.disabled)
      ?.map((tab, index) => (
        <TabPanel key={`tab_${index}`} value={currentTabIndex} index={index}>
          {React.cloneElement(tab.content, {
            collapse,
            onTabCollapse: onCollapse,
          })}
        </TabPanel>
      ));
  }, [withRouter, dataset, collapse, currentTabIndex, onCollapse]);

  const cnHeader = cn(styles.header, className);

  return (
    <>
      <div className={cnHeader}>
        {renderHeader}
        {renderTabs}
      </div>
      <ContentWrapper>{renderContent}</ContentWrapper>
    </>
  );
};

const TabPanelComponent: React.FC<
  PropsWithChildren<{
    value: number;
    index: number;
  }>
> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {/* TODO: find way to lazyload this bcz it causes rerender at each tab change */}
      {value === index && children}
    </div>
  );
};

TabPanelComponent.displayName = 'TabPanel';

const TabPanel = memo(TabPanelComponent);

TabsPage.defaultProps = {
  title: '',
  subTitle: '',
  collapse: false,
  onCollapse: undefined,
  withRouter: false,
  headerBottomMargin: undefined,
  ContentWrapper: defaultContentWrapper,
  className: undefined,
  padding: undefined,
  noCollapse: false,
};

TabsPage.displayName = 'TabsPage';

export default memo(TabsPage);
