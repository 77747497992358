import { IntlShape } from 'react-intl';
import { Tooltip } from 'kolkit';
import { Licence, LicenceDetails } from 'slices/organization.slice';

const renderLicenceDetails = (
  licence: Partial<Omit<LicenceDetails & Licence, 'id'> & { id: string | number }>,
  intl: IntlShape,
) => {
  if (licence.listening_name) return licence.listening_name;

  if (licence.days) {
    let details = '';
    if (licence.used_days) details += `${licence.days - licence.used_days}/`;
    details += intl.formatMessage(
      { id: 'global.settings.days' },
      { days: licence.days },
    );
    return (
      <Tooltip
        content={intl.formatMessage({ id: 'global.settings.days.tooltip' })}
      >
        <span>
          {details}
        </span>
      </Tooltip>
    );
  }

  return '-';
};

export default renderLicenceDetails;
