import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input2, Truncate } from 'kolkit';
import { PortalContainer } from 'components';

import './InputGmap.scss';

const mapGmapToApi = data => {
  const findComponent = (address, type, key = "short_name") => {
    if (!address) return '';

    const obj = address.find(a => a.types.indexOf(type) > -1);
    if (obj) return obj[key] || '';

    return '';
  };

  return data ? ({
    g_street_number: findComponent(data.address_components, 'street_number'),
    g_street: findComponent(data.address_components, 'route'),
    g_city: findComponent(data.address_components, 'locality'),
    g_state: findComponent(data.address_components, 'administrative_area_level_1'),
    g_zip_code: findComponent(data.address_components, 'postal_code'),
    g_country: findComponent(data.address_components, 'country', 'long_name'),
    g_country_code: findComponent(data.address_components, 'country'),
    g_geometry_location: data.geometry && data.geometry.location ? {
      lat: data.geometry.location.lat(),
      lng: data.geometry.location.lng(),
    } : null,
    g_place_id: data.place_id || '',
    g_formatted_address: data.formatted_address,
  }) : {};
};

export const mapper = {
  fromApi: ({ gmaps }) => ({
    placeSearched: gmaps ? gmaps.g_formatted_address || '' : ''
  }),
  toApi: gmap => !gmap ? {} : mapGmapToApi(gmap),
};

const loadScript = url => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    script.addEventListener('load', () => resolve(script));
    script.addEventListener('error', () => reject(script));
    document.body.appendChild(script);
  });
};

class InputGmap extends PureComponent {
  keyActive = -1;
  service = null;
  autocompleteService = null;
  delayType = null;

  state = {
    displayPredictions: false,
    predictions: [],
  };

  componentDidMount = async () => {
    const { GmapApiKey, GmapApiLanguage } = this.props;
    if (!window.scriptLoading) {
      window.scriptLoading = true;
      window.initMap = () => {};
      await loadScript(`https://maps.googleapis.com/maps/api/js?key=${GmapApiKey}&callback=initMap&libraries=places&language=${GmapApiLanguage}`);
    }
  };

  getInfoOnLocation = (placeId, placeName) => {
    if (!this.service) {
      this.service = new window.google.maps.places.PlacesService(document.createElement('div'));
    }

    const { onChange } = this.props;

    this.service.getDetails({ placeId }, place => {
      onChange({
        placeSearched: placeName,
        infoLocation: place,
        mapped: mapper.toApi(place)
      });
    })
  };

  onChange = ({ value: newValue }) => {
    const { onChange } = this.props;
    const { displayPredictions } = this.state;

    if (!displayPredictions) this.setState({ displayPredictions: true });

    if (!newValue || newValue === '') {
      this.setState({ displayPredictions: false, predictions: [] });
    }

    onChange({
      placeSearched: newValue,
      infoLocation: [],
      mapped: {}
    });

    if (this.delayType) clearTimeout(this.delayType);

    this.delayType = setTimeout(this.triggerAutoComplete(newValue), 200);
  };

  triggerAutoComplete = value => () => {
    const { google } = window;

    if (!google || !value) return null;

    if (!this.autocompleteService)
      this.autocompleteService = new google.maps.places.AutocompleteService();

    const { extraProperties } = this.props;

    this.autocompleteService.getPlacePredictions({ input: value, ...extraProperties },
      (prediction, status) => {
        let predictions = [];
        const index = predictions.findIndex(element =>
          element.description?.toLowerCase() === value?.trim()?.toLowerCase());

        const selectedId = index !== -1 ? predictions[index].place_id : null;

        if (status === 'OK') {
          predictions = prediction;
          this.setState({ predictions });
        }

        if (selectedId) this.getInfoOnLocation(selectedId, value);
      }
    )
  };

  onKeyPress = ({ keyCode: key }) => {
    if (this.state.predictions.length === 0 || (key !== 38 && key !== 40)) return null;
    if (key === 38) this.keyActive -= 1;
    if (key === 40) this.keyActive += 1;
    if (this.keyActive >= this.state.predictions.length) this.keyActive = 0;
    if (this.keyActive < 0) this.keyActive = this.state.predictions.length - 1;

    const selectedId = this.state.predictions[this.keyActive].place_id;
    const newValue = this.state.predictions[this.keyActive].description;
    this.getInfoOnLocation(selectedId, newValue);

    this.setState({ displayPredictions: true });
  };

  selectItemFromList = index => async () => {
    const target = this.state.predictions[index];
    const newValue = target.description;
    const selectedId = target.place_id;
    await this.getInfoOnLocation(selectedId, newValue);
    this.keyActive = index;
    this.setState({ displayPredictions: false });
  };

  onFocus = () => {
    this.setState({ displayPredictions: true });
  };

  onBlur = () => {
    setTimeout(() => this.setState({ displayPredictions: false }), 200);
  };

  onEnter = () => {
    this.setState({ displayPredictions: false })
  };

  inPortal = () => {
    const { displayPredictions, predictions } = this.state;

    return displayPredictions && predictions?.length > 0 && (
      <div className="bnc_field_input_gmap_predictions">
        <ul>
          {React.Children.toArray(predictions.map((prediction, index) => (
            <li
              className={this.keyActive === index ? 'active' : null}
              onClick={this.selectItemFromList(index)}
            >
              <Truncate>{prediction.description}</Truncate>
            </li>
          )))}
        </ul>
      </div>
    );
  };

  render() {
    const { placeholder, disabled, disablePortal, value, size } = this.props;
    const { displayPredictions } = this.state;

    return (
      <div className="bnc_field_input_gmap">
        <PortalContainer disablePortal={disablePortal} html={this.inPortal()} on={displayPredictions}>
          <Input2
            value={value}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onEnterPress={this.onEnter}
            disabled={disabled}
            fullWidth
            size={size}
          />
        </PortalContainer>
      </div>
    )
  }
}

InputGmap.defaultProps = {
  value:  '',
  placeholder: '',
  GmapApiLanguage: 'en',
  disabled: false,
  disablePortal: false,
  extraProperties: {
    types: ["(regions)"]
  }
};

InputGmap.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  GmapApiKey: PropTypes.string.isRequired,
  GmapApiLanguage: PropTypes.string,
  disabled: PropTypes.bool,
  disablePortal: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  extraProperties: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.oneOf([
      "geocode",
      "address",
      "establishment",
      "(regions)",
      "(cities)",
    ])),
    radius: PropTypes.number,
    offset: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      noWrap: PropTypes.bool
    })
  })
};

export default InputGmap;
