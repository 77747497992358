import { useEffect, useState } from 'react';
import {
  enUS as enLocale,
  fr as frLocale,
  es as esLocale,
  de as deLocale,
} from 'date-fns/locale';

import { useSelector } from './redux';

export const languageCodeToDateFNSLocale = (code: string) => {
  return code === 'fr-FR'
    ? frLocale
    : code === 'es-ES'
      ? esLocale
      : code === 'de-DE'
        ? deLocale
        : enLocale
};

export const useDateFnsLocale = () => {
  const [locale, setLocale] = useState(enLocale);
  const reduxLocaleString = useSelector(({ env: { locale } }) => locale);

  useEffect(() => {
    const currentLocale = languageCodeToDateFNSLocale(reduxLocaleString);
    setLocale(currentLocale);
  }, [locale, reduxLocaleString]);

  return locale;
};
