import { createAction } from '@reduxjs/toolkit';

import { updateDiscountCodes } from 'actions/campaignDetails';
import { update as updateCampaign } from 'actions/campaigns';
import { hideModal, setControlledLoader, showModal, updateModal } from 'actions/ui';
import * as api from 'api/shopify';
import * as apiUser from 'api/user';
import * as mappers from 'mappers/shopify';
import { SHOPIFY_MODAL_ID } from 'config/integrations';
import { AppThunk } from 'config/store';
import { SET_CONNECTED, SET_DISCONNECTED } from 'constants/shopify';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import toast from 'utils/toast';

export const setShopifyConnected = createAction<{
  store_id: string;
  store_name: string;
}>(SET_CONNECTED);

export const setShopifyDisconnected = createAction(SET_DISCONNECTED);

export const verifyNewCodesPromo = ({ recheck = false }): AppThunk<void> => async (dispatch, getState) => {
  const {
    views: {
      campaignDetails: {
        shopify: {
          newDiscountCodes: {
            kolsIds,
            prefix,
            sufix,
            patternType,
            codeName,
            reviewCodes,
          },
        },
      },
    },
  } = getState();

  const codes = !recheck && reviewCodes !== null
    ? Object.values(reviewCodes as {[id: number]: {profileId: number, code: string}})
        .reduce( (old, curr) => ({
          ...old,
          [curr?.profileId]: curr?.code?.toUpperCase()
        }), {})
    : null;

  const dataToApi = Object.assign({},
    { pattern: mappers.mapPatternTypes(patternType) },
    kolsIds.length > 1 && {
      prefix: prefix?.value?.toUpperCase(),
      suffix: sufix?.value?.toUpperCase(),
    },
    kolsIds.length === 1 && codes === null && {
      discount_codes_data: {[kolsIds?.[0]]: codeName?.toUpperCase()}
    },
    (codes === null && kolsIds.length > 1) && { profile_ids: kolsIds },
    codes !== null && { discount_codes_data: codes }
  );

  const response = await api.verifyNewCodesPromo(dataToApi);
  if (response) {
    const dataFromApi = [
      ...(response.invalid_codes || []),
      ...(response.valid_codes || []),
    ].reduce(
      (old, curr) => ({
        ...old,
        [curr.profile_id]: {
          profileId: curr.profile_id,
          code: curr.code,
          isMissingPattern: curr.is_missing_pattern,
          isExisting: curr.is_existing,
          isDuplicated: curr.is_duplicated,
          hasError:
            curr.is_missing_pattern || curr.is_existing || curr.is_duplicated,
          name: curr.profile_display_name,
          pseudo: curr.profile_pseudo,
          picture: curr.profile_picture,
        },
      }),
      {},
    );

    dispatch(updateDiscountCodes({
      key: 'reviewCodes',
      value: dataFromApi
    }));
  }
}

export const saveNewCodesPromo = (): AppThunk<void> => async (dispatch, getState) => {
  const {
    views: {
      campaignDetails: {
        id: campaignId,
        shopify: {
          newDiscountCodes: { codePromo, reviewCodes },
        },
      },
    },
  } = getState();

  const dataToApi = mappers.saveNewCodesPromo.toApi({
    codePromo,
    reviewCodes,
  });

  const response = await api.batchCreateDiscountCodes({
    id: campaignId,
    ...dataToApi
  }) as any[];

  const { success, failed } = mappers.saveNewCodesPromo.fromApi(response);

  const intl = getIntl();
  if (success?.length > 0) {
    toast(
      intl.formatMessage(
        { id: "shopify.toast.success.message"},
        { count: success?.length }
      ),
      {
        type: 'success',
        title: intl.formatMessage({ id: "global.success"}),
      }
    );
  }
  if (failed?.length > 0) {
    toast(
      intl.formatMessage(
        { id: "shopify.toast.error.message"},
        { count: failed?.length }
      ),
      {
        type: 'error',
        title: intl.formatMessage({ id: "shopify.toast.error.title"}),
      }
    );
  }

  dispatch(getDiscountCodesForCampaign());
}

export const openShopifyIntegration = (): AppThunk<void> => dispatch => {
  dispatch(
    showModal({
      id: SHOPIFY_MODAL_ID,
      data: { step: 0, appToken: '', hasError: false },
    }),
  );
};

export const removeShopifyIntegration = (): AppThunk<void> => async (
  dispatch,
  getState,
) => {
  const {
    shopify: { store_id },
  } = getState();

  const intl = getIntl();

  try {
    const res = await api.disconnectShopifyStore({
      store_id,
    });
    if (!res || res.error) {
      throw new Error(`${res.status}`);
    }
    dispatch(setShopifyDisconnected());
    toast(
      intl.formatMessage({ id: "toasts.disconnectShopify.success"}),
      { type: 'success' }
    );
  } catch {
    toast(
      intl.formatMessage({ id: "toasts.disconnectShopify.error"}),
      { type: 'error' }
    );
  }
};

export const connectShopify = (): AppThunk<void> => async (
  dispatch,
  getState,
) => {
  const LOADING_ID = 'connectShopifyStore';
  const {
    ui: {
      loader,
      modales: {
        [SHOPIFY_MODAL_ID]: {
          data: { appToken, address, ...rest },
        },
      },
    },
  } = getState();

  if (loader.includes(LOADING_ID)) return null;

  dispatch(setControlledLoader({ id: LOADING_ID, show: true }));

  const intl = getIntl();

  try {
    const res = await api.connectShopifyStore({
      name: address as string,
      access_token: appToken as string,
    });
    if (!res || res.error) {
      throw new Error(`${res.payload?.reason || 'access_rights'}`);
    }
    dispatch(
      setShopifyConnected({
        store_id: res.shopify_store.id,
        store_name: res.shopify_store.name,
      }),
    );
    dispatch(setControlledLoader({ id: LOADING_ID, show: false }));
    dispatch(
      hideModal({
        id: SHOPIFY_MODAL_ID,
      }),
    );
    toast(
      intl.formatMessage({ id: "toasts.connectShopify.success"}),
      {
        title: intl.formatMessage({ id: "global.success"}),
        type: 'success'
      }
    );
  } catch (e) {
    dispatch(setControlledLoader({ id: LOADING_ID, show: false }));
    dispatch(
      updateModal({
        id: SHOPIFY_MODAL_ID,
        datas: {
          data: {
            appToken,
            address,
            ...rest,
            hasError: true,
            errorCode: e instanceof Error ? e.message : e,
          },
        },
      }),
    );
  }
};

export const getDiscountCodesForCampaign = (): AppThunk<void> => async (
  dispatch,
  getState,
) => {
  const LOADING_ID = 'getDiscountCodesForCampaign';
  const {
    ui: { loader },
    views: {
      campaignDetails: { id: campaignId },
    },
  } = getState();
  if ((loader).includes(LOADING_ID)) return null;
  if (!campaignId) return null;

  dispatch(setControlledLoader({ id: LOADING_ID, show: true }));

  const intl = getIntl();
  try {
    const { discount_codes } = await api.getDiscountCodesForCampaign({
      campaign_id: campaignId,
    });
    const mapped = discount_codes.map(discount_code => ({
      name: discount_code.public_profile.name,
      mention: discount_code.public_profile.pseudo,
      id: discount_code.public_profile.id,
      discount_code_id: discount_code.id,
      picture: discount_code.public_profile.picture_url,
      discount_code: discount_code.key,
      status: discount_code.status,
      sent: discount_code.sent || false,
      sales_count: discount_code.sales_count || 0,
      sales_turnover: discount_code.sales_turnover || 0,
      start_date: discount_code.starts_at,
      end_date: discount_code.ends_at,
    }));

    dispatch(updateCampaign({ id: campaignId, discount_codes: mapped }));
  } catch {
    toast(
      intl.formatMessage({ id: "toasts.getDiscountCodesForCampaign"}),
      { type: 'error' }
    );
  } finally {
    dispatch(setControlledLoader({ id: LOADING_ID, show: false }));
  }
};

export const updateSentStatus = ({ campaignId, discountCodeId, isSent }): AppThunk<void> => async (dispatch) => {
  await api.updateSentStatus({
    campaign_id: campaignId,
    discount_code_id: discountCodeId,
    sent: !isSent,
  });
  dispatch(getDiscountCodesForCampaign());
}

export const saveCmsPreference = ({ platformName, onSuccess }) => async () => {
  const intl = getIntl();
  try {
    const res = await apiUser.updateUserPreferences({
      cmsPreference: platformName,
    });

    if (!res || res.error) {
      throw new Error();
    }
    toast(
      intl.formatMessage({ id: "toasts.saveCmsPreference.success.message"}),
      {
        title: intl.formatMessage({ id: "global.success"}),
        type: 'success'
      }
    );
    if (onSuccess) {
      onSuccess();
    }
  } catch {
    toast(
      intl.formatMessage({ id: "toasts.saveCmsPreference.error.message"}),
      {
        title: intl.formatMessage({ id: "global.oups"}),
        type: 'error'
      }
    );
  }
};

export const promptDeleteDiscountCode = ({
  discountCodeIds = [] as string[],
  profileIds = [] as string[] | undefined,
}): AppThunk<void> => (dispatch, getState) => {
  const discountCodeIdsFromProfileIds = [] as number[];
  if (profileIds?.length) {
    const {
      views: {
        campaignDetails: { id: campaignId },
      },
      campaigns,
    } = getState();

    if (campaignId && campaigns?.[campaignId]) {
      const campaign = campaigns[campaignId];
      discountCodeIdsFromProfileIds.push(
        ...profileIds.reduce((discountCodes, profileId) => {
          const discountCode = campaign.discount_codes.find(
            discountCode => Number(discountCode.id) === Number(profileId),
          );
          return discountCode?.discount_code_id &&
            discountCode.status !== 'deleted'
            ? [...discountCodes, Number(discountCode.discount_code_id)]
            : discountCodes;
        }, [] as number[]),
      );
    }
  }
  dispatch(
    showModal({
      id: 'shopify-delete-code',
      data: {
        discountCodeIds: [...discountCodeIdsFromProfileIds, ...discountCodeIds],
      },
    }),
  );
};

export const deleteDiscountCodes = ({
  discountCodeIds = [] as string[],
}): AppThunk<void> => async (dispatch, getState) => {
  const {
    views: {
      campaignDetails: { id: campaignId },
    },
  } = getState();

  const LOADING_ID = 'deleteDiscountCodes';
  const intl = getIntl();
  dispatch(setControlledLoader({ id: LOADING_ID, show: true }));

  try {
    if (!campaignId) throw new Error('CampaignId missing');
    const response = await api.disableDiscountCodes({
      discount_code_ids: discountCodeIds,
      campaign_id: campaignId,
    });

    if (!response || response.error) {
      throw new Error();
    }

    if (response.disable_ids?.length) {
      toast(
        intl.formatMessage(
          { id: "toasts.deleteDiscountCodes.success.message"},
          { count: response.disable_ids.length }
        ),
        {
          title: intl.formatMessage({ id: "global.success"}),
          type: 'success'
        }
      );
    }
    if (response.failure_ids?.length) {
      toast(
        intl.formatMessage(
          { id: "toasts.deleteDiscountCodes.failure.message"},
          { count: response.failure_ids.length }
        ),
        {
          title: intl.formatMessage({ id: "global.oups"}),
          type: 'warning'
        }
      );
    }
  } catch {
    toast(
      intl.formatMessage({ id: "toasts.deleteDiscountCodes.error.message"}),
      {
        title: intl.formatMessage({ id: "global.oups"}),
        type: 'error'
      }
    );
  } finally {
    dispatch(hideModal({ id: 'shopify-delete-code' }));
    dispatch(getDiscountCodesForCampaign());
  }
  dispatch(setControlledLoader({ id: LOADING_ID, show: false }));
};
