
export const LOCALISATION_TYPES = ['country', 'city'] as const;

export const INSTAGRAM_CONTENT_TYPES = ['all', 'post', 'reel', 'story'] as const;

export const SNAPCHAT_CONTENT_TYPES = ['all', 'saved_story', 'spotlight'] as const;

export const AGES_SLICE = ['13-17', '18-24', '25-34', '35-44', '45-64', '65-'];

export const CONTENT_STATS = [
    {
        api: 'nb_of_kol',
        label: 'forecast.statistics.nbrKols.label',
    },
    {
        api: 'content',
        label: 'forecast.statistics.vlmContent.label',
    },
    {
        api: 'engagement_rate',
        label: 'forecast.statistics.engagementRate.label',
        disabledContents: ['story'],
    },
    {
        api: 'engagements',
        label: 'forecast.statistics.engagement.label',
        disabledContents: ['story'],
    },
    {
        api: 'impressions',
        label: 'forecast.statistics.impression.label',
        disabledSnas: ['youtube', 'snapchat']
    },
    {
        api: 'reach',
        label: 'forecast.statistics.reach.label',
        disabledSnas: ['youtube', 'tiktok']
    },
    {
        api: 'avg_views',
        label: 'forecast.statistics.avgViews.label',
        disabledSnas: ['all', 'instagram', 'tiktok', 'facebook']
    },
    {
        api: 'emv',
        label: 'forecast.statistics.emv.label',
        disabledSnas: ['snapchat']
    },
]

export const DEFAULT_DATA = {
    followersInsight: {
        audience_credibility: null,
        total_followers: null,
        female: null,
        male: null,
        interests: [],
        languages: [],
        locations: []
    },
    statistics: {
        nb_of_kol: 0,
        content: 0,
        engagement_rate: null,
        engagements: null,
        impressions: null,
        reach: null,
        emv: null,
    }
}