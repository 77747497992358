import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Portal } from 'components';
import useKeyboard from 'utils/hooks/useKeyboard';
import useLockBodyOnScroll from 'utils/hooks/useLockBodyOnScroll';

import styles from './Modal.module.scss';


const Modal = ({ on, setToggle, children, className }) => {
  useLockBodyOnScroll(on);
  const keyPressed = useKeyboard('Escape');

  useEffect(() => {
    if (keyPressed && on) setToggle();
    // eslint-disable-next-line
  }, [keyPressed, on]);

  const cnModal = cn(styles.modal, {
    [styles.display]: on,
  });

  const cnContent = cn(styles.content, className);

  return on && (
    <Portal>
      <div className={cnModal}>
        <div
          onClick={setToggle}
          className={styles.overlay}
          role="dialog"
        />
        <div
          className={cnContent}
          onClick={e => e.stopPropagation()}
          role="contentinfo"
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};

Modal.dislayName = 'Modal';

Modal.propTypes = {
  on: PropTypes.bool.isRequired,
  setToggle: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: '',
};

export default memo(Modal);
