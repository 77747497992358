import React, { Children, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { HeightTransition, Modal2, Input2, Text, Spaces } from 'kolkit';

import { saveSearch, getSavedSearches, deleteSearch, updateSearch } from 'actions/engine';
import { useSelector, useDispatch } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import renderModal from 'utils/HOCs/renderModal';

import { ItemModal } from '../../Components';

const MODAL_ID = 'saveSearch';

const SaveSearch = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [searchName, setSearchName] = useState('');
  const [selected, setSelected] = useState(null);

  const { show, off } = useModal(MODAL_ID);

  const savedSearches = useSelector(({ engine }) => engine.savedSearch);

  const handleSelect = useCallback(
    item => setSelected(item),
    []
  );

  const handleDelete = useCallback(
    async (id, name) => {
      if(selected?.id === id) { // Reset current selected item already deleted
        setSelected(null);
      }

      await dispatch(deleteSearch(id, name))
      await dispatch(getSavedSearches());
      return true;
    },
    [dispatch, selected]
  );

  const handleSaveSearch = useCallback(
    async () => {
      if (selected) {
        await dispatch(updateSearch({
          id: selected.id,
          name: selected.name,
          query: window.location.href,
        }));
      }
      if (!selected && searchName?.length > 0) {
        await dispatch(saveSearch({
          name: searchName,
          query: window.location.href,
        }));
      }
      await setSearchName('');
      await dispatch(getSavedSearches());
      off();
    },
    [dispatch, searchName, selected, off]
  );

  const handleChange = useCallback(
    ({ value }) => setSearchName(value),
    []
  );

  const actions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: 'global.cta.save' }),
        onClick: () => handleSaveSearch(),
        disabled: !selected && (!searchName || searchName === '')
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: () => off(),
      }
    }),
    [handleSaveSearch, off, searchName, selected, intl]
  );

  const displaySavedSearches = useMemo(
    () => Children.toArray(
      savedSearches?.map(({ id, name, query }) => (
        <ItemModal
          id={id}
          name={name}
          query={query}
          onSelect={handleSelect}
          onDelete={handleDelete}
          selected={selected?.id === id}
        />
      ))
    ),
    [savedSearches, handleSelect, handleDelete, selected]
  );

  return (
    <Modal2
      title={intl.formatMessage({ id: 'engineSearch.save.addTitle' })}
      on={show}
      onClick={off}
      action={actions}
      onExit={() => setSelected(null)}
    >
      <div>
        <Input2
          placeholder={intl.formatMessage({ id: 'engineSearch.save.name' })}
          onChange={handleChange}
          fullWidth
        />
        <Spaces top={24} />
        {savedSearches?.length > 0 ? (
          <>
            <Text>{intl.formatMessage({ id: 'global.saveAs' })}</Text>
            <HeightTransition on={Boolean(savedSearches)}>
              {displaySavedSearches}
            </HeightTransition>
          </>
        ) : null}
      </div>
    </Modal2>
  )
};

export default renderModal(MODAL_ID, SaveSearch);
