const datesFormatter = {
  'dd/mm/yyyy': {
    slashDate: 'DD/MM/YYYY',
    slashDateShort: 'DD/MM/YY',
    shortDate: 'DD MMM',
    shortDateY: 'DD MMM YYYY',
    mediumDate: 'D MMMM',
    mediumDateY: 'D MMMM YYYY',
    longDate: 'dddd D MMMM YYYY',
    commaLongDate: 'dddd, D MMMM, YYYY',
    monthYear: 'MMMM YYYY',
  },
  'mm/dd/yyyy': {
    slashDate: 'MM/DD/YYYY',
    slashDateShort: 'MM/DD/YY',
    shortDate: 'MMM DD',
    shortDateY: 'MMM DD YYYY',
    mediumDate: 'MMMM D',
    mediumDateY: 'MMMM D YYYY',
    longDate: 'dddd MMMM D YYYY',
    commaLongDate: 'dddd, MMMM D, YYYY',
    monthYear: 'MMMM YYYY',
  }
};

export default datesFormatter;
