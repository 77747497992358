import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'kolkit/Loader';

const CampaignsList = Loadable({
  loader: () => import('components/campaigns/CampaignsList/CampaignsList'),
  loading: () => <Loader full />,
});

export default CampaignsList;

