import React, { Children, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl-phraseapp';
import {HeightTransition, Item, Modal2} from 'kolkit';

import { deleteSearch, getSavedSearches } from 'actions/engine';
import {  useDispatch,useSelector } from 'utils/redux';
import useModal from 'utils/hooks/useModal';

import { ItemModal } from '../../Components';

import styles from './savedSearches.module.scss';


const SavedSearches = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const [selected, setSelected] = useState(null);

  const { show, off } = useModal('savedSearched');

  const savedSearches = useSelector(({ engine }) => engine.savedSearch);

  const handleSelect = useCallback(
    item => setSelected(item),
    []
  );

  const handleDelete = useCallback(
    async (id, name) => {
      await dispatch(deleteSearch(id, name))
      await dispatch(getSavedSearches());
      return true;
    },
    [dispatch]
  );

  const handleApply = useCallback(
    async () => {
      await navigate({
        pathname: '/',
        search: `?${selected?.query}`
      });
      off();
    },
    [navigate, selected, off]
  );

  const actions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: 'global.cta.select' }),
        onClick: () => handleApply(),
        disabled: !savedSearches?.length || !selected
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: () => off(),
      }
    }),
    [handleApply, savedSearches, selected, off, intl]
  );

  return (
    <Modal2
      title={intl.formatMessage({ id: 'engineSearch.save.listTitle' })}
      on={show}
      onClick={off}
      action={actions}
      onExit={() => setSelected(null)}
    >
      <HeightTransition on={Boolean(savedSearches)}>
        {savedSearches?.length > 0
          ? Children.toArray(
            savedSearches?.map(({ id, name, query }) => (
              <ItemModal
                id={id}
                name={name}
                query={query}
                onSelect={handleSelect}
                onDelete={handleDelete}
                selected={selected?.id === id}
              />
            )))
          : (
            <Item className={styles.searchesItem}>
              {intl.formatMessage({ id: 'engineSearch.save.empty' })}
            </Item>
          )
        }
      </HeightTransition>
    </Modal2>
  )
};

export default SavedSearches;
