import React, { memo, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { TextareaAutosize } from '@mui/material'
import { Icon } from 'kolkit';

import styles from './Textarea2.module.scss';

type Props = {
  value: string;
  id?: string;
  className?: string;
  error?: boolean;
  errorText?: string;
  errorIcon?: string;
  fullWidth?: boolean;
  valid?: boolean;
  helper?: string;
  showNumberOfLines?: boolean;
  maxLines?: number;
  onChange?: (value: string) => void;
  autoGrowing?: boolean;
} & Omit<JSX.IntrinsicElements['textarea'], 'onChange'>;

const Textarea2: React.FC<Props> = ({
  placeholder,
  id,
  name,
  value,
  cols = 20,
  rows = 5,
  required,
  disabled,
  form,
  onChange,
  className,
  error,
  errorText,
  errorIcon,
  valid,
  helper,
  showNumberOfLines,
  maxLines,
  autoGrowing,
  ...rest
}) => {
  const numberOfLines = useMemo(() => {
    if (showNumberOfLines)
      return `${value ? value.toString().split('\n').length : 0}${
        maxLines ? `/${maxLines}` : ''
      }`;
    return null;
  }, [showNumberOfLines, maxLines, value]);

  const handleChange = useCallback(
    (newValue: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = newValue.target;
      if (maxLines && value.split('\n').length > maxLines) {
        return null;
      }

      if (onChange) onChange(value);
    },
    [onChange, maxLines],
  );

  const container = cn(styles.container, className, {
    [styles.hasValue]: value !== '',
    [styles.hasSuccess]: valid,
  });

  const renderTextarea = useMemo(
    () => {
      const cnTextarea = cn(styles.textarea, {
        [styles.hasError]: error,
      });

      if (autoGrowing) return (
        <TextareaAutosize
          id={id}
          className={cnTextarea}
          placeholder={placeholder}
          name={name}
          value={value}
          required={required}
          onChange={handleChange}
          disabled={disabled}
          minRows={rows}
        />
      );

      return (
        <textarea
          {...rest}
          id={id}
          className={cnTextarea}
          placeholder={placeholder}
          name={name}
          value={value}
          cols={cols}
          rows={rows}
          required={required}
          disabled={disabled}
          form={form}
          onChange={handleChange}
          aria-multiline="true"
          aria-required={required}
          spellCheck={false}
        />
      )
    },
    [autoGrowing, placeholder, name, value, handleChange, rows, form, cols, required, disabled, id, rest, error]
  );

  return (
    <div className={container}>
      {renderTextarea}
      <div>
        <div>
          {helper && <div className={styles.helper}>{helper}</div>}
          {error && (
            <div className={styles.error}>
              {errorIcon && <Icon label={errorIcon} theme="solid" />}
              {errorText}
            </div>
          )}
        </div>
        <div className={styles.numberOfLines}>{numberOfLines}</div>
      </div>
    </div>
  );
};

Textarea2.defaultProps = {
  id: undefined,
  className: '',
  error: false,
  errorText: '',
  errorIcon: '',
  fullWidth: false,
  valid: true,
  helper: '',
  showNumberOfLines: false,
  maxLines: undefined,
  autoGrowing: false,
};

export default memo(Textarea2);
