
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputCheck from './InputCheck';
import styles from './InputCheck.module.scss';

const InputCheckGroup = ({
  className,
  type,
  direction,
  disabled,
  error,
  onChange,
  options,
  required,
  selectedOption,
  touched,
  ref,
}) => {
  const handleChange = e => {
    const isChecked = selectedOption.indexOf(e.currentTarget.value) > -1;
    const selected = !isChecked
      ? [...selectedOption, e.currentTarget.value]
      : selectedOption.filter((option) => option !== e.currentTarget.value);

    return onChange({
      item: e.currentTarget.value,
      selected,
    })
  };

  const cnInputCheckGroup = cn(
    styles.inputCheckGroup,
    styles[direction],
    className,
  );

  return (
    <div className={cnInputCheckGroup}>
      {options && React.Children.toArray(options.map(option => {
        const { id, label } = option;
        const value = option.value ? option.value : option.label;

        return (
          <InputCheck
            ref={ref}
            id={id}
            type={type}
            name={value}
            label={label}
            value={value}
            checked={selectedOption.indexOf(value) > -1}
            disabled={disabled}
            onChange={handleChange}
            required={required}
          />
        );
      }))}
      {touched && error && error.length > 0 && (
        <span className={styles.error}>{error}</span>
      )}
    </div>
  );
};

InputCheckGroup.displayName = 'InputCheckGroup';

InputCheckGroup.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  required: PropTypes.bool,
  selectedOption: PropTypes.arrayOf([PropTypes.string]),
  touched: PropTypes.bool,
};

InputCheckGroup.defaultProps = {
  type: 'checkbox',
  className: '',
  direction: 'horizontal',
  disabled: false,
  error: '',
  required: false,
  selectedOption: '',
  touched: true,
  onChange: null,
};

export default InputCheckGroup;
