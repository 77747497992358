import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  memo,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';

import {
  Checkbox2,
  Icon,
  Input2,
  Item,
  HeightTransition,
  Truncate,
} from 'kolkit';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { MenuProps as MuiMenuProps } from '@mui/material';

import styles from './SelectMultiple.module.scss';

type Option<T = string> = {
  label: string;
  react?: Element;
  value: T;
  disabled?: boolean;
  dataId?: string;
  style?: React.CSSProperties;
};

type Props<T = string> = {
  labelId?: string;
  options: Option<T>[];
  selected?: Option<T>['value'][];
  noneIsAll?: boolean;
  onChange?: (selection: {
    item: Option<T>['value'];
    selected: Option<T>['value'][];
  }) => void;
  renderValue?: (selected: Option<T>['value'][]) => React.ReactNode;
  inputSearchPlaceholder?: string;
  searchable?: boolean;
  searchKey?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  theme?: 'primary' | 'secondary' | 'tone';
  className?: string;
  allOptionLabel?: string;
  allOptionDataId?: string;
  fullWidth?: boolean;
  popperMaxWidth?: string | number;
  error?: boolean;
  errorMessage?: string;
  emptyOptionLabel?: string;
  anchorOrigin?: {
    vertical?: 'top' | 'center' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
  };
  transformOrigin?: {
    vertical?: 'top' | 'center' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
  };
  dataId?: string;
};

const defaults = {
  labelId: undefined,
  options: [],
  selected: [],
  noneIsAll: false,
  onChange: ({ item, selected }) => {
    console.log('item: ', item, '; selected: ', selected);
  },
  renderValue: undefined,
  inputSearchPlaceholder: '',
  searchable: false,
  searchKey: undefined,
  disabled: false,
  placeholder: '',
  size: 'medium' as const,
  theme: 'primary' as const,
  className: '',
  allOptionLabel: '',
  allOptionDataId: undefined,
  fullWidth: false,
  error: false,
  errorMessage: '',
  emptyOptionLabel: '',
  dataId: undefined,
};

function SelectMultiple<T extends string | number = string>({
  labelId = defaults.labelId,
  options = defaults.options,
  selected: _selected = defaults.selected,
  noneIsAll = defaults.noneIsAll,
  renderValue: _renderValue = defaults.renderValue,
  onChange = defaults.onChange,
  inputSearchPlaceholder = defaults.inputSearchPlaceholder,
  searchable = defaults.searchable,
  searchKey = defaults.searchKey,
  placeholder = defaults.placeholder,
  disabled = defaults.disabled,
  size = defaults.size,
  className = defaults.className,
  allOptionLabel = defaults.allOptionLabel,
  allOptionDataId = defaults.allOptionDataId,
  theme = defaults.theme,
  fullWidth = defaults.fullWidth,
  popperMaxWidth,
  error = defaults.error,
  errorMessage = defaults.errorMessage,
  emptyOptionLabel = defaults.emptyOptionLabel,
  dataId = defaults.dataId,
  anchorOrigin,
  transformOrigin,
  ...rest
}: Props<T>) {
  const intl = useIntl();

  const [searchText, setSearchText] = useState<string>('');
  const [selected, setSelected] = useState<Option<T>['value'][]>(_selected);

  const triggerRef = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState(0);

  useEffect(() => {
    setSelected(_selected);
  }, [_selected]);

  useLayoutEffect(() => {
    setMenuWidth(triggerRef?.current?.clientWidth || 0);
  }, []);

  const menuProps = useMemo<Partial<MuiMenuProps>>(
    () => ({
      PaperProps: {
        className: cn(styles.menu, styles[size]),
        style: {
          maxWidth: popperMaxWidth || (fullWidth ? menuWidth : 'auto'),
          maxHeight: 420,
        },
      },
      MenuListProps: {
        className: styles.menuList,
      },
      anchorOrigin: {
        vertical: anchorOrigin?.vertical || 'bottom',
        horizontal: anchorOrigin?.horizontal || 'center',
      },
      transformOrigin: {
        vertical: transformOrigin?.vertical || 'top',
        horizontal: transformOrigin?.horizontal || 'center',
      },
      variant: 'menu',
    }),
    [
      size,
      popperMaxWidth,
      fullWidth,
      menuWidth,
      anchorOrigin?.vertical,
      anchorOrigin?.horizontal,
      transformOrigin?.vertical,
      transformOrigin?.horizontal,
    ],
  );

  const displayedOptions = useMemo(
    () =>
      options
        .filter(option => option.label !== 'All favorites')
        .filter(option => option.label !== 'My favorites')
        .filter((option) => option?.[searchKey || 'label']?.toLowerCase().includes(searchText),
      ),
    [options, searchText, searchKey],
  );

  const getAllValues = useMemo(
    () => options
      .filter(option => option.label !== 'All favorites')
      .filter(option => option.label !== 'My favorites')
      .map(({ value }) => value),
    [options]
  );

  const isAllSelected = useMemo(
    () => selected?.length > 0 && selected?.flat()?.length === displayedOptions?.length,
    [selected, displayedOptions],
  );

  const halfChecked = useMemo(
    () => options?.length > 0 && selected?.length > 0 && selected?.length < options?.length,
    [options, selected],
  );

  const handleChange = useCallback(
    e => {
      const { value } = e.target;
      // if(value?.length === 0) return false;

      // const newSelection = value?.includes('all')
      //   ? getAllValues
      //   : value


      let newSelection = value;

      if (value[value?.length - 1] === 'all') {
        // eslint-disable-next-line no-multi-assign
        newSelection = isAllSelected || noneIsAll ? [] : getAllValues;
      }

      setSelected(newSelection);
      onChange({ item: value[value?.length - 1], selected: newSelection });
    },
    [getAllValues, isAllSelected, onChange, noneIsAll],
  );

  const handleChangeSearch = useCallback(
    ({ value }) => setSearchText(value || ''),
    []
  );

  const renderValue = useCallback(
    (selected) => {
      if (!selected?.length && !noneIsAll) {
        return (
          <div className={styles.placeholder}>
            {placeholder || allOptionLabel}
          </div>
        );
      }
      const renderNotEmpty = _renderValue ? (
        _renderValue(selected)
      ) : (
        <FormattedMessage
          id="global.select.selected"
          values={{ count: selected?.length }}
        />
      );
      return (
        <div className={styles.content}>
          <Truncate>
            {renderNotEmpty}
          </Truncate>
        </div>
      );
    },
    [_renderValue, placeholder, allOptionLabel, noneIsAll],
  );

  const labelAll = useMemo(
    () => allOptionLabel || intl.formatMessage({ id: 'global.selectAll' }),
    [allOptionLabel, intl]
  );

  const labelEmptyOptionLabel = useMemo(
    () => emptyOptionLabel || intl.formatMessage({ id: 'global.inputSearch.noResult' }),
    [emptyOptionLabel, intl]
  );

  const labelInputSearchPlaceholder = useMemo(
    () => inputSearchPlaceholder || intl.formatMessage({ id: 'global.search.title' }),
    [inputSearchPlaceholder, intl]
  );

  const cnWrapper = cn(
    styles.wrapper,
    { [styles.fullWidth]: fullWidth },
    className
  );

  const cnSelect = cn(styles.select, styles[size], styles[theme], {
    [styles.filled]: selected?.length,
  });

  return (
    <FormControl variant="outlined" className={cnWrapper} error={error}>
      <Select
        {...rest}
        data-id={dataId}
        labelId={labelId}
        multiple
        disabled={disabled}
        value={selected}
        onChange={handleChange}
        displayEmpty
        renderValue={renderValue}
        MenuProps={menuProps}
        className={cnSelect}
        classes={{
          icon: styles.icon,
          disabled: styles.disabled,
        }}
        IconComponent={(props) => {
          return (
            <Icon {...props} label="caret-circle-down" theme="solid" fill="" />
          );
        }}
        fullWidth={fullWidth}
        ref={triggerRef}
      >
        {/*
          TextField is put into ListSubheader so that it doesn't act as a selectable item in the menu,
          we can click the TextField without triggering any selection.
        */}
        {searchable ? (
          <ListSubheader className={styles.subheader}>
            <Input2
              autoFocus
              value={searchText}
              fullWidth
              theme="secondary"
              size={size === 'large' ? 'medium' : 'small'}
              placeholder={labelInputSearchPlaceholder}
              onChange={handleChangeSearch}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        ) : null}
        {displayedOptions?.length ? (
          <MenuItem
            value="all"
            className={styles.item}
            data-id={allOptionDataId}
          >
            <Checkbox2
              label={labelAll}
              checked={isAllSelected || halfChecked || noneIsAll}
              halfchecked={halfChecked}
              className={styles.checkbox}
            />
          </MenuItem>
        ) : (
          <ListSubheader className={styles.subheader}>
            <Item className={styles.noResult}>
              {labelEmptyOptionLabel}
            </Item>
          </ListSubheader>
        )}
        {displayedOptions?.map(({ label, react, value, disabled, dataId, style }) => (
          <MenuItem
            key={value}
            value={value}
            disabled={disabled}
            className={styles.item}
            data-id={dataId}
            style={style}
          >
            <Checkbox2
              label={react || label}
              checked={selected.includes(value)}
              className={styles.checkbox}
            />
          </MenuItem>
        ))}
      </Select>
      <div className={styles.helper}>
        <HeightTransition
          config={{
            tension: 340,
            friction: 26,
            mass: 1,
            precision: 1,
          }}
          on={error}
        >
          <div className={styles.wrapper}>
            <Icon
              label="exclamation-circle"
              size="small"
              theme="solid"
              fill="#E93030"
            />
            <span className={styles.message}>{errorMessage}</span>
          </div>
        </HeightTransition>
      </div>
    </FormControl>
  );
}

export default memo(SelectMultiple) as typeof SelectMultiple;
