import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

import { Truncate, Icon } from 'kolkit';

import ConditionalWrapper from 'components/ui/ConditionalWrapper';
import Image from 'components/ui/LazyImage/Image';
import Typography from 'components/ui/Typography';
import { STATS_SHARING_STATUS } from 'constants/consent';
import useNumberFormat from 'utils/hooks/useNumberFormat';

import styles from './Avatar.module.scss';

const Avatar: React.FC<Props> = ({
  image,
  fallback,
  name,
  mention,
  communityCount,
  form = defaultProps.form,
  withBorder,
  statsSharingStatus,
  size = defaultProps.size,
  profileUrl,
  className,
  customSize,
  noGap,
  grouped,
  highlight,
  formatMentionLink,
  onClick,
  imageOnly,
  ...rest
}) => {
  const intl = useIntl();
  const formatNumber = useNumberFormat();

  const customSizeStyle = useMemo(
    () => (customSize ? { width: customSize, height: customSize } : null),
    [customSize],
  );

  const showStatsSharingStatus = useMemo(
    () => Boolean(statsSharingStatus && [
      STATS_SHARING_STATUS.REQUESTED,
      STATS_SHARING_STATUS.APPROVED,
      STATS_SHARING_STATUS.AUTO_REQUESTED
    ].includes(statsSharingStatus)),
    [statsSharingStatus]
  );

  const cnRoot = cn(
    styles.root,
    styles[size],
    { [styles.noGap]: noGap },
    { [styles.grouped]: grouped },
    className,
  );
  const cnImage = cn(styles.image, styles[size], styles[form], {
    [styles.withBorder]: withBorder,
  });
  const cnTitle = cn(styles.title, styles[size], {
    [styles.statsSharingStatus]: showStatsSharingStatus,
  });
  const cnSubtitle = cn(styles.subtitle, styles[size], {
    [styles.mention]: mention,
  });

  return (
    <ConditionalWrapper
      wrapper={(children) =>
        profileUrl ? (
          <Link to={profileUrl} onClick={onClick} className={cnRoot} {...rest}>
            {children}
          </Link>
        ) : (
          <div role="button" onClick={onClick} className={cnRoot} {...rest}>
            {children}
          </div>
        )
      }
    >
      <Image
        className={cnImage}
        src={image}
        fallback={fallback}
        placeholder="/svg/avatar-placeholder-large.svg"
        alt={mention || name}
        style={customSizeStyle}
        loading="lazy"
      />

      {!imageOnly && (
        <>
          <Typography
            className={cnTitle}
            variant={size === 'small' ? 'kol/small-identity' : 'kol/identity'}
          >
            <Truncate>{name}</Truncate>
            {showStatsSharingStatus && (
              <Icon
                tooltip={intl.formatMessage({ id: `statsSharing.status.${statsSharingStatus}`})}
                label="square-poll-vertical"
                theme="solid"
                className={statsSharingStatus && styles[statsSharingStatus]}
              />
            )}
          </Typography>
          <Typography
            className={cnSubtitle}
            variant={size === 'small' ? 'kol/small-username' : 'kol/username'}
          >
            <Truncate>
              {highlight ? (
                <span
                  className={styles.highlight}
                  dangerouslySetInnerHTML={{ __html: highlight }}
                />
              ) : (
                mention && (
                  <ConditionalWrapper
                    wrapper={(children) =>
                      formatMentionLink ? (
                        <a
                          href={formatMentionLink(mention)}
                          target="_blank"
                          rel="noreferrer"
                          onClick={stopClickPropagation}
                        >
                          {children}
                        </a>
                      ) : (
                        <>{children}</>
                      )
                    }
                  >
                    @{mention}
                  </ConditionalWrapper>
                )
              )}
              {mention && !!communityCount && ' - '}
              {!!communityCount && (
                <FormattedMessage
                  id="global.followersNumber"
                  values={{ number: formatNumber(communityCount) }}
                />
              )}
            </Truncate>
          </Typography>
        </>
      )}
    </ConditionalWrapper>
  );
};

const stopClickPropagation: React.MouseEventHandler = (e) =>
  e.stopPropagation();

interface Props {
  name?: string;
  mention?: string;
  communityCount?: number;
  image?: string;
  fallback?: string;
  form?: 'square' | 'circle';
  withBorder?: boolean;
  statsSharingStatus?: string;
  size?: 'small' | 'medium' | 'large';
  customSize?: string;
  className?: string;
  noGap?: boolean;
  grouped?: boolean;
  profileUrl?: string;
  highlight?: string;
  formatMentionLink?: (mention: string) => string;
  onClick?: (e: React.MouseEvent) => void;
  imageOnly?: boolean;
  style?: React.CSSProperties;
  rel?: string;
  target?: string;
}

const defaultProps = {
  name: '',
  mention: '',
  communityCount: undefined,
  image: undefined,
  fallback: undefined,
  form: 'square' as const,
  withBorder: undefined,
  statsSharingStatus: undefined,
  size: 'medium' as const,
  customSize: undefined,
  className: undefined,
  noGap: false,
  grouped: false,
  profileUrl: undefined,
  highlight: undefined,
  formatMentionLink: undefined,
  onClick: undefined,
  imageOnly: false,
  rel: '',
  target: '',
};

Avatar.defaultProps = defaultProps;

Avatar.displayName = 'Avatar';

export default memo(Avatar);
