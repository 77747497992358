import React, { useCallback, useEffect, useMemo, memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

import { Button3, Icon, Loader } from 'kolkit';
import { DELIVERY_NETWORKS } from 'constants/networks';
import { CAMPAIGN_STATES, CONTENT_VALIDATION_KEYS } from 'constants/states';

import useModal from 'utils/hooks/useModal';
import usePanel from 'utils/hooks/usePanel';
import useLoading from 'utils/hooks/useLoading';
import { useDispatch, useSelector } from 'utils/redux';
import useKolStatuses from 'components/campaigns/details/hooks/useKolStatuses';
import { updateProfileState } from 'actions/campaigns';
import {
  updateContentInstructions,
  getContentProposals,
} from 'slices/contentValidation.slice';

import {
  InlineMessage,
  Markdown,
  Textarea2,
  Tile,
  Typography,
} from 'components/ui';

import { PANEL_ID } from '../ProfilePanel';

import DisplayExpectedContent from './components/DisplayExpectedContent';
import ContentToBeApproved from './components/ContentToBeApproved';
import { MODAL_ID as UPLOAD_CONTENT_VERSION_MODAL } from './components/UploadContentVersionModal';
import ContentInstructionsModal, {
  MODAL_ID as CONTENT_INSTRUCTIONS_MODAL,
} from './components/ContentInstructionsModal';
import DisplayContentVersionModal from './components/DisplayContentVersionModal';

import styles from './TabContent.module.scss';

const TAB_CONTENT_ACTIONS = [
  'campaign/content_validation/getContentRequests',
  'campaign/content_validation/getContentProposals',
  'campaign/content_validation/updateContentInstructions',
  'campaign/content_validation/updateContentRequestStatus',
];

const TabContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { statuses } = useKolStatuses();
  const [contentInstructionsState, setContentInstructionsState] = useState('');

  const { on: openWorkflowPanel } = usePanel('editCampaignWorkflow');
  const {
    show,
    off: closeProfilePanel,
    data,
    onUpdateData,
  } = usePanel(PANEL_ID, false);
  const { on: showUploadContentModal } = useModal(UPLOAD_CONTENT_VERSION_MODAL);
  const { on: showInstructionsModal } = useModal(CONTENT_INSTRUCTIONS_MODAL);
  const { loading: saveStatesLoding } = useLoading('saveStates');
  const profileId = useMemo(() => data?.profileId || '', [data?.profileId]);

  const {
    contentValidationLoading,
    campaignId,
    profile,
    isCampaignArchived,
    isAdmin,
    contentInstructions,
    aggregateContentRequests,
    contentRequests,
    contentProposals,
    isUploadInProgress,
  } = useSelector(({ campaigns, views, user, contentValidation }) => {
    const campaignId = views.campaignDetails.id;
    const isCampaignArchived = campaignId
      ? campaigns[campaignId]?.core.state === CAMPAIGN_STATES.ARCHIVED
      : false;
    const profile = campaignId
      ? campaigns[campaignId]?.profiles[profileId]
      : null;
    return {
      campaignId,
      profile,
      isCampaignArchived,
      isAdmin: !!user.profile.admin,
      contentValidationLoading: contentValidation.loading,
      contentInstructions: profile?.content_instructions || '',
      aggregateContentRequests: campaignId
        ? contentValidation.contentRequestsByCampaignAggregated[campaignId]?.[
            profileId
          ] || {}
        : {},
      contentRequests: campaignId
        ? contentValidation.contentRequestsByCampaign[campaignId]?.[
            profileId
          ] || []
        : [],
      contentProposals: campaignId
        ? contentValidation.contentProposalsToEdit || []
        : [],
      isUploadInProgress: contentValidation.isUploadInProgress,
    };
  });

  const handleGoToMediaPlan = useCallback(() => {
    if (onUpdateData) onUpdateData({ tabToOpen: 'delivery' });
  }, [onUpdateData]);

  const {
    isKOLInContentInstructions,
    isKOLInContentApprovalOrAfter,
    contentApprovalStatusId,
  } = useMemo(() => {
    const contentInstructionsStatusId = statuses.find(
      (status) =>
        status.is_default && status.key === CONTENT_VALIDATION_KEYS[0],
    )?.id;
    const contentApprovalStatusIndex = statuses.findIndex(
      (status) =>
        status.is_default && status.key === CONTENT_VALIDATION_KEYS[1],
    );
    const currentStatusIndex = statuses.findIndex(
      (status) => status.id === profile?.state,
    );
    const contentApprovalStatusId = statuses[contentApprovalStatusIndex]?.id;

    return {
      isKOLInContentInstructions:
        contentInstructionsStatusId === profile?.state,
      isKOLInContentApprovalOrAfter:
        contentApprovalStatusId === profile?.state ||
        (contentApprovalStatusIndex !== -1 &&
          currentStatusIndex > contentApprovalStatusIndex),
      contentApprovalStatusId,
    };
  }, [profile?.state, statuses]);

  const handleChangeContentInstruction = useCallback((value) => {
    setContentInstructionsState(value);
  }, []);

  const { isMediaPlanEmpty, isSomeMediaPlanWithNoDate } = useMemo(() => {
    const mediaPlanDates = DELIVERY_NETWORKS.flatMap(({ network, mediaType }) =>
      Object.keys(aggregateContentRequests[network]?.[mediaType] || {}),
    );
    return {
      isMediaPlanEmpty: mediaPlanDates?.length === 0,
      isSomeMediaPlanWithNoDate: mediaPlanDates?.some((date) => !date),
    };
  }, [aggregateContentRequests]);

  const handleClickApply = useCallback(() => {
    if (campaignId && !isMediaPlanEmpty) {
      void dispatch(
        updateContentInstructions({
          profile_id: profileId,
          project_id: campaignId,
          content_instructions: contentInstructionsState,
        }),
      ).then(() => {
        // INFO: Wait for instructions to be updated before updating status, backend don't like if it's at the same time
        // Move KOL to next status if media plan is filled
        if (contentApprovalStatusId) {
          void dispatch(
            updateProfileState({
              profileId,
              profilesId: [],
              campaignId,
              stateId: contentApprovalStatusId,
              fromConversation: false,
            }),
          );
        }
      });
    }
  }, [
    campaignId,
    profileId,
    contentInstructionsState,
    isMediaPlanEmpty,
    contentApprovalStatusId,
    dispatch,
  ]);

  const handleClickUploadNewVersion = useCallback(() => {
    if (campaignId) {
      showUploadContentModal({ profileId, campaignId });
    }
  }, [campaignId, profileId, showUploadContentModal]);

  const handleClickInstructions = useCallback(() => {
    if (campaignId) {
      showInstructionsModal({
        campaignId,
        profileId,
        instructions: contentInstructions,
      });
    }
  }, [campaignId, profileId, contentInstructions, showInstructionsModal]);

  useEffect(() => {
    setContentInstructionsState(contentInstructions || '');
    if (campaignId) {
      void dispatch(
        getContentProposals({ profile_id: profileId, project_id: campaignId }),
      );
    }
    // showDisplayContentModal();

    return () => {
      setContentInstructionsState('');
    };
  }, [
    contentInstructions,
    campaignId,
    dispatch,
    profileId,
    // showDisplayContentModal,
  ]);

  const loading = useMemo(
    () =>
      saveStatesLoding ||
      TAB_CONTENT_ACTIONS.some((action) =>
        contentValidationLoading.includes(action),
      ),
    [saveStatesLoding, contentValidationLoading],
  );

  if (!show || !profileId) return null;

  if (
    !isKOLInContentInstructions &&
    !isKOLInContentApprovalOrAfter &&
    contentProposals.length === 0
  ) {
    return (
      <div className={styles.container}>
        <Tile noShadow className="flex fdc gap16 aic">
          <div className={styles.placeholder}>
            <img
              srcSet="https://static.kolsquare.com/assets/campaigns/profile_panel/profile_panel_contents_placeholder.png 1.5x"
              alt="https://static.kolsquare.com/assets/campaigns/profile_panel/profile_panel_contents_placeholder.png 1.5x"
            />
            <Typography variant="title/modal-title" className="tac">
              <FormattedMessage id="profileDetails.panel.content.placeholder.title" />
            </Typography>
            <Typography variant="text/body large" className="tac">
              <FormattedMessage
                id={
                  contentApprovalStatusId
                    ? 'profileDetails.panel.content.placeholder.description'
                    : 'profileDetails.panel.content.placeholder.inactiveContentValidation'
                }
              />
            </Typography>
            {!contentApprovalStatusId && (
              <Button3
                theme="primary"
                size="small"
                label={intl.formatMessage({
                  id: 'profileDetails.panel.content.placeholder.activateFeature',
                })}
                className="asc"
                onClick={() => {
                  closeProfilePanel();
                  openWorkflowPanel();
                }}
              />
            )}
          </div>
        </Tile>
      </div>
    );
  }

  return (
    <div className="flex fdc ais jcsb fg1 prel">
      {loading && <Loader full background="rgba(255, 255, 255, .8)" />}
      <div className={styles.container}>
        {(isKOLInContentApprovalOrAfter || contentProposals.length > 0) &&
          !isMediaPlanEmpty && (
            <>
              <Tile
                noShadow
                padding="var(--spacing-spacing-xx-small)"
                className="flex fdc gap16"
              >
                <div className="flex aic gap16 jcsb">
                  <Typography variant="title/tile-title">
                    <FormattedMessage id="profileDetails.panel.content.contentApproval" />
                  </Typography>
                  <Button3
                    onClick={handleClickUploadNewVersion}
                    size="small"
                    label={intl.formatMessage({
                      id: 'profileDetails.panel.content.cta.uploadVersion',
                    })}
                    dataId="CA-CTA-contentValidation-uploadNewVersion"
                    disabled={isUploadInProgress}
                    tooltip={
                      isUploadInProgress
                        ? intl.formatMessage({
                            id: 'profileDetails.panel.content.tooltip.uploadInProgress',
                          })
                        : null
                    }
                  />
                </div>
                <ContentToBeApproved
                  campaignId={campaignId}
                  profileId={profileId}
                  contentRequests={contentRequests}
                  contentProposals={contentProposals}
                />
                <DisplayContentVersionModal />
              </Tile>
              <Tile
                noShadow
                padding="var(--spacing-spacing-xx-small)"
                className="flex fdc gap16"
              >
                <Typography variant="title/tile-title">
                  <FormattedMessage id="profileDetails.panel.content.contentInformations" />
                </Typography>
                <div className="flex fdc gap8">
                  <div className="flex aic jcsb">
                    <Typography variant="text/body medium bold">
                      <FormattedMessage id="profileDetails.panel.content.instructions" />
                    </Typography>
                    <Icon
                      label="pencil"
                      theme="solid"
                      isButton
                      onClick={handleClickInstructions}
                    />
                  </div>
                  <Typography variant="text/body medium" className="pre-wrap">
                    {contentInstructions}
                  </Typography>
                </div>
                <ContentInstructionsModal />
              </Tile>
            </>
          )}

        {(isKOLInContentInstructions || isMediaPlanEmpty) && (
          <Tile
            noShadow
            padding="var(--spacing-spacing-xx-small)"
            className="flex fdc gap24"
          >
            <Typography variant="title/tile-title">
              <FormattedMessage id="profileDetails.panel.content.contentInstructions" />
            </Typography>
            <DisplayExpectedContent
              aggregateContentProposals={aggregateContentRequests}
              isMediaPlanEmpty={isMediaPlanEmpty}
              onEdit={handleGoToMediaPlan}
            />
            {isSomeMediaPlanWithNoDate && (
              <InlineMessage type="warning">
                <div className="flex aic">
                  <Typography variant="label/default" className="flex f1">
                    <FormattedMessage id="profileDetails.profileInfos.contentExpected.noDate.warning" />
                  </Typography>
                  <Button3
                    theme="link"
                    label={intl.formatMessage({
                      id: 'profileDetails.profileInfos.contentExpected.noDate.updateCta',
                    })}
                    onClick={handleGoToMediaPlan}
                  />
                </div>
              </InlineMessage>
            )}
            <div className="flex fdc aib gap8">
              <Typography variant="text/body large">
                <FormattedMessage id="profileDetails.panel.content.optionalInstruction.title" />
              </Typography>
              <Textarea2
                id="profile-content-instruction"
                name="instructionsKol"
                value={contentInstructionsState}
                onChange={handleChangeContentInstruction}
                rows={10}
                autoGrowing
                fullWidth
              />
              <div className="flex jce w100p">
                <Button3
                  onClick={handleClickApply}
                  label={intl.formatMessage({ id: 'global.cta.validate' })}
                  disabled={
                    isAdmin ||
                    isCampaignArchived ||
                    isMediaPlanEmpty ||
                    isSomeMediaPlanWithNoDate
                  }
                  tooltip={
                    isMediaPlanEmpty && (
                      <Markdown>
                        {intl.formatMessage({
                          id: 'profileDetails.profileInfos.contentExpected.fillMediaPlanFist',
                        })}
                      </Markdown>
                    )
                  }
                />
              </div>
            </div>
          </Tile>
        )}
      </div>
    </div>
  );
};

export default memo(TabContent);
