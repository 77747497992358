import React, { memo, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import {
  Button3,
  Chips2,
  Item,
  Loader,
  Text,
  Truncate,
} from 'kolkit';

import { useSelector, useDispatch } from 'utils/redux';
import useModal from 'utils/hooks/useModal';

import DataTable from 'components/ui/DataTable';

import { Member, fetchMembers } from 'slices/organization.slice';
import { USER_ROLES } from 'constants/userRoles';
import useTimezone from 'utils/hooks/useTimezone';

import { COLUMNS, GRID_TEMPLATE_COLUMNS } from './config';

import renderLicenceDetails from '../utils/renderLicenceDetails';

import EditMemberModal from '../EditMemberModal';
import EditTeamModal from '../EditTeamModal';

import styles from './ListMembers.module.scss';

const ListMembers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    timezoneFormatter,
    format: { slashDate },
  } = useTimezone();

  const { on: openAddOrganizationMemberModal } = useModal(
    'addOrganizationMemberModal',
  );

  const { loading, members, user } = useSelector(
    ({
       organization: { members, loading },
       user: {
         profile,
       },
     }) => ({
      loading: loading?.includes(fetchMembers.typePrefix),
      members,
      user: profile.userRole,
    }),
  );

  useEffect(() => {
    void dispatch(fetchMembers());
    // eslint-disable-next-line
  }, []);

  const isSuperUser = useMemo(
    () => user === USER_ROLES.SUPER_USER || user === USER_ROLES.MANAGER,
    [user]
  );

  const handleClickInvite = useCallback(
    () => openAddOrganizationMemberModal(),
    [openAddOrganizationMemberModal]
  );

  const handleClickEditMember = useCallback(
    (member: Member) => {
      if (!isSuperUser) return null;
      openAddOrganizationMemberModal(member);
    },
    [isSuperUser, openAddOrganizationMemberModal],
  );

  const renderItem = useCallback(
    (member: Member) => {
      const cnChip = cn(styles.chip, styles[member.role]);
      return (
        <div
          className={cn(styles.row, { [styles.clickable]: isSuperUser })}
          role="row"
          onClick={() => handleClickEditMember(member)}
          data-id="settings-edit-user"
        >
          <div
            style={{
              gridRow: `1 / span ${member.licences.length || 1}`,
              minWidth: COLUMNS[0].minWidth,
            }}
            translate="no"
          >
            <Text resetMargin fontWeight="700">
              {member.first_name || ''} {member.last_name || ''}
            </Text>
            <span className={styles.email}>
              <Truncate>{member.email}</Truncate>
            </span>
          </div>
          <div
            style={{
              gridRow: `1 / span ${member.licences.length || 1}`,
              minWidth: COLUMNS[1].minWidth,
            }}
            translate="no"
          >
            <div>
              {member.team?.is_ghost_team ? '-' : member.team?.name}
            </div>
          </div>
          <div
            style={{
              gridRow: `1 / span ${member.licences.length || 1}`,
              minWidth: COLUMNS[2].minWidth,
            }}
          >
            {member.role && (
              <div>
                <Chips2
                  label={intl.formatMessage({ id: `settings.organization.role.${member.role}.label` })}
                  className={cnChip}
                />
              </div>
            )}
          </div>
          {member.licences?.length ? (
            React.Children.toArray(
              member.licences.map(licence => (
                <>
                  <div
                    style={{ minWidth: COLUMNS[3].minWidth }}
                    className={styles.stacking}
                  >
                    <div
                      className={licence.is_active ? undefined : styles.expired}
                    >
                      {intl.formatMessage({ id: `licence.${licence.name}` })}
                    </div>
                  </div>
                  <div
                    style={{ minWidth: COLUMNS[4].minWidth }}
                    className={styles.stacking}
                  >
                    <div
                      className={licence.is_active ? undefined : styles.expired}
                    >
                      {renderLicenceDetails(licence, intl)}
                    </div>
                  </div>
                  <div
                    className={cn(styles.stacking, styles.endDate)}
                    style={{ minWidth: COLUMNS[5].minWidth }}
                  >
                    <span
                      className={licence.is_active ? undefined : styles.expired}
                    >
                      {licence.end_date
                        ? timezoneFormatter(licence.end_date, slashDate)
                        : '-'}
                    </span>
                    {!licence.is_active && (
                      <Item
                        icon="times-circle"
                        iconTheme="solid"
                        iconWidth={16}
                        iconFill="#E93030"
                        className={styles.expiredLabel}
                      >
                        {intl.formatMessage({
                          id: 'global.settings.expired',
                        })}
                      </Item>
                    )}
                  </div>
                </>
              )),
            )
          ) : (
            <>
              <div style={{ minWidth: COLUMNS[3].minWidth }}>-</div>
              <div style={{ minWidth: COLUMNS[4].minWidth }}>-</div>
              <div style={{ minWidth: COLUMNS[5].minWidth }}>-</div>
            </>
          )}
        </div>
      );
    },
    [isSuperUser, intl, handleClickEditMember, timezoneFormatter, slashDate],
  );

  const columns = useMemo(
    () =>
      COLUMNS.map(column => ({
        ...column,
        label: intl.formatMessage({ id: column.label }),
        subLabel: column.subLabel
          ? intl.formatMessage({ id: column.subLabel })
          : null,
      })),
    [intl],
  );

  return (
    <>
      {loading && <Loader full background="rgba(255, 255, 255, .8)" />}
      <EditMemberModal />
      <EditTeamModal />
      <div className={styles.tableHeader}>
        <h3 className={styles.title}>
          <FormattedMessage id="settings.organization.members.title" />
        </h3>
        {isSuperUser && (
          <Button3
            icon="user-plus"
            iconSize="big"
            size="small"
            label={intl.formatMessage({
              id: 'settings.organization.members.invite',
            })}
            onClick={handleClickInvite}
            loading={loading}
            disabled={loading}
            dataId="settings-CTA-invite"
          />
        )}
      </div>
      <DataTable
        columns={columns}
        contentClassName={styles.content}
        headerClassName={styles.header}
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
        data={members}
        renderItem={renderItem}
        defaultSortedColumn="first_name"
        defaultSortDirection="asc"
        forceTopBorder
        stickyFirstColumn
      />
    </>
  );
};

export default memo(ListMembers);
