import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import cn from 'classnames';
import { Button3, Select2, Tooltip, Icon } from 'kolkit';

import { isFeatureActive } from 'utils/feature-toggle';
import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'utils/redux';

import * as featureNames from 'constants/feature-names';
import { setRecipients } from 'actions/messaging';
import { celebrityNameFormatter } from 'utils';
import { getStatsSharingsStatus } from 'actions/consent';
import {
  addProfilesToFavorite,
  removeProfilesFromFavorite,
} from 'actions/favorites';
import { updateProfilesToLists } from 'actions/profiles';
import { askRemoveProfilesFromCampaign } from 'actions/campaignDetails';
import { STATS_SHARING_STATUS } from 'constants/consent';
import { USER_ROLES } from 'constants/states';
import { getUserRightsOnSelection } from 'utils/rights';
import { Typography } from 'components/ui';

import styles from './KolAction.module.scss';

/**
 * All available actions on KOL by default: [
    'favorites'
    'add_to_list'
    'remove_from_list'
    'note'
    'message'
    'ask_stats_sharing'
    'remove_from_campaigns'
    'add_to_campaign'
  ]
  * @props {string[]} expect: to remove unnecessary options - ex: ['ask_stats_sharing']
 */

type Option =
  'favorites' |
  'add_to_list' |
  'remove_from_list' |
  'note' |
  'message' |
  'ask_stats_sharing' |
  'remove_from_campaigns' |
  'add_to_campaign';

type Props = {
  id: number;
  from?: string;
  trigger?: ReactNode;
  className?: string;
  expect?: Option[];
};

const KolAction: React.FC<Props> = ({
  id,
  from = '',
  trigger = undefined,
  className = '',
  expect = [],
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { on: openNoteModal } = useModal('profileNote');
  const { on: displayCampaign } = useModal('campaignsSelectModal');
  const { on: openAddEmailModal } = useModal('add_email');
  const { on: openChatModal } = useModal('chatModal');
  const { on: openManageList } = useModal('manage_lists');
  const { on: openAskStats } = useModal('askStatsModal');

  const {
    profile,
    campaignId,
    isCampaignViewer,
    isCampaignArchived,
    userCan,
    isAdmin,
    promoCode,
    currentListId,
  } = useSelector(
    ({
      campaigns,
      profiles,
      views: {
        campaignDetails: { id: campaignId },
      },
      user,
      myInfluencers,
    }) => {
      const campaignDetails = campaignId ? campaigns[campaignId] : null;
      return {
        campaignId: campaignId ? (campaignId as number) : null,
        profile: profiles?.[id],
        isCampaignViewer: campaignDetails?.core.role === USER_ROLES.VIEWER,
        isCampaignArchived: campaignDetails?.core.state === 'archived',
        userCan: getUserRightsOnSelection(campaignDetails?.core?.role),
        isAdmin: !!user.profile.admin,
        promoCode: campaignDetails?.profiles?.[id]?.discountCode,
        currentListId: myInfluencers?.filters?.listId,
      };
    },
  );

  const isFavorite = profile?.core?.isFavorite || false;

  const handleClickFavorites = useCallback(() => {
    if (isFavorite) {
      void dispatch(removeProfilesFromFavorite({ ids: [id], from }));
    } else {
      void dispatch(addProfilesToFavorite({ ids: [id], from }));
    }
  }, [isFavorite, dispatch, id, from]);

  const handleOpenChatModal = useCallback(() => {
    dispatch(
      setRecipients([
        {
          id: Number(id),
          name: celebrityNameFormatter(profile?.core),
          fullName: profile?.core?.fullName,
          pseudo: profile?.core?.pseudo,
          avatar: profile?.core?.picture,
          promoCode
        },
      ]),
    );
    openChatModal({
      from,
      campaignId: null,
    });
  }, [dispatch, profile, id, openChatModal, promoCode, from]);

  const handleSendMessage = useCallback(() => {
    if (profile?.core?.isContactable) {
      handleOpenChatModal();
    } else {
      openAddEmailModal({
        profileId: id,
        name: celebrityNameFormatter(profile?.core),
        onAdded: async () => {
          await dispatch(getStatsSharingsStatus(id));
          handleOpenChatModal();
        },
      });
    }
  }, [dispatch, profile, id, openAddEmailModal, handleOpenChatModal]);

  const removeFromCampaign = useCallback(() => {
    if (!campaignId) return null;
    dispatch(
      askRemoveProfilesFromCampaign({
        profileIds: [id],
        campaignIds: [campaignId],
        from,
        fromMultiSelect: false,
        onConfirm: null,
      }),
    );
  }, [campaignId, dispatch, id, from]);

  const handleAskStatsSharing = useCallback(() => {
    openAskStats({
      profileIds: [id],
    });
  }, [openAskStats, id]);

  const handleRemoveFromList = useCallback(
    () => {
      if (!currentListId) return null;
      void dispatch(updateProfilesToLists({
        profileIds: [id],
        listIds: [currentListId],
        action: 'remove',
        intl: intl.formatMessage(
          { id: 'lists.deleteProfiles.success' },
          {
            profilesLength: [id]?.length,
            listLength: [currentListId]?.length,
          }),
      }));
    },
    [currentListId, id, dispatch, intl]
  );

  const options = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: `favorite.cta.${isFavorite ? 'remove' : 'add'}`,
        }),
        value: 'favorites' as Option,
        onClick: handleClickFavorites,
        icon: 'star',
        iconTheme: isFavorite ? 'solid' : 'regular',
        disabled: isAdmin || !isFeatureActive(featureNames.FAVORITES),
        dataId: 'CA-KOL-fav',
      },
      {
        label: intl.formatMessage({ id: 'lists.cta.addTo' }),
        value: 'add_to_list' as Option,
        onClick: () => openManageList({ id, from }),
        icon: 'list',
        disabled: isAdmin,
        dataId: 'CA-KOL-list',
      },
      {
        label: intl.formatMessage({ id: 'lists.cta.removeTo' }),
        value: 'remove_from_list' as Option,
        onClick: handleRemoveFromList,
        icon: 'trash-list',
        disabled: !currentListId || isAdmin,
        dataId: 'CA-KOL-list',
      },
      {
        label: intl.formatMessage({ id: 'note.cta.add' }),
        value: 'note' as Option,
        onClick: () => openNoteModal({ id, from }),
        icon: 'file-alt',
        disabled: isAdmin || !isFeatureActive(featureNames.NOTES),
        dataId: 'CA-KOL-note',
      },
      {
        label: profile?.core?.isContactable
          ? intl.formatMessage({ id: 'messaging.cta.sendMessage' })
          : intl.formatMessage({ id: 'messaging.cta.addEmailAdress' }),
        value: 'message' as Option,
        onClick: () => handleSendMessage(),
        icon: 'envelope',
        disabled:
          isAdmin ||
          (!userCan.sendMessage && from === 'Campaign') ||
          !isFeatureActive(featureNames.MANAGE_CAMPAIGN),
        dataId: 'CA-KOL-email',
      },
      {
        label: intl.formatMessage({ id: 'statsSharing.status.requestable' }),
        value: 'ask_stats_sharing' as Option,
        onClick: () => {
          if(!isFeatureActive(featureNames.MANAGE_CAMPAIGN) ||
          ![STATS_SHARING_STATUS.REQUESTABLE, STATS_SHARING_STATUS.AUTO_REQUESTED].includes(profile?.statsSharing?.status)) {
            return false
          }
          handleAskStatsSharing()
        },
        icon: 'square-poll-vertical',
        dataId: 'CA-KOL-instaco',
        disabled:profile?.statsSharing?.status === STATS_SHARING_STATUS.UNREQUESTABLE,
        tooltip:
          profile?.statsSharing?.status === STATS_SHARING_STATUS.UNREQUESTABLE
            ? intl.formatMessage({ id: 'profile.addEmailFromProfilePage' })
            : intl.formatMessage({
                id: `statsSharing.status.${
                  profile?.statsSharing?.status || 'requestable'
                }`,
              }),
      },
      {
        label: intl.formatMessage({ id: 'campaigns.cta.removeKol' }),
        value: 'remove_from_campaigns' as Option,
        onClick: removeFromCampaign,
        icon: 'user-minus',
        disabled:
          isAdmin ||
          isCampaignViewer ||
          isCampaignArchived ||
          !isFeatureActive(featureNames.MANAGE_CAMPAIGN),
        dataId: 'CA-KOL-remove',
      },
      {
        label: intl.formatMessage({ id: 'campaigns.cta.addKol' }),
        value: 'add_to_campaign' as Option,
        onClick: () => displayCampaign({ id, from }),
        icon: 'plus',
        disabled: isAdmin || !isFeatureActive(featureNames.MANAGE_CAMPAIGN),
        dataId: 'CA-KOL-add-camp',
      },
    ].filter((option) => !expect.includes(option.value));
  }, [
    intl,
    isFavorite,
    handleClickFavorites,
    isAdmin,
    currentListId,
    profile?.core?.isContactable,
    profile?.statsSharing?.status,
    userCan.sendMessage,
    handleAskStatsSharing,
    removeFromCampaign,
    isCampaignViewer,
    isCampaignArchived,
    openManageList,
    handleRemoveFromList,
    id,
    openNoteModal,
    handleSendMessage,
    displayCampaign,
    expect,
    from
  ]);

  const renderItem = useCallback(
    (item) => {
      if (item.value === 'ask_stats_sharing') {
        const tooltip = profile?.statsSharing?.status === STATS_SHARING_STATUS.UNREQUESTABLE
          ? intl.formatMessage({ id: 'profile.addEmailFromProfilePage' })
          : null

        const iconTheme = [
          STATS_SHARING_STATUS.REQUESTED,
          STATS_SHARING_STATUS.APPROVED,
          STATS_SHARING_STATUS.AUTO_REQUESTED
        ].includes(profile?.statsSharing?.status) ? "solid" :"regular";

        const showStatusLabel = [
          STATS_SHARING_STATUS.REQUESTED,
          STATS_SHARING_STATUS.APPROVED,
          STATS_SHARING_STATUS.AUTO_REQUESTED,
        ].includes(profile?.statsSharing?.status);

        return (
          <li className={styles.item}>
            <Tooltip content={tooltip}>
              <Typography
                variant="text/body medium"
                className={cn("flex gap8", styles.instaSharing, styles[profile?.statsSharing?.status], {'aic': !showStatusLabel})}
              >
                <Icon
                  label={item?.icon}
                  theme={iconTheme}
                />
                <span className={cn("flex fdc")}>
                  <span>
                    {intl.formatMessage({ id: 'statsSharing.status.requestable' })}
                  </span>
                  {showStatusLabel && (
                    <span className={styles.statusLabel}>
                      {intl.formatMessage({ id: `statsSharing.status.${profile?.statsSharing?.status}.fullLabel`})}
                    </span>
                  )}
                </span>
              </Typography>
            </Tooltip>
          </li>
        );
      }

      return (
        <li className={styles.item}>
          <Typography variant="text/body medium" className="flex gap8 aic">
            <Icon
              label={item?.icon}
              theme={item.iconTheme || 'regular'}
            />
            {item.label}
          </Typography>
        </li>
      );
    },
    [profile, intl]
  );

  return (
    <Select2
      options={options}
      renderItem={renderItem}
      width={300}
      maxHeight="auto"
      selected={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      className={className}
      trigger={
        trigger || (
          <Button3
            icon="ellipsis-v"
            iconTheme="regular"
            iconSize="big"
            theme="tertiary"
          />
        )
      }
    />
  );
};

export default KolAction;
