import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Portal from '@mui/material/Portal';
import useResizeObserver from 'utils/hooks/useResizeObserver';
import zIndex from 'config/zIndex';

const PortalContainer = ({ on, children, html, disablePortal, className, ...rest }) => {
  const [node, size] = useResizeObserver(on);

  const style = useMemo(
    () => size && ({
      position: 'absolute',
      width: size?.width,
      top: size?.bottom + window.scrollY || 0,
      left: size?.left,
      zIndex: zIndex.portal
    }),
    [size]
  );

  return (
    <>
      {React.cloneElement(children, {
        ...rest,
        ref: node,
      })}
      <Portal disablePortal={disablePortal}>
        <div style={disablePortal ? {} : style}>
          <div className={className}>
            {html}
          </div>
        </div>
      </Portal>
    </>
  )
};

PortalContainer.propTypes = {
  children: PropTypes.element.isRequired,
  html: PropTypes.node.isRequired,
  disablePortal: PropTypes.bool,
  on: PropTypes.bool,
  className: PropTypes.string,
};

PortalContainer.defaultProps = {
  className: null,
  disablePortal: false,
  on: false,
};

export default PortalContainer;
