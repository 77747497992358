import React from 'react';
import cn from 'classnames';
import { Label } from 'components';
import styles from './Field.module.scss';

const Field = ({ label, className, children }) => {
  const cnField = cn(styles.field, className);
  return(
    <div className={cnField}>
      <Label label={label} className={styles.label} />
      {children}
    </div>
  )
};

export default Field;
