import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Icon, Spaces } from 'kolkit';

import SelectMultiple from 'kolkit/SelectMultiple';
import { Field } from 'components';
import { useDeepSelector } from 'utils/redux';
import { useFilter } from 'components/engine/hooks';


const ExcludeTag = ({ network, onChange }) => {
  const filterTarget = 'excludeTag';
  const intl = useIntl();
  const [isFull, setIsFull] = useState(false);
  const value = useFilter(filterTarget, network);

  const list = useDeepSelector(({ user }) => user?.tags);

  const getDataSet = useMemo(
    () => list?.map(item => ({
      value: item.id,
      label: item.value
    })),
    [list]
  );

  const handleChange = useCallback(
    ({ item, selected }) => {
      const isFull = item === 'all';
      setIsFull(isFull);
      onChange({
        field: filterTarget,
        value: selected,
        selected: item,
        network
      })
    },
    [onChange, network, filterTarget]
  );

  const renderValue = useCallback(
    (selected) => {
      if (isFull) return intl.formatMessage({ id: 'global.selectAll' });
      return intl.formatMessage(
        { id: `tags.exclude.${isFull ? 'all' : 'many'}` },
        { count: selected.length }
      );
    },
    [intl, isFull]
  );

  return (
    <>
      <div>
        <Field className="flex fdc" label={
          <>
            <span style={{ marginRight: 8 }}>{intl.formatMessage({ id: 'tags.exclude.label' })}</span>
            <Icon
              tooltip={intl.formatMessage({ id: 'tags.exclude.tooltip' })}
              label="info-circle"
              size="small"
            />
          </>
        }>
           <SelectMultiple
            options={getDataSet}
            selected={value}
            onChange={handleChange}
            renderValue={renderValue}
            size="small"
            searchable
            placeholder={intl.formatMessage({ id: 'tags.exclude.placeholder' })}
          />
        </Field>
      </div>
      <Spaces bottom={8} />
    </>
  )
};

ExcludeTag.displayName = 'ExcludeTag';

ExcludeTag.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ExcludeTag;
