import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl-phraseapp';

import { getIntegrations } from 'actions/user';
import { API_INTEGRATIONS_LIST } from 'config/integrations';
import { useDispatch } from 'utils/redux';
import { isFeatureActive } from 'utils/feature-toggle';

import Block from './Block';

const PaneIntegrations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIntegrations());
  }, [dispatch]);

  return API_INTEGRATIONS_LIST.map(({ id, icon, featureSwitchID }) =>
    featureSwitchID && !isFeatureActive(featureSwitchID) ? null : (
      <Block
        key={`${id}-integration-block`}
        id={id}
        title={intl.formatMessage({ id: `settings.${id}.title` })}
        subtitle={intl.formatMessage({ id: `settings.${id}.subTitle` })}
        iconLabel={icon}
        whiteSpace={id === 'shopify' ? 'pre' : undefined}
      />
    )
  );
};

export default memo(PaneIntegrations);
