/* eslint-disable no-param-reassign */
import { useCallback, useEffect } from 'react';
import { useImmerAtom } from 'jotai-immer';

import { panels } from 'state/app';

import useDeepComparaison from './useDeepComparaison';

const usePanel = (id, status) => {
  const [panel, setPanel] = useImmerAtom(panels);

  const data = useDeepComparaison(status);

  useEffect(() => {
    if (id && data) {
      setPanel(draft => {
        draft[id] = data
      })
    }
    // eslint-disable-next-line
  }, [id, data])

  const handleChange = useCallback(
    () => {
      if (id) {
        setPanel(draft => {
          draft[id] = !draft[id]
        })
      }
    },
    [id, setPanel]
  );

  const handleOn = useCallback(
    (data) => {
      if (id) {
        setPanel(draft => {
          draft[id] = data || true
        })
      }
    },
    [id, setPanel]
  );

  const handleOff = useCallback(
    () => {
      if (id) {
        setPanel(draft => {
          draft[id] = false
        })
      }
    },
    [id, setPanel]
  );

  const handleUpdateData = useCallback(
    (data) => {
      if (id) {
        setPanel(draft => {
          draft[id] = {
            ...draft[id],
            ...data
          }
        })
      }
    },
    [id, setPanel]
  );

  return {
    on: handleOn,
    off: handleOff,
    onChange: handleChange,
    show: !!panel[id],
    data: panel[id],
    onUpdateData: handleUpdateData,
  };
};

export default usePanel;
