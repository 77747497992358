import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { Item, Select2, Tooltip } from 'kolkit';
import SelectMultiple from 'kolkit/SelectMultiple';

import { useDeepSelector } from 'utils/redux';
import { USER_ROLES } from 'constants/userRoles';

import styles from './RoleAndRules.module.scss'

type Props = {
  userId?: number | null;
  teamId: number | undefined;
  selectedRole: string;
  selectedTeams: number[];
  onChangeRole: (role: string) => void;
  onChangeTeamAccess: (ids: string[]) => void;
  disabled?: boolean;
};

const RoleAndRules: React.FC<Props> = ({
  userId,
  teamId,
  selectedRole=USER_ROLES.MAKER,
  selectedTeams=[],
  onChangeRole,
  onChangeTeamAccess,
  disabled=false,
}) => {
  const intl = useIntl();

  const [isFullTeams, setIsFullTeams] = useState(false);

  const teams = useDeepSelector(({ organization: { teams } }) => teams);
  const members = useDeepSelector(({ organization: { members } }) => members);

  const superUser = useMemo(
    () => members?.find(member => member?.role === USER_ROLES.SUPER_USER),
    [members]
  );

  const rolesDataset = useMemo(
    () => Object.values(USER_ROLES).map(role => ({
      value: role,
      label: intl.formatMessage({ id: `settings.organization.role.${role}.label`}),
      subLabel: intl.formatMessage({ id: `settings.organization.role.${role}.subLabel`}),
      disabled: role === USER_ROLES.SUPER_USER && Number(superUser?.id) !== userId,
    })),
    [intl, superUser, userId]
  );

  const teamsDataset = useMemo(
    () => {
      const data = teams?.map(team => ({
        id: team.id,
        value: team.id,
        label: team.name || '',
      }));
      return teamId
        ? data.filter(team => Number(team.id) !== Number(teamId)) // remove selected user team from list
        : data
    },
    [teams, teamId]
  );

  const handleSelectRole = useCallback(
    ({ value }) => {
      onChangeRole(value);
      if(value === USER_ROLES.MAKER) onChangeTeamAccess([]);
    },
    [onChangeRole, onChangeTeamAccess]
  );

  const handleSelectTeams = useCallback(
    ({ item, selected }) => {
      const isFull = item === 'all' || selected?.length === teams?.length;
      setIsFullTeams(isFull);
      onChangeTeamAccess(selected)
    },
    [onChangeTeamAccess, teams]
  );

  const renderRoleItem = useCallback(
    item => (
      <div className={styles.selectItem}>
        <Item className={styles.label}>{item.label}</Item>
        {item.subLabel && <Item className={styles.subLabel}>{item.subLabel}</Item>}
      </div>
    ),
    []
  );

  const renderTeamsLabel = useCallback(
    (selected) => {
      if (isFullTeams) return intl.formatMessage({ id: 'modal.organization.addMember.access.all' });
      return intl.formatMessage(
        { id: 'modal.organization.addMember.access.many' },
        { count: selected.length }
      );
    },
    [intl, isFullTeams]
  );

  return (
    <div className={styles.grid}>
      <div className={styles.col}>
        <span>
          {intl.formatMessage({
            id: 'modal.organization.addMember.role.label',
          })}
        </span>
        <Select2
          options={rolesDataset}
          selected={selectedRole}
          onSelectItem={handleSelectRole}
          fullWidth
          width={470}
          renderItem={renderRoleItem}
          disabled={disabled}
          dataId="settings-role-change"
        />
      </div>
      <div className={styles.col}>
        <span>
          {intl.formatMessage({
            id: 'modal.organization.addMember.access.label',
          })}
        </span>
        <Tooltip
          content={selectedRole === USER_ROLES.MAKER
          ? intl.formatMessage({ id: 'modal.organization.addMember.access.disabled'})
          : null}
        >
          <span>
            <SelectMultiple
              dataId="settings-team-access"
              options={teamsDataset}
              selected={selectedTeams}
              disabled={selectedRole === USER_ROLES.MAKER || disabled}
              onChange={handleSelectTeams}
              renderValue={renderTeamsLabel}
              size="medium"
              searchable
              searchKey="label"
              inputSearchPlaceholder={intl.formatMessage({ id: 'global.search.placeholder' })}
              placeholder={intl.formatMessage({ id: 'modal.organization.addMember.access.placeholder' })}
              fullWidth
              theme='secondary'
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default RoleAndRules;
