import React from 'react';
import cn from 'classnames';
import Icon from 'kolkit/Icon';

import styles from './Button.module.scss';

const Button = React.forwardRef(({
  children,
  size,
  theme,
  icon,
  disabled,
  type,
  reverse,
  onClick,
  className,
  ...rest
}, ref) => {
  const cnButton = cn(
    className,
    styles.button,
    styles[size],
    styles[theme],
    {
      [styles.icon]: icon,
      [styles.reverse]: reverse,
      [styles.onlyIcon]: !children,
    },
  );

  return (
    <button
      {...rest}
      ref={ref}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={cnButton}
    >
      {icon &&
      <Icon label={icon} className={styles.icon} />}
      {children && children}
    </button>
  );
});

Button.displayName = 'Button';

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  theme: 'default',
};

export default Button;
