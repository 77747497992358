import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import renderModal from 'utils/HOCs/renderModal';
import useModal from 'utils/hooks/useModal';
import { useDispatch } from 'utils/redux';
import { Guest } from 'components/campaigns/hooks/useGuestSession';

import { Input2, Modal2, Text } from 'kolkit';
import { saveGuestUser } from 'actions/campaigns';


export const RegisterGuest_MODAL_ID = 'RegisterGuest_MODAL_ID';

const RegisterGuestModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [guestInfos, setGuestInfos] = useState<Partial<Guest>>({});
  const { show, off } = useModal(RegisterGuest_MODAL_ID);

  const handleClickSave = useCallback(async () => {
    setLoading(true);
    await dispatch(saveGuestUser(guestInfos));
    off();
    setLoading(false);
  }, [dispatch, guestInfos, off]);

  const modalActions = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: 'global.cta.save' }),
        onClick: handleClickSave,
        disabled: !guestInfos.email?.length,
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: off,
      },
    }),
    [guestInfos.email?.length, handleClickSave, intl, off],
  );

  const handleChangeInput = useCallback<
    (params: { name?: string; value: string }) => void
  >(({ value, name }) => {
    if (!name) return null;
    return setGuestInfos((prev) => ({ ...prev, [name]: value }));
  }, []);

  return (
    <Modal2
      title={intl.formatMessage({ id: 'campaigns.registerGuest.modal.title' })}
      subTitle={intl.formatMessage({
        id: 'campaigns.registerGuest.modal.subtitle',
      })}
      on={show}
      onClick={off}
      action={modalActions}
      loading={loading}
    >
      <div className="flex fdc gap24">
        <div className="flex aix gap16">
          <div className="fg1">
            <Text resetMargin size="small" tag="span">
              <FormattedMessage id="profileDetails.profileInfos.firstName.label" />
            </Text>
            <Input2
              placeholder={intl.formatMessage({
                id: 'profileDetails.profileInfos.firstName.label',
              })}
              name="first_name"
              onChange={handleChangeInput}
              errorMessage={intl.formatMessage({
                id: 'global.messages.error.required',
              })}
              fullWidth
              disabled={loading}
            />
          </div>
          <div className="fg1">
            <Text resetMargin size="small" tag="span">
              <FormattedMessage id="profileDetails.profileInfos.lastName.label" />
            </Text>
            <Input2
              placeholder={intl.formatMessage({
                id: 'profileDetails.profileInfos.lastName.label',
              })}
              name="last_name"
              onChange={handleChangeInput}
              errorMessage={intl.formatMessage({
                id: 'global.messages.error.required',
              })}
              fullWidth
              disabled={loading}
            />
          </div>
        </div>
        <div className="fg1">
          <Text resetMargin size="small" tag="span">
            <FormattedMessage id="profileDetails.email.label" />*
          </Text>
          <Input2
            placeholder={intl.formatMessage({
              id: 'profileDetails.email.label',
            })}
            name="email"
            value={guestInfos.email}
            onChange={handleChangeInput}
            errorMessage={intl.formatMessage({
              id: 'global.messages.error.required',
            })}
            fullWidth
            required
            disabled={loading}
          />
        </div>
      </div>
    </Modal2>
  );
};

export default renderModal(RegisterGuest_MODAL_ID, RegisterGuestModal);
