import React, { forwardRef, memo, useCallback, useMemo, useId } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../../Icon';
import Truncate from '../../Truncate';

import styles from './Checkbox2.module.scss';

const Checkbox2 = forwardRef(({
  checked,
  className,
  disabled,
  id,
  dataId,
  label,
  name,
  onChange,
  required,
  halfchecked,
  fullWidth,
  labelClassName,
  reverseLabel,
  theme,
  ...rest
}, ref) => {
  const htmlForId = useId();

  const cnInputCheck = cn(styles.inputCheck, styles[theme], className, {
    [styles.fullWidth]: fullWidth,
    [styles.reverseLabel]: reverseLabel,
    [styles.required]: required,
    [styles.disabled]: disabled
  });

  const cnLabel = cn(styles.label, styles[theme], labelClassName, {
    [styles.disabled]: disabled
  });

  const checkbox = useMemo(
    () => (
      <label className={styles.customInput} htmlFor={htmlForId}>
        {(checked || halfchecked) ? (
          <Icon
            label={halfchecked ? 'minus' : 'check'}
            theme="solid"
            className={styles.icon}
            width={10}
            fill={theme === 'primary' ? "var(--color-dominant-on-dominant)" : "var(--color-primary-300)"}
          />
        ) : null}
      </label>
    ),
    [htmlForId, checked, halfchecked, theme]
  );

  const handleChange = useCallback(
    () => onChange({ id, label, name }),
    [onChange, id, label, name]
  );

  return (
    <div
      className={cnInputCheck}
      ref={ref}
      role="checkbox"
      aria-checked={checked}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        {...rest}
        type="checkbox"
        id={htmlForId}
        data-id={dataId || id}
        aria-describedby={label}
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        required={required}
      />
      {checkbox}
      {label && (
        <label htmlFor={htmlForId} className={cnLabel}>
          <Truncate>{label}</Truncate>
        </label>
      )}
    </div>
  );
});

Checkbox2.displayName = 'Checkbox2';

Checkbox2.propTypes = {
  id: PropTypes.string,
  dataId: PropTypes.string,
  checked: PropTypes.bool,
  halfchecked: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  theme: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.bool
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  reverseLabel: PropTypes.bool,
  required: PropTypes.bool
};

Checkbox2.defaultProps = {
  id: '',
  name: null,
  dataId: null,
  checked: false,
  halfchecked: false,
  className: undefined,
  labelClassName: undefined,
  disabled: false,
  fullWidth: false,
  theme: 'primary',
  label: null,
  reverseLabel: false,
  required: false,
  onChange: () => {}
};

export default memo(Checkbox2);
