import { useSelector } from 'utils/redux';

const useActiveFilter = network => {
  const isActive = useSelector(
    ({ user }) => user.filters.activeFilter.includes(network)
  );

  return isActive;
};

export default useActiveFilter;
