import React, {Children, memo, useState, useCallback, useRef, useEffect} from 'react';
import { useIntl } from 'react-intl-phraseapp';
import { useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import { animated, useTransition } from 'react-spring';
import { Scrollbars } from 'react-custom-scrollbars-2';
import useMeasure from 'react-use-measure';
import ResizeObserver from 'resize-observer-polyfill';

import { Input, Item } from 'kolkit';
import Icon from 'kolkit/Icon';

import { useSelector } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import SearchBarTag from 'components/ui/searchBar/Tag';

import 'css/scss/ui/search-bar.scss';
import styles from './SearchBar.module.scss';

const newTermsInputClassName = 'kol-search-bar-input';

const SearchBar = ({
 autoHashtag, onlyHashtagOrAt, highlightWords, disabled, displaySearchButton,
 selectable, editable, autoFocus, autoAt, displayPlaceholder, tags, onChange,
 placeholder, className, error, errorMessage
}) => {
  const myRef = useRef(null);
  const inputRef = useRef(null);
  const intl = useIntl();
  const navigate = useNavigate();

  const [ref, measure] = useMeasure({ polyfill: ResizeObserver });

  const { on } = useModal('savedSearched');

  const [focused, setFocused] = useState(false);
  const [state, setState] = useState({
    searchValue: '',
    selectedTagIndex: null,
    onKeyDownSelectedTagActive: true,
  });

  const transitions = useTransition(focused, null, {
    unique: true,
    from: { transform: 'translate(0, -20px)', opacity: 0 },
    enter: { transform: `translate(0, ${measure.height - 58}px)`, opacity: 1 },
    leave: { transform: 'translate(0, -20px)', opacity: 0 },
    config: {
      tension: 380,
      friction: 35,
      mass: 1,
      precision: 0.01
    }
  })

  const { savedSearches, isAdmin } = useSelector(({ engine, user }) => ({
    savedSearches: engine.savedSearch,
    isAdmin: user.profile.admin
  }));

  const inputCn = cn(newTermsInputClassName, className);

  useEffect(() => {
    setFocused(false);
    myRef?.current?.querySelector(`.${newTermsInputClassName} input`)?.blur();
  }, [measure])

  const handleFocus = useCallback(
    () => setFocused(true),
    []
  );

  const handleBlur = useCallback(
    () => setFocused(false),
    []
  );

  const cleanInputValue = useCallback(
    inputValue => {
      const prefix = ['+', '-', '@', '#'];
      const wrapper = ['"'];

      const value = inputValue?.trim();

      if (value === "") return value;

      const prefixIndex = prefix.indexOf(value[0]);
      const wrapperIndex = wrapper.indexOf(value[0]);

      if (prefixIndex !== -1) return `${prefix[prefixIndex]}${value.substring(1)?.trim()}`;
      if (wrapperIndex !== -1) {
        if (value[0] === value[value.length - 1]) return `${wrapper[wrapperIndex]}${value.substring(1, value.length - 1)?.trim()}${wrapper[wrapperIndex]}`;
        return `${wrapper[wrapperIndex]}${value.substring(1)?.trim()}${wrapper[wrapperIndex]}`;
      }
      if (autoHashtag) return `#${value}`;
      if (autoAt) return `@${value}`;
      return value;
    },
    [autoAt, autoHashtag]
  );

  const addTag = useCallback(
    () => {
      const tag = cleanInputValue(state.searchValue);
      // handleBlur();
      // clear search value
      setState(prevState => ({...prevState, searchValue: ''}));

      // avoid empty tags
      if (tag === '') return null;

      // prevent from adding tags who are not prefixed by '@' or '#' if onlyHashtagOrAt props
      if (onlyHashtagOrAt && tag[0] !== '@' && tag[0] !== '#') return;
      const newTags = [...tags, tag];
      onChange({
        tags: newTags,
        tag,
        action: 'addition'
      });
    },
    [cleanInputValue, onChange, onlyHashtagOrAt, state.searchValue, tags]
  );

  const updateSearchValue = useCallback(
    ({ value: searchValue }) => {
      setState(prevState => ({ ...prevState, selectedTagIndex: null }));
      if (searchValue[searchValue.length - 1] === ',') {
        addTag();
      } else {
        setState(prevState => ({ ...prevState, searchValue }));
      }
    },
    [addTag]
  );

  const deleteTag = useCallback(tagIndex => {
    const newTags = tags.filter((_, index) => index !== tagIndex);
    const deletedTag = tags[tagIndex];

    onChange({
      tags: newTags,
      tag: deletedTag,
      action: 'deletion'
    });
  }, [onChange, tags]);

  const onKeyPress = useCallback(
    (e) => {
      const { key } = e;
      // Force focus on tab if tags
      if (key === 'Tab') {
          if (state.searchValue.length > 0) e.preventDefault();
          addTag();
          return;
        }

      if (key === 'ArrowLeft'
        && selectable && state.searchValue === ''
        && state.selectedTagIndex === null) {
        setState(prevState => ({
          ...prevState,
          onKeyDownSelectedTagActive: false,
          selectedTagIndex: tags.length - 1
        }));
      }

      if (key === 'ArrowRight'
        && selectable && state.searchValue === ''
        && state.selectedTagIndex === null) {
        setState(prevState => ({
          ...prevState,
          onKeyDownSelectedTagActive: false,
          selectedTagIndex: 0
        }));
      }

      if (key === 'Backspace'
        && state.searchValue === ''
        && tags.length > 0
        && (state.selectedTagIndex === null
          || state.selectedTagIndex < 0
          || state.selectedTagIndex >= tags.length)) {
        deleteTag(tags.length - 1)
      }
    },
    [addTag, deleteTag, selectable, state.searchValue, state.selectedTagIndex, tags.length]
  );

  const activateOnKeyDownSelectedTag = useCallback(
    () => {
      setState(prevState => ({
        ...prevState,
        onKeyDownSelectedTagActive: true
      }));
    },
    []
  );

  const handleDeleteTag = index => () => deleteTag(index);

  const focusOnNewTermsInput = () => {
    myRef?.current?.querySelector(`.${newTermsInputClassName} input`)?.focus();
    inputRef?.current?.focus();
  }

  const handleUpdateTag = index => value => {
    focusOnNewTermsInput();
    updateTag(value, index)
  };

  const updateTag = useCallback((value, tagIndex) => {
    const newTag = cleanInputValue(value);
    const newTags = tags?.map((tag, index) => (index === tagIndex ? newTag : tag));
    const updatedTag = tags[tagIndex];

    onChange({
      tags: newTags,
      tag: updatedTag,
      action: 'edition'
    });
  }, [cleanInputValue, tags, onChange]);

  const onChangeSelectedTag = ({ index, value: isSelected }) => {
    setState(prevState => ({
      ...prevState,
      selectedTagIndex: isSelected ? index : null
    }));
  };

  const onArrowKeySelectedTag = direction => {
    if (direction === 'left') {
      setState(prevState => ({
        ...prevState,
        selectedTagIndex: state.selectedTagIndex === 0 ?
          tags.length - 1 :
          prevState.selectedTagIndex - 1
      }));
    }

    if (direction === 'right') {
      setState(prevState => ({
        ...prevState,
        selectedTagIndex: state.selectedTagIndex === tags.length - 1
          ? 0
          : prevState.selectedTagIndex - 1
      }));
    }
  };

  const handleClickSearchItem = useCallback(
    async ({ query }) => {
      await navigate({
        pathname: '/',
        search: `?${query}`
      });
      handleBlur();
    },
    [navigate, handleBlur]
  );

  const handleClickOpenSearches = useCallback(
    () => {
      on();
      handleBlur();
    },
    [on, handleBlur]
  );

  /*
  ** Render functions
  */
  const renderSearchBar = () => {
    return (
      <div className="kol-search-bar-flex-container" ref={ref}>
        <ClickAwayListener onClickAway={handleBlur}>
          <div className="kol-search-bar-tags-container">
            {tags?.map((tag, index) => (
              <SearchBarTag
                value={tag}
                key={index}
                editable={editable}
                selectable={selectable}
                onArrowKey={onArrowKeySelectedTag}
                onChangeSelectedTag={onChangeSelectedTag}
                isSelected={state.selectedTagIndex === null ? null : (selectable && state.selectedTagIndex === index)}
                disabled={disabled}
                onlyHashtagOrAt={onlyHashtagOrAt}
                highlightWords={highlightWords || []}
                deleteTag={handleDeleteTag(index)}
                updateTag={handleUpdateTag(index)}
                onQuitEditMode={focusOnNewTermsInput}
                onKeyDownSelectedTagActive={state.onKeyDownSelectedTagActive}
                activateOnKeyDownSelectedTag={activateOnKeyDownSelectedTag}
              />
            ))}
            <Input
              ref={inputRef}
              className={inputCn}
              autoFocus={autoFocus}
              value={state.searchValue}
              onChange={updateSearchValue}
              disabled={disabled}
              onEnterPress={addTag}
              onBlur={addTag}
              onFocus={() => handleFocus()}
              onKeyPress={onKeyPress}
              placeholder={(displayPlaceholder && tags.length === 0 && state.searchValue === '') ? placeholder : undefined}
              resetStyle
            />
            {transitions.map(({ item, props, key }) => {
              return (item && !isAdmin) ? (
                <animated.div style={props} className={styles.searchViewContainer} key={key}>
                  <div>
                    <Item className={styles.searchViewContainerTitle}>
                      {intl.formatMessage({ id: 'engineSearch.save.last' })}
                    </Item>
                    <Scrollbars autoHeight hideTracksWhenNotNeeded autoHeightMax={276}>
                      {savedSearches.length > 0
                        ? Children.toArray(savedSearches.map(searchItem => {
                          return (
                            <Item
                              className={styles.searchesItem}
                              item={searchItem}
                              onClick={handleClickSearchItem}
                            >
                              {searchItem.name}
                            </Item>
                          )
                        }))
                        : (
                          <Item className={styles.searchesPlaceholder}>
                            {intl.formatMessage({ id: 'engineSearch.save.empty' })}
                          </Item>
                        )
                      }
                    </Scrollbars>
                    <span className={styles.separator} />
                    <Item
                      className={styles.searchesItem}
                      onClick={handleClickOpenSearches}
                    >
                      {intl.formatMessage({ id: 'engineSearch.save.all' })}
                    </Item>
                  </div>
️                </animated.div>
              ) : null
            })}
          </div>
        </ClickAwayListener>
        {displaySearchButton && (
          <button
            type="button"
            className="kol-search-bar-button-search"
            disabled={disabled}
            onClick={addTag}
          >
            <Icon label='search' theme='solid' width={20} fill="#001b3e" />
          </button>
        )}
      </div>
    )
  };

  return (
    <div className="kol-search-bar" ref={myRef}>
      {renderSearchBar()}
      <Collapse in={Boolean(error)} timeout="auto" unmountOnExit>
        <span className="kol-search-bar_error">
          {error && errorMessage}
        </span>
      </Collapse>
    </div>
  )
}

export default memo(SearchBar);
