import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { Collapse } from '@mui/material';

import Typography from 'components/ui/Typography';

import styles from './Header.module.scss';


const Header = ({ title, subTitle, className, collapse, bottomMargin, style }) => {
  const cnHeader = cn(className, styles.header, {
    [styles.collapse]: collapse,
  });

  const sxCollapse = useMemo(
    () => ({
      marginBottom: bottomMargin ?? '16px'
    }),
    [bottomMargin]
  );

  return (
    <>
      <Collapse
        sx={sxCollapse}
        in={!collapse}
        timeout="auto"
      >
        <div className={cnHeader} style={style}>
          {typeof title === 'string' ? (
            <Typography tag="h1" variant="title/page-title">
              {title}
            </Typography>
          ) : (
            title
          )}
          {typeof subTitle === 'string' ? (
            <Typography variant="title/subtitle">
              {subTitle}
            </Typography>
          ) : (
            subTitle
          )}
        </div>
      </Collapse>
    </>
  );
}

export default memo(Header);
