import React, {
  forwardRef,
  memo,
  PropsWithoutRef,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import cn from 'classnames';

import { Loader } from 'kolkit';

import Icon from '../Icon';
import Footer from './Footer2';
import ModalFooter from './ModalFooter2';

import styles from './Modal2.module.scss';

const Transition = React.forwardRef(
  (props: PropsWithoutRef<{ children: React.ReactElement }>, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
  },
);

Transition.displayName = 'Transition';

type OnClickEventHandler = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  reason?: 'escapeKeyDown' | 'backdropClick',
) => void;

interface Modal2Action {
  onClick: () => void;
  loading?: boolean;
  title: string;
  tooltip?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: string;
  isAlignRight?: boolean;
  dataId?: string;
}

interface Modal2Actions {
  primary?: Modal2Action;
  secondary?: Modal2Action;
  tertiary?: Modal2Action;
}

type Props = {
  title?: ReactElement;
  subTitle?: string;
  on: boolean;
  onClick?: OnClickEventHandler;
  TransitionProps?: {
    onEnter?: () => void;
    onEntered?: () => void;
    onEntering?: () => void;
    onExit?: () => void;
    onExited?: () => void;
    onExiting?: () => void;
  };
  children: React.ReactNode;
  loading?: boolean;
  action?: Modal2Actions;
  footerHint?: React.ReactNode;
  headerActions?: React.ReactNode;
  size?: 'default' | 'large' | 'big';
  isAlert?: boolean;
  disableBackdropClick?: false;
  disableEscapeKeyDown?: false;
  className?: string;
  fullScreen?: boolean;
  fullHeight?: boolean;
  modalFooter?: React.ReactElement;
  width?: 'default' | 'auto';
  classNameSubtitle?: string;
  noScroll?: boolean;
  dataId?: string;
} & { children: [React.ReactElement, React.ReactElement] };

const SideContentModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      on,
      title,
      onClick,
      action,
      className,
      fullScreen,
      loading,
      disableBackdropClick,
      fullHeight,
      disableEscapeKeyDown,
      modalFooter,
      footerHint,
      headerActions,
      width = 'auto',
      subTitle,
      classNameSubtitle,
      noScroll,
      size = 'default',
      isAlert,
      dataId,
      ...rest
    },
    ref,
  ) => {
    const cnSubtitle = cn(styles.subTitle, classNameSubtitle);

    const handleClick: OnClickEventHandler = useCallback(
      (e, reason) => {
        if ((isAlert || disableBackdropClick) && reason === 'backdropClick')
          return null;
        if (onClick) onClick(e, reason); // reason: "escapeKeyDown", "backdropClick"
      },
      [onClick, isAlert, disableBackdropClick],
    );

    const renderHeader = useMemo(() => {
      return (
        <div className={styles.header}>
          <div className={styles.titleWithActions}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {headerActions}
          </div>
          {subTitle && <h3 className={cnSubtitle}>{subTitle}</h3>}
        </div>
      );
    }, [title, headerActions, subTitle, cnSubtitle]);

    const renderFooter = useMemo(
      () => action && <Footer action={action} hint={footerHint} />,
      [action, footerHint],
    );

    const renderModalFooter = useMemo(
      () => modalFooter && <ModalFooter>{modalFooter}</ModalFooter>,
      [modalFooter],
    );

    const cnDialog = cn(styles.dialog, styles[size]);

    const cnModal = cn(
      styles.modal,
      styles.sideContent,
      styles[width],
      className,
      {
        [styles.fullScreen]: fullScreen,
        [styles.fullHeight]: fullHeight,
        [styles.noScroll]: noScroll,
        [styles.noTitle]: !title,
        [styles.noAction]: !action,
      },
    );

    return (
      <Dialog
        classes={{
          root: cnDialog,
          paper: styles.paper,
        }}
        onClose={handleClick}
        open={on}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        disableEscapeKeyDown={!isAlert ? disableEscapeKeyDown : true}
        {...rest}
        ref={ref}
        maxWidth="xl"
      >
        <div className={cnModal}>
          <Icon
            label="times"
            theme="regular"
            size="huge"
            className={styles.icon}
            onClick={handleClick}
            fill="var(--color-dominant-on-dominant)"
            isButton
            dataId={dataId ? `${dataId}-close` : undefined}
          />
          {loading && <Loader full />}
          <div className="flex ais">
            <div className="fg2 fb0 fs1">
              {children[0]}
            </div>
            <div className={`fg2 fb0 fs1 ${styles.body}`}>
              <div className={styles.content}>
                {renderHeader}
                {children[1]}
              </div>
              {renderFooter}
              {renderModalFooter}
            </div>
          </div>
        </div>
      </Dialog>
    );
  },
);

SideContentModal.displayName = 'SideContentModal';

// For storybook
export const NotMemoizedSideContentModal = SideContentModal;

export default memo(SideContentModal);
