import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';
import { FilePond, FilePondProps } from 'react-filepond';

import { Icon, Li, Modal2, Select2 } from 'kolkit';

import { DELIVERY_NETWORKS } from 'constants/networks';
import { useSelector, useDispatch } from 'utils/redux';
import useModal from 'utils/hooks/useModal';
import toast from 'utils/toast';
import {
  CHUNK_UPLOAD_API,
  uploadContentVersion,
  updateContentRequestStatus,
  ContentRequest,
  updateUploadInProgress,
} from 'slices/contentValidation.slice';

import Typography from 'components/ui/Typography';
import Textarea2 from 'components/ui/Textarea2';
import UploadMultiPartDropZone from 'components/ui/UploadMultiPartDropZone';

export const MODAL_ID = 'UploadContentVersionModal';

const UploadContentVersionModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const uploadMultiPartDropZoneRef = useRef<FilePond>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    show,
    on,
    off,
    hide,
    data: { profileId, campaignId },
  } = useModal<{ profileId: number; campaignId: number }>(MODAL_ID);
  const [contentRequestId, setContentRequestId] = useState('');
  const [caption, setCaption] = useState('');
  const [files, setFiles] = useState<FilePondProps['files']>([]);

  const { contentRequests, isUploadInProgress } = useSelector(
    ({
      views: {
        campaignDetails: { id: campaignId },
      },
      contentValidation: { contentRequestsByCampaign, isUploadInProgress },
    }) => {
      return {
        contentRequests: campaignId
          ? contentRequestsByCampaign[campaignId]?.[profileId]
          : [],
        isUploadInProgress,
      };
    },
  );

  const clearData = useCallback(() => {
    setContentRequestId('');
    setCaption('');
    setFiles([]);

    if (uploadMultiPartDropZoneRef.current) {
      uploadMultiPartDropZoneRef.current.removeFiles();
    }
  }, []);

  const handleUpload = useCallback(() => {
    if (uploadMultiPartDropZoneRef.current && campaignId) {
      // Show toast message only if there are video files
      if (
        uploadMultiPartDropZoneRef.current
          .getFiles()
          .some((file) => file.fileType.includes('video'))
      ) {
        toast(
          intl.formatMessage({ id: 'global.toast.uploadContent.startingSoon' }),
          {
            info: 'info',
          },
        );
      }
      hide();
      dispatch(updateUploadInProgress(true));
      void uploadMultiPartDropZoneRef.current
        .processFiles()
        .then(async () => {
          const allFiles = uploadMultiPartDropZoneRef.current?.getFiles() || [];
          await dispatch(
            uploadContentVersion({
              profile_id: profileId,
              project_id: campaignId,
              caption,
              content_request_id: Number(contentRequestId),
              upload_ids: allFiles.map((file) => String(file.serverId)),
            }),
          );
          // Clear data if no error
          clearData();

          const contenRequestData = contentRequests?.find(
            ({ id }) => Number(contentRequestId) === Number(id),
          );
          // After uploaded a first version of a content, the status should change automatically to ‘To approved’
          if (contenRequestData && !contenRequestData?.last_version) {
            await dispatch(
              updateContentRequestStatus({
                project_id: campaignId,
                profile_id: profileId,
                requestId: Number(contentRequestId),
                oldStatus: contenRequestData?.content_status,
                newStatus: 'to_be_approved',
              }),
            );
          }
        })
        .catch((error) => {
          console.error(error);
          on({ profileId, campaignId });
        })
        .finally(() => {
          dispatch(updateUploadInProgress(false));
        });
    }
  }, [
    campaignId,
    contentRequestId,
    profileId,
    caption,
    contentRequests,
    intl,
    clearData,
    dispatch,
    hide,
    on,
  ]);

  const handleClose = useCallback(() => {
    off();
  }, [off]);

  const handleSelectRequest = useCallback(
    (selected) => setContentRequestId(String(selected.value)),
    [],
  );

  const contentRequestsDataset = useMemo(() => {
    const requests = DELIVERY_NETWORKS.reduce(
      (prev, { network, mediaType }) => {
        const requests = (
          contentRequests?.filter(
            (request) =>
              request.network === network && request.content_type === mediaType,
          ) || []
        ).sort((a, b) => a.id - b.id);

        return [...prev, ...requests];
      },
      [] as ContentRequest[],
    );

    return (
      requests.map(({ id, content_title, network }) => ({
        label: content_title,
        value: String(id),
        icon: `${network}-color`,
      })) || []
    );
  }, [contentRequests]);

  const uploadExtraData = useMemo(
    () =>
      profileId
        ? {
            public_profile_id: String(profileId),
          }
        : undefined,
    [profileId],
  );

  const renderRequestItem = useCallback(
    (item: { label: string; value: string; icon: string }) => {
      return (
        <Li className="flex aic gap8">
          <Icon label={item.icon} theme="brand" size="small" className="ml8" />
          <Typography
            variant="label/default"
            label={item.label}
            className="mr8"
          />
        </Li>
      );
    },
    [],
  );

  const action = useMemo(() => {
    return {
      primary: {
        title: intl.formatMessage({ id: 'global.cta.uploadNewVersion' }),
        onClick: handleUpload,
        disabled: !contentRequestId || files?.length === 0,
        dataId: 'CA-CTA-contentValidation-uploadNewVersion-addVersion',
      },
      secondary: {
        title: intl.formatMessage({ id: 'global.cta.cancel' }),
        onClick: handleClose,
      },
    };
  }, [contentRequestId, files, handleClose, handleUpload, intl]);

  return (
    <Modal2
      title={intl.formatMessage({
        id: 'profileDetails.profileInfos.uploadContent.modal.title',
      })}
      on={show}
      action={action}
      onClick={handleClose}
      // Keep it mounted to allow UploadMultiPartDropZone to do its job in the background
      keepMounted
      id="UploadContentVersionModal"
    >
      <div className="flex fdc gap8 ais" ref={modalRef}>
        <Typography variant="text/body large">
          <FormattedMessage id="profileDetails.profileInfos.uploadContent.modal.file.label" />
        </Typography>
        {campaignId && (
          <UploadMultiPartDropZone
            ref={uploadMultiPartDropZoneRef}
            asButton
            allowMultiple
            processURL={CHUNK_UPLOAD_API(campaignId)}
            label={intl.formatMessage({
              id: 'profileDetails.profileInfos.uploadContent.modal.file.uploadFile',
            })}
            files={files}
            appendData={uploadExtraData}
            onUpdateFiles={(files) =>
              setFiles(files as unknown as FilePondProps['files'])
            }
            disabled={isUploadInProgress}
            // processURL={CHUNK_UPLOAD_API(94837)}
            // forceToken="eyJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjU0MjEzLCJtaWQiOm51bGwsInQiOiIyMDI0MDcyOTA5NTc0OSJ9.3MRs1RhpvkFirEtoaNgmkWF3c7iqL028jGyZuUl_k4M"
            // forceUrl="https://merry-turtle-obviously.ngrok-free.app"
            // appendData={{ public_profile_id: '1952675' }}
          />
        )}

        <Typography variant="text/body large">
          <FormattedMessage id="profileDetails.profileInfos.uploadContent.modal.typeOfPublication.label" />
        </Typography>
        <Select2
          options={contentRequestsDataset}
          selected={contentRequestId}
          renderItem={renderRequestItem}
          onSelectItem={handleSelectRequest}
          fullWidth
          size="large"
          placeholder=" "
          disabled={isUploadInProgress}
          dataId="CA-SELECT-contentValidation- uploadNewVersion-contentType"
          container={modalRef.current || undefined}
        />

        <Typography variant="text/body large">
          <FormattedMessage id="profileDetails.profileInfos.uploadContent.modal.caption.label" />
        </Typography>
        <Textarea2
          id="profile-content-version-upload"
          name="caption"
          value={caption}
          onChange={setCaption}
          rows={5}
          autoFocus
          autoGrowing
          fullWidth
          disabled={isUploadInProgress}
        />
      </div>
    </Modal2>
  );
};

UploadContentVersionModal.displayName = 'UploadContentVersionModal';

// INFO: Do not use below code, we need this to retain last state on modal close, in case we need to open it again.
// export default renderModal(MODAL_ID, UploadContentVersionModal);
export default UploadContentVersionModal;
