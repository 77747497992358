import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Field, SelectPercent } from 'components';
import { configFilters } from 'config/filters';
import { useFilter } from 'components/engine/hooks';


const CommunityLanguages = ({ network, onChange, disabled }) => {
  const filterTarget = 'communityLanguages';
  const intl = useIntl();
  const { datas, params, defaultPercent } = configFilters[filterTarget];
  const value = useFilter(filterTarget, network);

  const handleChange = useCallback(
    ({ item, selected }) => onChange({
      field: filterTarget,
      value: selected,
      selected: item,
      network,
    }),
    [onChange, network, filterTarget]
  );

  const getData = useMemo(
    () => {
      return datas.map(data => {
        const translatedLabel = intl.formatDisplayName(data, {type: "language"});
        return {
          id : data,
          value: data,
          label: translatedLabel
          ? translatedLabel.charAt(0).toUpperCase() + translatedLabel.slice(1)
          : data,
        }
      })
    },
    [datas, intl]
  );

  return (
    <Field label={intl.formatMessage(
      { id: "audienceLanguages.label"},
      { count: params.max}
    )}>
      <SelectPercent
        id="filter-communityLanguages"
        selected={value}
        dataset={getData}
        defaultPercent={defaultPercent}
        pinned={params.pinned}
        sort
        label={intl.formatMessage(
          {id: "languages.filterBy"},
          {max: params.max}
        )}
        onChange={handleChange}
        disabled={disabled}
        max={params.max}
        noMatchPlaceholder={intl.formatMessage({ id: "languages.noMatching"})}
        inPortal
      />
    </Field>
  )
};

CommunityLanguages.displayName = 'CommunityLanguages';

CommunityLanguages.propTypes = {
  network: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CommunityLanguages.defaultProps = {
  disabled: false,
};

export default CommunityLanguages;
