import _uniq from 'lodash.uniq';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import {celebrityNameFormatter} from 'utils';
import toast from 'utils/toast';
import { planhatTraker } from 'utils/tags';

import * as profilesActions from './profiles';
import * as viewsActions from "./views";
import * as api from '../api/favorites'
import * as actionsTags from './tags';
import * as actionsCampaigns from './campaigns';

export const addProfileToFavorite = ({ id }) => async (dispatch, getState) => {
	const { profiles, views, profile } = getState();

	const obj = {
		id,
		core: {
			isFavorite: true,
		}
	}

	await api.addNewFavorite(id);

	await planhatTraker({
		action: 'add_profiles_to_favorite',
	})

	dispatch(profilesActions.update(obj));

	const intl = getIntl();
	const getName = views.profileDetails.show
		? profile.display_name
		: celebrityNameFormatter(profiles?.[id]?.core)?.trim()

	toast(
        intl.formatMessage(
			{ id: "toasts.profilesAddedToFavorite" },
			{
				count: 1,
				name: getName
			}
		),
        {
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
        },
	);
}

export const addProfilesToFavorite = ({ ids, from }) => async (dispatch) => {
	const manyProfiles = ids?.length > 1;

	if (!manyProfiles) {
		await dispatch(addProfileToFavorite({ id: ids[0], from }))
		return true;
	}

	const profiles = ids.map(id => ({
		id,
		core: {
			isFavorite: true,
		}
	}))

	await api.addProfilesToFavorite(ids);
	dispatch(profilesActions.batch(profiles));

	const intl = getIntl();
	toast(
        intl.formatMessage(
			{ id: "toasts.profilesAddedToFavorite" },
			{ count: ids?.length }
		),
        {
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
        },
	);
}

export const removeProfileFromFavorite = ({ id }) => async (dispatch, getState) => {
	const { profiles } = getState();
	const profile = profiles[id];

	await api.removeFavorite({ id });
	await planhatTraker({
		action: 'remove_profiles_from_favorite',
	})

	// Update profile
	dispatch(profilesActions.update({
		id,
		core: {
			isFavorite: false,
		}
	}));

	const intl = getIntl();
	toast(
        intl.formatMessage(
			{ id: "toasts.removeProfilesFromFavorite" },
			{
				count: 1,
				name: celebrityNameFormatter(profile.core)?.trim()
			}
		),
        {
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
        },
	);
}

export const removeProfilesFromFavorite = ({ ids, from }) => async (dispatch) => {
	const manyProfiles = ids?.length > 1;

	if (!manyProfiles) {
		await dispatch(removeProfileFromFavorite({ id: ids[0], from }))
		return true;
	}

	const profiles = ids.map(id => ({
		id,
		core: {
			isFavorite: false,
		}
	}))

	await api.removeFavorites(ids);
	dispatch(profilesActions.batch(profiles));

	const intl = getIntl();
	toast(
        intl.formatMessage(
			{ id: "toasts.removeProfilesFromFavorite" },
			{ count: ids?.length }
		),
        {
			type: 'success',
			title: intl.formatMessage({ id: 'global.success' }),
        },
	);
}

export const selectProfiles = profilesIds => (dispatch, getState) => {
	const { favorites: { selectedProfiles }} = getState().views;

	dispatch(viewsActions.update({
		view: 'favorites',
		selectedProfiles: _uniq([...selectedProfiles, ...profilesIds])
	}))
}

export const unselectProfiles = profilesIds => (dispatch, getState) => {
	const { favorites: { selectedProfiles }} = getState().views;

	dispatch(viewsActions.update({
		view: 'favorites',
		selectedProfiles: selectedProfiles.filter(id => !profilesIds.includes(id))
	}))
}

export const updateFavoritesTags = tagsIds => (dispatch, getState) => {
	const { favorites: {selectedProfiles }} = getState().views;

	dispatch(actionsTags.updateProfilesTags({
		from: 'favorites',
		profileIds: selectedProfiles,
		tagsIds,
	}))
}

export const updateFavoritesCampaign = campaignId => (dispatch, getState) => {
	const { favorites: {selectedProfiles }} = getState().views;

	dispatch(actionsCampaigns.addProfilesToCampaigns({
		from: 'favorites',
		profileIds: selectedProfiles,
		campaignIds: [campaignId],
	}))
}
