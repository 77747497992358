import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl-phraseapp';
import { Checkbox2 } from 'kolkit';
import { Wrapper } from 'components';

import ActionButtons from './ActionButtons';
import { ActionButtonsPropTypes } from './SharedPropTypes';

import styles from './ProfileListMultiAction.module.scss';

const ProfileListMultiAction = ({
  actions,
  checkboxId,
  enableAutoDeselection,
  onSelectAllChange,
  profileIds,
  selectedProfiles,
  theme,
}) => {
  const intl = useIntl();

  const { checked, halfchecked, disabled } = useMemo(
    () => ({
      checked: !!profileIds?.length && !!selectedProfiles?.length,
      halfchecked: !!(
        profileIds?.length &&
        selectedProfiles?.length &&
        profileIds?.length > selectedProfiles?.length),
      disabled: !profileIds?.length,
    }),
    [profileIds, selectedProfiles],
  );

  const handleSelectionChange = useCallback(
    () => {
      const selectAll = !(checked || halfchecked);
      onSelectAllChange(selectAll);
    },
    [onSelectAllChange, checked, halfchecked]
  );

  const handleActionDone = useCallback(
    () => {
      if (enableAutoDeselection) {
        onSelectAllChange(false);
      }
    },
    [onSelectAllChange, enableAutoDeselection]
  );

  const displayFullActions = useMemo(
    () => checked && (
      <ActionButtons
        actions={actions}
        selectedProfiles={selectedProfiles}
        onActionDone={handleActionDone}
      />
    ),
    [checked, actions, selectedProfiles, handleActionDone]
  );

  return (
    <Wrapper className={styles.ProfileListMultiActionWrapper}>
      <Checkbox2
        name={`ProfileListMultiAction-${checkboxId}`}
        id={checkboxId}
        className={styles.checkbox}
        label={!checked && intl.formatMessage({id: 'global.selectAll'})}
        halfchecked={halfchecked}
        checked={checked}
        onChange={handleSelectionChange}
        disabled={disabled}
        theme={theme}
      />
      {displayFullActions}
    </Wrapper>
  );
};

ProfileListMultiAction.displayName = 'ProfileListMultiAction';

ProfileListMultiAction.propTypes = {
  checkboxId: PropTypes.string,
  profileIds: PropTypes.arrayOf(PropTypes.string),
  onSelectAllChange: PropTypes.func,
  selectedProfiles: ActionButtonsPropTypes.selectedProfiles,
  actions: ActionButtonsPropTypes.actions,
  enableAutoDeselection: PropTypes.bool,
};

ProfileListMultiAction.defaultProps = {
  checkboxId: 'multiSelect',
  profileIds: [],
  selectedProfiles: [],
  onSelectAllChange: () => {},
  actions: [],
  enableAutoDeselection: false,
};

export default ProfileListMultiAction;
