import React, { memo, useMemo, useCallback, useState, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl-phraseapp';
import { Button3, Checkbox2, Tooltip } from 'kolkit';
import Icon from 'kolkit/Icon';

import { updateSelected } from 'actions/engine';
import routes from 'config/routes';
import { celebrityNameFormatter, getColorByNetwork, formatUrl } from 'utils';
import { useSelector, useDispatch } from 'utils/redux';
import useActivitiesIntl from 'utils/hooks/useActivitiesIntl';

import { TruncateContent } from 'components/ui';
import { AddToCampaign, AddToList } from 'components/profileController';
import NumberFormat from 'components/ui/NumberFormat';
import Avatar from 'components/ui/Avatar';
import QualityScore from 'components/ui/QualityScore';
import SocialIcon from 'components/ui/SocialIcon';
import ProfileActions from 'components/ui/ProfileActions';

import styles from './style/Card.module.scss';

const SNAS_ORDER = ['instagram', 'youtube', 'tiktok', 'facebook', 'twitter'];
const SLIDE_WIDTH = 190;
const SNAS_PER_SLIDE = 4;
const SNA_WIDTH = SLIDE_WIDTH / SNAS_PER_SLIDE;
const QUALITY_SCORE_RADIUS = 40;

const newTabLinkProps = { target: "_blank", rel: "noopener noreferrer" }

export const CardActions = memo(({ id, omitActions, isChecked, show }) => {
  const dispatch = useDispatch();

  const selectCheckbox = useCallback(
    () => dispatch(updateSelected(id)),
    [dispatch, id]
  );

  const renderCheckbox = useMemo(
    () => (
      <Checkbox2
        id={id}
        name={id}
        checked={isChecked}
        onChange={selectCheckbox}
      />
    ),
    [id, isChecked, selectCheckbox]
  );

  const profileDataset = useMemo(() => [id], [id]);

  const cnCardAction = cn(styles.action, {
    [styles.actionOn]: show
  })

  if (omitActions || !id) return <div className={styles.action} />;

  return (
    <div className={cnCardAction}>
      {renderCheckbox}
      <div className={styles.right}>
        <ProfileActions
          from="engine-card"
          profileId={id}
          position="right"
          iconSize="small"
        />
        {/* TODO: move AddToCampaign and AddToList components to ProfileActions */}
        <AddToCampaign
          ids={profileDataset}
          from="engine-card"
          noLabel
          icon
          iconProps={{
            size: 'small'
          }}
        />
        <AddToList
          profileIds={profileDataset}
          iconProps={{ size: 'small' }}
          icon
        />
      </div>
    </div>
  );
});

export const CardStats = memo(({ qualityScore, arpp, werberat_certified, influencerregels_certified }) => {
  const intl = useIntl();

  const arppStyle = useMemo(
    () => ({ marginBottom: 12, width: 32 }),
    [],
  );

  const cnStats = cn(styles.stats, styles.flexCol);

  return (
    <div className={cnStats}>
      {influencerregels_certified && (
        <Tooltip
          content={intl.formatMessage({ id: 'influencerregels_certified.tooltip' })}
        >
          <img
            src="/imgs/influencerregels_certified/regels_certified.png"
            alt="influencerregels_certified"
            style={arppStyle}
          />
        </Tooltip>
      )}
      {werberat_certified && (
        <Tooltip
          content={intl.formatMessage({ id: 'werberat_certified.tooltip' })}
        >
          <img
            src="/imgs/werberat_certified/LogoDW.png"
            alt="werberat_certified"
            style={arppStyle}
          />
        </Tooltip>
      )}
      {arpp && (
        <Tooltip content={intl.formatMessage({ id: 'arpp.tooltip' })}>
          <img src="/imgs/arpp/arrp_small.jpeg" alt="arpp" style={arppStyle} />
        </Tooltip>
      )}
      <Tooltip
        content={intl.formatMessage({
          id: 'search.results.card.quality_score.tooltip',
        })}
      >
        <div>
          <QualityScore
            qualityScore={qualityScore}
            radiusSize={QUALITY_SCORE_RADIUS}
          />
        </div>
      </Tooltip>
    </div>
  );
});

export const CardActivity = memo(({ gender, activities, influencerActivities, show }) => {
  const activitiesIntl = useActivitiesIntl();
  const cnCardUnhovered = cn(styles.unhovered, {
    [styles.show]: show
  });

  const activity = useMemo(
    () => {
      const match = activities?.find(activity => influencerActivities?.indexOf(activity) > -1);

      // if there are no activity filters it's useless to go further => display the first one in the list
      if (influencerActivities?.length === 0)
        return activitiesIntl.formatMessage({ id: activities?.[0], gender: gender || "male" });
      if (match)
        return activitiesIntl.formatMessage({ id: match, gender: gender || "male" });

      return activitiesIntl.formatMessage({ id: activities?.[0], gender: gender || "male" });
    },
    [activitiesIntl, gender, activities, influencerActivities]
  );

  return (
    <div className={cnCardUnhovered}>
      <div className={styles.activity}>
        {activity}
      </div>
    </div>
  );
});

export const CardCommunity = memo(({ snasByNetworks, snas, matchingSnas }) => {
  const [{ x }, set] = useSpring(() => ({ x: 0 }));

  const currentSna = useRef(0);

  const slides = useMemo(
    () => Math.ceil(Object.keys(snas).length / 4),
    [snas]
  );

  const bind = useDrag(
    ({ down, delta: [mx] }) => {
      // we check if mx <= 0 => if mx is positive there is no sna to display
      // also, mx should'nt be pushed further than the last slide
      if (slides > 1 && down && mx <= 0 && mx >= - SNA_WIDTH * (Object.keys(snas).length - 1)) {
        currentSna.current = Math.round(-mx / SNA_WIDTH);
        set(() => ({ x: mx }))
      }
    }
  );

  const renderSna = useCallback(
    id => {
      const sna = snas[id];
      // Meaning: If matchingSnas is undefined, all snas are active
      const activated = !matchingSnas || matchingSnas?.includes(sna.id);
      const color = activated ? getColorByNetwork(sna.network) : '#cccccc';

      return (
        <div
          key={`${id}-${sna.network}`}
          className={cn(styles.flexCol, styles.communitySna, {
            [styles.deactivated]: !activated
          })}
        >
          <SocialIcon label={sna.network} color={color} />
          <div className={styles.communityCount}>
            <NumberFormat value={sna.stats.communityCount} threeDigitsRule />
          </div>
          <div className={styles.communityCommitment}>
            <NumberFormat value={sna.stats.commitmentPerMediaPercent} percentage />
          </div>
        </div>
      )
    }, [snas, matchingSnas]
  );

  const moveForward = useCallback(
    () => {
      if (currentSna.current < Object.keys(snas).length - SNAS_PER_SLIDE)
        currentSna.current += 1;
      set(() => ({ x: -(currentSna.current) * SNA_WIDTH }))
    }, [set, snas]
  );

  const transform = useMemo(() => x?.interpolate(x => `translateX(${x}px)`), [x]);

  const className = cn(
    styles.communitySliderWrapper, {
    [styles.gradient]: snas && Object.keys(snas).length > 4
  });

  return (
    <div className={cn(styles.flexRow, styles.communityWrapper)}>
      <div className={className}>
        <animated.div
          {...bind()}
          className={cn(styles.communitySlider, styles.flexRow)}
          style={{ transform }}
        >
         {SNAS_ORDER?.map(network => snasByNetworks?.[network]?.ids?.map(renderSna))}
        </animated.div>
      </div>
      {slides > 1 && (
        <button onClick={moveForward} className={cn(styles.communityButton, 'no-btn')} >
          <Icon label='chevron-right' theme='thin' width={7} />
        </button>
      )}
    </div>
  )
});

export const CardToProfile = memo(({ onClick, show, to, openInNewTab = false }) => {
  const intl = useIntl();
  const cnCardToProfile = cn(styles.toProfile, {
    [styles.show]: show
  });

  return (
    <div className={cnCardToProfile}>
      <Link to={to} onClick={onClick} {...(openInNewTab ? newTabLinkProps : {})}>
        <Button3
          className={styles.button}
          label={intl.formatMessage({ id: 'global.cta.seeProfile' })}
          theme="tertiary"
          size="small"
          fullWidth
        />
      </Link>
    </div>
  )
});

const Card = ({ className, id, onClickProfile, onClickArgs, matchingSnas, isChecked, disableActions, openInNewTab }) => {
  const [isHovered, setHover] = useState(false);

  const [
    profileCore,
    snas,
    snasByNetworks,
    userIsAdmin,
    influencerActivities,
    influencerregels_certified,
    werberat_certified,
    arpp,
  ] = useSelector(({ profiles, user }) => {
    return [
      profiles?.[id]?.core || {},
      profiles?.[id]?.snas || [],
      profiles?.[id]?.sortedSnas.byNetworks,
      user?.profile && user.profile.admin,
      user?.filters.activities,
      profiles?.[id]?.core.influencerregels_certified,
      profiles?.[id]?.core.werberat_certified,
      profiles?.[id]?.core.arpp_certified,
    ];
  });

  const handleClick = useCallback(
    () => onClickProfile && onClickProfile(onClickArgs),
    [onClickProfile, onClickArgs]
  );

  const getProfileUrl = useMemo(
    () => formatUrl(`/${routes.profile}/${id}`),
    [id]
  );

  const handleMouseEnter = useCallback(() => setHover(true), []);
  const handleMouseLeave = useCallback(() => setHover(false), []);

  return (
    <div className={cn(styles.card, className, { checked: isChecked })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardActions
        omitActions={userIsAdmin || disableActions}
        id={id}
        isChecked={isChecked}
        show={!disableActions && (isHovered || isChecked)}
      />
      <div className={styles.flexRow}>
        <Link to={getProfileUrl} onClick={handleClick} {...(openInNewTab ? newTabLinkProps : {})}>
          <Avatar
            image={profileCore.picture}
            fallback={profileCore.picture_fallback}
            className={styles.picture}
            form="circle"
            customSize="100px"
            noGap
          />
        </Link>
        <CardStats
          qualityScore={profileCore.qualityScore}
          arpp={arpp}
          werberat_certified={werberat_certified}
          influencerregels_certified={influencerregels_certified}
        />
      </div>
      <div className={styles.name} translate="no">
        <TruncateContent>
          {celebrityNameFormatter(profileCore)}
        </TruncateContent>
      </div>
      <div className={styles.hoverWrapper}>
        <CardActivity
          gender={profileCore.gender}
          activities={profileCore.activities}
          influencerActivities={influencerActivities}
          show={!isHovered}
        />
        <CardToProfile
          to={getProfileUrl}
          onClick={handleClick}
          show={isHovered}
          openInNewTab={openInNewTab}
        />
      </div>
      <CardCommunity
        matchingSnas={matchingSnas}
        snasByNetworks={snasByNetworks}
        snas={snas}
      />
    </div>
  )
};

Card.protoTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  onClickProfile: PropTypes.func,
  onClickArgs: PropTypes.func,
  matchingSnas: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  ),
  isChecked: PropTypes.bool,
  disableActions: PropTypes.bool,
  openInNewTab: PropTypes.bool,
};

Card.defaultProps = {
  className: undefined,
  onClickProfile: undefined,
  onClickArgs: undefined,
  matchingSnas: undefined,
  isChecked: false,
  disableActions: false,
  openInNewTab: false,
};

export default Card;
