import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useSelector } from 'utils/redux';
import { USER_ROLES } from 'constants/userRoles';
import Tile from 'components/ui/Tile';

import ChooseLicences from './ChooseLicences';
import ListMembers from './ListMembers';

import styles from './Organization.module.scss';

const Organization = () => {
  const user = useSelector(({ user }) => user?.profile?.userRole);

  const isSuperUser = useMemo(
    () => user === USER_ROLES.SUPER_USER || user === USER_ROLES.MANAGER,
    [user]
  );

  return (
    <>
      {isSuperUser && (
        <Tile className={styles.tile} noPadding>
          <div className={styles.tableHeader}>
            <h3 className={styles.title}>
              <FormattedMessage id="settings.organization.licenses.title" />
            </h3>
          </div>
          <ChooseLicences />
        </Tile>
      )}
      <Tile className={styles.tile} noPadding>
        <ListMembers />
      </Tile>
    </>
  );
};

export default Organization;
