import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { WrapperFilter } from '../Components';
import {
  Activities,
  Arpp,
  Covid,
  Credibility,
  ExcludeFavorite,
  ExcludeTag,
  Genders,
  Languages,
  LocalisationsGmap,
  SeedingKol,
  Status,
  Theme,
} from '../Modules';

const Influencer = ({ onChange }) => {
  const network = 'influencer';

  return (
    <WrapperFilter
      title="KOL"
      subTitle="(Key Opinion Leader)"
      isActive
    >
      <div>
        <Covid
          onChange={onChange}
          network={network}
        />
        <Status
          onChange={onChange}
          network={network}
        />
        <SeedingKol
          onChange={onChange}
          network={network}
        />
        <ExcludeFavorite
          onChange={onChange}
          network={network}
        />
       <Arpp
          onChange={onChange}
          network={network}
        />
        <ExcludeTag
          onChange={onChange}
          network={network}
        />
      </div>
      <div>
        <Theme
          onChange={onChange}
          network={network}
          max={3}
        />
        <Activities
          onChange={onChange}
          network={network}
          max={3}
        />
        <Credibility
          onChange={onChange}
          network={network}
        />
      </div>
      <div>
        <Languages
          onChange={onChange}
          network={network}
          max={3}
        />
        <LocalisationsGmap
          onChange={onChange}
          network={network}
          max={3}
        />
        <Genders
          onChange={onChange}
          network={network}
        />
      </div>
    </WrapperFilter>
  )
};

Influencer.displayName = 'Influencer';

Influencer.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default memo(Influencer);
